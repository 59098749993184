//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TableEmptyData from '@/components/Shared/Table/TableEmptyData'
import { matchStringIgnoreCase } from '~/util/string'
export default {
  name: 'SkillPackTable',
  components: { TableEmptyData },
  props: {
    skillsPacks: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      filterData: [],
    }
  },
  computed: {
    hasSkillsPacks() {
      return this.filterData.length > 0
    },
  },
  created() {
    if (this.$props.skillsPacks) this.filterData = this.skillsPacks
  },
  methods: {
    searchSkillPack(query) {
      if (!!query === false) {
        this.filterData = this.skillsPacks
        return
      }

      this.filterData = this.skillsPacks.filter(({ name }) =>
        matchStringIgnoreCase(name, query)
      )
    },
  },
}
