//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Multiselect from 'vue-multiselect'
import ModalWithTitleBar from '~/components/Shared/ModalWithTitleBar'
import ReminderSuccess from '~/components/SkillsInventory/modals/ReminderSuccess.vue'
import { MESSAGE_TYPE } from '~/constants/core'

export default {
  components: { ModalWithTitleBar, ReminderSuccess, Multiselect },
  props: {
    value: {
      type: Boolean,
      default: true,
    },
    defaultProject: {
      type: Number,
      default: null,
    },
    projects: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      form: {
        message: '',
      },
      active: this.value,
      confirmedModal: false,
      isLoading: false,
      project: null,
    }
  },
  watch: {
    value() {
      this.active = this.value
    },
    defaultProject() {
      if (this.defaultProject) {
        this.project = this.projects.find(
          (project) => project.id === this.defaultProject
        )
      }
    },
  },
  methods: {
    close() {
      this.active = !this.active
      this.$emit('close')
    },
    async preview() {
      const role = 'REMINDER'
      const customMessage = this.form.message
      const companyName = this.$repository.me.getMe().company?.name

      const response = await this.$repository.company.previewReminderMail({
        role,
        customMessage,
        companyName,
      })
      window.open(response.url, '_blank')
    },
    async submit() {
      this.isLoading = true
      const body = this.getQueryInfo()
      try {
        await this.$repository.invitation.sendAllProjectInvitation(body)
      } catch (e) {
        const message = e.response.data.map(
          (e) => `
          Ocurrió un error con la invitación ${e.id} de ${
            e.email ? e.email : ''
          }: ${e.error}
        `
        )
        await this.$store.dispatch('core/showMessage', {
          message,
          type: MESSAGE_TYPE.DANGER,
        })
      }
      this.confirmedModal = true
      this.isLoading = false
      this.form.message = ''
      this.$emit('close')
    },
    getQueryInfo() {
      const body = {}
      body.custom_message = this.form.message
      body.project_id = this.project.id
      body.reminder = true
      return body
    },
  },
}
