//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { FormInput } from '@/components/Form'

const { ValidationObserver } = require('vee-validate')

export default {
  name: 'CompanyDemoForm',
  components: { FormInput, ValidationObserver },
  data: () => ({
    form: {
      company: {
        name: '',
      },
      user: {
        names: '',
        lastnames: '',
        username: '',
        role: '',
        email: '',
        repeatEmail: '',
        password: '',
        confirmPassword: '',
      },
    },
  }),
  watch: {
    'form.user.email': {
      handler(val) {
        this.form.user.username = val
      },
    },
  },
  methods: {
    closeDialog() {
      this.$emit('close')
    },
    submit() {
      this.$emit('submit', this.form)
    },
  },
}
