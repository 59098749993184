const ENTITY_TYPES = {
  EXPERIENCE_WORKS: 'experience_works',
  EXPERIENCE_EDUCATIONALS: 'experience_educationals',
  EXPERIENCE_OTHERS: 'experience_others',
  QUESTIONARIES: 'questionaries',
}

const ORIGIN_TYPES = {
  SUGGEST: 'SUGGEST',
  FREE: 'FREE',
}

export default { ENTITY_TYPES, ORIGIN_TYPES }
