//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ValidationProvider } from 'vee-validate'
import FormLabel from '@/components/Form/FormLabel'
export default {
  name: 'FormSelect',
  components: {
    FormLabel,
    ValidationProvider,
  },
  props: {
    labelField: {
      type: String,
      default: '',
    },
    vid: {
      type: String,
      default: '',
    },
    rules: {
      type: [Object, String],
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    value: {
      type: null,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    innerValue: '',
    selected: null,
  }),
  computed: {
    filteredOptions() {
      return this.options.filter((option) => {
        return option.title
          .toString()
          .toLowerCase()
          .includes(this.innerValue.toLowerCase())
      })
    },
  },
  watch: {
    selected(value) {
      this.$emit('input', value)
    },
  },
  created() {
    if (this.value) {
      this.innerValue = this.value
    }
  },
}
