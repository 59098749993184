//
//
//
//
//
//
//
//
//
//
//

import UserSkillsInventory from '@/components/Dashboard/UserSkillsInventory.vue'

export default {
  components: {
    UserSkillsInventory,
  },
  data() {
    return {
      opportunities: [
        {
          title: this.$t(
            'dashboard.employee.skillsInventory.cards.mySkillsInventory.title'
          ),
          buttonText: this.$t(
            'dashboard.employee.skillsInventory.cards.mySkillsInventory.buttonText'
          ),
          imgSource: '/img/dashboard/incomplete-skills-inventory.svg',
          link: '/mi-inventario-habilidades',
        },
        {
          title: this.$t(
            'dashboard.employee.skillsInventory.cards.moreSkills.title'
          ),
          buttonText: this.$t(
            'dashboard.employee.skillsInventory.cards.moreSkills.buttonText'
          ),
          imgSource: '/img/dashboard/incomplete-experience.svg',
          link: '/experiencias',
        },
      ],
    }
  },
}
