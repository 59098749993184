//
//
//
//
//
//
//
//
//

import DashboardSectionHeader from '~/components/Dashboard/DashboardSectionHeader'
export default {
  components: {
    DashboardSectionHeader,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
  },
}
