//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { FormDatePicker } from '@/components/Form'
import NavigationWizardExperience from '~/components/Experiences/NavigationWizardExperience.vue'

export default {
  name: 'FormExperienceLaboral',
  components: {
    FormDatePicker,
    ValidationObserver,
    NavigationWizardExperience,
    ValidationProvider,
  },
  props: {
    goBack: {
      type: Function,
      default: () => {},
    },
    currentExperience: {
      type: Object,
      default: null,
    },
    isRedirected: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      experience: this.currentExperience
        ? this.prepareExperienceToForm(this.currentExperience)
        : {
            id: null,
            detail_experience: '',
            start_date_experience: '',
            end_date_experience: '',
            present: false,
          },
    }
  },
  computed: {
    isNewExperience() {
      return !this.experience.id
    },
    prevText() {
      return !this.isNewExperience ? 'Cancelar' : 'Anterior'
    },
    nextText() {
      return !this.isNewExperience ? 'Guardar' : 'Siguiente'
    },
  },
  watch: {
    experience: {
      handler(val) {
        this.experience.end_date_experience = val.present
          ? null
          : this.experience.end_date_experience
      },
      deep: true,
    },
  },
  methods: {
    prepareExperienceToForm(experience) {
      return {
        ...experience,
        start_date_experience: new Date(experience.start_date_experience),
        end_date_experience: experience.end_date_experience
          ? new Date(experience.end_date_experience)
          : '',
      }
    },
    changeEndDateExperience() {
      if (
        this.experience.start_date_experience >
        this.experience.end_date_experience
      ) {
        this.experience.present = true
      }
    },
    prepareFormSend(experience) {
      const {
        start_date_experience: startDate,
        end_date_experience: endDate,
        detail_experience: detail,
        id,
        ...others
      } = experience
      return {
        ...others,
        user: this.$strapi.user.id,
        start_date_experience: new Date(startDate).toISOString(),
        end_date_experience: endDate ? new Date(endDate).toISOString() : null,
        detail_experience: detail,
        is_redirected: this.isRedirected,
      }
    },
    create() {
      const prepareForm = this.prepareFormSend(this.experience)
      const createData = {
        ...prepareForm,
        experienceSkills: [],
      }

      if (!createData.end_date_experience) {
        delete createData.end_date_experience
      }
      return this.$repository.experienceOther.create(createData)
    },

    update() {
      return this.$repository.experienceOther.update(
        this.experience.id,
        this.prepareFormSend(this.experience)
      )
    },
    async saveChanges() {
      this.$store.commit('loading', true)
      try {
        const experience = this.isNewExperience
          ? await this.create()
          : await this.update()

        // TODO: remove experience_skills component relation
        delete experience.experience_skills
        experience.experienceSkills = []

        this.$store.dispatch('experience/setOtherExperience', experience)
        this.$emit('next')
      } catch (e) {
        this.$buefy.notification.open({
          message:
            'Ha ocurrido un error inesperado, vuelva a intentarlo más tarde.',
          type: 'is-danger',
          autoclose: true,
          duration: 5000,
        })
      } finally {
        this.$store.commit('loading', false)
      }
    },
  },
}
