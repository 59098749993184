//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Button from '~/components/Shared/Button.vue'
export default {
  components: {
    Button,
  },
  props: {
    dateStart: {
      type: String,
      default: '01-01-2021',
    },
  },
  methods: {
    onClick() {
      this.$emit('click')
    },
  },
}
