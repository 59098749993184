//
//
//
//
//
//
//
//
//
//
//
//

import UserOpportunities from '@/components/Dashboard/UserOpportunities'
import { showErrorNotification } from '@/util/notifications'
import { mapMutations } from 'vuex'
const NUM_OPPORTUNITIES = 4

export default {
  components: {
    UserOpportunities,
  },
  props: {
    userId: {
      type: Number,
      required: true,
    },
    titlePath: {
      type: String,
      default: () => 'opportunities.reskill.title',
    },
    subTitlePath: {
      type: String,
      default: () => 'opportunities.reskill.subtitle.completed.text',
    },
  },
  async fetch() {
    try {
      this.$store.commit('loading', true)
      this.opportunitiesUser = await this.fetchOpportunities()
    } catch (e) {
      showErrorNotification('Error al obtener las oportunidades')
    } finally {
      this.$store.commit('loading', false)
    }
  },
  data: () => ({
    opportunitiesUser: [],
  }),
  computed: {
    opportunities() {
      return this.opportunitiesUser.map((opportunity) => ({
        ...opportunity,
        subheader: '',
        title: opportunity.name,
        titleHighlight: opportunity.project_name
          ? `"${opportunity.project_name}"`
          : '',
        subtitle: `Piloto:<br/> <strong class="color-tertiary">${opportunity.job_seeker_fair.name}</strong>`,
        imgSource: '/img/dashboard/trainning.svg',
        buttonText: this.$t('dashboard.complete.buttons.opportunity'),
      }))
    },
  },
  methods: {
    ...mapMutations({
      setOpportunity: 'opportunity/setOpportunity',
    }),
    handleClickOpportunity(opportunity) {
      this.setOpportunity(opportunity)
      this.$router.push(
        `/oportunidades/pilotos/${opportunity.job_seeker_fair.id}/${opportunity.occupation.id}`
      )
    },
    async fetchOpportunities() {
      return await this.$repository.user.getUnemployeeOpportunitiesByUserId(
        this.userId,
        NUM_OPPORTUNITIES
      )
    },
  },
}
