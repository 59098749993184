//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Tag from '@/components/Shared/Tag'
import PROJECT_CONSTANTS from '@/constants/projects'
import Download from '@/assets/svg/download.svg'
import FileExcelOutline from '@/assets/svg/file-excel-outline.svg'
import FilePdfBox from '@/assets/svg/file-pdf-box.svg'

export default {
  name: 'DetailHeader',
  components: {
    Tag,
    Download,
    FileExcelOutline,
    FilePdfBox,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    status: {
      type: String,
      default: '',
    },
    buttonText: {
      type: String,
      default: '',
    },
    isFinished: {
      type: Boolean,
      default: () => false,
    },
    notHavePermissions: {
      type: Boolean,
      default: () => false,
    },
    hasSomePermission: {
      type: Boolean,
      default: () => false,
    },
    isEnabledInventorySkills: {
      type: Boolean,
      default: () => false,
    },
    isEnabledPotentialMatch: {
      type: Boolean,
      default: () => false,
    },
    isEnabledKitOfSkills: {
      type: Boolean,
      default: () => false,
    },
    downloadReportExecutiveProject: {
      type: Function,
      default: () => {},
    },
    downloadReportSkillsInventoryProject: {
      type: Function,
      default: () => {},
    },
    downloadReportMatchCoursesProject: {
      type: Function,
      default: () => {},
    },
    downloadReportModelsProject: {
      type: Function,
      default: () => {},
    },
    downloadReportExperiencesProject: {
      type: Function,
      default: () => {},
    },
    downloadReportSkillsToBeWorkedProject: {
      type: Function,
      default: () => {},
    },
    downloadCurrentTabOnPDF: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    getTagType() {
      let tagType = PROJECT_CONSTANTS[this.status]
      if (!tagType) {
        tagType = PROJECT_CONSTANTS[PROJECT_CONSTANTS.IN_PROGRESS.value]
      }

      tagType.customClass = `custom-${tagType.class}`
      return tagType
    },
  },
  methods: {
    handleClick() {
      this.$emit('click')
    },
  },
}
