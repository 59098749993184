const Request = require('@/repositories/request')

class Security extends Request {
  /**
   *
   * @param {{email:string}} params
   * @returns {*}
   */
  forgotPassword(params) {
    return this.$strapi.forgotPassword(params)
  }

  /**
   *
   * @param {string} codeConfirmation
   * @returns {Promise<unknown>}
   */
  confirmationAccount(codeConfirmation) {
    return this.$strapi.$http.$get(
      `/auth/email-confirmation?confirmation=${codeConfirmation}`
    )
  }

  /**
   *
   * @param {Object} user
   * @returns {Promise<unknown>}
   */
  register(user) {
    return this.$strapi.register(user)
  }

  /**
   *
   * @param {{identifier: string, password: string}} user
   * @returns {Promise<{jwt: string, user: Object}>}
   */
  login(user) {
    return this.$strapi.login(user)
  }

  /**
   *
   */
  logout() {
    this.$strapi.logout()
  }
}

module.exports = Security
