const MESSAGE_TYPE = {
  DANGER: 'is-danger',
  SUCCESS: 'is-success',
}

const ACTION_ENTITY = {
  CREATE: 'create',
  UPDATE: 'update',
  DELETE: 'delete',
}

module.exports = { MESSAGE_TYPE, ACTION_ENTITY }
