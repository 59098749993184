//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ChartPie from '~/components/Charts/ChartPie.vue'
const COLORS = {
  background: ['#EF4A81', '#2CD5C4', '#DBDBDB', '#0E75B9'],
  fonts: ['#FFFFFF', '#FFFFFF', '#4A4A4A', '#FFFFFF'],
}
export default {
  components: { ChartPie },
  props: {
    chartData: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: 'title of chart',
    },
    tooltipTitle: {
      type: String,
      default: 'tooltip title of chart',
    },
    description: {
      type: String,
      default: 'description of chart',
    },
  },
  computed: {
    chartColors() {
      return COLORS.background
    },
  },
  methods: {
    styleBadge(rowNumber) {
      return `
      background-color:${COLORS.background[rowNumber]};
      color:${COLORS.fonts[rowNumber]}`
    },
  },
}
