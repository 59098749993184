//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Button from '@/components/Shared/Button.vue'
import ModalWithTitleBar from '~/components/Shared/ModalWithTitleBar'
import DeleteSkills from '~/assets/experiences/delete-skills.svg'

export default {
  components: {
    ModalWithTitleBar,
    Button,
    DeleteSkills,
  },
  props: {
    modal: {
      type: Boolean,
      default: () => false,
    },
  },
  methods: {
    handleCancel() {
      this.$emit('cancel')
    },
    handleConfirm() {
      this.$emit('confirm')
    },
  },
}
