//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SkillQuestion from '~/components/Experiences/SkillQuestion'

const TYPES = {
  WORK: 'laboral',
  EDUCATION: 'educational',
}

export default {
  components: {
    SkillQuestion,
  },
  props: {
    type: {
      type: String,
      required: true,
      validator: (value) => Object.values(TYPES).includes(value),
    },
  },
  async fetch() {
    this.$store.commit('loading', true)
    if (this.type === TYPES.WORK) {
      await this.loadWorkSkills()
    } else if (this.type === TYPES.EDUCATION) {
      await this.loadEducationalSkills()
    }
    this.$store.commit('loading', false)
    this.loading = false
  },
  data() {
    return {
      currentSkillIndex: 0,
      posessedSkills: [],
      notPosessedSkills: [],
      experience: null,
      skillsToAsk: [],
      total_skills_for_validate: 0,
      loading: true,
    }
  },
  computed: {
    allQuestionsAnswered() {
      const selectedSkills = [...this.posessedSkills, ...this.notPosessedSkills]
      return this.skillsToAsk.length === selectedSkills.length
    },
  },
  methods: {
    async loadWorkSkills() {
      const experience = this.$store.state.workingExperience.workingExperience

      const occupationWithSkillMoreRelevants = await this.$repository.occupation.skillsMoreRelevantsBeta(
        experience.occupation.id,
        experience.id,
        experience.total_skills_for_validate
      )

      this.setSkills(
        experience,
        occupationWithSkillMoreRelevants.skillsMoreRelevants
      )

      this.$emit(
        'init',
        occupationWithSkillMoreRelevants.skillsMoreRelevants.length
      )
    },
    async loadEducationalSkills() {
      const experienceId = this.$store.state.experience.educationalExperienceId
      const experience = await this.$repository.experienceEducational.findOne(
        experienceId
      )
      const {
        skills_more_relevants: skills = [],
      } = await this.$repository.educationalArea.skillMoreRelevantsBeta(
        experience.area_educational,
        experience.id,
        experience.total_skills_for_validate
      )
      this.setSkills(experience, skills)
      this.$emit('init', skills.length)
    },
    setSkills(experience, skills) {
      this.total_skills_for_validate = skills.length
      this.experience = experience
      this.skillsToAsk = skills
    },
    forth() {
      if (this.currentSkillIndex + 1 === this.skillsToAsk.length) {
        this.modalVisible = true
        this.total_skills_for_validate = 0
      } else {
        this.currentSkillIndex = this.currentSkillIndex + 1
        this.total_skills_for_validate = this.total_skills_for_validate - 1
      }
    },
    back() {
      if (this.currentSkillIndex === 0) {
        return
      }
      this.currentSkillIndex = this.currentSkillIndex - 1
      this.total_skills_for_validate = this.total_skills_for_validate + 1
    },
    apply() {
      this.posessedSkills = this.posessedSkills.filter(
        (skill) => skill.index !== this.currentSkillIndex
      )
      this.notPosessedSkills = this.notPosessedSkills.filter(
        (skill) => skill.index !== this.currentSkillIndex
      )
      this.posessedSkills = [
        ...this.posessedSkills,
        {
          ...this.skillsToAsk[this.currentSkillIndex],
          index: this.currentSkillIndex,
        },
      ]
      this.$emit(
        'select',
        this.allQuestionsAnswered,
        this.posessedSkills,
        this.notPosessedSkills,
        this.total_skills_for_validate - 1
      )
      this.forth()
    },
    doNotApply() {
      this.posessedSkills = this.posessedSkills.filter(
        (skill) => skill.index !== this.currentSkillIndex
      )
      this.notPosessedSkills = this.notPosessedSkills.filter(
        (skill) => skill.index !== this.currentSkillIndex
      )
      this.notPosessedSkills = [
        ...this.notPosessedSkills,
        {
          ...this.skillsToAsk[this.currentSkillIndex],
          index: this.currentSkillIndex,
        },
      ]
      this.$emit(
        'select',
        this.allQuestionsAnswered,
        this.posessedSkills,
        this.notPosessedSkills,
        this.total_skills_for_validate - 1
      )
      this.forth()
    },
  },
}
