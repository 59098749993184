//
//
//
//
//
//

import EXPERIENCE_STATUS from '~/constants/experience-status'
const TYPES = []
TYPES[EXPERIENCE_STATUS.COMPLETED] = 'is-success'
TYPES[EXPERIENCE_STATUS.INCOMPLETED] = 'is-danger'
TYPES[EXPERIENCE_STATUS.FOR_VALIDATE] = 'is-warning'

const MESSAGES = []
MESSAGES[EXPERIENCE_STATUS.COMPLETED] = 'experience.messages.status.completed'
MESSAGES[EXPERIENCE_STATUS.INCOMPLETED] =
  'experience.messages.status.uncompleted'
MESSAGES[EXPERIENCE_STATUS.FOR_VALIDATE] =
  'experience.messages.status.forValidate'

export default {
  props: {
    status: {
      type: String,
      default: '',
    },
  },
  computed: {
    type() {
      return TYPES[this.status]
    },
    message() {
      return this.$t(MESSAGES[this.status])
    },
  },
}
