//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    numberLogosDesktop: {
      type: Number,
      default: 8,
    },
    numberLogosMobile: {
      type: Number,
      default: 2,
    },
    indicator: {
      type: Boolean,
      default: true,
    },
    logos: {
      type: Array,
      default: () => {
        return [
          {
            src: 'img/logos/walmart.svg',
            srcColor: 'img/logos/walmart_color.svg',
            alt: 'logo walmart',
          },
          {
            src: 'img/logos/bci.svg',
            srcColor: 'img/logos/bci_color.svg',
            alt: 'logo bci',
          },
          {
            src: 'img/logos/banco-estado.svg',
            srcColor: 'img/logos/banco-estado_color.svg',
            alt: 'logo banco estado',
          },
          {
            src: 'img/logos/nestle.svg',
            srcColor: 'img/logos/nestle_color.svg',
            alt: 'logo nestle',
          },
          {
            src: 'img/logos/finning.svg',
            srcColor: 'img/logos/finning_color.svg',
            alt: 'logo finning',
          },
          {
            src: 'img/logos/flsmidth.svg',
            srcColor: 'img/logos/flsmidth_color.svg',
            alt: 'logo flsmidth',
          },
        ]
      },
    },
  },
  data() {
    return {
      widthScreen: window.innerWidth,
    }
  },
  computed: {
    carouselItems() {
      let numberLogos = this.numberLogosMobile
      if (this.widthScreen > 768) numberLogos = this.numberLogosDesktop
      const newCarouselItems = []
      for (let i = 0; i < this.logos.length; i += numberLogos) {
        newCarouselItems.push({ logos: this.logos.slice(i, i + numberLogos) })
      }
      return newCarouselItems
    },
  },
}
