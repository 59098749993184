//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ChevronRight from 'vue-material-design-icons/ChevronRight'
import TableEmptyData from '@/components/Shared/Table/TableEmptyData'

export default {
  components: { ChevronRight, TableEmptyData },
  filters: {
    fullNames(user) {
      return `${user.names} ${user.lastnames}`
    },
    projectFormatted(user) {
      if (user.project) {
        return user.project.name
      }
      return 'Sin proyecto'
    },
  },
  props: {
    onPageChange: {
      default: () => {},
      type: Function,
    },
  },
  methods: {
    getSkillTotal(user) {
      return user.totalSkills || 0
    },
    onRowClick(user) {
      this.$emit('click', user)
    },
  },
}
