//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ValidationProvider } from 'vee-validate'
import FormLabel from '~/components/Form/FormLabel'

export default {
  name: 'FormInputNumber',
  components: {
    FormLabel,
    ValidationProvider,
  },
  props: {
    vid: {
      type: String,
      default: '',
    },
    rules: {
      type: [Object, String],
      default: '',
    },
    value: {
      type: null,
      default: null,
    },
  },
  data: () => ({
    innerValue: 0,
    init: false,
  }),
  watch: {
    innerValue(newVal) {
      this.$emit('input', newVal)
      this.init = true
    },
    value(newVal) {
      this.innerValue = newVal
      this.init = true
    },
  },
  created() {
    if (this.value) {
      this.innerValue = this.value
    }
  },
  methods: {
    iconRightClick(event) {
      this.$emit('icon-right-click', event)
    },
  },
}
