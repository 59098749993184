//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LandingCarouselOccupation from '~/components/Landing/LandingCarouselOccupation.vue'
import config from '~/config'
const MAX_CHARACTER_TEXT = 300
const MAX_ELEMENTS_LIST = 3
export default {
  components: { LandingCarouselOccupation },
  props: {
    description: {
      type: String,
      default: '',
    },
    requirements: {
      type: String,
      default: '',
    },
    jobAnnouncements: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      contactEmail: config.contactEmail,
      isShowMore: {
        description: false,
        requirements: false,
      },
    }
  },
  computed: {
    descriptionText() {
      if (this.isShowMore.description) {
        return this.description
      }
      return this.getRichTextAbbreviation(this.description)
    },
    requirementsText() {
      if (this.isShowMore.requirements) {
        return this.requirements
      }
      return this.getRichTextAbbreviation(this.requirements)
    },
  },
  methods: {
    showMoreDescription() {
      this.isShowMore.description = !this.isShowMore.description
    },
    showMoreRequirements() {
      this.isShowMore.requirements = !this.isShowMore.requirements
    },
    getRichTextAbbreviation(richText) {
      const textList = richText.split('</li>')
      if (textList.length === 1 && textList[0].length <= MAX_CHARACTER_TEXT) {
        return richText
      }
      if (textList[0].length > MAX_CHARACTER_TEXT) {
        return richText.substring(0, MAX_CHARACTER_TEXT) + '...'
      }

      if (textList.length - 1 <= MAX_ELEMENTS_LIST) {
        return richText
      }
      return (
        textList
          .map((element, index) => {
            return index === textList.length ? element : element + '</li>'
          })
          .slice(0, MAX_ELEMENTS_LIST)
          .join('') + '</lu>'
      )
    },
    isEnableShowMore(richText) {
      return this.getRichTextAbbreviation(richText).trim() !== richText.trim()
    },
    showMoreText(status) {
      return status ? 'Ver menos' : 'Ver más'
    },
  },
}
