//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Button from '@/components/Shared/Button'
export default {
  components: {
    Button,
  },
  props: {
    icon: {
      type: String,
      default: 'head-lightbulb-outline',
    },
    imageSrc: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      default: 'Action title',
    },
    action: {
      type: String,
      default: 'Action',
    },
    urlRedirect: {
      type: String,
      default: '/inicio',
    },
  },
}
