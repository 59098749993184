const Request = require('@/repositories/request')
const qs = require('qs')

class Permission extends Request {
  async find(services) {
    try {
      const query = qs.stringify({ services }, { encode: false })
      const result = await this.$strapi.$http.$get(
        `/companies/permission-levels?${query}`
      )
      return result
    } catch (error) {
      return []
    }
  }

  async allowByProject(projectId, services) {
    try {
      const query = qs.stringify({ services }, { encode: false })
      const result = await this.$strapi.$http.$get(
        `/projects/${projectId}/permission-levels?${query}`
      )
      return result
    } catch (error) {
      return []
    }
  }

  async findAll() {
    try {
      const result = await this.$strapi.$http.$get(
        `/companies/permission-levels`
      )
      return result
    } catch (error) {
      return []
    }
  }

  getAllNames() {
    return this.$strapi.$http.$get(`/permission-levels/options`)
  }
}

module.exports = Permission
