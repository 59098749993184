//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Button from '@/components/Shared/Button'
import { ValidationObserver } from 'vee-validate'
import ROLES from '~/constants/roles'
import FormInput from '~/components/Form/FormInput'
export default {
  components: { ValidationObserver, Button, FormInput },
  props: {
    code: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    form: {
      password: '',
      passwordConfirmation: '',
    },
    showPassword: false,

    history: [{ name: 'Inicio', url: '/inicio' }],
  }),
  watch: {
    '$route.query.code': {
      handler(code) {
        this.form.code = code
      },
      deep: true,
      immediate: true,
    },
    showPassword: {
      handler(value) {
        const passInput = document.querySelector('input[name="pass"]')
        const passRepeatInput = document.querySelector(
          'input[name="repeat-pass"]'
        )
        passInput.type = value ? 'text' : 'password'
        passRepeatInput.type = value ? 'text' : 'password'
      },
    },
  },
  methods: {
    async changePassword() {
      try {
        const { user } = await this.$strapi.resetPassword({
          ...this.form,
          code: this.code,
        })
        this.$buefy.toast.open({
          message: this.$t('misc.messages.success'),
          type: 'is-success',
          queue: false,
          duration: 3000,
        })
        this.isAdminUser(user.role.type)
          ? this.$router.push('/login/admin')
          : this.$router.push('/login/persona')
      } catch (error) {
        this.$buefy.toast.open({
          message: this.$t('misc.errors.genericError'),
          type: 'is-danger',
          queue: false,
        })
      }
    },
    isAdminUser(role) {
      return [ROLES.ADMIN_UNEMPLOYED, ROLES.EMPLOYEE, ROLES.RELINK].includes(
        role
      )
    },
  },
}
