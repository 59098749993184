//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import RadialProgressBar from 'vue-radial-progress'
export default {
  components: {
    RadialProgressBar,
  },
  props: {
    percent: {
      type: Number,
      default: 0,
      validator(value) {
        return value > -1 && value < 101
      },
    },
    diameter: {
      type: Number,
      default: 150,
    },
    strokeWidth: {
      type: Number,
      default: 20,
    },
    innerStrokeWidth: {
      type: Number,
      default: 20,
    },
    subtitle: {
      type: String,
      default: '',
    },
    isMatchBold: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    matchRounded() {
      return Math.round(this.percent)
    },
    matchFixed() {
      return this.percent.toFixed(1)
    },
  },
}
