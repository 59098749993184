//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import _ from 'underscore'
import { ValidationProvider } from 'vee-validate'
import Multiselect from 'vue-multiselect'
import { sanitizeOccupations } from '@/util/occupation'
import FormLabel from '~/components/Form/FormLabel'
import { capitalizeWord } from '~/util/string'

export default {
  name: 'SelectOccupation',
  components: {
    FormLabel,
    ValidationProvider,
    Multiselect,
  },
  props: {
    occupation: {
      default: () => {},
      type: Object,
    },
    meta: {
      default: false,
      type: Boolean,
    },
  },
  async fetch() {
    try {
      const occupations = await this.$repository.occupation.getOccupationsForLibrary(
        {
          search: this.titleSearch,
          meta: this.meta,
        }
      )

      this.occupations = sanitizeOccupations(
        occupations,
        this.$t('occupations.messages.withoutParent')
      )
    } catch (e) {
      this.$buefy.notification.open({
        message: 'Ha ocurrido un error inesperado',
        type: 'is-danger',
        autoclose: true,
        duration: 10000,
      })
    }
  },
  data() {
    return {
      selectLoading: false,
      titleSearch: '',
      filter: {},
      optionSelect: null,
      occupations: [],
      justClosed: false,
    }
  },
  computed: {
    canVisibleSuggest() {
      return (
        this.occupations.length > 0 &&
        this.justClosed &&
        !!this.optionSelect === false
      )
    },
  },
  watch: {
    occupation(val) {
      this.optionSelect = val
    },
  },
  methods: {
    async close() {
      await this.$refs.provider.validate()
      this.justClosed = true
      this.$emit('change', {
        title: this.titleSearch,
        visibleSuggest: this.canVisibleSuggest,
      })
    },
    selectOption(item) {
      this.justClosed = false
      this.optionSelect = item
      this.$emit('select', item)
    },
    sanitizeOccupations(occupations) {
      const groupBy = _.groupBy(
        occupations,
        (item) =>
          item?.hierarchy_occupation?.title ||
          this.$t('occupations.messages.withoutParent')
      )

      return Object.keys(groupBy)
        .map((keyGroupBy) => {
          return {
            label: capitalizeWord(keyGroupBy),
            options: groupBy[keyGroupBy],
          }
        })
        .sort((a, b) => a.label.localeCompare(b.label))
        .map((item) => {
          item.options = item.options
            .map((item) => {
              const { title, ...others } = item
              return { title: capitalizeWord(title), ...others }
            })
            .sort((a, b) => (a.title || '').localeCompare(b.title || ''))
          return item
        })
    },
    async searchOccupations(title) {
      if (title) this.titleSearch = title
      this.$emit('change', {
        title,
        visibleSuggest: this.canVisibleSuggest && !!title,
      })
      this.selectLoading = true
      try {
        await this.$fetch()
      } catch (e) {
        this.$buefy.notification.open({
          message: 'Ha ocurrido un error inesperado',
          type: 'is-danger',
          autoclose: true,
          duration: 10000,
        })
      } finally {
        this.selectLoading = false
      }
    },
  },
}
