//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import { ValidationObserver } from 'vee-validate'
import { ValidWatcher, FormSelect, FormInput } from '@/components/Form'
import FormSearchOccupation from '../Occupation/FormSearchOccupation.vue'
import EDUCATIONAL_TYPES from '~/constants/educational-types'

export default {
  components: {
    ValidationObserver,
    ValidWatcher,
    FormSelect,
    FormInput,
    FormSearchOccupation,
  },
  props: {
    showSubmit: {
      type: Boolean,
      default: true,
    },
    goBack: {
      type: Function,
      default: () => {},
    },
    canSelectHierarchy: {
      type: Boolean,
      default: false,
    },
    defaultExperience: {
      type: Object,
      default: () => {
        return {
          id: null,
          occupation: '',
          educational_type: '',
          status: '',
          completed: false,
          study_location: '',
          custom_occupation: '',
        }
      },
    },
    isEditMode: {
      type: Boolean,
      default: false,
    },
  },
  async fetch() {
    this.$store.commit('loading', true)
    try {
      const [
        educationalTypes,
        educationalAreas,
        specialties,
        educationalStatuses,
        metaOccupations,
      ] = await Promise.all([
        this.$repository.educationalType.findAll(this.filterDefault),
        this.$repository.educationalArea.findAll(this.filterDefault),
        this.$repository.specialty.findAll(),
        this.$repository.educationalStatuses.findAll({
          ...this.filterDefault,
          _sort: 'title',
        }),
        this.$repository.occupation.getMetaOccupations(),
      ])

      this.options = {
        educationalTypes,
        educationalStatuses,
        educationalAreas,
        specialties: specialties.sort((a, b) => a.title.localeCompare(b.title)),
        metaOccupations,
      }
      if (!this.isChilean()) {
        this.options.educationalTypes = this.options.educationalTypes.filter(
          ({ name }) => {
            return name !== EDUCATIONAL_TYPES.TECHNICAL_COLLEGE
          }
        )
      }
      if (this.defaultExperience.id) this.experience = this.defaultExperience
    } finally {
      this.$store.commit('loading', false)
    }
  },
  data() {
    return {
      select: {
        loading: false,
        title: '',
      },
      isVisibleEducationalCarrer: true,
      isVisibleEducationalAreas: true,
      isVisibleEducationalSpecialty: false,
      options: {
        educationalTypes: [],
        educationalStatuses: [],
        educationalAreas: [],
        metaOccupations: null,
      },
      searchTerm: {
        educational_type: '',
        area_educational: '',
      },
      experience: {
        id: null,
        occupation: null,
        educational_type: null,
        status: null,
        area_educational: null,
        completed: false,
        study_location: '',
        custom_occupation: '',
      },
      showAddCustomOccupation: false,
    }
  },
  computed: {
    ...mapState(['loading']),
    filterDefault() {
      return {
        _sort: 'name',
        _limit: -1,
      }
    },
  },
  watch: {
    experience: {
      handler(val) {
        if (val.educational_type) {
          const defaultOptions = [
            EDUCATIONAL_TYPES.DIPLOMAT,
            EDUCATIONAL_TYPES.COURSE,
            EDUCATIONAL_TYPES.WORKSHOP,
            EDUCATIONAL_TYPES.DOCTORATE,
            EDUCATIONAL_TYPES.MASTER,
            EDUCATIONAL_TYPES.ADVANCED_TECHNICIAN,
            EDUCATIONAL_TYPES.ACADEMIC,
          ]
          if (
            val.educational_type.name === EDUCATIONAL_TYPES.TECHNICAL_COLLEGE
          ) {
            this.isVisibleEducationalCarrer = false
            this.isVisibleEducationalAreas = false
            this.isVisibleEducationalSpecialty = true
          }

          if (
            val.educational_type.name === EDUCATIONAL_TYPES.MIDDLE_EDUCATION
          ) {
            this.isVisibleEducationalCarrer = false
            this.isVisibleEducationalAreas = false
            this.isVisibleEducationalSpecialty = false
            this.experience.specialty = null
          }

          if (defaultOptions.includes(val.educational_type.name)) {
            this.isVisibleEducationalCarrer = true
            this.isVisibleEducationalAreas = true
            this.isVisibleEducationalSpecialty = false
          }
        }
      },
      deep: true,
    },
    defaultExperience(value) {
      if (value.id) {
        this.experience = this.defaultExperience
      }
    },
  },

  methods: {
    handleChangeValue() {
      this.$emit('change', this.experience)
    },
    onValidChanged(valid) {
      this.$emit('valid-changed', valid)
    },
    handleCustomOccupationMode(value) {
      this.experience.custom_occupation = ''
      this.showAddCustomOccupation = value
    },
    isChilean() {
      return this.$store.getters['me/isChilean']
    },
  },
}
