import { render, staticRenderFns } from "./RegisterFormForeignFinalStep.vue?vue&type=template&id=741e4f1d&scoped=true&"
import script from "./RegisterFormForeignFinalStep.vue?vue&type=script&lang=js&"
export * from "./RegisterFormForeignFinalStep.vue?vue&type=script&lang=js&"
import style0 from "./RegisterFormForeignFinalStep.vue?vue&type=style&index=0&id=741e4f1d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "741e4f1d",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FormInput: require('/workspace/components/Form/FormInput.vue').default,FormCheckBox: require('/workspace/components/Form/FormCheckBox.vue').default,Form: require('/workspace/components/Form/index.js').default})
