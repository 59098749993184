//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Button from '@/components/Shared/Button'
import TextInformativeModalAdminUnemployed from '@/components/Shared/TextInformativeModalAdminUnemployed.vue'
import TextInformativeModalPublic from '@/components/Shared/TextInformativeModalPublic.vue'
import TextInformativeModalEmployee from '@/components/Shared/TextInformativeModalEmployee.vue'
import TextInformativeModalCompany from '~/components/Shared/TextInformativeModalCompany.vue'
import TextInformativeModalUnemployed from '~/components/Shared/TextInformativeModalUnemployed.vue'
import ModalWithTitleBar from '~/components/Shared/ModalWithTitleBar.vue'
import RevalidationSkill from '~/assets/svg/revalidation-skill.svg'

export default {
  name: 'InformativeModal',
  components: {
    ModalWithTitleBar,
    Button,
    RevalidationSkill,
    TextInformativeModalUnemployed,
    TextInformativeModalCompany,
    TextInformativeModalAdminUnemployed,
    TextInformativeModalPublic,
    TextInformativeModalEmployee,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toggleModal() {
      this.$emit('toggle')
    },
    viewed() {
      this.$emit('viewed')
    },
  },
}
