export const state = (context) => ({
  id: 0,
  occupation: {
    id: 0,
    title: '',
    skills: [],
  },
  vacancies: 0,
  project_name: '',
  requirements: '',
  created_at: new Date(),
  updated_at: new Date(),
  publisher: {},
  job_seeker_fair: {},
  active: false,
})
export const mutations = {
  SET_ID(state, id) {
    state.id = id
  },
  SET_OCCUPATION(state, occupation) {
    state.occupation = occupation
  },
  SET_VACANCIES(state, vacancies) {
    state.vacancies = vacancies
  },
  SET_PROJECT_NAME(state, projectName) {
    state.project_name = projectName
  },
  SET_CREATED_AT(state, createdAt) {
    state.created_at = createdAt
  },
  SET_UPDATED_AT(state, updatedAt) {
    state.updated_at = updatedAt
  },
  SET_PUBLISHER(state, publisher) {
    state.publisher = publisher
  },
  SET_JOB_SEEKER_FAIR(state, jobSeekerFair) {
    state.job_seeker_fair = jobSeekerFair
  },
  SET_REQUIREMENTS(state, requirements) {
    state.requirements = requirements
  },
  SET_ACTIVE(state, active) {
    state.active = active
  },
}
export const getters = {
  fullNamesPublisher(state) {
    return `${state.publisher?.names} ${state.publisher?.lastnames}`
  },
  isRegistered(state) {
    return state.id !== 0
  },
}
export const actions = {
  changeData(
    context,
    {
      occupation,
      vacancies,
      project_name: projectName,
      publisher,
      job_seeker_fair: jobSeekerFair,
      requirements,
      id = 0,
      created_at: createdAt = new Date(),
      updated_at: updatedAt = new Date(),
      active = false,
    }
  ) {
    context.commit('SET_ID', id)
    context.commit('SET_OCCUPATION', occupation)
    context.commit('SET_VACANCIES', vacancies)
    context.commit('SET_PROJECT_NAME', projectName)
    context.commit('SET_CREATED_AT', createdAt)
    context.commit('SET_UPDATED_AT', updatedAt)
    context.commit('SET_PUBLISHER', publisher)
    context.commit('SET_JOB_SEEKER_FAIR', jobSeekerFair)
    context.commit('SET_REQUIREMENTS', requirements)
    context.commit('SET_ACTIVE', active)
  },
}
