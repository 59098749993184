//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MessageWithoutHeader from '~/components/Shared/MessageWithoutHeader'
import DashboardCard from '~/components/Dashboard/DashboardCard.vue'

export default {
  components: {
    DashboardCard,
    MessageWithoutHeader,
  },
  props: {
    steps: {
      type: Array,
      default: () => [],
    },
    progress: {
      type: Object,
      default: () => ({}),
    },
    routes: {
      type: Object,
      default: () => ({}),
    },
    incompleteMessage: {
      type: String,
      default: 'dashboard.incomplete.missingSteps.default',
    },
  },

  methods: {
    subtitle(step) {
      if (step === 'tests') {
        return 'dashboard.incomplete.tests'
      }
      return 'dashboard.incomplete.progress'
    },
  },
}
