/*
Estructura de la etiqueta
[SERVICIO]::[VARIANTE]

Por defecto cada servicio tiene una variante (BASE)
*/

export const PERMISSION_LABELS = {
  INVENTORY_SKILLS: {
    BASE: 'inventory_skills',
  },
  MATCH_POTENTIAL: {
    BASE: 'match_potential',
  },
  CUSTOM_OCCUPATIONS: {
    BASE: 'custom_occupations',
    BY_COMPANY: 'custom_occupations__max_by_company',
    BY_PROJECT: 'custom_occupations__max_by_project',
  },
  PEOPLE_ANALYTICS: {
    BASE: 'people_analytics',
  },
  KIT_OF_SKILLS: {
    EMPLEABILITY: 'kit_of_skills__empleability',
    PRODUCTIVITY: 'kit_of_skills__productivity',
  },
  PROJECTS: {
    BY_COMPANY: 'projects__max_by_company',
    KIT_OF_SKILLS: 'projects__kit_of_skills',
    RESKILLING: 'projects__reskilling',
    UPSKILLING: 'projects__upskilling',
    SELFDIAGNOSIS: 'projects__selfdiagnosis',
  },
  EMPLOYEE_INVITATIONS: {
    BY_PROJECT: 'employee_invitations__max_by_project',
  },
}

/*
NOTA: las siguientes constantes son mocks de lo que estaría en base de datos, se incluye te manera temporal
*/
const PERMISSIONS_LEVEL_0 = [
  {
    service: PERMISSION_LABELS.INVENTORY_SKILLS.BASE,
    enabled: 1,
  },
  {
    service: PERMISSION_LABELS.MATCH_POTENTIAL.BASE,
    enabled: 0,
  },
  {
    service: PERMISSION_LABELS.CUSTOM_OCCUPATIONS.BASE,
    enabled: 0,
  },
  {
    service: PERMISSION_LABELS.CUSTOM_OCCUPATIONS.BY_COMPANY,
    enabled: 0,
    limit: 0,
  },
  {
    service: PERMISSION_LABELS.CUSTOM_OCCUPATIONS.BY_PROJECT,
    enabled: 0,
  },
  {
    service: PERMISSION_LABELS.PEOPLE_ANALYTICS.BASE,
    enabled: 0,
  },
  {
    service: PERMISSION_LABELS.KIT_OF_SKILLS.EMPLEABILITY,
    enabled: 1,
  },
  {
    service: PERMISSION_LABELS.KIT_OF_SKILLS.PRODUCTIVITY,
    enabled: 1,
  },
  {
    service: PERMISSION_LABELS.PROJECTS.BY_COMPANY,
    enabled: 1,
  },
  {
    service: PERMISSION_LABELS.PROJECTS.KIT_OF_SKILLS,
    enabled: 1,
  },
  {
    service: PERMISSION_LABELS.PROJECTS.RESKILLING,
    enabled: 0,
  },
  {
    service: PERMISSION_LABELS.PROJECTS.UPSKILLING,
    enabled: 0,
  },
  {
    service: PERMISSION_LABELS.PROJECTS.SELFDIAGNOSIS,
    enabled: 0,
  },
  {
    service: PERMISSION_LABELS.EMPLOYEE_INVITATIONS.BY_PROJECT,
    enabled: 1,
  },
]
const PERMISSIONS_LEVEL_1 = [
  {
    service: PERMISSION_LABELS.INVENTORY_SKILLS.BASE,
    enabled: 1,
  },
  {
    service: PERMISSION_LABELS.MATCH_POTENTIAL.BASE,
    enabled: 1,
  },
  {
    service: PERMISSION_LABELS.CUSTOM_OCCUPATIONS.BASE,
    enabled: 0,
  },
  {
    service: PERMISSION_LABELS.CUSTOM_OCCUPATIONS.BY_COMPANY,
    enabled: 0,
  },
  {
    service: PERMISSION_LABELS.CUSTOM_OCCUPATIONS.BY_PROJECT,
    enabled: 0,
  },
  {
    service: PERMISSION_LABELS.PEOPLE_ANALYTICS.BASE,
    enabled: 0,
  },
  {
    service: PERMISSION_LABELS.KIT_OF_SKILLS.EMPLEABILITY,
    enabled: 1,
  },
  {
    service: PERMISSION_LABELS.KIT_OF_SKILLS.PRODUCTIVITY,
    enabled: 1,
  },
  {
    service: PERMISSION_LABELS.PROJECTS.BY_COMPANY,
    enabled: 1,
  },
  {
    service: PERMISSION_LABELS.PROJECTS.KIT_OF_SKILLS,
    enabled: 1,
  },
  {
    service: PERMISSION_LABELS.PROJECTS.RESKILLING,
    enabled: 1,
  },
  {
    service: PERMISSION_LABELS.PROJECTS.UPSKILLING,
    enabled: 1,
  },
  {
    service: PERMISSION_LABELS.PROJECTS.SELFDIAGNOSIS,
    enabled: 1,
  },
  {
    service: PERMISSION_LABELS.EMPLOYEE_INVITATIONS.BY_PROJECT,
    enabled: 1,
  },
]
const PERMISSIONS_LEVEL_2 = [
  {
    service: PERMISSION_LABELS.INVENTORY_SKILLS.BASE,
    enabled: 1,
  },
  {
    service: PERMISSION_LABELS.MATCH_POTENTIAL.BASE,
    enabled: 1,
  },
  {
    service: PERMISSION_LABELS.CUSTOM_OCCUPATIONS.BASE,
    enabled: 1,
  },
  {
    service: PERMISSION_LABELS.CUSTOM_OCCUPATIONS.BY_COMPANY,
    enabled: 1,
  },
  {
    service: PERMISSION_LABELS.CUSTOM_OCCUPATIONS.BY_PROJECT,
    enabled: 1,
  },
  {
    service: PERMISSION_LABELS.PEOPLE_ANALYTICS.BASE,
    enabled: 0,
  },
  {
    service: PERMISSION_LABELS.KIT_OF_SKILLS.EMPLEABILITY,
  },
  {
    service: PERMISSION_LABELS.KIT_OF_SKILLS.PRODUCTIVITY,
    enabled: 1,
  },
  {
    service: PERMISSION_LABELS.PROJECTS.BY_COMPANY,
    enabled: 1,
  },
  {
    service: PERMISSION_LABELS.PROJECTS.KIT_OF_SKILLS,
    enabled: 1,
  },
  {
    service: PERMISSION_LABELS.PROJECTS.RESKILLING,
    enabled: 1,
  },
  {
    service: PERMISSION_LABELS.PROJECTS.UPSKILLING,
    enabled: 1,
  },
  {
    service: PERMISSION_LABELS.PROJECTS.SELFDIAGNOSIS,
    enabled: 1,
  },
  {
    service: PERMISSION_LABELS.EMPLOYEE_INVITATIONS.BY_PROJECT,
    enabled: 1,
  },
]
const PERMISSIONS_LEVEL_3 = [
  {
    service: PERMISSION_LABELS.INVENTORY_SKILLS.BASE,
    enabled: 1,
  },
  {
    service: PERMISSION_LABELS.MATCH_POTENTIAL.BASE,
    enabled: 1,
  },
  {
    service: PERMISSION_LABELS.CUSTOM_OCCUPATIONS.BASE,
    enabled: 1,
  },
  {
    service: PERMISSION_LABELS.CUSTOM_OCCUPATIONS.BY_COMPANY,
    enabled: 1,
  },
  {
    service: PERMISSION_LABELS.CUSTOM_OCCUPATIONS.BY_PROJECT,
    enabled: 1,
    limit: -1,
  },
  {
    service: PERMISSION_LABELS.PEOPLE_ANALYTICS.BASE,
    enabled: 0,
  },
  {
    service: PERMISSION_LABELS.KIT_OF_SKILLS.EMPLEABILITY,
    enabled: 1,
  },
  {
    service: PERMISSION_LABELS.KIT_OF_SKILLS.PRODUCTIVITY,
    enabled: 1,
  },
  {
    service: PERMISSION_LABELS.PROJECTS.BY_COMPANY,
    enabled: 1,
  },
  {
    service: PERMISSION_LABELS.PROJECTS.KIT_OF_SKILLS,
    enabled: 1,
  },
  {
    service: PERMISSION_LABELS.PROJECTS.RESKILLING,
    enabled: 1,
  },
  {
    service: PERMISSION_LABELS.PROJECTS.UPSKILLING,
    enabled: 1,
  },
  {
    service: PERMISSION_LABELS.PROJECTS.SELFDIAGNOSIS,
    enabled: 1,
  },
  {
    service: PERMISSION_LABELS.EMPLOYEE_INVITATIONS.BY_PROJECT,
    enabled: 1,
  },
]
const PERMISSIONS_LEVEL_4 = [
  {
    service: PERMISSION_LABELS.INVENTORY_SKILLS.BASE,
    enabled: 1,
  },
  {
    service: PERMISSION_LABELS.MATCH_POTENTIAL.BASE,
    enabled: 1,
  },
  {
    service: PERMISSION_LABELS.CUSTOM_OCCUPATIONS.BASE,
    enabled: 1,
  },
  {
    service: PERMISSION_LABELS.CUSTOM_OCCUPATIONS.BY_COMPANY,
    enabled: 1,
  },
  {
    service: PERMISSION_LABELS.CUSTOM_OCCUPATIONS.BY_PROJECT,
    enabled: 1,
  },
  {
    service: PERMISSION_LABELS.PEOPLE_ANALYTICS.BASE,
    enabled: 1,
  },
  {
    service: PERMISSION_LABELS.KIT_OF_SKILLS.EMPLEABILITY,
    enabled: 1,
  },
  {
    service: PERMISSION_LABELS.KIT_OF_SKILLS.PRODUCTIVITY,
    enabled: 1,
  },
  {
    service: PERMISSION_LABELS.PROJECTS.BY_COMPANY,
    enabled: 1,
  },
  {
    service: PERMISSION_LABELS.PROJECTS.KIT_OF_SKILLS,
    enabled: 1,
  },
  {
    service: PERMISSION_LABELS.PROJECTS.RESKILLING,
    enabled: 1,
  },
  {
    service: PERMISSION_LABELS.PROJECTS.UPSKILLING,
    enabled: 1,
  },
  {
    service: PERMISSION_LABELS.PROJECTS.SELFDIAGNOSIS,
    enabled: 1,
  },
  {
    service: PERMISSION_LABELS.EMPLOYEE_INVITATIONS.BY_PROJECT,
    enabled: 1,
  },
]

export const PERMISSIONS = {
  LEVEL_0: PERMISSIONS_LEVEL_0,
  LEVEL_1: PERMISSIONS_LEVEL_1,
  LEVEL_2: PERMISSIONS_LEVEL_2,
  LEVEL_3: PERMISSIONS_LEVEL_3,
  LEVEL_4: PERMISSIONS_LEVEL_4,
}
