//
//
//
//
//
//
//
//
//

export default {
  props: {
    icon: {
      type: String,
      default: 'robot-happy-outline',
    },
    message: {
      type: String,
      default: 'No hay datos para mostrar',
    },
  },
}
