//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ModalWithTitleBar from '~/components/Shared/ModalWithTitleBar'

export default {
  name: 'ConfirmRegisterUserWithEmailModal',
  components: { ModalWithTitleBar },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    async viewedModal() {
      this.$store.commit('loading', true)
      try {
        await this.$repository.me.update({
          show_change_password_modal: false,
        })
        window.location.reload()
      } catch (error) {
        this.$buefy.notification.open({
          message:
            'No se pudo actualizar la visibilidad del modal de cambio de contraseña',
          type: 'is-danger',
          duration: 5000,
        })
      } finally {
        this.$store.commit('loading', false)
      }
    },
  },
}
