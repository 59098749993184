//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    imgHeader: {
      type: Object,
      default: () => {
        return {
          alt: 'default-image',
          src: {
            desktop: '/img/about/img-header.png',
            mobile: '/img/register/img-header-mobile.png',
          },
          url: '',
        }
      },
    },
    menu: {
      type: Array,
      default: () => [
        {
          label: 'Home',
          url: '/',
        },
      ],
    },
  },
}
