//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Button from '@/components/Shared/Button.vue'
import EmoticonSadOutline from 'vue-material-design-icons/EmoticonSadOutline'
import DashboardCard from '~/components/Dashboard/DashboardCard'

export default {
  components: {
    Button,
    EmoticonSadOutline,
    DashboardCard,
  },
  props: {
    opportunities: {
      type: Array,
      default: () => [],
    },
    seeMoreLink: {
      type: String,
      default: '',
    },
  },
  methods: {
    handleClickOpportunity(opportunity) {
      this.$emit('click-opportunity', opportunity)
    },
    goToExperiences() {
      this.$router.push('/experiencias')
    },
  },
}
