import _ from 'underscore'

const PROFILE_FIELDS = [
  'names',
  'lastnames',
  'gender',
  'email',
  'birthdate',
  'addresses',
]

export const calcPotentialWorkerCompleteness = (user) => {
  return (
    100 *
    (user.experience_works?.length ||
    user.experience_educationals?.length ||
    user.experience_others?.length
      ? 1
      : 0)
  )
}

export const checkProfileProgress = (user) => {
  const userValues = PROFILE_FIELDS.reduce((acc, field) => {
    return [...acc, user[field]]
  }, [])
  const progress = userValues.filter((value) => value).length
  return (progress / userValues.length) * 100
}

/**
 *
 * @param user
 * @param {string} integrationOfTest
 * @returns {unknown}
 */
export const getOneTest = (user, integrationOfTest) => {
  return user.tests.find(({ integration }) => integration === integrationOfTest)
}

/**
 *
 * @param user
 * @returns {*}
 */
export const getAllExperiences = (user) => {
  return _.union(
    user.experience_works || [],
    user.experience_educationals || [],
    user.experience_others || []
  )
}
