//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TableEmptyData from '@/components/Shared/Table/TableEmptyData'
import Tag from '@/components/Shared/Tag'
import ModalDeleteUser from '@/components/Projects/users/ModalDeleteUser.vue'
import Button from '@/components/Shared/Button'
import { SECTIONS } from '~/constants/questionary'
import { INVITATION_TO_PROJECT } from '~/constants/reminder-email-templates'

export default {
  name: 'UserInvitationList',
  components: { TableEmptyData, Tag, ModalDeleteUser, Button },
  filters: {
    styleForActiveRow(isActive) {
      if (isActive) {
        return 'is-danger'
      }
      return 'is-success'
    },
    customStyleForActiveRow(isActive) {
      if (isActive) {
        return 'custom-is-danger'
      }
      return 'custom-is-success'
    },
    textForActiveRow(isActive) {
      if (isActive) {
        return 'Pendiente'
      }
      return 'Aceptada'
    },
    completenessStyleForActiveRow(user) {
      return SECTIONS.FINISH === user.currentQuestionary?.section
        ? 'is-success'
        : 'is-danger'
    },
    completenessCustomStyleForActiveRow(user) {
      return SECTIONS.FINISH === user.currentQuestionary?.section
        ? 'custom-is-success'
        : 'custom-is-danger'
    },
    completenessTextForActiveRow(user) {
      return SECTIONS.FINISH === user.currentQuestionary?.section
        ? 'Completo'
        : 'Incompleto'
    },
    fullNames(user) {
      return `${user?.names} ${user?.lastnames}`
    },
    showEmail(user) {
      return user?.email
    },
    showShortEmail(user) {
      return user?.email?.length > 20
        ? `${user?.email?.substring(0, 20)}...`
        : user?.email
    },
  },
  props: {
    data: {
      default: () => [],
      type: Array,
    },
    total: {
      default: 0,
      type: Number,
    },
    totalPending: {
      default: 0,
      type: Number,
    },
    perPage: {
      default: 10,
      type: Number,
    },
    project: {
      type: Object,
      default: () => ({}),
    },
    allUsers: {
      type: Array,
      default: () => [],
    },
    toggleMassiveResend: {
      type: Function,
      default: () => {},
    },
    isResendDisabled: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      currentPage: 1,
      checkedRows: [],
      showDropdown: false,
      showTooltip: false,
      selectedRowIndex: -1,
      userToDelete: {},
      isShowModalDeleteUsers: false,
      ids: {
        filtersPendient: {
          _sort: 'created_at:DESC',
          populate: ['user.role'],
          _where: { active: true },
          _start: 0,
        },
      },
    }
  },
  computed: {
    showSelectMassive() {
      return this.resendEmails.length > 0
    },
    resendEmails: {
      get() {
        return this.$store.state.emails.resendEmails
      },
      set(rows) {
        this.$store.commit('emails/setResendEmails', rows)
      },
    },
    resendAll: {
      get() {
        return this.$store.state.emails.resendAll
      },
      set(data) {
        this.$store.commit('emails/setResendAll', data)
      },
    },
    isVisibleSelectedAll() {
      const resendPending = this.data.filter(
        (c) => c.show_change_password_modal === true
      )
      if (this.resendEmails.length === resendPending.length) {
        return true
      }
      return true
    },
    showUsersAndAction() {
      if (this.resendAll) {
        return {
          users: this.totalPending,
          message: 'Anular seleccción',
        }
      }
      return {
        users: this.resendEmails.length,
        message: `Seleccionar los ${
          this.totalPending - this.resendEmails.length
        } usuarios restantes`,
      }
    },
    isAnyCheckboxEnabled() {
      return this.data.some(
        (employee) =>
          employee.show_change_password_modal === true &&
          !employee.autogenerated_email
      )
    },
    isPartialSelected() {
      return this.resendEmails.length > 0 && !this.resendAll
    },
  },
  methods: {
    onPageChange(page) {
      this.currentPage = page
      this.$emit('on-page-change', page)
      this.$store.commit('emails/clearResendEmails')
      this.$store.commit('emails/setResendAll', false)
    },
    OnRowChecked(data) {
      this.$store.commit('emails/setResendEmails', data)
      this.$emit('on-row-checked', data)
      this.$store.commit(
        'emails/setResendAll',
        this.resendEmails.length === this.totalPending
      )
    },
    selectedAllEmails() {
      if (this.resendAll) {
        this.$store.commit('emails/clearResendEmails')
      } else {
        this.$store.commit(
          'emails/setResendEmails',
          this.data.filter(
            (employee) =>
              employee.show_change_password_modal === true &&
              !employee.autogenerated_email
          )
        )
      }
      this.$store.commit('emails/setResendAll', !this.resendAll)
    },
    toggleDropdown(index) {
      if (this.selectedRowIndex === index) {
        this.selectedRowIndex = -1
      } else {
        this.selectedRowIndex = index
      }
    },
    async goToProfile(user) {
      await this.$router.push(`/mis-trabajadores/${user.id}`)
    },
    toogleModalDeleteUsers() {
      this.isShowModalDeleteUsers = !this.isShowModalDeleteUsers
    },
    deleteUserAction(user) {
      this.userToDelete = user
      this.isShowModalDeleteUsers = true
    },
    resendInvitation(user) {
      this.$repository.invitation.sendInvitationByUserIds({
        users: [user.id],
        message: this.project.custom_invitation,
        isReminder: true,
        emailTemplate: INVITATION_TO_PROJECT,
      })
      this.$emit('resend-invitation')
    },
    async deleteUserConfirmed(userToDelete) {
      this.$store.commit('loading', true)
      const usersIds = this.allUsers
        .filter((user) => user.id !== userToDelete.id)
        .map((user) => user.id)
      await this.$repository.project.update(this.$route.params.id, {
        users: usersIds,
        total_users: usersIds.length,
      })
      this.$store.commit('project/SET_TOTAL_USERS', usersIds.length)
      this.$emit('user-deleted')
    },
  },
}
