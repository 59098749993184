//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ExperienceComplete from '@/assets/experiences/experience-complete.svg'
import ExperienceIncomplete from '@/assets/experiences/experience-incomplete.svg'
import AddExperienceButton from '@/components/Experiences/AddExperienceButton'
import EXP_TYPES from '@/constants/experience-types'
import NavigationWizardExperience from '~/components/Experiences/NavigationWizardExperience.vue'
import Button from '~/components/Shared/Button.vue'

export default {
  components: {
    NavigationWizardExperience,
    AddExperienceButton,
    Button,
  },
  props: {
    experience: {
      type: Object,
      default: () => ({}),
    },
    goBack: {
      type: Function,
      default: () => {},
    },
    type: {
      type: String,
      required: true,
      validator: (value) => Object.values(EXP_TYPES).includes(value),
    },
  },
  computed: {
    experienceRegisterImage() {
      return this.experience.completed
        ? ExperienceComplete
        : ExperienceIncomplete
    },
    experienceRegisterText() {
      return this.experience.completed
        ? this.$t('experience.skills.completed.title')
        : this.$t('experience.skills.uncompleted.title')
    },
  },
  methods: {
    handleNext() {
      this.$emit('next', {})
    },

    navigateExperienceWork() {
      if (this.$route.path === `/experiencias/nuevas/laboral`) {
        this.$router.go()
      }
      this.$router.push(`/experiencias/nuevas/laboral`)
    },
    navigateExperienceEducational() {
      if (this.$route.path === `/experiencias/nuevas/educativa`) {
        this.$router.go()
      }
      this.$router.push(`/experiencias/nuevas/educativa`)
    },
    navigateExperienceOther() {
      if (this.$route.path === `/experiencias/nuevas/otras`) {
        this.$router.go()
      }
      this.$router.push(`/experiencias/nuevas/otras`)
    },
  },
}
