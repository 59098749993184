//
//
//
//
//
//

export default {
  props: {
    photoUrl: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      defaultUrl: '/img/user.png',
    }
  },
  computed: {
    imgSrc() {
      return this.photoUrl || this.defaultUrl
    },
  },
}
