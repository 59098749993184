//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import _ from 'underscore'
import Logo from '@/components/Logo'
import ListSkills from '~/components/Occupation/ListSkills'
import { capitalizeWord, stringWithCommaToArray } from '~/util/string'

export default {
  components: { ListSkills, Logo },
  props: {
    occupation: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      obligatorySkills: [],
      optionalSkills: [],
      parents: [],
    }
  },
  async created() {
    this.obligatorySkills = this.getSkillsByType('Obligatorio')
    this.optionalSkills = this.getSkillsByType('Opcional')
    this.parents = await this.$repository.occupation.getOccupationHierarchy({
      hierarchyId: this.occupation.is_custom
        ? this.occupation.base_occupation.occupation_group
        : this.occupation.occupation_group,
    })
  },
  methods: {
    getSkillsByType(skillType) {
      return this.occupation.skills.filter(
        (skill) => skill.skillType === skillType
      )[0]?.skills
    },
    filterSkillByTypeSkill(skills, typeId) {
      return skills.filter((skill) => skill.typeSkill.id === parseInt(typeId))
    },
    groupByHierarchySkill(skills) {
      return _.map(
        _.groupBy(skills, (skill) => {
          if (!skill['skills-group_id']) return ''
          return `${skill['skills-group_id']?.code} ${skill['skills-group_id']?.name}`
        }),
        (values, key) => {
          return {
            hierarchy: key,
            skills: values,
          }
        }
      )
    },
    capitalizeWord,
    stringWithCommaToArray,
  },
}
