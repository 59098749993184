//
//
//
//
//
//
//
//
//
//

import TYPE_SUGGEST_COURSE from '@/constants/type-suggest-course'
import CustomTag from './CustomTag.vue'
export default {
  components: {
    CustomTag,
  },
  props: {
    typeCourse: {
      type: String,
      default: null,
    },
  },
  computed: {
    typeOfTags() {
      const typesTag = {}
      typesTag[TYPE_SUGGEST_COURSE.COMPLETED] = {
        title: TYPE_SUGGEST_COURSE.COMPLETED,
        tooltipMessage: this.$t(
          'company.potentialsUser.table.tagTooltip.completed'
        ),
        type: 'tag-completed',
      }
      typesTag[TYPE_SUGGEST_COURSE.OPTIONAL] = {
        title: TYPE_SUGGEST_COURSE.OPTIONAL,
        tooltipMessage: this.$t(
          'company.potentialsUser.table.tagTooltip.optional'
        ),
        type: 'tag__optional',
      }
      typesTag[TYPE_SUGGEST_COURSE.TO_VALIDATE] = {
        title: TYPE_SUGGEST_COURSE.TO_VALIDATE,
        tooltipMessage: this.$t(
          'company.potentialsUser.table.tagTooltip.toValidate'
        ),
        type: 'tag-to-validate',
      }
      typesTag[TYPE_SUGGEST_COURSE.REQUIRED] = {
        title: TYPE_SUGGEST_COURSE.REQUIRED,
        tooltipMessage: this.$t(
          'company.potentialsUser.table.tagTooltip.required'
        ),
        type: 'tag-required',
      }
      return typesTag[this.typeCourse]
    },
  },
}
