//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment'
import config from '~/config'
export default {
  props: {
    isCompany: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      contactEmail: config.contactEmail,
      logos: [
        {
          src: '/img/logos/sence_white.svg',
          alt: 'logo sence',
        },
        {
          src: '/img/logos/subtrabajo_white.png',
          alt: 'logo subtrabajo',
        },
        {
          src: '/img/logos/otic_sofofa_white.svg',
          alt: 'logo otic sofofa',
        },
        {
          src: '/img/logos/sofofa_white.svg',
          alt: 'logo sofofa',
        },
        {
          src: '/img/logos/bid_white.svg',
          alt: 'logo bid',
        },
      ],
    }
  },
  computed: {
    year() {
      return moment().format('YYYY')
    },
  },
}
