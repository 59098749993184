//
//
//
//
//
//
//
//
//
//
//
//

import UserOpportunities from '@/components/Dashboard/UserOpportunities'
import { mapMutations } from 'vuex'

export default {
  components: {
    UserOpportunities,
  },
  props: {
    userId: {
      type: Number,
      required: true,
    },
    titlePath: {
      type: String,
      default: () => 'dashboard.employee.opportunities.title',
    },
    subTitlePath: {
      type: String,
      default: () => 'dashboard.employee.opportunities.subtitle',
    },
    opportunities: {
      type: Array,
      default: () => [],
    },
    url: {
      type: String,
      default: () => '/oportunidades/reconversion-laboral',
    },
  },
  data: () => ({
    opportunitiesUser: [],
  }),

  computed: {
    formatedOpportunities() {
      return this.opportunities.map((opportunity) => ({
        ...opportunity,
        subheader: '',
        title: opportunity.name,
        titleHighlight: opportunity.project_name
          ? `"${opportunity.project_name}"`
          : '',
        subtitle: this.$t('dashboard.complete.match', [
          Math.round(opportunity.distance),
        ]),
        imgSource: '/img/dashboard/opportunity.svg',
        buttonText: this.$t('dashboard.complete.buttons.opportunity'),
      }))
    },
  },
  methods: {
    ...mapMutations({
      setOpportunity: 'opportunity/setOpportunity',
    }),
    handleClickOpportunity(opportunity) {
      this.$router.push(`/oportunidades/${opportunity.id}`)
    },
  },
}
