//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ValidationObserver } from 'vee-validate'
import ModalWithTitleBar from '~/components/Shared/ModalWithTitleBar.vue'
import Button from '~/components/Shared/Button'
import { ACTION_ENTITY } from '~/constants/core'

export default {
  name: 'JobSeekerFairModalForm',
  components: {
    ModalWithTitleBar,
    ValidationObserver,
    Button,
  },
  props: {
    form: {
      type: Object,
      default: () => {
        return {
          vacancies: 0,
          occupation: undefined,
          project_name: '',
          requirements: '',
        }
      },
    },
    visible: {
      type: Boolean,
      default: false,
    },
    occupations: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    textAction: {
      type: String,
      default: '',
    },
    actionForm: {
      type: String,
      default: ACTION_ENTITY.CREATE,
    },
  },
  data() {
    return {
      defaultForm: {
        vacancies: 0,
        occupation: null,
        project_name: '',
        requirements: '',
        active: false,
      },
    }
  },
  created() {
    if (this.$props.form) this.defaultForm = { ...this.$props.form }
  },
  methods: {
    onToggle() {
      this.$emit('toggle')
    },
    onCancel() {
      this.$emit('cancel')
    },
    onSubmit() {
      if (this.defaultForm.id === 0) {
        this.$emit('edit', this.defaultForm)
      } else {
        this.$emit('submit', this.defaultForm)
      }
    },
  },
}
