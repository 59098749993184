//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'FilteredTag',
  props: {
    label: {
      type: String,
      default: '',
    },
    filteredData: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    getTagText() {
      return this.label + this.formatTagText(this.filteredData)
    },
    getTooltipText() {
      return this.label + this.formatTooltipText(this.filteredData)
    },
  },
  methods: {
    formatTagText(selectedArray) {
      return (
        selectedArray[0].name +
        `${selectedArray.length > 1 ? ` + ${selectedArray.length - 1}` : ''}`
      )
    },
    formatTooltipText(selectedArray) {
      return selectedArray
        .map((type) => type.name)
        .toString()
        .replaceAll(',', ', ')
    },
    onCloseTag() {
      this.$emit('close')
    },
  },
}
