import { render, staticRenderFns } from "./LandingCarouselOccupation.vue?vue&type=template&id=9fffe6fc&scoped=true&"
import script from "./LandingCarouselOccupation.vue?vue&type=script&lang=js&"
export * from "./LandingCarouselOccupation.vue?vue&type=script&lang=js&"
import style0 from "./LandingCarouselOccupation.vue?vue&type=style&index=0&id=9fffe6fc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9fffe6fc",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LandingCardOccupation: require('/workspace/components/Landing/LandingCardOccupation.vue').default})
