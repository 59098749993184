//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment'
import Button from '@/components/Shared/Button.vue'
import config from '~/config'
export default {
  components: {
    Button,
  },
  data() {
    return {
      contactEmail: config.contactEmail,
    }
  },
  computed: {
    year() {
      return moment().format('YYYY')
    },
  },
  methods: {
    backToHome() {
      if (this.$strapi.user) {
        this.$router.push('/inicio')
      } else {
        this.$router.push('/')
      }
    },
  },
}
