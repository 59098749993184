//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Button from '@/components/Shared/Button.vue'
import ModalWithTitleBar from '~/components/Shared/ModalWithTitleBar'
import WarningExitExperience from '~/assets/experiences/warning-exit-experience.svg'

export default {
  components: {
    ModalWithTitleBar,
    Button,
    WarningExitExperience,
  },
  props: {
    modal: {
      type: Boolean,
      default: () => false,
    },
  },
  methods: {
    handleCancel() {
      this.$emit('cancel')
    },
    handleConfirm() {
      this.$emit('confirm')
    },
  },
}
