//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import NoResultFinder from './NoResultFinder.vue'

export default {
  name: 'SkillsInventoryFinder',
  components: {
    NoResultFinder,
  },
  props: {
    hasResult: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      criteria: '',
      doneTypingInterval: 500,
      typingTimer: 0,
    }
  },

  methods: {
    filterByName() {
      clearTimeout(this.typingTimer)
      this.typingTimer = setTimeout(
        this.findByCriteria,
        this.doneTypingInterval
      )
    },
    findByCriteria() {
      this.$emit('search-skill', this.criteria)
    },
  },
}
