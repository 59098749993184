var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wizard-new-other-experience"},[_c('div',{staticClass:"container is-fluid",staticStyle:{"padding-bottom":"1rem"}},[_c('b-steps',{attrs:{"animated":true,"rounded":true,"size":"is-small","has-navigation":false},model:{value:(_vm.currentStep),callback:function ($$v) {_vm.currentStep=$$v},expression:"currentStep"}},[_c('b-step-item',{attrs:{"icon":"clipboard-edit-outline","label":_vm.$t('experience.wizard.steps.form.label')}},[_c('div',{staticClass:"columns is-centered"},[_vm._t("step-1",null,null,{ onPreviousStep: _vm.onPreviousStep, onNextStep: _vm.onNextStep, currentExperience: _vm.currentExperience })],2)]),_vm._v(" "),_c('b-step-item',{attrs:{"icon":"school","label":_vm.$t('experience.wizard.steps.skills.label')}},[_vm._t("step-2",null,null,{
            onPreviousStep: _vm.onPreviousStep,
            onNextStep: _vm.onNextStep,
            currentExperience: _vm.currentExperience,
          })],2),_vm._v(" "),_c('b-step-item',{attrs:{"icon":"checkbox-marked-circle-outline","label":_vm.$t('experience.wizard.steps.finished.label')}},[_vm._t("step-3",null,null,{
            onPreviousStep: _vm.onPreviousStep,
            onNextStep: _vm.onNextStep,
            currentExperience: _vm.currentExperience,
          })],2)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }