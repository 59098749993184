const Request = require('@/repositories/request')
const qs = require('qs')

class Address extends Request {
  /**
   *
   * @param {{_where: Object,
        _limit: int,
        _start: int}} params
   * @returns {*}
   */
  findAll(params) {
    const stringifyParams = qs.stringify({ ...params }, { encode: false })
    return this.$strapi.$addresses.find(stringifyParams)
  }

  /**
   *
   * @param data
   * @returns {*}
   */
  create(data) {
    return this.$strapi.$addresses.create(data)
  }
}
module.exports = Address
