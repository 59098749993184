//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FormInput from '~/components/Form/FormInput'

export default {
  name: 'FormChangePassword',
  components: { FormInput },
  props: {
    form: {
      type: Object,
      default: () => {
        return { password: '', newPassword: '', passwordConfirm: '' }
      },
    },
  },

  methods: {
    submit() {
      this.$emit('submit', this.form)
    },
  },
}
