//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import KanbanBoardConstants from '@/constants/kanban-board'
import Button from '@/components/Shared/Button.vue'
import FilterFormGroup from '@/components/SkillsInventory/Filters/FilterFormGroup'
import FilteredTag from '@/components/SkillsInventory/FilteredTag.vue'
export default {
  name: 'KanbanBoardFilters',
  components: {
    Button,
    FilterFormGroup,
    FilteredTag,
  },
  fetch() {
    this.skillTypes = KanbanBoardConstants.skillTypes
    this.skillEsenciality = KanbanBoardConstants.skillEsenciality
  },
  data() {
    return {
      criteria: '',
      skillTypes: [],
      skillEsenciality: [],
      selectedSkillTypes: [],
      selectedSkillEsenciality: [],
      filteredSkillTypes: [],
      filteredSkillEsenciality: [],
      doneTypingInterval: 500,
      typingTimer: 0,
      showFilters: false,
    }
  },
  computed: {
    disabledSearchButton() {
      return (
        this.selectedSkillTypes.length > 0 ||
        this.selectedSkillEsenciality.length > 0
      )
    },
    showFiltersTag() {
      return (
        this.filteredSkillTypes.length > 0 ||
        this.filteredSkillEsenciality.length > 0
      )
    },
  },
  methods: {
    findByCriteria() {
      if (this.showFilters) {
        this.onShowFilters()
      }
      this.$emit('search-skill', this.criteria)
    },
    onSearch() {
      this.onShowFilters()
      this.filteredSkillTypes = this.selectedSkillTypes
      this.filteredSkillEsenciality = this.selectedSkillEsenciality
      this.$emit('search-filters', {
        selectedSkillTypes: this.formatSkillTypes(this.selectedSkillTypes),
        selectedSkillEsenciality: this.formatSkillEsenciality(
          this.selectedSkillEsenciality
        ),
      })
    },
    formatSkillTypes() {
      return this.selectedSkillTypes.map((selectedSkillType) => ({
        key: 'skill_type',
        value: selectedSkillType.skill_type,
      }))
    },
    formatSkillEsenciality() {
      return this.selectedSkillEsenciality.map((skillEsenciality) => ({
        key: 'skillType',
        value: this.formatSkillEsencialityName(skillEsenciality.name),
      }))
    },
    formatSkillEsencialityName(name) {
      if (name === 'Esencial') {
        return 'Obligatorio'
      }
      if (name === 'Optativa') {
        return 'Opcional'
      }
      return 'Pendiente'
    },
    onCloseSkillTypesTag() {
      this.filteredSkillTypes = []
      this.selectedSkillTypes = []
      this.$emit('close-skill-types', this.selectedSkillTypes)
    },
    onCloseSkillReuseLevelsTag() {
      this.filteredSkillEsenciality = []
      this.selectedSkillEsenciality = []
      this.$emit('close-skill-esenciality', this.selectedSkillEsenciality)
    },
    onShowFilters() {
      this.showFilters = !this.showFilters
    },
    onShowInsights() {
      this.showInsights = !this.showInsights
    },
    showInsights() {
      if (this.showInsights) {
        this.onShowInsights()
      }
      this.$emit('show-insights', this.showInsights)
    },
    onAddSkills() {
      this.$emit('add-skills')
    },
  },
}
