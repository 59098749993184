//
//
//
//
//
//
//
//
//
//
//

import LogosCarousel from '../Shared/LogosCarousel.vue'

export default {
  components: {
    LogosCarousel,
  },
}
