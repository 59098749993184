//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CloseCardWizard from './CloseCardWizard.vue'
export default {
  components: { CloseCardWizard },
  props: {
    mainTargetOccupationId: {
      type: Number,
      default: null,
    },
  },
}
