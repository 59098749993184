//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import NewProjectsWizardButtons from '@/components/Projects/NewProjectWizardButtons'
import Button from '~/components/Shared/Button'

export default {
  name: 'MainStepSection',
  components: {
    NewProjectsWizardButtons,
    Button,
  },
  props: {
    skillsInventory: {
      type: Boolean,
      default: false,
    },
    matchPotential: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    validServices() {
      return this.skillsInventory || this.matchPotential
    },
  },
  methods: {
    updateServiceMatch() {
      if (this.matchPotential) {
        document.getElementById('servicesContent').focus()
      }
      this.$emit('update-match', !this.matchPotential)
    },
    updateServiceInventory() {
      if (this.skillsInventory) {
        document.getElementById('servicesContent').focus()
      }
      this.$emit('update-inventory', !this.skillsInventory)
    },
    handleBack() {
      this.$emit('back')
    },
    handleContinue() {
      this.$emit('next')
    },
  },
}
