//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import KanbanBoard from '@/components/KanbanBoard/KanbanBoard.vue'
import { getReuseLevel } from '@/util/skill'
import SKILL_TYPES from '@/constants/skill-types'
import SkillConstants from '@/constants/skill'
import KanbanBoardConstants from '@/constants/kanban-board'
import KanbanBoardInsights from './KanbanBoardInsights.vue'
import SkillDetailModal from './SkillDetailModal.vue'
import KanbanBoardFilters from './KanbanBoardFilters.vue'
import KanbanAlertMessage from './KanbanAlertMessage.vue'

export default {
  components: {
    KanbanBoard,
    KanbanBoardFilters,
    SkillDetailModal,
    KanbanBoardInsights,
    KanbanAlertMessage,
  },
  props: {
    cards: {
      type: Array,
      default: () => [],
    },
    deletedSkills: {
      type: Array,
      default: () => [],
    },
    pendingSkills: {
      type: Array,
      default: () => [],
    },
    isPublished: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchTerm: '',
      selectedSkillTypes: [],
      selectedSkillEsenciality: [],
      filters: [],
      selectOptions: [
        {
          label: 'Pendiente',
          value: SKILL_TYPES.PENDING,
        },
        {
          label: 'Esencial',
          value: SKILL_TYPES.MANDATORY,
        },
        {
          label: 'Optativa',
          value: SKILL_TYPES.OPTIONAL,
        },
      ],
      modal: {
        currentSkill: {},
        visible: false,
      },
      isVisibleInsights: false,
    }
  },
  computed: {
    formattedCards() {
      if (this.cards.length > 0) {
        const mandatorySkills = this.cards
          .find((skillGroup) => skillGroup.skillType === SKILL_TYPES.MANDATORY)
          .skills.map((skill) => {
            return this.formatCard(skill, SKILL_TYPES.MANDATORY)
          })

        const optionalSkills = this.cards
          .find((skillGroup) => skillGroup.skillType === SKILL_TYPES.OPTIONAL)
          .skills.map((skill) => {
            return this.formatCard(skill, SKILL_TYPES.OPTIONAL)
          })

        const pendingSkills = this.pendingSkills.map((skill) => {
          return this.formatCard(skill, SKILL_TYPES.PENDING)
        })
        const deletedSkills = this.deletedSkills.map((skill) => {
          return this.formatCard(skill, SKILL_TYPES.PENDING)
        })
        return [
          ...this.sortSkills(pendingSkills),
          ...this.sortSkills(mandatorySkills),
          ...this.sortSkills(optionalSkills),
          ...this.sortSkills(deletedSkills),
        ]
      }
      return []
    },

    customFilters() {
      if (this.searchTerm === '') {
        return this.filters
      }

      return [
        { key: 'title', value: this.searchTerm, search: true },
        ...this.filters,
      ]
    },
    getColumns() {
      return [
        ...SkillConstants.skillReuseLevels,
        {
          name: 'Eliminadas',
          code: 'deleted',
        },
      ]
    },
    areTherePendingCards() {
      if (!this.isPublished) {
        return false
      }
      const filteredCards = this.formattedCards.filter(
        (card) => card.skillType === 'Pendiente' && !card.isDeleted
      )
      return filteredCards.length > 0
    },
  },
  methods: {
    sortSkills(skills) {
      return skills.sort((a, b) => a.title.localeCompare(b.title))
    },
    formatCard(skill, skillType) {
      return {
        ...skill,
        skillType,
        typeOfSkill: this.getSkillName(skill.skill_type),
        reuse_level_code: skill.reuseLevel.title_hola,
        reuse_level: getReuseLevel(skill.reuseLevel.title_hola),
      }
    },
    onSearchByCriteria(criteria) {
      this.searchTerm = criteria
    },
    onSearchByFilters(filters) {
      this.selectedSkillTypes = filters.selectedSkillTypes
      this.selectedSkillEsenciality = filters.selectedSkillEsenciality
      this.filters = [
        ...this.selectedSkillTypes,
        ...this.selectedSkillEsenciality,
      ]
    },
    onCloseSkillTypesTag(selectedSkillTypes) {
      this.selectedSkillTypes = selectedSkillTypes
      this.filters = [
        ...this.selectedSkillTypes,
        ...this.selectedSkillEsenciality,
      ]
    },
    onCloseSkillEsencialityTag(selectedSkillEsenciality) {
      this.selectedSkillEsenciality = selectedSkillEsenciality
      this.filters = [
        ...this.selectedSkillTypes,
        ...this.selectedSkillEsenciality,
      ]
    },
    onToggle() {
      this.modal.visible = !this.modal.visible
    },
    onToggleInsights() {
      this.isVisibleInsights = !this.isVisibleInsights
    },
    handleCardClick(card) {
      this.modal.currentSkill = card
      this.modal.visible = !this.modal.visible
    },
    handleUpdateCard(card) {
      this.$emit('update-selected', card)
    },

    onAddSkills() {
      this.$emit('add-skills')
    },
    getSkillName(skillType) {
      const matches = KanbanBoardConstants.skillTypes.filter(
        (skill) => skill.skill_type === skillType
      )
      if (matches.length === 0) {
        return '' // return an empty string if the skill type is not found
      }
      return matches[0].name
    },
    handleDeleteCard(card) {
      this.$emit('delete-skill', card)
    },
    handleRestoreCard(card) {
      this.$emit('restore-skill', card)
    },
  },
}
