const Request = require('@/repositories/request')

class SkillsGroup extends Request {
  /**
   *
   * @param {Object} params
   * @return {*}
   */
  findHeaders() {
    return this.$strapi.$http.$get(`/skills-groups/headers`)
  }
}

module.exports = SkillsGroup
