//
//
//
//
//
//
//
//
//
//
//
//
//
//

import InstructiveSection from '@/components/Questionaries/instructive/InstructiveSection'
import ModalStartQuestionary from '~/components/QuestionaryProject/Modals/ModalStartQuestionary'

export default {
  name: 'Introduction',
  components: {
    ModalStartQuestionary,
    InstructiveSection,
  },
  props: {
    subtitle: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showStartQuestionaryModal: false,
    }
  },
  methods: {
    togleStartQuestionaryModal() {
      this.showStartQuestionaryModal = !this.showStartQuestionaryModal
    },
    startQuestionary() {
      this.togleStartQuestionaryModal()
      this.$emit('next')
    },
  },
}
