//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ModalWithTitleBar from '@/components/Shared/ModalWithTitleBar'
import AnnouncementImg from '~/assets/svg/announcement.svg'
import Button from '~/components/Shared/Button'

export default {
  name: 'DashboardAnnouncementModal',
  components: {
    AnnouncementImg,
    ModalWithTitleBar,
    Button,
  },
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    onCancel() {
      this.$emit('cancel')
    },
    onSubmit() {
      this.$emit('submit')
    },
  },
}
