import { render, staticRenderFns } from "./CompanyProjectsSearchBar.vue?vue&type=template&id=b9bed5ae&scoped=true&"
import script from "./CompanyProjectsSearchBar.vue?vue&type=script&lang=js&"
export * from "./CompanyProjectsSearchBar.vue?vue&type=script&lang=js&"
import style0 from "./CompanyProjectsSearchBar.vue?vue&type=style&index=0&id=b9bed5ae&lang=scss&scoped=true&"
import style1 from "./CompanyProjectsSearchBar.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b9bed5ae",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FormInput: require('/workspace/components/Form/FormInput.vue').default,FormSelect: require('/workspace/components/Form/FormSelect.vue').default})
