//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Button from '~/components/Shared/Button'
export default {
  components: {
    Button,
  },
  methods: {
    btnReturn() {
      this.$emit('btnReturn')
    },

    btnCustomOccupation() {
      this.$emit('btnCustomOccupation')
    },
  },
}
