//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PROJECT from '@/constants/projects'
import ChevronRight from 'vue-material-design-icons/ChevronRight'
import TableEmptyData from '@/components/Shared/Table/TableEmptyData'
export default {
  components: {
    ChevronRight,
    TableEmptyData,
  },
  filters: {
    getTitleOccupation(project) {
      return project?.occupation?.title
    },
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },

    filters: {
      type: Object,
      default: () => ({
        text: '',
        projectId: null,
      }),
    },
    perPage: {
      type: Number,
      default: 10,
    },
  },
  computed: {
    filteredData() {
      return this.data.filter((project) => {
        const { text, projectId } = this.filters
        const textMatch = project.occupation.title
          .toLowerCase()
          .includes(text.toLowerCase())
        const projectMatch = projectId ? project.id === projectId.value : true
        return textMatch && projectMatch
      })
    },
  },
  methods: {
    getClassByStatus(status) {
      return PROJECT[status].class
    },
    getNameByStatus(status) {
      return PROJECT[status].name
    },
    onPageChange(page) {
      this.page = page
      this.$fetch()
    },
    isVisibleDetail(project) {
      return project.status !== PROJECT.FINISHED.value
    },
    openOccupationDetail(project) {
      this.isVisibleDetail(project) &&
        this.$router.push(
          `/mis-proyectos/${project.id}/ocupaciones/${project.occupation.id}`
        )
    },
  },
}
