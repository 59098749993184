//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import config from '~/config'
import Wrapper from '~/components/Shared/Wrapper.vue'
import Button from '~/components/Shared/Button.vue'
import ModalWithTitleBar from '~/components/Shared/ModalWithTitleBar.vue'
const TOTAL_LOCATIONS_VISIBLE = 8
export default {
  components: {
    Wrapper,
    Button,
    ModalWithTitleBar,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    isOpen: {
      type: Boolean,
      default: true,
    },
    locations: {
      type: Array,
      default: () => [],
    },
    headerDesktop: {
      type: Object,
      default: () => null,
    },
    headerMobile: {
      type: Object,
      default: () => null,
    },
  },
  data: () => {
    return {
      modalCommunesTitle: 'Revisa si tu comuna participa de esta convocatoria',
      isVisibleModalCommunes: false,
      baseUrl: config.gcsBaseurl,
    }
  },
  computed: {
    locationsVisible() {
      return this.locations
        .slice(0, TOTAL_LOCATIONS_VISIBLE)
        .reduce((locationsText, location) => {
          locationsText += `${location.name}, `
          return locationsText
        }, '')
    },
    isMore() {
      const TOTAL_LOCATIONS = this.locations.length
      return TOTAL_LOCATIONS > TOTAL_LOCATIONS_VISIBLE
    },
    statusLabel() {
      return this.isOpen
        ? this.$t('jobSeekerFair.status.open')
        : this.$t('jobSeekerFair.status.close')
    },
    srcImageHeaderDesktop() {
      return this.headerDesktop
        ? `${this.baseUrl}${this.headerDesktop.url}`
        : require(`~/assets/images/landing/img-header.png`)
    },
    srcImageHeaderMobile() {
      return this.headerMobile
        ? `${this.baseUrl}${this.headerMobile.url}`
        : require(`~/assets/images/landing/img-header-mobile.png`)
    },
  },
  methods: {
    toggleModalCommunes() {
      this.isVisibleModalCommunes = !this.isVisibleModalCommunes
    },
    togglePilotModalReq() {
      this.$emit('toggle-pilot-model')
    },
  },
}
