const defaultWorkingExperience = {
  id: undefined,
}

export const state = () => ({
  workingExperience: defaultWorkingExperience,
})

export const mutations = {
  setWorkingExperience(state, data) {
    state.workingExperience = data
  },
  clearWorkingExperience(state) {
    state.workingExperienceId = defaultWorkingExperience
  },
}
