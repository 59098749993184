//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CloseCircle from 'vue-material-design-icons/CloseCircle'
export default {
  components: {
    CloseCircle,
  },
  props: {
    close: {
      default: () => {},
      type: Function,
    },
    title: {
      default: 'title',
      type: String,
    },
  },
}
