//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ValidationObserver } from 'vee-validate'
import NewProjectsWizardOptionalButtons from '@/components/Projects/NewProjectWizardOptionalButtons.vue'
import { mapGetters, mapActions } from 'vuex'
import Tabs from '@/components/Shared/Tabs.vue'
import { TYPES } from '@/constants/project-types'
import ModelsContainer from './ModelsContainer.vue'

export default {
  name: 'StepModels',
  components: {
    ValidationObserver,
    NewProjectsWizardOptionalButtons,
    Tabs,
    ModelsContainer,
  },
  async fetch() {
    this.$store.commit('loading', true)
    const dimensions = await this.$repository.skillPacks.findAll({
      populate: 'models',
      _limit: -1,
    })
    this.models = this.formatModels(dimensions)
    this.selectedDimensionIds = [
      ...(this.getBaseProject?.skill_packs || [])?.map((sp) => sp.id || sp),
    ]
    this.$store.commit('loading', false)
  },
  data() {
    return {
      models: [],
      selectedDimensionIds: [],
    }
  },
  computed: {
    ...mapGetters({
      getBaseProject: 'project/getBaseProject',
    }),
    isValidOmit() {
      return this.getBaseProject.type !== TYPES.MODELS
    },
    headerTexts() {
      return this.getBaseProject.type === TYPES.MODELS
        ? {
            title: this.$t('projects.new.steps.models.only_models.title'),
            subtitle: this.$t('projects.new.steps.models.only_models.subtitle'),
          }
        : {
            title: this.$t('projects.new.steps.models.with_models.title'),
            subtitle: this.$t('projects.new.steps.models.with_models.subtitle'),
          }
    },
    isValidNext() {
      return this.selectedDimensionIds.length > 0
    },
    modelsWithMetadata() {
      return this.models.map((model) => ({
        ...model,
        metadata:
          model.id === 0
            ? this.selectedDimensionIds.length
            : this.selectedDimensionIds.filter((id) =>
                model.skill_packs.map((sp) => sp.id).includes(id)
              ).length,
      }))
    },
    skillPacksFormatted() {
      const skillPackMap = {}
      this.models.forEach((model) => {
        if (model.id === 0) return // Missing the defined 'Total'
        model.skill_packs.forEach((skillPack) => {
          if (!skillPackMap[skillPack.id]) {
            skillPackMap[skillPack.id] = {
              id: skillPack.id,
              name: skillPack.name,
              models: [],
            }
          }
          skillPackMap[skillPack.id].models.push({
            id: model.id,
            name: model.name,
          })
        })
      })

      return Object.values(skillPackMap)
    },
  },
  methods: {
    ...mapActions({
      setProjectAttribute: 'project/setProjectAttribute',
    }),
    handleOmit() {
      this.$emit('next')
      this.setProjectAttribute({
        key: 'has_models',
        value: false,
      })
      this.setProjectAttribute({
        key: 'skill_packs',
        value: [],
      })
    },
    handleBack() {
      this.$emit('back')
      this.setProjectAttribute({
        key: 'has_models',
        value: false,
      })
    },
    handleContinue() {
      this.setProjectAttribute({
        key: 'skill_packs',
        value: this.skillPacksFormatted.filter((sk) =>
          this.selectedDimensionIds.includes(sk.id)
        ),
      })
      this.setProjectAttribute({
        key: 'has_models',
        value: true,
      })
      this.$emit('next')
    },
    handleChange(value) {
      if (!this.selectedDimensionIds.includes(value)) {
        this.selectedDimensionIds.push(value)
      } else {
        this.selectedDimensionIds = this.selectedDimensionIds.filter(
          (id) => id !== value
        )
      }
      this.setProjectAttribute({
        key: 'skill_packs',
        value: this.selectedDimensionIds,
      })
    },
    formatModels(dimensions) {
      const models = [{ id: 0, name: 'Todos', metadata: '0', skill_packs: [] }]
      dimensions.forEach((dimension) => {
        const modelIds = models.map((model) => model.id)
        models[0].skill_packs.push({
          id: dimension.id,
          name: dimension.name,
          description: dimension.description,
          image_url: dimension.image_url,
        })
        dimension.models.forEach((model) => {
          if (!modelIds.includes(model.id)) {
            models.push({
              id: model.id,
              name: model.name,
              metadata: '0',
              skill_packs: [],
            })
          }
          models
            .find(({ id }) => id === model.id)
            .skill_packs.push({
              id: dimension.id,
              name: dimension.name,
              description: dimension.description,
              image_url: dimension.image_url,
            })
        })
      })
      return models
    },
  },
}
