var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"trap-focus":"","destroy-on-hide":false,"aria-role":"dialog","aria-modal":"","can-cancel":false},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('modal-with-title-bar',{attrs:{"title":_vm.$t('companies.requiredOccupations.createRequiredOccupation.title'),"close":_vm.handleClose}},[[_c('div',{staticClass:"p-4"},[_c('p',{staticClass:"has-text-grey"},[_vm._v("\n              "+_vm._s(_vm.$t(
                  'companies.requiredOccupations.createRequiredOccupation.description'
                ))+"\n            ")])]),_vm._v(" "),_c('div',{staticClass:"vacant-modal-input-container"},[_c('FormAutocomplete',{attrs:{"data-test":"ocuppationSearchBar","rules":'required',"label":"title","options":_vm.metaOccupations,"loading":_vm.occupationSearchLoading,"disabled":_vm.occupationSearchLoading,"placeholder":_vm.$t(
                  'companies.requiredOccupations.createRequiredOccupation.occupationInput.placeholder'
                ),"label-field":_vm.$t(
                  'companies.requiredOccupations.createRequiredOccupation.occupationInput.label'
                )},on:{"select":function($event){_vm.selectedOccupation = $event}},model:{value:(_vm.selectedOccupation),callback:function ($$v) {_vm.selectedOccupation=$$v},expression:"selectedOccupation"}}),_vm._v(" "),_c('FormSelect',{attrs:{"data-test":"add-occupation-modal-project","options":_vm.activeProjects,"track-by":"id","label":"name","placeholder":"Seleccione un proyecto","label-field":_vm.$t(
                  'companies.requiredOccupations.createRequiredOccupation.projectInput.label'
                ),"disabled":!_vm.selectedOccupation,"rules":"required"},model:{value:(_vm.selectedProject),callback:function ($$v) {_vm.selectedProject=$$v},expression:"selectedProject"}})],1)],_vm._v(" "),_c('div',{staticClass:"is-justify-content-flex-end",attrs:{"slot":"footer"},slot:"footer"},[_c('Button',{attrs:{"type":"is-white"},on:{"click":_vm.handleClose}},[_vm._v("\n            "+_vm._s(_vm.$t(
                'companies.requiredOccupations.createRequiredOccupation.secondaryButton'
              ))+"\n          ")]),_vm._v(" "),_c('Button',{attrs:{"disabled":invalid,"type":"is-primary is-rounded"},on:{"click":_vm.handleSave}},[_vm._v("\n            "+_vm._s(_vm.$t(
                'companies.requiredOccupations.createRequiredOccupation.primaryButton'
              ))+"\n          ")])],1)],2)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }