//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SearchEngine from '@/components/Shared/SearchEngine/SearchEngine.vue'
import FormLabel from '@/components/Form/FormLabel'
export default {
  components: {
    FormLabel,
    SearchEngine,
  },
  props: {
    vid: {
      type: String,
      default: '',
    },
    rules: {
      type: [Object, String],
      default: '',
    },
    value: {
      type: null,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    focusPrevent: {
      type: Function,
      default: () => {},
    },
    currentValue: {
      type: Object,
      default: () => null,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    addNewMessage: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    innerValue: '',
    pattern: '',
    valid: null,
    errors: [],
  }),
  computed: {
    groupLabels() {
      return {
        'match-pattern': this.$t(
          'components.searchEngine.groups.occupations.byTitle',
          [this.pattern]
        ),
        'not-match-pattern': this.$t(
          'components.searchEngine.groups.occupations.byLabels'
        ),
      }
    },
  },
  watch: {
    innerValue(newVal) {
      this.$emit('input', newVal)
    },
  },
  created() {
    if (this.value) {
      this.innerValue = this.value
    }
  },
  methods: {
    searchOccupations(pattern) {
      return this.$repository.occupation.searchOccupations(pattern)
    },
    handleInputSearchOccupation(input) {
      this.pattern = input
    },
    handleSelectOccupation(option) {
      this.validate(option)
      this.innerValue = option
      this.$emit('custom-occupation-mode', false)
    },
    validate(option) {
      this.valid = !!option
      this.errors = option ? [] : [`Este campo ${this.name} es obligatorio`]
    },
    handleAddCustomOccupation() {
      this.validate('ok')
      this.$emit('custom-occupation-mode', true)
    },
  },
}
