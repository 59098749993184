//
//
//
//
//
//
//
//

import MessageMixin from 'buefy/src/utils/MessageMixin'

export default {
  mixins: [MessageMixin],
  props: {
    fullwidth: {
      type: Boolean,
      default: false,
    },
  },
}
