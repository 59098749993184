//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ProgressIndicator from '@/components/Shared/ProgressIndicator'
import SkillPackTable from '~/components/Skills/SkillPackTable'
export default {
  components: { SkillPackTable, ProgressIndicator },
  filters: {
    getTotalHours({ courses }) {
      if (courses.length === 0) return 0
      return courses.reduce((acc, course) => acc + course.hours, 0)
    },
  },
  props: {
    opportunities: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: () => 'Oportunidades',
    },
    titleTooltip: {
      type: String,
      default: () => '',
    },
  },
  methods: {
    isRowDetailEnabled(row) {
      return row.description
    },
    toggleDetails(row) {
      if (this.isRowDetailEnabled(row)) {
        this.$refs.jobMatchesTable.toggleDetails(row)
      }
    },
    isRowExpanded(row) {
      return this.$refs.jobMatchesTable.isVisibleDetailRow(row)
    },
  },
}
