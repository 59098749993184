//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ProjectFormSelect from '@/components/Form/ProjectFormSelect.vue'
import ObjectivesModal from '@/components/Projects/Modals/ObjectivesModal.vue'
import { mapFields } from '@/util/helpers'
import { getObjectives, getObjectiveDescription } from '@/util/project'
import { mapGetters, mapActions } from 'vuex'
import PROJECT_CONSTANTS from '@/constants/projects'
import FormLabel from '@/components/Form/FormLabel'

export default {
  name: 'StepOne',
  components: {
    ProjectFormSelect,
    ObjectivesModal,
    FormLabel,
  },
  props: {
    minimal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showObjectivesModal: false,
    }
  },
  computed: {
    ...mapFields({
      fields: ['objective', 'objective_detail'],
      module: 'project',
      base: 'baseProject',
      mutation: 'SET_PROJECT_ATTRIBUTE',
    }),
    ...mapGetters({
      getBaseProject: 'project/getBaseProject',
    }),
    showObjectiveDetail() {
      return this.getBaseProject.objective?.value === PROJECT_CONSTANTS.OTHERS
    },
    objectives() {
      return getObjectives(this.$t('projects.new.steps.first'))
    },
  },
  watch: {
    objective(objective) {
      const value = objective?.value
      const STEP_CONFIGURATION = 0
      if (this.getBaseProject.current_step > STEP_CONFIGURATION) return
      const description = getObjectiveDescription(
        value,
        this.$t('projects.new.steps.first.modal.objectives.list')
      )
      this.setProjectAttribute({
        key: 'description',
        value: description,
      })
    },
  },
  methods: {
    ...mapActions({
      setProjectAttribute: 'project/setProjectAttribute',
    }),
    toogleObjectivesModal() {
      this.showObjectivesModal = !this.showObjectivesModal
    },
  },
}
