const Request = require('@/repositories/request')
const qs = require('qs')

class ExperienceWork extends Request {
  /**
   *
   * @param {Object} params
   * @return {*}
   */
  find(params) {
    const stringifyParams = qs.stringify({ ...params }, { encode: false })
    return this.$strapi['$experience-works'].find(stringifyParams)
  }

  /**
   *
   * @param {int} id
   * @param queryObject
   * @returns {*}
   */
  findOne(id, queryObject) {
    const query = qs.stringify(queryObject, { encode: false })
    return this.$strapi.$http.$get(`/experience-works/${id}?${query}`)
  }

  /**
  *
  * @param {{_where: Object,
    _limit: int,
    _start: int}} params
  * @returns {*}
  */
  count(params) {
    const stringifyParams = qs.stringify({ ...params })
    return this.$strapi.count('experience-works', stringifyParams)
  }

  /**
   *
   * @param params
   * @returns {*}
   */
  create(params) {
    return this.$strapi['$experience-works'].create(params)
  }

  /**
   * @param {int} id
   * @param {Object} data
   * @returns {*}
   */
  async update(id, data) {
    return await this.$strapi['$experience-works'].update(id, data)
  }

  updateTotalHasSkills(id, data) {
    this.$strapi.$http.$put(`/experience-works/update-total-skills/${id}`, data)
  }

  /**
   * @param {int} id
   * @returns {*}
   */
  async delete(id) {
    return await this.$strapi['$experience-works'].delete(id)
  }
}

module.exports = ExperienceWork
