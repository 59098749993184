//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'NewProjectWizard',
  computed: {
    ...mapGetters({
      getBaseProject: 'project/getBaseProject',
    }),
  },
  methods: {
    ...mapActions({
      setProjectAttribute: 'project/setProjectAttribute',
    }),
    onPreviousStep() {
      if (
        this.getBaseProject.current_step === 0 &&
        !this.getBaseProject.current_sub_step
      ) {
        this.$router.back()
        return
      }
      if (
        this.getBaseProject.current_step === 0 &&
        this.getBaseProject.current_sub_step
      ) {
        this.setProjectAttribute({
          key: 'current_sub_step',
          value: null,
        })
        return
      }
      if (this.getBaseProject.current_step === 1) {
        this.setProjectAttribute({
          key: 'current_sub_step',
          value: '1.5',
        })
      }
      this.setProjectAttribute({
        key: 'current_step',
        value: this.getBaseProject.current_step - 1,
      })
    },
    onNextStep() {
      if (
        this.getBaseProject.current_step === 0 &&
        !this.getBaseProject.current_sub_step
      ) {
        this.setProjectAttribute({
          key: 'current_sub_step',
          value: '1.5',
        })
        return
      }
      this.setProjectAttribute({
        key: 'current_step',
        value: this.getBaseProject.current_step + 1,
      })
      this.setProjectAttribute({
        key: 'current_sub_step',
        value: null,
      })
    },
  },
}
