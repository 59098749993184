//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import KanbanCard from './KanbanCard.vue'

export default {
  components: {
    KanbanCard,
  },
  props: {
    columns: {
      type: Array,
      required: true,
    },
    cards: {
      type: Array,
      required: true,
    },
    cardColumnIdentifier: {
      type: String,
      default: null,
    },
    cardChipIdentifier: {
      type: String,
      default: null,
    },
    cardSelectOptions: {
      type: Array,
      default: null,
    },
    cardSelectIdentifier: {
      type: String,
      default: null,
    },
    shrink: {
      type: Boolean,
      default: false,
    },
    // Eg. :cardActions="[{ title: 'Eliminar', action: 'delete' }]"
    cardActions: {
      type: Array,
      default: () => [],
    },
    // Eg. :filters="[{ key: 'code', value: 'K' }]"
    filters: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      searchTerm: '',
      shrinkKB: this.shrink,
      isScrolling: false,
      lastMaxHeight: 0,
    }
  },
  mounted() {
    this.setScrollAnimation()
    this.updateSizeWhenBoardIsVisible()
  },
  updated() {
    this.$nextTick(() => {
      this.updateColumnsHeight()
    })
  },
  methods: {
    updateSizeWhenBoardIsVisible() {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            this.updateColumnsHeight()
            observer.disconnect()
          }
        },
        { threshold: 0.1 }
      )
      observer.observe(this.$refs.kanbanBoard)
    },
    getMaxHeight() {
      if (this.$refs.kanbanColumnContent) {
        this.setColumnsHeight(0)
        const heights = this.$refs.kanbanColumnContent.map(
          (el) => el.clientHeight
        )
        this.setColumnsHeight(this.lastMaxHeight)
        return Math.max(...heights)
      }

      return 0
    },
    updateColumnsHeight() {
      const maxHeight = this.getMaxHeight()
      if (this.lastMaxHeight !== maxHeight) {
        this.setColumnsHeight(maxHeight)
        this.lastMaxHeight = maxHeight
      }
    },
    setColumnsHeight(newHeight) {
      this.$refs.kanbanColumnContent?.forEach(
        (el) => (el.style.minHeight = `${newHeight}px`)
      )
    },
    filteredCards(columnCode, columnIdentifier) {
      const cards = this.filteredCardsByColumn(columnCode, columnIdentifier)

      const filtersByKey = this.filters.reduce((acc, curr) => {
        if (!acc[curr.key]) {
          acc[curr.key] = [curr]
        } else {
          acc[curr.key].push(curr)
        }
        return acc
      }, {})

      return cards.filter((card) => {
        return Object.entries(filtersByKey).every(
          ([filterKey, filterObjects]) => {
            return filterObjects.some(
              (filterObject) =>
                card[filterKey] === filterObject.value ||
                (filterObject.search === true &&
                  card[filterKey]
                    .toLowerCase()
                    .includes(filterObject.value.toLowerCase()))
            )
          }
        )
      })
    },
    filteredCardsByColumn(columnCode, columnIdentifier) {
      if (columnCode === 'deleted') {
        return this.cards.filter((card) => card.isDeleted)
      }
      return this.cards.filter(
        (card) => card[columnIdentifier] === columnCode && !card.isDeleted
      )
    },
    setScrollAnimation() {
      // sync horizontal scroll of headers and board in small screens
      const kanbanHeaders = document.getElementById('kanban-headers')
      const kanbanBoard = document.getElementById('kanban-board')

      kanbanHeaders.addEventListener('scroll', () => {
        if (!this.isScrolling) {
          this.isScrolling = true
          requestAnimationFrame(() => {
            kanbanBoard.scrollLeft = kanbanHeaders.scrollLeft
            this.isScrolling = false
          })
        }
      })

      kanbanBoard.addEventListener('scroll', () => {
        if (!this.isScrolling) {
          this.isScrolling = true
          requestAnimationFrame(() => {
            kanbanHeaders.scrollLeft = kanbanBoard.scrollLeft
            this.isScrolling = false
          })
        }
      })
    },
    handleDelete(card) {
      this.$emit('delete', card)
    },
    handleRestore(card) {
      this.$emit('restore', card)
    },
    handleCardClick(card) {
      this.$emit('card-click', card)
    },
    handleUpdateSelect(card) {
      this.$emit('update-select', card)
    },
  },
}
