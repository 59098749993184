//
//
//
//
//
//
//
//
//

export default {
  props: {
    type: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      icons: {
        'in-pending': 'bell-ring',
        'in-progress': 'clock-time-four-outline',
        'in-complete': 'check',
      },
      labels: {
        'in-pending': 'Pendiente',
        'in-progress': 'En progreso',
        'in-complete': 'Completado',
      },
    }
  },
  computed: {
    icon() {
      return this.icons[this.type] || ''
    },
    label() {
      return this.labels[this.type] || ''
    },
  },
}
