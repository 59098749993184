//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Button from '~/components/Shared/Button'
import ModalWithTitleBar from '~/components/Shared/ModalWithTitleBar'

export default {
  name: 'ModalDeleteUser',
  components: { Button, ModalWithTitleBar },
  props: {
    value: {
      type: Boolean,
      default: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      active: this.value,
    }
  },
  watch: {
    value() {
      this.active = this.value
    },
  },
  methods: {
    close() {
      this.active = !this.active
      this.$emit('close')
      this.$emit('input', this.active)
    },
    deleteUser() {
      this.$emit('delete-user', this.user)
      this.$emit('input', this.active)
    },
  },
}
