//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    history: {
      default: () => [{ name: 'Inicio', url: '/inicio' }],
      type: Array,
    },
    actual: {
      default: '',
      type: String,
    },
  },
}
