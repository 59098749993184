//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { FormInput } from '@/components/Form'

export default {
  name: 'CustomOccupationFormResume',
  components: {
    FormInput,
  },
  props: {
    occupation: {
      type: Object,
      default: () => ({}),
    },
  },
}
