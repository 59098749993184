const qs = require('qs')
const Request = require('@/repositories/request')
class MeetingRequest extends Request {
  /**
   *
   * @param {{_where: Object,
        _limit: int,
        _start: int}} params
   * @returns {*}
   */
  findAll(params) {
    const stringifyParams = qs.stringify({ ...params })
    return this.$strapi['$meeting-requests'].find(stringifyParams)
  }

  /**
   *
   * @param {{_where: Object,
        _limit: int,
        _start: int}} params
   * @returns {*}
   */
  count(params) {
    const stringifyParams = qs.stringify({ ...params })
    return this.$strapi['$meeting-requests'].count(stringifyParams)
  }

  /**
   *
   * @param {int} id
   * @returns {*}
   */
  findOne(id) {
    return this.$strapi['$meeting-requests'].findOne(id)
  }

  /**
   * @param {int} id
   * @param {Object} data
   * @returns {*}
   */
  update(id, data) {
    return this.$strapi['$meeting-requests'].update(id, data)
  }
}

module.exports = MeetingRequest
