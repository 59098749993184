var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"width":640,"can-cancel":false},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('modal-with-title-bar',{attrs:{"title":_vm.$t('customOccupationDetail.detail.modalSummaryPublish.title'),"close":_vm.cancel},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('div',{staticClass:"modal-cta-summary-publish"},[_c('Button',{attrs:{"type":"is-text"},on:{"click":_vm.cancel}},[_vm._v(_vm._s(_vm.$t('customOccupationDetail.detail.modalSummaryPublish.btnCancel')))]),_vm._v(" "),_c('button',{staticClass:"button is-primary is-rounded",on:{"click":_vm.publish}},[_vm._v("\n            "+_vm._s(_vm.$t(
                'customOccupationDetail.detail.modalSummaryPublish.btnPublish'
              ))+"\n          ")])],1)]},proxy:true}])},[_c('div',{staticClass:"modal-summary-publish"},[_c('div',{staticClass:"modal-text"},[_c('div',{staticClass:"summary-occupation"},[_c('div',{staticClass:"mb-4"},[_c('h3',{staticClass:"summary-occupation-title"},[_vm._v("\n                "+_vm._s(_vm.$t(
                    'customOccupationDetail.detail.modalSummaryPublish.companyName'
                  ))+"\n              ")]),_vm._v(" "),_c('p',{staticClass:"ml-0"},[_vm._v("\n                "+_vm._s(_vm._f("list")(_vm.occupation.active_companies.map(function (ref) {
                                var name = ref.name;

                                return name;
})))+"\n              ")])]),_vm._v(" "),_c('div',{staticClass:"mb-4"},[_c('h3',{staticClass:"summary-occupation-title"},[_vm._v("\n                "+_vm._s(_vm.$t(
                    'customOccupationDetail.detail.modalSummaryPublish.basicProfile'
                  ))+"\n              ")]),_vm._v(" "),_c('p',{staticClass:"ml-0"},[_vm._v(_vm._s(_vm.occupation.base_occupation.title))])]),_vm._v(" "),_c('div',{staticClass:"mb-4"},[_c('h3',{staticClass:"summary-occupation-title"},[_vm._v("\n                "+_vm._s(_vm.$t(
                    'customOccupationDetail.detail.modalSummaryPublish.occupationName'
                  ))+"\n              ")]),_vm._v(" "),_c('p',{staticClass:"ml-0"},[_vm._v(_vm._s(_vm.occupation.title))])]),_vm._v(" "),_c('div',[_c('h3',{staticClass:"summary-occupation-title"},[_vm._v("\n                "+_vm._s(_vm.$t(
                    'customOccupationDetail.detail.modalSummaryPublish.description'
                  ))+"\n              ")]),_vm._v(" "),_c('p',{staticClass:"ml-0"},[_vm._v(_vm._s(_vm.occupation.description))])])]),_vm._v(" "),_c('b-collapse',{staticClass:"collapse-container",attrs:{"open":false,"animation":"slide","aria-id":"contentIdForA11y1"},scopedSlots:_vm._u([{key:"trigger",fn:function(props){return [_c('div',{staticClass:"collapse-container-title"},[_c('div',{staticClass:"card-header"},[_c('strong',[_vm._v(_vm._s(_vm.$t(
                        'customOccupationDetail.detail.modalSummaryPublish.addedSkills'
                      ))+"\n                    ("+_vm._s(_vm.formattedAddedSkills.total)+")")])]),_vm._v(" "),_c('a',{staticClass:"card-header-icon"},[_c('b-icon',{attrs:{"icon":props.open ? 'chevron-up' : 'chevron-down'}})],1)])]}}])},[_vm._v(" "),_c('div',{staticClass:"show-skills has-text-left"},[_vm._v("\n              "+_vm._s(_vm.formattedAddedSkills.skills)+"\n            ")])]),_vm._v(" "),_c('b-collapse',{staticClass:"collapse-container",attrs:{"open":false,"animation":"slide","aria-id":"contentIdForA11y2"},scopedSlots:_vm._u([{key:"trigger",fn:function(props){return [_c('div',{staticClass:"collapse-container-title"},[_c('div',[_c('strong',[_vm._v(_vm._s(_vm.$t(
                        'customOccupationDetail.detail.modalSummaryPublish.deletedSkills'
                      ))+"\n                    ("+_vm._s(_vm.formattedOriginDeletedSkills.total)+")")])]),_vm._v(" "),_c('a',{staticClass:"card-header-icon"},[_c('b-icon',{attrs:{"icon":props.open ? 'chevron-up' : 'chevron-down'}})],1)])]}}])},[_vm._v(" "),_c('div',{staticClass:"show-skills has-text-left"},[_vm._v("\n              "+_vm._s(_vm.formattedOriginDeletedSkills.skills)+"\n            ")])]),_vm._v(" "),_c('b-collapse',{staticClass:"collapse-container",attrs:{"open":false,"animation":"slide","aria-id":"contentIdForA11y3"},scopedSlots:_vm._u([{key:"trigger",fn:function(props){return [_c('div',{staticClass:"collapse-container-title"},[_c('div',[_c('strong',[_vm._v(_vm._s(_vm.$t(
                        'customOccupationDetail.detail.modalSummaryPublish.baseProfileSkills'
                      ))+"\n                    ("+_vm._s(_vm.formattedOriginSkills.total)+")")])]),_vm._v(" "),_c('a',{staticClass:"card-header-icon"},[_c('b-icon',{attrs:{"icon":props.open ? 'chevron-up' : 'chevron-down'}})],1)])]}}])},[_vm._v(" "),_c('div',{staticClass:"show-skills has-text-left"},[_vm._v("\n              "+_vm._s(_vm.formattedOriginSkills.skills)+"\n            ")])])],1)])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }