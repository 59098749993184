const getUniqueSkillsSortedByFrequency = (skills) => {
  const skillFrequency = {}
  skills.forEach((skill) => {
    const { id } = skill
    if (skillFrequency[id]) {
      skillFrequency[id].count++
    } else {
      skillFrequency[id] = { ...skill, count: 1 }
    }
  })

  const sortedSkills = Object.values(skillFrequency).sort(
    (a, b) => b.count - a.count
  )

  return sortedSkills
}

module.exports = { getUniqueSkillsSortedByFrequency }
