//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { matchStringIgnoreCase } from '@/util/string'
import ProgressIndicator from '@/components/Shared/ProgressIndicator'
import SkillPackTable from '~/components/Skills/SkillPackTable'
export default {
  components: { SkillPackTable, ProgressIndicator },
  filters: {
    getTotalHours({ courses }) {
      if (courses.length === 0) return 0
      return courses.reduce((acc, course) => acc + course.hours, 0)
    },
  },
  props: {
    opportunities: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      filteredOpportunities: [],
    }
  },
  mounted() {
    this.filteredOpportunities = this.opportunities
  },
  methods: {
    isRowDetailEnabled(row) {
      return row.description
    },
    toggleDetails(tableIndex, row) {
      if (this.isRowDetailEnabled(row)) {
        this.$refs.jobMatchesTable[tableIndex].toggleDetails(row)
      }
    },
    isRowExpanded(tableIndex, row) {
      return this.$refs.jobMatchesTable[tableIndex].isVisibleDetailRow(row)
    },
    searchOpportunity(query) {
      if (!query) {
        this.filteredOpportunities = this.opportunities
        return
      }
      this.filteredOpportunities = this.opportunities
        .map((opportunities) => {
          return opportunities.filter((opportunity) => {
            const name = opportunity.name || ''
            return matchStringIgnoreCase(name, query)
          })
        })
        .filter((opportunities) => opportunities.length > 0)
    },
  },
}
