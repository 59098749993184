//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import lastEvaluateMatchMixin from '@/mixins/lastEvalutateMatchMixin'
import SimpleModal from '@/components/Experiences/SimpleModal'
import NavigationWizardExperience from '~/components/Experiences/NavigationWizardExperience.vue'
import SKILL_MORPHS_CONSTANTS from '~/constants/skill-morphs'
import AddFreeSkillsWithRecommendations from '~/components/Experiences/AddFreeSkillsWithRecommendations.vue'
import { getSuggestSkillsForExperienceOthers } from '~/util/entity/experience'
const MIN_SKILLS_FOR_COMPLETE_EXPERIENCE = 10

export default {
  components: {
    NavigationWizardExperience,
    SimpleModal,
    AddFreeSkillsWithRecommendations,
  },
  mixins: [lastEvaluateMatchMixin],
  props: {
    experience: {
      type: Object,
      default: () => {},
    },
    userSkills: {
      type: Array,
      default: () => [],
    },
    goBack: {
      type: Function,
      default: () => {},
    },
  },
  data: () => ({
    currentExperience: {},
    skills: [],
    suggestSkills: [],
    confirmModalVisible: false,
  }),
  computed: {
    valid() {
      return this.skills.length > 0
    },
    completed() {
      return (
        this.skills.length >= MIN_SKILLS_FOR_COMPLETE_EXPERIENCE ||
        this.experience.completed
      )
    },
  },

  watch: {
    async experience(value) {
      this.currentExperience = value
      if (value.id) {
        this.suggestSkills = await getSuggestSkillsForExperienceOthers(
          this.$repository,
          this.userSkills
        )
      }
    },
  },
  methods: {
    handleSelectedSkills(skills) {
      this.skills = [...skills]
    },
    prepareExperience() {
      return {
        ...this.experience,
        completed: this.completed,
        total_has_skills: this.skills.length,
      }
    },
    async saveChanges() {
      const skillMorphs = this.skills.map((skill) => {
        return {
          related_id: this.experience.id,
          related_type: SKILL_MORPHS_CONSTANTS.ENTITY_TYPES.EXPERIENCE_OTHERS,
          origin_type: SKILL_MORPHS_CONSTANTS.ORIGIN_TYPES.FREE,
          skill,
          user: this.experience.user.id,
          has_skill: true,
        }
      })

      const userId = this.$strapi.user.id

      await this.$repository.skillMorph.saveMassive(
        userId,
        this.experience?.id,
        SKILL_MORPHS_CONSTANTS.ENTITY_TYPES.EXPERIENCE_OTHERS,
        skillMorphs
      )
      this.evaluateMatchUser(userId)

      const experience = await this.$repository.experienceOther.update(
        this.experience.id,
        this.prepareExperience()
      )

      this.$store.dispatch('experience/setOtherExperience', experience)
      this.$emit('next')
    },
    toggleConfirmModal() {
      this.confirmModalVisible = !this.confirmModalVisible
    },
  },
}
