//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getSkillTypes, getReuseLevel } from '@/util/skill'
import EmptyTable from './EmptyTable.vue'

export default {
  name: 'CompanySkillsInventoryTable',
  components: {
    EmptyTable,
  },
  props: {
    skills: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: true,
    },
    currentPage: {
      type: Number,
      default: 0,
    },
    perPage: {
      type: Number,
      default: 0,
    },
    total: {
      type: Number,
      default: 0,
    },
    sortField: {
      type: String,
      default: 'total_users',
    },
    sortOrder: {
      type: String,
      default: 'desc',
    },
  },
  computed: {
    calculate() {
      return this.skills.length === this.perPage
        ? `${this.skills.length * this.currentPage - (this.perPage - 1)}-${
            this.skills.length * this.currentPage
          }`
        : `Últimos ${this.skills.length}`
    },
  },
  methods: {
    onPageChange(page) {
      this.$emit('page-change', page)
    },
    viewSkillDetail(skill) {
      this.$emit('view-skill', skill.id)
    },
    getSkillTypes(code) {
      return getSkillTypes(code)
    },
    getReuseLevel(reuseLevel) {
      return getReuseLevel(reuseLevel)
    },
    onSort(field, order) {
      this.$emit('sort', { field, order })
    },
  },
}
