//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex'
import NewProjectsWizardButtons from '@/components/Projects/NewProjectWizardButtons.vue'
import ProjectAutocomplete from './ProjectAutocomplete.vue'
import ProjectOccupationChip from './ProjectOccupationChip.vue'

export default {
  name: 'OccupationsStepSection',
  components: {
    NewProjectsWizardButtons,
    ProjectAutocomplete,
    ProjectOccupationChip,
  },
  props: {
    occupations: {
      type: Array,
      default: () => [],
    },
    selectedOccupations: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedOccupationsIds: [],
    }
  },
  computed: {
    ...mapGetters({
      getBaseProject: 'project/getBaseProject',
    }),
  },
  methods: {
    ...mapActions({
      setProjectAttribute: 'project/setProjectAttribute',
    }),
    onSelectOccupation(selectedOccupation) {
      this.setProjectAttribute({
        key: 'occupations',
        value: [...this.getBaseProject.occupations, selectedOccupation],
      })
    },
    handleRemoveChip(id) {
      const filteredOccupations = this.getBaseProject.occupations.filter(
        (occupation) => occupation.id !== id
      )
      this.setProjectAttribute({
        key: 'occupations',
        value: [...filteredOccupations],
      })
    },
    handleBack() {
      this.$emit('back')
    },
    handleContinue() {
      this.$emit('next')
    },
  },
}
