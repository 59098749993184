//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'AsideMenuItem',
  components: {
    AsideMenuList: () => import('@/components/AsideMenuList'),
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isDropdownActive: false,
    }
  },
  computed: {
    componentIs() {
      return this.item.to ? 'nuxt-link' : 'a'
    },
    hasDropdown() {
      return !!this.item.children === false
        ? false
        : this.item.children.length > 0
    },
    dropdownIcon() {
      return this.isDropdownActive ? 'minus' : 'plus'
    },
    itemTo() {
      return this.item.to ? this.item.to : null
    },
    itemHref() {
      return this.item.href ? this.item.href : null
    },
  },
  methods: {
    menuClick() {
      this.$emit('menu-click', this.item)
      if (this.hasDropdown) {
        this.isDropdownActive = !this.isDropdownActive
      }
    },
  },
}
