const Request = require('@/repositories/request')
const qs = require('qs')
const { encodeQueryParams } = require('~/util/query')
class EducationalCareer extends Request {
  /**
   *
   * @param {int} id
   * @returns {*}
   */
  findOne(id) {
    return this.$strapi['$educational-careers'].findOne(id)
  }

  /**
   *
   * @param {Object} params
   * @return {*}
   */
  findAll(params) {
    const stringifyParams = qs.stringify({ ...params }, { encode: false })
    return this.$strapi['$educational-careers'].find(stringifyParams)
  }

  /**
   *
   * @param {int[]} ids
   * @returns {*}
   */
  findAnyWithIds(ids) {
    return this.$strapi.$http.$get(
      `/educational-careers?${encodeQueryParams({ id: ids })}`
    )
  }
}

module.exports = EducationalCareer
