import Vue from 'vue'
import moment from 'moment'
Vue.filter('formatDate', (value, format) => {
  if (!value) return ''

  return moment(value).format(format ?? 'DD/MM/YYYY')
})

Vue.filter('capitalize', (word = '') => {
  if (word?.length === 0 || !word) {
    return word
  }
  return word.charAt(0).toUpperCase() + word.slice(1)
})

Vue.filter('list', (elements = []) => {
  const formatter = new Intl.ListFormat('es', {
    style: 'long',
    type: 'conjunction',
  })
  return formatter.format(elements)
})
