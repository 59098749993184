const Request = require('@/repositories/request')
const qs = require('qs')

class Questionary extends Request {
  save(body) {
    return this.$strapi.$http.$post('/questionaries', body)
  }

  get(params) {
    const query = qs.stringify(params, { encode: false })
    return this.$strapi.$http.$get(`/questionaries?${query}`)
  }

  update(id, body) {
    return this.$strapi.$http.$put(`/questionaries/${id}`, body)
  }

  findAll(params) {
    const stringifyParams = qs.stringify({ ...params }, { encode: false })
    return this.$strapi.$questionaries.find(stringifyParams)
  }

  saveBaseOccupations(data) {
    return this.$strapi.$http.$post(
      '/questionaries/save-base-occupations',
      data
    )
  }
}

module.exports = Questionary
