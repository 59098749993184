//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ModalWithTitleBar from '~/components/Shared/ModalWithTitleBar.vue'

export default {
  name: 'DialogConfirmation',
  components: {
    ModalWithTitleBar,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    confirmText: {
      type: String,
      default: 'Continuar',
    },
    cancelText: {
      type: String,
      default: 'Cancelar',
    },
    onConfirm: {
      type: Function,
      default: () => {},
    },
    onCancel: {
      type: Function,
      default: () => {},
    },
  },
}
