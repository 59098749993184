import { render, staticRenderFns } from "./StepData.vue?vue&type=template&id=8f43089a&scoped=true&"
import script from "./StepData.vue?vue&type=script&lang=js&"
export * from "./StepData.vue?vue&type=script&lang=js&"
import style0 from "./StepData.vue?vue&type=style&index=0&id=8f43089a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8f43089a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FormInput: require('/workspace/components/Form/FormInput.vue').default,FormGroup: require('/workspace/components/Form/FormGroup.vue').default})
