//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Button from '@/components/Shared/Button'
import SkillsEmptyState from './SkillsEmptyState.vue'
import CustomCheckBoxList from '~/components/Shared/CustomCheckBox/CustomCheckBoxList'
export default {
  components: {
    Button,
    CustomCheckBoxList,
    SkillsEmptyState,
  },
  props: {
    questionary: {
      type: Object,
      default: () => {},
    },
    skills: {
      type: Array,
      default: () => [],
    },
    page: {
      type: Number,
      default: 1,
    },
    totalPage: {
      type: Number,
      default: 1,
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    userSkillIds: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    skillsWithStatus() {
      return this.skills.map((skill) => ({
        ...skill,
        active: this.userSkillIds.includes(skill.id),
      }))
    },
  },
  methods: {
    handleChangeCheckbox(checkedElement) {
      this.$emit('change', {
        id: checkedElement.item.id,
        status: checkedElement.status,
      })
    },
    handleBack() {
      this.$emit('back')
    },
    handleNext() {
      this.$emit('next')
    },
  },
}
