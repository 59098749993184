//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ModalWithTitleBar from '@/components/Shared/ModalWithTitleBar'
import Button from '~/components/Shared/Button'

export default {
  components: {
    ModalWithTitleBar,
    Button,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    deleteAndClose() {
      this.$emit('deleteAndClose')
    },
    close() {
      this.$emit('close')
    },
  },
}
