const skillTypes = [
  {
    code: 'K',
    name: 'Conocimiento',
  },
  {
    code: 'L',
    name: 'Competencia lingüística',
  },
  {
    code: 'S',
    name: 'Habilidad',
  },
  {
    code: 'T',
    name: 'Competencia transversal',
  },
]

const skillReuseLevels = [
  {
    code: 'transversal',
    name: 'Transversal',
  },
  {
    code: 'cross-sector',
    name: 'Intersectorial',
  },
  {
    code: 'sector-specific',
    name: 'Específica del sector',
  },
  {
    code: 'occupation-specific',
    name: 'Específica de la ocupación',
  },
]

export default { skillTypes, skillReuseLevels }
