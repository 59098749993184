//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import StatsCard from '@/components/DeliveryResults/StatsCard.vue'

export default {
  name: 'CompanyStatsSection',
  components: {
    StatsCard,
  },
  props: {
    statsData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      totalEducational: this.statsData?.totalEducational || 0,
      totalWork: this.statsData?.totalWork || 0,
      totalOther: this.statsData?.totalOther || 0,
      totalSkills: this.statsData?.totalSkills || 0,
      totalUsers: this.statsData?.totalUsers || 0,
    }
  },
  computed: {
    totalExperiences() {
      return this.totalEducational + this.totalWork + this.totalOther
    },
    averageExperiences() {
      return parseFloat(this.totalExperiences / this.totalUsers).toFixed(2)
    },
    experienceDescription() {
      return `Total: ${this.numberWithThousands(this.totalExperiences)}`
    },
    averageSkills() {
      return parseFloat(this.totalSkills / this.totalUsers).toFixed(2)
    },
    skillsDescription() {
      return `Total: ${this.numberWithThousands(this.totalSkills)}`
    },
  },
  methods: {
    numberWithThousands(x) {
      return x.toLocaleString('en-US', {
        maximumFractionDigits: 2,
      })
    },
  },
}
