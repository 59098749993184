//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Button from '~/components/Shared/Button.vue'
export default {
  components: {
    Button,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    elements: {
      type: Array,
      default: () => {},
    },
    isSvg: {
      type: Boolean,
      default: false,
    },
  },
}
