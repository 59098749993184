//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import NavbarBurger from '~/node_modules/buefy/src/components/navbar/NavbarBurger.vue'
export default {
  components: {
    NavbarBurger,
  },
  props: {
    isCompany: {
      type: Boolean,
      default: false,
    },
    logInText: {
      type: String,
      default: '',
    },
    secondaryText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      navMain: [
        {
          label: 'Personas',
          link: '/',
          items: [
            'quienes-somos',
            'nuestra-propuesta',
            'preguntas-frecuentes',
            'politicas-de-privacidad',
            'registro',
          ],
        },
        {
          label: 'Empresas',
          link: '/empresas',
          items: ['como-funciona', 'agenda-una-reunion'],
        },
        {
          label: 'Blog',
          link: '/blog',
          items: [],
        },
        {
          label: 'Informes Sectoriales',
          link: '/reports',
          items: [],
        },
      ],
      isCollapsed: false,
    }
  },
  computed: {
    isLoggin() {
      return !!this.$strapi.user
    },
    navSecondary() {
      if (this.isCompany) {
        return this.$t('navBar.navSecondary.company')
      } else {
        return this.$t('navBar.navSecondary.person')
      }
    },
    secondaryClick() {
      if (this.isCompany) {
        return this.goToCompanyAccount
      } else {
        return this.goToRegister
      }
    },
    ...mapState(['isActiveNavBarHomePage']),
  },
  methods: {
    goToPanel() {
      this.$router.push('/inicio')
    },
    goToRegister() {
      this.$router.push('/registro')
    },
    goToCompanyAccount() {
      this.$router.push('/solicita-cuenta-empresa')
    },
    goToMessage() {
      this.$router.push('#messages')
    },
    gotoLoginPerson() {
      this.$router.push('/login/persona')
    },
    gotoLoginAdmin() {
      this.$router.push('/login/admin')
    },
    activePath(labelNav) {
      const path = this.$nuxt.$route.path.split('/')[1].split('#')[0]
      const currectItems = this.navMain.find((item) => item.label === labelNav)
        .items
      if (currectItems.includes(path)) {
        return 'nuxt-link-exact-active'
      }
      return ''
    },
    collapseButtons() {
      this.isCollapsed = !this.isCollapsed
    },
    handleChangeActiveNavbar() {
      this.$store.commit('toogleNavBarHomePage')
    },
  },
}
