//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Button from '../../Shared/Button.vue'
import ModalWithTitleBar from '~/components/Shared/ModalWithTitleBar.vue'

export default {
  name: 'RemoveOccupationModal',
  components: { Button, ModalWithTitleBar },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
      default: null,
    },
  },
  methods: {
    onCancel() {
      this.$emit('cancel')
    },
    onDelete() {
      this.$emit('delete', this.id)
    },
  },
}
