//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import UnemployedRegisterEvaluator from '@/components/Dashboard/UnemployedRegisterEvaluator.vue'
import UserSkillsInventory from '~/components/Dashboard/User/UserSkillsInventorySection.vue'
import UserOpportunities from '~/components/Dashboard/User/UserOpportunitiesSection.vue'

const NUM_OPPORTUNITIES = 4
export default {
  components: {
    UserSkillsInventory,
    UserOpportunities,
    UnemployedRegisterEvaluator,
  },
  async fetch() {
    this.$store.commit('loading', true)
    this.user = this.$strapi.user
    this.opportunities = await this.fetchOpportunities()
    this.$store.commit('loading', false)
  },
  data() {
    return {
      routes: {
        profile: '/perfil',
        experience: '/experiencias',
        tests: '/tests',
      },
      registerComplete: false,
      opportunities: [],
    }
  },
  methods: {
    async fetchOpportunities() {
      return await this.$repository.user.getOpportunitiesMetaByUserId(
        this.user.id,
        NUM_OPPORTUNITIES
      )
    },
    onCompleteCheck(complete) {
      this.registerComplete = complete
    },
  },
}
