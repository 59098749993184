import ROLES from '@/constants/roles'

export default function ({ redirect, $strapi }) {
  if (
    ![ROLES.RELINK, ROLES.COMPANY, ROLES.ADMIN_UNEMPLOYED].includes(
      $strapi.user.role.type
    )
  ) {
    return redirect('/inicio')
  }
}
