//
//
//
//
//
//
//
//
//
//
//

import AsideMenuItem from '@/components/AsideMenuItem'

export default {
  name: 'AsideMenuList',
  components: {
    AsideMenuItem,
  },
  props: {
    isSubmenuList: {
      type: Boolean,
      default: false,
    },
    menu: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    menuClick(item) {
      this.$emit('menu-click', item)
    },
  },
}
