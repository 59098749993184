//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ValidationObserver } from 'vee-validate'
import { FormInput } from '@/components/Form'
import { rutFilter } from 'vue-dni'
import ROLES from '@/constants/roles'
import { INVITATION } from '@/constants/mode_registers'

export default {
  name: 'IndividualForm',
  components: { FormInput, ValidationObserver },
  props: {
    onSuccess: {
      default: () => {},
      type: Function,
    },
    roleType: {
      type: String,
      default: ROLES.EMPLOYEE,
    },
  },
  async fetch() {
    this.$store.commit('loading', true)
    const [{ roles }] = await Promise.all([
      this.$strapi.$http.$get('/users-permissions/roles'),
    ])
    this.role = roles.find((role) => role.type === this.roleType).id
    this.roles = roles
    this.$store.commit('loading', false)
  },
  data: () => ({
    ROLES,
    roles: [],
    selectedCompany: null,
    radioRole: ROLES.UNEMPLOYED,
    options: {
      languages: [],
      locations: [],
      situationLaboral: [
        {
          value: 'Empleado',
          label: 'Empleado',
        },
      ],
    },
    form: {
      situationLaboral: '',
      occupation: '',
      user: {
        names: '',
        lastnames: '',
        email: '',
        repeatEmail: '',
        identification_number: '',
        password: Math.random().toString(36).substring(7),
      },
      message: null,
    },
  }),
  computed: {
    formSubmit() {
      const { ...form } = this.form.user
      return {
        username: form.email,
        identification_number: form.identification_number,
        ...form,
        company: this.$repository.me.getMe().company,
      }
    },
  },

  watch: {
    form: {
      handler(val) {
        this.form.user.identification_number = rutFilter(
          this.form.user.identification_number
        )
      },
      deep: true,
    },
  },
  methods: {
    closeDialog() {
      this.$emit('close')
    },
    async submit() {
      this.$store.commit('loading', true)
      try {
        const user = await this.$strapi.$users.create({
          ...this.formSubmit,
          role: this.role,
          visibleCompany: true,
          provide_data: true,
          mode_register: INVITATION,
          identification_number: this.form.user.identification_number.replaceAll(
            '.',
            ''
          ),
          job_seeker_fair: this.$repository.me.getMe().job_seeker_fair,
        })

        await this.$strapi.$invitations.create({
          user,
          custom_message: this.form.message,
        })
        this.onSuccess()
        this.$buefy.notification.open({
          message: 'Invitación enviada exitosamente',
          type: 'is-success',
          duration: 5000,
        })
      } catch (e) {
        this.$buefy.notification.open({
          message: e.message,
          type: 'is-danger',
          duration: 5000,
        })
      } finally {
        this.$store.commit('loading', false)
      }
    },
    async preview() {
      const role = this.roleType
      const names =
        this.form.user.names &&
        this.form.user.lastnames &&
        `${this.form.user.names} ${this.form.user.lastnames}`
      const companyName = this.$repository.me.getMe().company?.name
      const jobSeekerFair = this.$repository.me.getMe().job_seeker_fair?.name

      const response = await this.$repository.invitation.previewMail({
        role,
        names,
        companyName,
        jobSeekerFair,
        customMessage: this.form.message,
      })
      window.open(response.url, '_blank')
    },
  },
}
