//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import IconEmptyState from '~/assets/experiences/suggestedSkillsEmpty.svg'
export default {
  components: { IconEmptyState },
}
