// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/homepage/op_home-personas-1.jpg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/images/homepage/op_home-personas-2.jpg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../assets/images/homepage/op_home-personas-3.jpg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".has-border-bottom-grey-light[data-v-39ff1bac]{border-bottom:1px solid #dbdbdb}.color-tertiary[data-v-39ff1bac]{color:#2cd5c4}.color-primary[data-v-39ff1bac]{color:#ef4a81}.color-grey-darker[data-v-39ff1bac]{color:#363636}.color-grey[data-v-39ff1bac]{color:#7a7a7a}.help.is-danger[data-v-39ff1bac]{position:static}@media not all and (min-resolution:0.001dpcm){@supports(-webkit-appearance:none){.content-end[data-v-39ff1bac]{align-content:flex-end}.content-top[data-v-39ff1bac]{align-content:flex-start}@media only screen and (max-width:1023px){.final[data-v-39ff1bac]{margin-top:6rem}}.img-1[data-v-39ff1bac]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:50%;background-size:cover}.img-2[data-v-39ff1bac]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-position:50%;background-size:cover}.img-3[data-v-39ff1bac]{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");background-position:50%;background-size:cover}}}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
