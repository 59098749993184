//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TableEmptyData from '@/components/Shared/Table/TableEmptyData'
import Tag from '@/components/Shared/Tag'
import ModalDeleteUser from '@/components/Projects/users/ModalDeleteUser.vue'
import ModalResendMassive from '@/components/Projects/users/ModalResendMassive.vue'
import Button from '@/components/Shared/Button'
import ROLES from '@/constants/roles'
import { SECTIONS } from '~/constants/questionary'
import { INVITATION_TO_MODELS } from '~/constants/reminder-email-templates'
import { PERMISSION_LABELS } from '~/constants/permission-labels'

export default {
  name: 'QuizProgressList',
  components: {
    TableEmptyData,
    Tag,
    ModalDeleteUser,
    Button,
    ModalResendMassive,
  },
  filters: {
    completenessStyleForActiveRow(questionary) {
      return SECTIONS.FINISH === questionary?.section
        ? 'is-success'
        : 'is-danger'
    },
    completenessCustomStyleForActiveRow(questionary) {
      return SECTIONS.FINISH === questionary?.section
        ? 'custom-is-success'
        : 'custom-is-danger'
    },
    completenessTextForActiveRow(questionary) {
      return SECTIONS.FINISH === questionary?.section
        ? 'Completo'
        : 'Incompleto'
    },
    fullNames(user) {
      return `${user?.names} ${user?.lastnames}`
    },
  },
  props: {
    data: {
      default: () => [],
      type: Array,
    },
    total: {
      default: 0,
      type: Number,
    },
    totalPending: {
      default: 0,
      type: Number,
    },
    perPage: {
      default: 10,
      type: Number,
    },
    project: {
      type: Object,
      default: () => ({}),
    },
    allUsers: {
      type: Array,
      default: () => [],
    },
    existsModelQuestionary: {
      type: Boolean,
      default: false,
    },
    existsActiveQuestionary: {
      type: Boolean,
      default: false,
    },
  },
  async fetch() {
    this.$store.commit('loading', true)
    const [
      { enabled: isEnabledMatchPotential },
    ] = await this.$permissions.allow([PERMISSION_LABELS.MATCH_POTENTIAL.BASE])
    this.isEnabledMatchPotential = !!isEnabledMatchPotential
    this.$store.commit('loading', false)
  },
  data() {
    return {
      isShowModalResendMassive: false,
      currentPage: 1,
      selectedRowIndex: -1,
      userToDelete: {},
      isShowModalDeleteUsers: false,
      emailTemplate: INVITATION_TO_MODELS,
      isEnabledMatchPotential: false,
    }
  },
  computed: {
    rolTypeToResend() {
      return ROLES.EMPLOYEE
    },
    showSelectMassive() {
      return this.resendEmails.length > 0
    },
    resendEmails: {
      get() {
        return this.$store.state.emails.resendEmails
      },
      set(rows) {
        this.$store.commit('emails/setResendEmails', rows)
      },
    },
    resendAll: {
      get() {
        return this.$store.state.emails.resendAll
      },
      set(data) {
        this.$store.commit('emails/setResendAll', data)
      },
    },
    showUsersAndAction() {
      if (this.resendAll) {
        return {
          users: this.totalPending,
          message: 'Anular seleccción',
        }
      }
      return {
        users: this.resendEmails.length,
        message: `Seleccionar los ${
          this.totalPending - this.resendEmails.length
        } usuarios restantes`,
      }
    },
    isDisabled() {
      if (this.$store.getters['emails/resendEmails'].length > 0) {
        return false
      }
      return true
    },
    showActiveQuestionary() {
      return this.existsActiveQuestionary && this.isEnabledMatchPotential
    },
    isPartialSelected() {
      return this.resendEmails.length > 0 && !this.resendAll
    },
  },
  methods: {
    onPageChange(page) {
      this.currentPage = page
      this.$emit('on-page-change', page)
      this.$store.commit('emails/clearResendEmails')
      this.$store.commit('emails/setResendAll', false)
    },
    onRowChecked(data) {
      this.$store.commit('emails/setResendEmails', data)
      this.$emit('on-row-checked', data)
      this.$store.commit(
        'emails/setResendAll',
        this.resendEmails.length === this.totalPending
      )
    },
    selectedAllEmails() {
      if (this.resendAll) {
        this.$store.commit('emails/clearResendEmails')
      } else {
        this.$store.commit(
          'emails/setResendEmails',
          this.data.filter(
            (employee) =>
              (employee.currentQuestionary?.section !== SECTIONS.FINISH ||
                employee.modelQuestionary?.section !== SECTIONS.FINISH) &&
              !employee.disabled
          )
        )
      }
      this.$store.commit('emails/setResendAll', !this.resendAll)
    },
    toggleDropdown(index) {
      if (this.selectedRowIndex === index) {
        this.selectedRowIndex = -1
      } else {
        this.selectedRowIndex = index
      }
    },
    toggleModalDeleteUsers() {
      this.isShowModalDeleteUsers = !this.isShowModalDeleteUsers
    },
    setTimeoutDisabled(user, state, time = 0) {
      setTimeout(() => {
        user.disabled = state
        this.$forceUpdate()
      }, time)
    },
    async resendInvitation(user) {
      this.setTimeoutDisabled(user, true)
      await this.$repository.invitation
        .sendInvitationByUserIds({
          users: [user.id],
          message: this.project.custom_invitation,
          isReminder: true,
          emailTemplate: this.emailTemplate,
        })
        .finally(() => {
          this.setTimeoutDisabled(user, false, 5000)
        })
    },
    async deleteUserConfirmed(userToDelete) {
      this.$store.commit('loading', true)
      const usersIds = this.allUsers
        .filter((user) => user.id !== userToDelete.id)
        .map((user) => user.id)
      await this.$repository.project.update(this.$route.params.id, {
        users: usersIds,
        total_users: usersIds.length,
      })
      this.$store.commit('project/SET_TOTAL_USERS', usersIds.length)
      this.$emit('user-deleted')
    },
    isEnableCheckbox(row) {
      return (
        (row.currentQuestionary?.section !== SECTIONS.FINISH ||
          row.modelQuestionary?.section !== SECTIONS.FINISH) &&
        !row.disabled
      )
    },
    isAnyCheckboxEnabled() {
      return this.data.some((row) => this.isEnableCheckbox(row))
    },
    toggleMassiveResend() {
      this.isShowModalResendMassive = !this.isShowModalResendMassive
    },
    toggleModalConfirmResendMassiveUser() {
      this.toggleMassiveResend()
      this.$store.commit('emails/setResendAll', false)
      this.$store.commit('emails/setResendEmails', [])
      this.$emit('get-employees-in-project')
      this.$store.commit('loading', false)
    },
  },
}
