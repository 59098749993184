//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CardSelect from '~/components/Shared/CardSelectTwo.vue'
export default {
  components: {
    CardSelect,
  },
  props: {
    dimensions: {
      type: Array,
      default: () => [],
    },
    selectedDimensionsIds: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    isActive(value) {
      return this.selectedDimensionsIds.includes(value)
    },
    handleSelect(value) {
      this.$emit('update', value)
    },
  },
}
