//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Empty from './Empty.vue'
import AddNew from './AddNew.vue'
import GroupHeader from './GroupHeader.vue'
import Option from './Option.vue'
import { isBlank } from '~/util/string'
export default {
  components: { Empty, AddNew, GroupHeader, Option },
  props: {
    addNewMessage: {
      type: String,
      default: null,
    },
    enableAddNew: {
      type: Boolean,
      default: true,
    },
    clearOnSelect: {
      type: Boolean,
      default: false,
    },
    emptyMessage: {
      type: String,
      default: null,
    },
    emptyListDetails: {
      type: Array,
      default: null,
    },
    search: {
      type: Function,
      default: () => [],
    },
    currentValue: {
      type: [Object, String],
      default: null,
    },
    icon: {
      type: String,
      default: () => 'magnify',
    },
    groupLabels: {
      type: Object,
      default: () => ({}),
    },
    field: {
      type: String,
      default: () => 'title',
    },
    groupField: {
      type: String,
      default: () => 'type',
    },
    groupOptions: {
      type: String,
      default: () => 'items',
    },
    placeholder: {
      type: String,
      default: () => '',
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    errors: {
      type: Array,
      default: () => [],
    },
    valid: {
      type: Boolean,
      default: () => null,
    },
    smallOptionContent: {
      type: Boolean,
      default: () => false,
    },
    dropdownPosition: {
      type: String,
      default: () => 'bottom',
    },
  },
  data() {
    return {
      data: [],
      pattern: '',
      loading: false,
      selected: null,
      storedData: {},
    }
  },
  computed: {
    isVisibleEmpty() {
      return this.data.length === 0 && !this.loading
    },
  },
  watch: {
    currentValue(newVal) {
      if (newVal) {
        this.selected = newVal
        this.pattern = newVal[this.field]
      } else {
        this.selected = null
        this.pattern = ''
      }
    },
  },
  mounted() {
    if (this.currentValue) {
      this.selected = this.currentValue
      this.pattern = this.currentValue[this.field]
    }

    this.$refs.dropdownContent = document.querySelector('.dropdown-content')
    this.resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.target === this.$refs.dropdownContent) {
          this.updateAddNewPosition()
        }
      }
    })

    this.resizeObserver.observe(this.$refs.dropdownContent)
  },
  beforeDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.unobserve(this.$refs.dropdownContent)
      this.resizeObserver.disconnect()
    }
  },
  methods: {
    handleSelect(option) {
      this.$emit('select', option)
      this.selected = option
      if (this.clearOnSelect) {
        this.pattern = ''
      }
      this.storedData = {}
    },
    handleNew() {
      this.$emit('add-new')
    },
    updateAddNewPosition() {
      if (!this.enableAddNew) return
      const dropdownContent = document.querySelector('.dropdown-content')
      const dropdownFooter = document.querySelector('.dropdown-footer')
      const defaultHeight = this.smallOptionContent ? 220 : 350
      if (dropdownFooter) {
        const height = dropdownContent
          ? dropdownContent.offsetHeight
          : defaultHeight
        dropdownFooter.style.top = `${height}px`
      }
    },
    async handleInput(pattern) {
      this.$emit('input', pattern)
      if (isBlank(pattern)) {
        this.data = []
        this.loading = false
        return
      }

      if (this.storedData[pattern]) {
        this.data = this.storedData[pattern]
        this.loading = false
        return
      }

      await this.fetchData(pattern)
    },
    async fetchData(pattern) {
      const currentPattern = this.pattern
      this.loading = true
      try {
        const data = await this.search(pattern)
        this.storedData[currentPattern] = data
        if (currentPattern === this.pattern) {
          this.data = data
          this.loading = false
        }
      } catch (error) {
        if (currentPattern === this.pattern) {
          this.loading = false
        }
      }
    },
    handleSelectFooter() {
      this.pattern = 'Otro'
      this.$emit('select-footer')
    },
  },
}
