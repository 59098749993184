import { render, staticRenderFns } from "./ProjectUserList.vue?vue&type=template&id=4ed35089&scoped=true&"
import script from "./ProjectUserList.vue?vue&type=script&lang=js&"
export * from "./ProjectUserList.vue?vue&type=script&lang=js&"
import style0 from "./ProjectUserList.vue?vue&type=style&index=0&id=4ed35089&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ed35089",
  null
  
)

export default component.exports