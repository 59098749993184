//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ModalWithTitleBar from '@/components/Shared/ModalWithTitleBar'
import Button from '@/components/Shared/Button.vue'

export default {
  name: 'ObjectivesModal',
  components: { ModalWithTitleBar, Button },
  props: {
    title: {
      type: String,
      default: '',
    },
    visible: {
      type: Boolean,
      default: false,
    },
    close: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      objectives: [
        {
          id: 1,
          icon: '/img/projects/intern.svg',
          title: this.$t(
            'projects.new.steps.first.modal.objectives.list.intern.title'
          ),
          description: this.$t(
            'projects.new.steps.first.modal.objectives.list.intern.description'
          ),
        },
        {
          id: 2,
          icon: '/img/projects/talent.svg',
          title: this.$t(
            'projects.new.steps.first.modal.objectives.list.talent.title'
          ),
          description: this.$t(
            'projects.new.steps.first.modal.objectives.list.talent.description'
          ),
        },
        {
          id: 3,
          icon: '/img/projects/unbundling.svg',
          title: this.$t(
            'projects.new.steps.first.modal.objectives.list.unbundling.title'
          ),
          description: this.$t(
            'projects.new.steps.first.modal.objectives.list.unbundling.description'
          ),
        },
        {
          id: 4,
          icon: '/img/projects/others.svg',
          title: this.$t(
            'projects.new.steps.first.modal.objectives.list.others.title'
          ),
          description: this.$t(
            'projects.new.steps.first.modal.objectives.list.others.description'
          ),
        },
      ],
    }
  },
}
