var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-table',_vm._b({on:{"page-change":_vm.onPageChange}},'b-table',_vm.$attrs,false),[_c('b-table-column',{attrs:{"field":"names","label":_vm.$t('company.potentials.fields.name')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var potentialWorker = ref.row;
return [_vm._v("\n    "+_vm._s(_vm._f("fullNames")(potentialWorker.user))+"\n  ")]}}])}),_vm._v(" "),_c('b-table-column',{attrs:{"field":"project","label":_vm.$t('company.potentials.fields.project'),"centered":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var potentialWorker = ref.row;
return [_c('span',{class:("tag " + (!potentialWorker.user.project ? 'is-danger' : ''))},[_vm._v("\n      "+_vm._s(_vm._f("projectFormatted")(potentialWorker.user))+"\n    ")])]}}])}),_vm._v(" "),_c('b-table-column',{attrs:{"field":"experiences","label":_vm.$t('company.potentials.fields.experiences'),"centered":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var potentialWorker = ref.row;
return [_c('b-button',{attrs:{"type":"is-primary is-rounded is-small"}},[_vm._v("\n      "+_vm._s(potentialWorker.user.experience_works.length +
          potentialWorker.user.experience_educationals.length +
          potentialWorker.user.experience_others.length || 0)+"\n    ")])]}}])}),_vm._v(" "),_c('b-table-column',{attrs:{"field":"skilss","label":_vm.$t('company.potentials.fields.skills'),"centered":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var potentialWorker = ref.row;
return [_c('b-button',{attrs:{"type":"is-tertiary is-rounded is-small"}},[_vm._v("\n      "+_vm._s(_vm.getSkillTotal(potentialWorker.user))+"\n    ")])]}}])}),_vm._v(" "),_c('b-table-column',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var potentialWorker = ref.row;
return [_c('chevron-right',{on:{"click":function () { return _vm.onRowClick(potentialWorker.user); }}})]}}])}),_vm._v(" "),_c('template',{slot:"empty"},[_c('TableEmptyData')],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }