





















import SkillsInventoryChart from '@/components/SkillsInventory/Charts/SkillsInventoryChart.vue'

export default {
  components: { SkillsInventoryChart },
  props: {
    skillMorphs: {
      type: Array,
      default: () => [],
    },
    byTypeTitle: {
      type: String,
      default: '',
    },
    byTypeDescription: {
      type: String,
      default: '',
    },
    byReuseLevelTitle: {
      type: String,
      default: '',
    },
    byReuseLevelDescription: {
      type: String,
      default: '',
    },
  },
  computed: {
    skills() {
      return this.getUniqueSkills(this.skillMorphs)
    },
    totalSkillsByType() {
      return this.getTotalSkillsByType(this.skills)
    },
    totalSkillsByReuseLevel() {
      return this.getTotalSkillsByReuseLevel(this.skills)
    },
  },
  methods: {
    getUniqueSkills(skillMorphs) {
      const skills = []
      skillMorphs.forEach((skillMorph) => {
        if (!skills.find((skill) => skill.id === skillMorph.skill.id)) {
          skills.push(skillMorph.skill)
        }
      })
      return skills
    },
    getTotalSkillsByType(skills) {
      const resumes = [
        {
          name: this.$t(
            'skillsInventory.charts.ByType.info.linguisticCompetence.name'
          ),
          descriptionTitle: this.$t(
            'skillsInventory.charts.ByType.info.linguisticCompetence.descriptionTitle'
          ),
          description: this.$t(
            'skillsInventory.charts.ByType.info.linguisticCompetence.description'
          ),
          code: 'L',
          value: 0,
        },
        {
          name: this.$t(
            'skillsInventory.charts.ByType.info.transversalCompetence.name'
          ),
          description: this.$t(
            'skillsInventory.charts.ByType.info.transversalCompetence.description'
          ),
          code: 'T',
          value: 0,
        },
        {
          name: this.$t('skillsInventory.charts.ByType.info.knowledge.name'),
          description: this.$t(
            'skillsInventory.charts.ByType.info.knowledge.description'
          ),
          code: 'K',
          value: 0,
        },

        {
          name: this.$t('skillsInventory.charts.ByType.info.skills.name'),
          description: this.$t(
            'skillsInventory.charts.ByType.info.skills.description'
          ),
          code: 'S',
          value: 0,
        },
      ]
      let total=0;
      skills.forEach((skill) => {
        resumes.forEach((resume) => {
          if ((skill.code || '').includes(resume.code)) {
            resume.value += 1
            total+=1
          }
        })
      })
      return this.setPercentages(resumes,total)
    },
    getTotalSkillsByReuseLevel(skills) {
      const resumes = [
        {
          name: this.$t(
            'skillsInventory.charts.ByReuseLevel.info.occupationSpecific.name'
          ),
          descriptionTitle: this.$t(
            'skillsInventory.charts.ByReuseLevel.info.occupationSpecific.descriptionTitle'
          ),
          description: this.$t(
            'skillsInventory.charts.ByReuseLevel.info.occupationSpecific.description'
          ),
          code: 2,
          value: 0,
        },
        {
          name: this.$t(
            'skillsInventory.charts.ByReuseLevel.info.sectorSpecific.name'
          ),
          descriptionTitle: this.$t(
            'skillsInventory.charts.ByReuseLevel.info.sectorSpecific.descriptionTitle'
          ),
          description: this.$t(
            'skillsInventory.charts.ByReuseLevel.info.sectorSpecific.description'
          ),
          code: 1,
          value: 0,
        },
        {
          name: this.$t(
            'skillsInventory.charts.ByReuseLevel.info.intersectorial.name'
          ),
          descriptionTitle: this.$t(
            'skillsInventory.charts.ByReuseLevel.info.intersectorial.descriptionTitle'
          ),
          description: this.$t(
            'skillsInventory.charts.ByReuseLevel.info.intersectorial.description'
          ),
          code: 3,
          value: 0,
        },
        {
          name: this.$t(
            'skillsInventory.charts.ByReuseLevel.info.transversal.name'
          ),
          descriptionTitle: this.$t(
            'skillsInventory.charts.ByReuseLevel.info.transversal.descriptionTitle'
          ),
          description: this.$t(
            'skillsInventory.charts.ByReuseLevel.info.transversal.description'
          ),
          code: 4,
          value: 0,
        },
      ]
      skills.forEach((skill) => {
        resumes.forEach((resume) => {
          if (skill.reuseLevel === resume.code) {
            resume.value += 1
          }
        })
      })
      return this.setPercentages(resumes,this.skills.length)
    },
    setPercentages(resumes,total){
      return resumes.map((resume) => ({
        ...resume,percentage: Number(Math.round(Number((resume.value/total)+'e+3'))+'e-1')
      }));
    }
  },

}
