//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Tag from '@/components/Shared/Tag'
import Button from '~/components/Shared/Button'
import ModalWithTitleBar from '~/components/Shared/ModalWithTitleBar'

export default {
  name: 'MassiveImportInfoUserModal',
  components: { Button, ModalWithTitleBar, Tag },
  filters: {
    styleForActiveRow(errors) {
      if (errors.length > 0) {
        return 'is-danger'
      }
      return 'is-success'
    },
    textForActiveRow(errors) {
      if (errors.length > 0) {
        return 'Error de carga'
      }
      return 'Carga completa'
    },
  },
  props: {
    value: {
      type: Boolean,
      default: true,
    },
    usersInfo: {
      type: Array,
      default: () => [],
    },
    sendInvitation: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      active: this.value,
      checkedRows: [],
    }
  },
  computed: {
    isDisabled() {
      return this.usersInfo
        .map((userInfo) => userInfo.errors)
        .some((userError) => userError.length > 0)
    },
  },
  watch: {
    value() {
      this.active = this.value
    },
  },
  methods: {
    close() {
      this.active = !this.active
      this.$emit('close')
      this.$emit('input', this.active)
    },
    isRowExpanded(row) {
      return this.$refs.massiveImportInfoUserTable.isVisibleDetailRow(row)
    },
    toggleDetails(row) {
      if (row.errors.length > 0) {
        this.$refs.massiveImportInfoUserTable.toggleDetails(row)
      }
    },
    async createUsers() {
      this.$store.commit('loading', true)
      try {
        await this.$repository.invitation.newMassCreateUsers({
          users: this.usersInfo,
          sendInvitation: this.sendInvitation,
        })
      } catch (e) {
        this.$buefy.notification.open({
          message: e.message,
          type: 'is-danger',
          duration: 5000,
        })
      } finally {
        this.$store.commit('loading', false)
        this.$emit('confirm-mass-invitation')
        this.close()
      }
    },
  },
}
