//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'CheckboxPicker',
  props: {
    options: {
      type: Object,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
    value: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      newValue: [],
    }
  },
  watch: {
    value(newValue) {
      this.newValue = newValue
    },
  },
  created() {
    this.newValue = this.value
  },
  methods: {
    input() {
      this.$emit('input', this.newValue)
    },
  },
}
