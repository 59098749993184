//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ModalWithTitleBar from '@/components/Shared/ModalWithTitleBar'
import Button from '@/components/Shared/Button.vue'
import SearchUsers from './SearchUsers.vue'
import AddUsers from './AddUsers.vue'

export default {
  components: { ModalWithTitleBar, Button, SearchUsers, AddUsers },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    employees: {
      type: Array,
      default: () => [],
    },
    employessInProject: {
      type: Array,
      default: () => [],
    },
    projectId: {
      type: Number,
      required: true,
    },
    draft: {
      type: Boolean,
      default: false,
    },
    sendInvitation: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    activeTab: 0,
    usersToAdd: [],
    project: {
      users: [],
    },
    isSubmitDisabled: false,
    isFormValid: false,
  }),
  watch: {
    activeTab(value) {
      if (value === 0) this.isSubmitDisabled = false
      if (value === 1) this.isSubmitDisabled = !this.isFormValid
    },
  },
  methods: {
    onToggle() {
      this.$emit('toggle')
      this.isFormValid = false
      this.activeTab = 0
    },
    setUsersToAdd(users) {
      this.usersToAdd = users
    },
    async handleAddUsers() {
      if (this.activeTab === 0) {
        this.$store.commit('loading', true)
        const users = [
          ...new Set([
            ...this.employessInProject.map((user) => user.id),
            ...this.usersToAdd.map((user) => user.id),
          ]),
        ]
        this.project.users = users
        this.project.total_users = users.length
        await this.$repository.project.update(this.projectId, this.project)
        if (!this.draft) {
          await this.$repository.invitation.sendInvitationByUserIds({
            users: this.usersToAdd.map((user) => user.id),
            message: '',
          })
        }
        this.$emit('user-created')
        this.$store.commit('loading', false)
      }
      if (this.activeTab === 1) {
        this.$refs.addUsers.submit()
        this.isFormValid = false
        this.activeTab = 0
      }
    },
    handleCreateUserWithoutEmail() {
      this.$refs.addUsers.saveUser()
    },
    finishSaveUser() {
      this.$emit('user-created')
    },
    handleMassiveModel() {
      this.$emit('import-massive-users')
    },
    handleUserWithError(message) {
      this.$emit('user-with-error', message)
    },
    handleUserWithoutEmailConfirmation() {
      this.$emit('user-without-email-confirmation')
    },
    handleValidationInvalid(invalid) {
      this.isFormValid = !invalid
      if (this.activeTab === 0) this.isSubmitDisabled = false
      else {
        this.isSubmitDisabled = invalid
      }
    },
  },
}
