//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    placeholders: {
      type: Object,
      default: () => ({
        text: 'Buscar ocupación',
        projectId: 'Proyectos',
      }),
    },
    projects: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    text: '',
    projectId: null,
  }),
  computed: {
    projectsName() {
      const uniqueIds = new Set() // set to store unique project IDs
      return this.projects.reduce((result, project) => {
        if (!uniqueIds.has(project.id)) {
          uniqueIds.add(project.id) // add the ID to the set
          result.push({
            value: project.id,
            text: project.name,
          })
        }
        return result
      }, [])
    },
  },
  methods: {
    handleChange() {
      this.$emit('change', {
        text: this.text,
        projectId: this.projectId,
      })
    },
  },
}
