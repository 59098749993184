//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DashboardOpportunityList from '~/components/Dashboard/DashboardOpportunityList'
import ProfileMissingSteps from '~/components/Dashboard/ProfileMissingSteps'

const STEPS = ['announcement']

export default {
  components: {
    DashboardOpportunityList,
    ProfileMissingSteps,
  },
  props: {
    user: {
      type: Object,
      default: () => ({}),
    },
    registerComplete: {
      type: Boolean,
      default: false,
    },
  },
  async fetch() {
    this.$store.commit('loading', true)
    this.stepProgress = await this.getProgress()
    this.stepsIncomplete = STEPS.filter((step) => !this.isComplete(step))
    this.$emit('complete', this.registerCompleted)
    this.$store.commit('loading', false)
  },
  data() {
    return {
      stepProgress: {},
      routes: {
        announcement: '/ocupaciones-requeridas/cesantes',
      },
      stepsIncomplete: [],
    }
  },
  computed: {
    registerCompleted() {
      return this.stepsIncomplete.length === 0
    },
  },
  methods: {
    isComplete(field) {
      return this.stepProgress[field] === 100
    },
    async getProgress() {
      const announcementProgress = await this.checkAnnouncementProgress()
      return {
        announcement: Math.floor(announcementProgress),
      }
    },
    async checkAnnouncementProgress() {
      const count = await this.$repository.jobAnnouncement.count({
        job_seeker_fair:
          this.user.job_seeker_fair.id || this.user.job_seeker_fair,
      })
      return count > 0 ? 100 : 0
    },
  },
}
