//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Button from '@/components/Shared/Button.vue'
import { mapGetters } from 'vuex'
import { showSuccessNotification } from '~/util/notifications'

export default {
  name: 'StepFive',
  components: {
    Button,
  },
  computed: {
    ...mapGetters({
      getBaseProject: 'project/getBaseProject',
    }),
  },
  methods: {
    goToProjectDetail() {
      this.$router.push(`/mis-proyectos/${this.getBaseProject.id}`)
      showSuccessNotification(
        this,
        this.$t('projects.new.steps.fifth.success'),
        5000
      )
    },
  },
}
