//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ValidationObserver } from 'vee-validate'
import { rutFilter } from 'vue-dni'
import { FormSelect, FormCheckBox, FormInput } from '@/components/Form'
import EDUCATION_LEVELS from '@/constants/education-levels'
export default {
  components: {
    ValidationObserver,
    FormSelect,
    FormCheckBox,
    FormInput,
  },
  async fetch() {
    this.regions = await this.$repository.location.find({
      type: 'region',
      _sort: 'name:ASC',
      populate: ['children'],
      _limit: -1,
    })
  },
  data: () => ({
    form: {
      identification_number: null,
    },
    currentEducationLevel: '',
    regions: [],
    communes: [],
    educationLevels: [
      {
        id: EDUCATION_LEVELS.BASIC,
        name: 'Educación básica',
      },
      {
        id: EDUCATION_LEVELS.MEDIUM,
        name: 'Educación media (Técnica Profesional y Científico-Humanista)',
      },
      {
        id: EDUCATION_LEVELS.HIGHER,
        name:
          'Educación superior (Centro de Formación Técnica, Institutos Profesionales, Universidades)',
      },
      {
        id: EDUCATION_LEVELS.POSTGRADUATE,
        name: 'Postgrado (Diplomados, Maestrías o Doctorados)',
      },
    ],
  }),
  watch: {
    form: {
      handler(val) {
        if (val.identification_number) {
          this.form.identification_number = rutFilter(val.identification_number)
        }
        this.form.terms = !this.form.terms ? undefined : true
        this.form.provide_data = !this.form.provide_data ? undefined : true
      },
      deep: true,
    },
    currentEducationLevel(newValue) {
      this.form.education_level = newValue?.id
    },
  },
  methods: {
    submit() {
      this.$emit('register', this.form)
    },
    async onInputRegion(select) {
      this.form.comune = ''
      const parentCodes = select?.children.map(({ id }) => id)
      this.communes = await this.$repository.location.find({
        type: 'comuna',
        parent_in: parentCodes,
        _sort: 'name:ASC',
      })
      this.$forceUpdate()
    },
    showTerms() {
      this.$emit('show-terms')
    },
    showPolicyData() {
      this.$emit('show-policy-data')
    },
  },
}
