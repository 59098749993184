const Request = require('@/repositories/request')

class Role extends Request {
  /**
   *
   * @returns {*}
   */
  getAll() {
    return this.$strapi['$users-permissions/roles'].find()
  }

  async findByTypeOrCreate(type) {
    const { roles = [] } = await this.getAll()
    const findOne = roles.find(({ type: typeRol }) => typeRol === type)
    if (findOne?.id) return findOne

    const params = { type, name: type }
    return this.$strapi['$users-permissions/roles'].create(params)
  }
}

module.exports = Role
