import {
  SECTIONS,
  SUBSECTIONS,
  SUBSECTIONS_SKILLS_FLOW,
} from '~/constants/questionary'

const SKILLS_ORIGIN_BY_PAGE = 15
const SKILLS_TARGET_BY_PAGE = 4

export const getOriginSkills = (questionary) => {
  if (SECTIONS.ORIGIN !== questionary.section) {
    return []
  }
  if (questionary.subsection === SUBSECTIONS.SKILLS) {
    const offset = (questionary.page - 1) * SKILLS_ORIGIN_BY_PAGE
    return questionary.origin_skills.skills.slice(
      offset,
      offset + SKILLS_ORIGIN_BY_PAGE
    )
  }
  if (questionary.subsection === SUBSECTIONS.KNOWLEDGES) {
    const skillPages = questionary.origin_skills.total_page
    const offset = (questionary.page - skillPages - 1) * SKILLS_ORIGIN_BY_PAGE
    return questionary.origin_knowledges.skills.slice(
      offset,
      offset + SKILLS_ORIGIN_BY_PAGE
    )
  }
}

export const getTargetSkills = (questionary, projectType) => {
  if (SECTIONS.TARGET !== questionary.section) {
    return []
  }
  const isStartSubSection =
    SUBSECTIONS_SKILLS_FLOW[projectType][questionary.section].indexOf(
      questionary.subsection
    ) === 0
  if (questionary.subsection === SUBSECTIONS.REUSE_COMMONS) {
    const PREVIOUS_SUBSECTION_PAGES = isStartSubSection
      ? 0
      : questionary.target_reuse_specifics.total_page
    const offset =
      (questionary.page - PREVIOUS_SUBSECTION_PAGES - 1) * SKILLS_TARGET_BY_PAGE
    return questionary.target_reuse_commons.skills.slice(
      offset,
      offset + SKILLS_TARGET_BY_PAGE
    )
  }
  if (questionary.subsection === SUBSECTIONS.REUSE_SPECIFICS) {
    const PREVIOUS_SUBSECTION_PAGES = isStartSubSection
      ? 0
      : questionary.target_reuse_commons.total_page
    const offset =
      (questionary.page - PREVIOUS_SUBSECTION_PAGES - 1) * SKILLS_TARGET_BY_PAGE
    return questionary.target_reuse_specifics.skills.slice(
      offset,
      offset + SKILLS_TARGET_BY_PAGE
    )
  }
}
