const defaultWorkingExperienceId = 0
const defaultEducationalExperienceId = 0

export const state = () => ({
  workingExperienceId: defaultWorkingExperienceId,
  educationalExperienceId: defaultEducationalExperienceId,
  otherExperience: {
    id: null,
    detail_experience: '',
    start_date_experience: '',
    end_date_experience: '',
    present: true,
    experienceSkills: [],
  },
  currentStep: 0,
})

export const getters = {
  getOtherExperience(state) {
    return state.otherExperience
  },
  getCurrentStep(state) {
    return state.currentStep
  },
}

export const mutations = {
  setWorkingExperienceId(state, data) {
    state.workingExperienceId = data
  },
  clearWorkingExperienceId(state) {
    state.workingExperienceId = defaultWorkingExperienceId
  },
  setEducationalExperienceId(state, data) {
    state.educationalExperienceId = data
  },
  clearEducationalExperienceId(state) {
    state.educationalExperienceId = defaultEducationalExperienceId
  },
  SET_OTHER_EXPERIENCE(state, data) {
    state.otherExperience = data
  },
  CLEAR_OTHER_EXPERIENCE(state) {
    state.otherExperience = {
      id: null,
      detail_experience: '',
      start_date_experience: '',
      end_date_experience: '',
      present: true,
      experienceSkills: [],
    }
  },
  SET_CURRENT_STEP(state, data) {
    state.currentStep = data
  },
}

export const actions = {
  setOtherExperience(context, experience) {
    context.commit('SET_OTHER_EXPERIENCE', experience)
  },
  clearOtherExperience(context) {
    context.commit('CLEAR_OTHER_EXPERIENCE')
  },
  setCurrentStep(context, step) {
    context.commit('SET_CURRENT_STEP', step)
  },
  resetCurrentStep(context) {
    context.commit('SET_CURRENT_STEP', 0)
  },
}
