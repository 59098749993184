const Request = require('@/repositories/request')
const qs = require('qs')
class SkillPacks extends Request {
  findOne(id, params) {
    const stringifyParams = qs.stringify({ ...params }, { encode: false })
    return this.$strapi.$http.$get(`/skill-packs/${id}?${stringifyParams}`)
  }

  findAll(params) {
    const stringifyParams = qs.stringify({ ...params }, { encode: false })
    return this.$strapi['$skill-packs'].find(stringifyParams)
  }
}

module.exports = SkillPacks
