//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import ROLES from '@/constants/roles'
import COUNTRY from '@/constants/country'
import { ValidationObserver } from 'vee-validate'
import ModalWithTitleBar from '@/components/Shared/ModalWithTitleBar'
import ProjectFormSelect from '@/components/Form/ProjectFormSelect'
import moment from 'moment'
import Button from '~/components/Shared/Button'
import Finder from '~/components/Shared/Finder/Finder.vue'

export default {
  name: 'ModalEditProfile',
  components: {
    ValidationObserver,
    ModalWithTitleBar,
    ProjectFormSelect,
    Button,
    Finder,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      default: () => {},
    },
    regions: {
      type: Array,
      default: () => [],
    },
    communes: {
      type: Array,
      default: () => [],
    },
    defaultCities: {
      type: Array,
      default: () => [],
    },
    genders: {
      type: Array,
      default: () => [],
    },
    educationLevels: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      homeLocation: {},
      form: {
        names: '',
        lastnames: '',
        gender: '',
        phone: '',
        birthdate: '',
        roleDescription: '',
        education_level: '',
        education: '',
        city: '',
        region: '',
        comune: '',
        address: '',
      },
      formCompany: {
        name: '',
        identification_number: '',
        createdAt: '',
      },
    }
  },
  computed: {
    ...mapGetters('me', [
      'isChilean',
      'isCompany',
      'isRelink',
      'isRelinkOrCompany',
      'getLabelProfile',
    ]),
    hasAddresses() {
      return (this.user?.addresses || []).length > 0
    },
    isVisibleForGeneralUsers() {
      return [ROLES.UNEMPLOYED, ROLES.PUBLIC].includes(this.user.role.type)
    },
    isVisibleForEmployees() {
      return this.user.role.type === ROLES.EMPLOYEE
    },
    isCompanyChilean() {
      return this.user?.company?.country?.name === COUNTRY.CHILE
    },
    title() {
      return this.isRelinkOrCompany
        ? this.$t('profile.companyData.title')
        : this.$t('profile.personalData.title')
    },
    showCompany() {
      return !!this.user.company
    },
  },
  watch: {
    visible(val) {
      if (val) {
        this.form.names = this.user.names
        this.form.lastnames = this.user.lastnames
        this.form.gender = this.genders.find(
          (gender) => gender.id === this.user.gender
        )
        this.form.phone = this.user.phone
        this.form.birthdate = this.user.birthdate
        this.form.roleDescription = this.user.roleDescription
        this.form.education = this.user.education
        this.form.education_level = this.educationLevels.find(
          (educationLevel) => educationLevel.id === this.user.education_level
        )
        if (this.isChilean === false) {
          this.form.city = this.searchCities(this.user.city)[0]
        }
        if (this.isRelink && this.showCompany) {
          this.formCompany.createdAt = moment(
            this.user.company.created_at
          ).format('DD/MM/YYYY')
        }
        if (this.isCompany) {
          this.formCompany.country = this.user.company.country?.name
        }
        if (this.user.homeLocation.location) {
          this.form.region = this.user.region
          this.form.comune = this.user.comune
          this.form.address = this.user.adress
        }
        if (this.isRelinkOrCompany && this.showCompany) {
          this.formCompany.name = this.user.company.name
          this.formCompany.identification_number = this.user.company.identification_number
        }
      }
    },
  },
  methods: {
    onToggle() {
      this.$emit('toggle')
    },
    submitForm() {
      const submitForm = {
        ...this.form,
        address: null,
        gender: this.form.gender?.id,
        city: this.form.city?.name,
        addresses: [
          {
            ...this.homeLocation,
            location: this.form.comune,
            text: this.form.address,
          },
        ],
      }
      if (
        this.isVisibleForEmployees ||
        (this.isVisibleForGeneralUsers && this.isChilean)
      ) {
        submitForm.education_level = this.form.education_level?.id
      }
      if (this.isRelinkOrCompany && this.showCompany) {
        delete this.formCompany.createdAt
        delete this.formCompany.country
        this.$emit('submitCompany', this.formCompany)
      }
      this.$emit('submit', submitForm)
    },
    selectRegion(selectOption) {
      this.form.comune = ''
      this.form.address = ''
      this.$emit('selectRegion', selectOption)
    },
    selectCity(city) {
      this.form.city = city
    },
    searchCities(term) {
      return this.defaultCities.filter((city) =>
        city.name.toLowerCase().includes(term.toLowerCase())
      )
    },
  },
}
