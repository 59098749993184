export function mapFields(options) {
  const object = {}
  for (let x = 0; x < options.fields.length; x++) {
    const field = options.fields[x]
    object[field] = {
      get() {
        return this.$store.state[options.module][options.base][field]
      },
      set(value) {
        this.$store.commit(`${options.module}/${options.mutation}`, {
          key: field,
          value,
        })
      },
    }
  }
  return object
}

export function executeOnlyLastCall(timerId, funToBeExecuted, seconds = 5) {
  if (timerId) {
    clearTimeout(timerId)
  }

  return setTimeout(funToBeExecuted, seconds * 1000)
}
