//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import EmptyDataIcon from '~/assets/svg/empty-data.svg'

export default {
  name: 'TopSkillsSection',
  components: {
    EmptyDataIcon,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    columns() {
      return [
        {
          label: 'Proyecto',
          field: 'project_name',
          sortable: true,
        },
        {
          label: 'Total de usuarios',
          field: 'total_employees',
          sortable: true,
          cellClass: 'table-cells',
        },
        {
          label: 'Nº finalizados',
          field: 'finished_questionaries',
          sortable: true,
          centered: true,
        },
      ]
    },
  },
}
