const Request = require('@/repositories/request')
const qs = require('qs')

class SkillSet extends Request {
  /**
   *
   * @param {Object} params
   * @return {*}
   */
  find(params) {
    const stringifyParams = qs.stringify({ ...params }, { encode: false })
    return this.$strapi['$skill-sets'].find(stringifyParams)
  }
}

module.exports = SkillSet
