var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"new-questionary-wizard"},[_c('div',{staticClass:"container is-fluid"},[(_vm.questionary.section === _vm.SECTIONS_CONSTANT.INSTRUCTIONS)?_c('div',{staticStyle:{"padding":"1rem"}},[_vm._t("step-introduction",null,null,{
            onNextStep: _vm.startQuestionary,
            current_sub_step: _vm.questionary.current_sub_step,
          })],2):_vm._e(),_vm._v(" "),(_vm.questionary.section !== _vm.SECTIONS_CONSTANT.INSTRUCTIONS)?_c('b-steps',{attrs:{"animated":true,"rounded":true,"size":"is-small","has-navigation":false},model:{value:(_vm.currentStep),callback:function ($$v) {_vm.currentStep=$$v},expression:"currentStep"}},[_c('b-step-item',{attrs:{"step":"0","icon":"lightbulb","label":_vm.$t('questionaryProject.validateSkills.wizard.step2')}},[_vm._t("step-models",null,null,{
              onPreviousStep: _vm.onPreviousStep,
              onNextStep: _vm.onNextStep,
              skillsToValidate: _vm.skillsToValidate,
              title: _vm.title,
              questionary: _vm.questionary,
              description: _vm.$t('questionaryModels.validateSkills.description'),
              handleChangeItem: _vm.handleChangeItem,
              userSkillIds: _vm.userSkillIds,
            })],2),_vm._v(" "),_c('b-step-item',{attrs:{"step":"1","icon":"checkbox-marked-circle-outline","label":_vm.$t('questionaryProject.validateSkills.wizard.step3')}},[_vm._t("step-thanks")],2)],1):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }