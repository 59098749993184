//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import SkillsInventoryFilters from '@/components/SkillsInventory/Filters/SkillsInventoryFilters'
import WorkerSkillsInventoryTable from '@/components/SkillsInventory/tables/WorkerSkillsInventoryTable'

export default {
  name: 'WorkerSkillsSection',
  components: {
    SkillsInventoryFilters,
    WorkerSkillsInventoryTable,
  },
  props: {
    userId: {
      type: Number,
      default: () => 0,
    },
  },
  async fetch() {
    await this.getSkillsByWorkerId()
  },
  data() {
    return {
      skills: [],
      page: 1,
      perPage: 10,
      total: 0,
      criteria: '',
      selectedSkillTypes: [],
      selectedSkillReuseLevels: [],
    }
  },
  computed: {
    ...mapState(['loading']),
  },
  methods: {
    async getSkillsByWorkerId() {
      this.$store.commit('loading', true)
      const response = await this.$repository.skill.findByWorkerId(
        this.userId,
        {
          per_page: this.perPage,
          page: this.page,
          criteria: this.criteria.length >= 3 ? this.criteria : undefined,
          skill_types: this.selectedSkillTypes.map((type) => type.code),
          reuse_levels: this.selectedSkillReuseLevels.map(
            (reuseLevel) => reuseLevel.code
          ),
        }
      )
      this.skills = response.skills
      this.total = response.total
      this.$store.commit('loading', false)
    },
    async onPageChange(page) {
      this.page = page
      await this.getSkillsByWorkerId()
    },
    async onSearchByCriteria(criteria) {
      this.criteria = criteria
      if (this.criteria.length >= 3 || this.criteria.length === 0) {
        this.page = 1
        await this.getSkillsByWorkerId()
      }
    },
    async onSearchByFilters(filters) {
      this.page = 1
      this.selectedSkillTypes = filters.selectedSkillTypes
      this.selectedSkillReuseLevels = filters.selectedSkillReuseLevels
      await this.getSkillsByWorkerId()
    },
    async onCloseSkillTypesTag(selectedSkillTypes) {
      this.page = 1
      this.selectedSkillTypes = selectedSkillTypes
      await this.getSkillsByWorkerId()
    },
    async onCloseSkillReuseLevelsTag(selectedSkillReuseLevels) {
      this.page = 1
      this.selectedSkillReuseLevels = selectedSkillReuseLevels
      await this.getSkillsByWorkerId()
    },
  },
}
