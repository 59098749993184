import { render, staticRenderFns } from "./IndexTable.vue?vue&type=template&id=468c8822&scoped=true&"
import script from "./IndexTable.vue?vue&type=script&lang=js&"
export * from "./IndexTable.vue?vue&type=script&lang=js&"
import style0 from "./IndexTable.vue?vue&type=style&index=0&id=468c8822&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "468c8822",
  null
  
)

export default component.exports