//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Question from '~/components/faqs/Question.vue'
export default {
  components: { Question },
  data: () => {
    return {
      questions: [
        {
          question:
            'Si ya estoy registrado en www.relink.cl ¿Debo volver a inscribirme?',
          answers: [
            'No. No es necesario inscribirse ni postular. Al estar en Relink tus datos de contacto y experiencias registradas están en línea con los pilotos. ',
          ],
        },
        {
          question: '¿En qué consiste un piloto de Reconversión Laboral?',
          answers: [
            'Es una iniciativa, al alero de RELINK, que junto con instituciones de formación reconocidas por SENCE, busca desarrollar nuevas capacidades en las personas, en sintonía con las demandas del mundo del trabajo, generando más oportunidades para acceder a empleos de calidad.',
          ],
        },
        {
          question:
            '¿A qué beneficios accedo al momento de ser parte de un piloto de Reconversión Laboral?',
          answers: [
            'Aquellas personas que cumplan con los requisitos específicos de cada convocatoria, y sean seleccionados, previa evaluación de su proceso en Relink, podrán acceder a capacitaciones gratuitas.',
          ],
        },
        {
          question: '¿Cómo se accede a un piloto de Reconversión Laboral?',
          answers: [
            'Podrás acceder a los pilotos a través de nuestro sitio web en la sección personas. Ahí encontrarás toda la información necesaria para que revises los requisitos y puedas postular.',
            'Si llegaras a coincidir con algún piloto (hacer match) serás contactado para validar si tú tienes interés en participar en ese proceso, así como si cuentas con todos los requisitos solicitados.',
          ],
        },
        {
          question:
            '¿Qué requisitos debo cumplir para participar en el programa?',
          answers: [
            'Los pilotos de reconversión laboral cuentan con requisitos específicos, los cuales debes cumplir para ser beneficiario del programa.',
            'Cada convocatoria tendrá requisitos específicos, por lo tanto es importante que te fijes bien antes de postular. No obstante, es necesario para ser considerado dentro del proceso, tener cédula de identidad chilena.',
          ],
        },
        {
          question: '¿Necesito conocimientos previos?',
          answers: [
            'No. Relink, por medio de su modelo, establece potencialidad de reconversión laboral, a partir de la información laboral y formativa que ingreses. Es importante que ingreses la mayor cantidad de información posible, con total ajuste a la realidad. Con esta información, la plataforma identifica tus habilidades, y calcula el mejor match en función de lo que sabes y/o has desarrollado como funciones anteriormente, indistintamente el rubro en el que te desempeñaste. ',
          ],
        },
        {
          question:
            '¿La postulación a una convocatoria, garantiza el acceso a un piloto de Reconversión Laboral?',
          answers: [
            'No. Cada convocatoria tiene requisitos específicos, por lo que la selección de participantes dependerá de criterios como:',
            '- % de match que tengas contra la ocupación que esté buscando el piloto',
            '- Cumplimiento de los requisitos solicitados y su verificación',
            '- Match geográfico entre piloto y candidato.*',
            '*En el caso de los pilotos que requieren formación presencial.',
          ],
        },
        {
          question:
            '¿Participar en un piloto me asegura el acceso a un puesto de trabajo? ',
          answers: [
            'No. El piloto contempla gestiones de intermediación laboral, no obstante no asegura la contratación. No es obligación de las empresas participantes en Relink la contratación de las personas que participan en esta iniciativa, así como no es obligación de los beneficiarios aceptar ofertas de trabajo de empresas participantes.',
          ],
        },
        {
          question: '¿Quién me contactará en caso de ser seleccionado?',
          answers: [
            'El aviso a los candidatos seleccionados estará a cargo de los administradores de cada piloto que verificarán tu información.',
          ],
        },
        {
          question: '¿Cómo puedo resolver otras dudas de convocatoria?',
          answers: [
            'Para cualquier duda escribenos a contacto@relink.cl estaremos encantados de orientarte.',
          ],
        },
      ],
    }
  },
}
