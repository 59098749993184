//
//
//
//
//
//
//
//
//
//
//
//

import HomePageNavbar from '~/components/HomePage/HomePageNavbar'
import HomePageFooter from '~/components/HomePage/HomePageFooter'
export default {
  middleware: 'maint',
  name: 'Person',
  components: {
    HomePageNavbar,
    HomePageFooter,
  },
  head() {
    const i18nSeo = this.$nuxtI18nSeo()
    return {
      htmlAttrs: {
        lang: 'es',
        ...i18nSeo.htmlAttrs,
      },
      meta: i18nSeo.meta,
      link: i18nSeo.link,
      title: 'Inicio Personas',
    }
  },
  layout: 'default',
}
