//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CloseCircle from 'vue-material-design-icons/CloseCircle'
export default {
  components: {
    CloseCircle,
  },
  props: {
    title: {
      default: '',
      type: String,
    },
    body: {
      default: '',
      type: String,
    },
    close: {
      default: () => {},
      type: Function,
    },
  },
}
