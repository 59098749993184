//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Button from '@/components/Shared/Button.vue'
import ModalWithTitleBar from '@/components/Shared/ModalWithTitleBar'

const skillDefaultValue = {
  title: '',
  description: '',
  hierarchy_skill: { externalCode: '' },
  reuse_level: '',
  typeOfSkill: '',
  skillType: [],
}

export default {
  components: {
    Button,
    ModalWithTitleBar,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    skill: {
      type: Object,
      default: () => ({ ...skillDefaultValue }),
      require: true,
    },
  },
  data: () => ({
    selectedItem: null,
  }),
  methods: {
    onClose() {
      this.$emit('toggle')
    },
    onDelete() {
      this.$emit('actionEvent', { action: 'delete', card: this.skill })
    },
  },
}
