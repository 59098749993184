import _ from 'underscore'
import SkillConstants from '@/constants/skill'

function sortByHierarchySkills(skills) {
  return _.sortBy(skills, (skill) => {
    if (!skill['skills-group_id']) skill['skills-group_id'] = { code: '' }
    const [firstLetter, secondNumber = '0', thirtyNumber = '0'] = skill[
      'skills-group_id'
    ].code.split('.')
    const aNum = firstLetter.slice(0, 1)

    const firstNumberSort = parseInt(firstLetter.slice(1, 2))

    const secondNumberSort = parseInt(secondNumber)

    const thirtyNumberSort = parseInt(thirtyNumber)

    return [aNum, firstNumberSort, secondNumberSort, thirtyNumberSort]
  })
}

export const underscore = { sortByHierarchySkills }

export const countUniqueSkills = (skills) => {
  return _.uniq(skills.map((object) => object.id)).length
}

export const getSkillTypes = (codes) => {
  const replacement = ','
  let description = ''
  codes.split(replacement).forEach((code) => {
    const skillType = SkillConstants.skillTypes.find(
      (type) => code === type.code
    )
    description += skillType.name + ', '
  })
  return description.replace(/,([^,]*)$/, '$1')
}

export const getReuseLevel = (reuseLevel) => {
  return SkillConstants.skillReuseLevels.find(
    (skillReuseLevel) => skillReuseLevel.code === reuseLevel
  ).name
}
