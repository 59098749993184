//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import EmoticonSadOutline from 'vue-material-design-icons/EmoticonSadOutline'
import ProgressIndicator from '@/components/Shared/ProgressIndicator'
import Tag from '@/components/Shared/Tag'
export default {
  components: {
    EmoticonSadOutline,
    ProgressIndicator,
    Tag,
  },
  filters: {
    getTotalHours({ courses }) {
      if (courses.length === 0) return 0
      return courses.reduce((acc, course) => acc + course.hours, 0)
    },
  },
  props: {
    actualOpportunities: {
      type: Array,
      default: () => [],
    },
    opportunities: {
      type: Array,
      default: () => [],
    },
    opportunityDetail: {
      type: Function,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: true,
    },
    perPage: {
      type: Number,
      default: 10,
    },
    hideColumns: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    goToExperiences() {
      this.$router.push('/experiencias')
    },
    isVisibleColumn(columnName) {
      return !this.hideColumns.includes(columnName)
    },
  },
}
