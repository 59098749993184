const defaultOpportunity = {
  distance: 0,
  id: 0,
  name: '',
  skills: [],
  skillsDoesHave: [],
}

export const state = () => ({
  opportunity: defaultOpportunity,
})

export const mutations = {
  setOpportunity(state, data) {
    state.opportunity = data
  },
  clearOpportunity(state) {
    state.opportunity = defaultOpportunity
  },
}
