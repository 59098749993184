//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Button from '@/components/Shared/Button'
export default {
  components: {
    Button,
  },
  props: {
    elements: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      tab_active: this.elements[0]?.id?.toString() || '0',
    }
  },
  methods: {
    handleInput(value) {
      this.tab_active = value
    },
  },
}
