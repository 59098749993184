//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { calculateARPText } from '@/util/entity/test'
import { mapMutations } from 'vuex'

import RadialProgressBar from 'vue-radial-progress'
import TableEmptyData from '@/components/Shared/Table/TableEmptyData'
import Avatar from '../Perfil/Avatar.vue'
import { getAllExperiences } from '~/util/entity/user'

export default {
  components: {
    Avatar,
    RadialProgressBar,
    TableEmptyData,
  },
  filters: {
    fullNames(user) {
      return `${user?.names}  ${user?.lastnames}`
    },
    latestWork(user) {
      return user?.roleDescription || ''
    },
    userWorkStatus(user) {
      return this.isCurrentlyEmployed(user)
        ? this.$t('requiredOccupations.tables.potentialWorkers.employed')
        : this.$t('requiredOccupations.tables.potentialWorkers.unemployed')
    },
  },
  props: {
    redirection: {
      type: Function,
      default: null,
    },
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      perPage: 10,
      page: 1,
    }
  },
  computed: {
    dataSort() {
      return this.data.filter(() => true).sort((a, b) => b.match - a.match)
    },
  },
  methods: {
    canShowLatestWork(user) {
      return !!user?.latestWork?.id
    },
    ...mapMutations({
      setWorkerId: 'potential-worker/setWorkerId',
    }),
    calculateARPText,
    isCurrentlyEmployed(user) {
      return user?.latestWork?.end_date_work === null || user?.company
    },
    skillCountText(opportunity) {
      return this.$t('opportunities.detail.skillCount', [
        opportunity.skillsItHas,
        opportunity.skills,
      ])
    },
    getExperiencesTotal(user) {
      return getAllExperiences(user).length
    },
    onRowClick(user) {
      this.$emit('click', user)
    },
  },
}
