
import lastEvaluateMatchMixin from '@/mixins/lastEvalutateMatchMixin'
import ExperienceSkillsBase from '~/components/Experiences/ExperienceSkillsBase.vue'
import SKILL_MORPHS from '~/constants/skill-morphs'
import { getSuggestSkillsForExperienceOthers } from '~/util/entity/experience'
const MIN_SKILLS_FOR_COMPLETE_EXPERIENCE = 10
export default {
  extends: ExperienceSkillsBase,
  mixins: [lastEvaluateMatchMixin],
  methods: {
    getValidatedSkillsOfExperience(experienceId) {
      return this.userSkillMorphs
        .filter(
          (skillMorph) =>
            skillMorph.related_id === experienceId &&
            skillMorph.related_type ===
              SKILL_MORPHS.ENTITY_TYPES.EXPERIENCE_OTHERS &&
            skillMorph.has_skill
        )
        .map(({ skill }) => skill)
    },
    getSuggestSkills(experience, userSkills) {
      return getSuggestSkillsForExperienceOthers(this.$repository, userSkills)
    },
    async addSkill(id) {
      await this.updateTotalHasSkills(1)
      const savedSkills = this.$repository.skillMorph.save({
        skill: {
          id,
        },
        user: this.experience.user.id,
        related_id: this.experience.id,
        related_type: SKILL_MORPHS.ENTITY_TYPES.EXPERIENCE_OTHERS,
        origin_type: SKILL_MORPHS.ORIGIN_TYPES.SUGGEST,
      })
      this.evaluateOnlyLastMatchUser(this.experience.user.id)
      return savedSkills
    },
    async saveSkills(skillsToAdd, suggestedSkillsIds) {
      const skillMorphsToAdd = skillsToAdd.map((skill) => {
        return {
          skill,
          user: this.experience.user.id,
          related_id: this.experience.id,
          related_type: SKILL_MORPHS.ENTITY_TYPES.EXPERIENCE_OTHERS,
          origin_type: suggestedSkillsIds.includes(skill.id)
            ? SKILL_MORPHS.ORIGIN_TYPES.SUGGEST
            : SKILL_MORPHS.ORIGIN_TYPES.FREE,
        }
      })
      const requests = skillMorphsToAdd.map((skillMorph) => {
        this.$repository.skillMorph.save(skillMorph)
      })
      await Promise.all(requests)
      await this.updateTotalHasSkills(skillMorphsToAdd.length)
      this.evaluateOnlyLastMatchUser(this.experience.user.id)
      return skillMorphsToAdd
    },
    updateTotalHasSkills(skillMorphsToAddLength) {
      this.experience.total_has_skills += skillMorphsToAddLength
      this.experience.completed =
        this.experience.total_has_skills >= MIN_SKILLS_FOR_COMPLETE_EXPERIENCE
      return this.$repository.experienceOther.update(this.experience.id, {
        total_has_skills: this.experience.total_has_skills,
        completed: this.experience.completed,
      })
    },
    async deleteSkill(skillId) {
      const skillMorphToRemove = {
        skill: skillId,
        user: this.experience.user.id,
        related_id: this.experience.id,
        related_type: SKILL_MORPHS.ENTITY_TYPES.EXPERIENCE_OTHERS,
      }
      await this.$repository.skillMorph.remove(skillMorphToRemove)
      this.evaluateOnlyLastMatchUser(this.experience.user.id)
    },
  },
}
