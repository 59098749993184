import { render, staticRenderFns } from "./WorkerSkillsSection.vue?vue&type=template&id=71208d8a&scoped=true&"
import script from "./WorkerSkillsSection.vue?vue&type=script&lang=js&"
export * from "./WorkerSkillsSection.vue?vue&type=script&lang=js&"
import style0 from "./WorkerSkillsSection.vue?vue&type=style&index=0&id=71208d8a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71208d8a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SkillsInventoryFilters: require('/workspace/components/SkillsInventory/Filters/SkillsInventoryFilters.vue').default})
