//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment'
import Field from '@/components/Perfil/Field'
import CustomButton from '@/components/Perfil/Button'
import OpportunitiesList from './OpportunitiesList.vue'
import ExperiencesTable from '~/components/PotentialWorker/ExperiencesTable'
import SkillsTable from '~/components/PotentialWorker/SkillsTable'

export default {
  components: {
    Field,
    CustomButton,
    ExperiencesTable,
    SkillsTable,
    OpportunitiesList,
  },
  props: {
    sortedOpportunities: {
      type: Array,
      default: () => [],
    },
    filteredOpportunities: {
      type: Array,
      default: () => [],
    },
    person: {
      type: Object,
      default: () => {},
    },
    experienceWorks: {
      type: Array,
      default: () => [],
    },
    experienceEducationals: {
      type: Array,
      default: () => [],
    },
    experienceOthers: {
      type: Array,
      default: () => [],
    },
    skills: {
      type: Array,
      default: () => [],
    },
    position: {
      type: String,
      default: () => '',
    },
    jobStatusLabel: {
      type: String,
      default: () => '',
    },
    isActiveDetailExperienceWorkClick: {
      type: Boolean,
      default: true,
    },
    isActiveDetailExperienceEducationalClick: {
      type: Boolean,
      default: true,
    },
    isActiveDetailExperienceOtherClick: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      activeTab: 0,
      personalData: [],
    }
  },
  computed: {
    personalDatas(context) {
      return [
        [
          {
            name: 'profile.personalData.form.names.label',
            value: context.person.names,
          },
          {
            name: 'profile.personalData.form.lastnames.label',
            value: context.person.lastnames,
          },
          {
            name: 'profile.personalData.form.idNumber',
            value: context.person.identification_number,
          },
          {
            name: 'profile.personalData.form.laboralSituation',
            value: this.jobStatusLabel,
          },
          {
            name: 'profile.personalData.form.position.label',
            value: context.position,
          },

          {
            name: 'profile.accessData.form.email',
            value: context.person.email,
          },
          {
            name: 'profile.personalData.form.phone.label',
            value: context.person.phone,
          },
          {
            name: 'profile.personalData.form.birthdate.label',
            value: context.person.birthdate
              ? moment(context.person.birthdate).format('DD/MM/YYYY')
              : '',
          },
          ...context.person.addresses.map((address) => {
            return {
              name: `profile.addresses.field.${address.use}`,
              value: `${address.text}, ${address?.location?.name}`,
            }
          }),
        ],
      ]
    },
  },
  methods: {
    getEducationalExperienceName(row) {
      return (
        row.educational_career?.title ??
        row.area_educational?.name ??
        row.study_name
      )
    },
    openWorkExperience(row) {
      this.$router.push(`/experiencias/laboral/${row.id}`)
    },
    openEducationalExperience(row) {
      this.$router.push(`/experiencias/educativa/${row.id}`)
    },
    openOtherExperience(row) {
      this.$router.push(`/experiencias/otra/${row.id}`)
    },
  },
}
