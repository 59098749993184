//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import EXP_TYPES from '@/constants/experience-types'
import Button from '@/components/Shared/Button.vue'
import FormExperienceLaboral from '~/components/Experiences/FormExperienceLaboral'
import FormExperienceEducation from '~/components/Experiences/FormExperienceEducation'
import ModalWithTitleBar from '~/components/Shared/ModalWithTitleBar'
import {
  showSuccessNotification,
  showErrorNotification,
} from '~/util/notifications'

export default {
  components: {
    Button,
    FormExperienceLaboral,
    FormExperienceEducation,
    ModalWithTitleBar,
  },
  props: {
    modal: {
      type: Object,
      default: () => {},
    },
    defaultExperience: {
      type: Object,
      default: () => {},
    },
    isMyExperience: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isValid: false,
      EXP_TYPES,
    }
  },
  methods: {
    handleChangeValue(experience) {
      this.form = experience
    },
    handleChangeForm(valid) {
      this.isValid = valid
    },
    toggleModal() {
      this.$emit('toggle')
    },
    prepareExperience() {
      if (this.modal.type === EXP_TYPES.EDUCATION_TYPE) {
        return this.defaultExperience
      } else if (this.modal.type === EXP_TYPES.WORK_TYPE) {
        return {
          ...this.defaultExperience,
          business_area: this.defaultExperience.business_area.id,
        }
      }
    },
    async saveChanges() {
      this.$store.commit('loading', true)
      const method = this.experienceType()
      if (this.isMyExperience && method) {
        try {
          const experienceForSave = this.prepareExperience()
          await this.$repository[method].update(
            experienceForSave.id,
            experienceForSave
          )
          showSuccessNotification(
            this,
            'Experiencia laboral editada con exito.'
          )
          this.$router.push('/experiencias')
        } catch (e) {
          showErrorNotification(this, this.$t('misc.errors.genericError'))
        } finally {
          this.$store.commit('loading', false)
        }
      } else {
        showErrorNotification(this, this.$t('misc.errors.genericError'))
      }
      this.$store.commit('loading', false)
    },
    experienceType() {
      switch (this.modal.type) {
        case EXP_TYPES.WORK_TYPE:
          return 'experienceWork'
        case EXP_TYPES.EDUCATION_TYPE:
          return 'experienceEducational'
        default:
          return undefined
      }
    },
  },
}
