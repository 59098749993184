//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ValidationObserver } from 'vee-validate'
import { rutFilter } from 'vue-dni'
import FormInput from '../Form/FormInput.vue'
import FormCheckBox from '../Form/FormCheckBox.vue'
import Alert from '../Shared/Alert.vue'

export default {
  name: 'MeetingRequestForm',
  components: {
    ValidationObserver,
    FormInput,
    FormCheckBox,
    Alert,
  },
  props: {
    conditionsButton: {
      type: Function,
      default: () => {},
    },
    toogleConfirmation: {
      type: Function,
      default: () => {},
    },
    toogleLoading: {
      type: Function,
      default: () => {},
    },
    countries: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    form: {
      country: '',
      company_rut: '',
      names: '',
      lastnames: '',
      position: '',
      business: '',
      email: '',
      phone: '',
      terms: undefined,
      suscription: false,
    },

    platformCountry: 'Chile',
    loading: false,
  }),
  computed: {
    isSelectedCountry() {
      return !!this.form.country
    },
    isLocalCompany() {
      if (!this.form.country) {
        return false
      }
      return this.form.country.name === this.platformCountry
    },
  },
  watch: {
    form: {
      handler(val) {
        this.form.terms = !this.form.terms ? undefined : true

        if (val.company_rut) {
          this.form.company_rut = rutFilter(val.company_rut)
        }
      },
      deep: true,
    },
  },
  methods: {
    async submit() {
      try {
        this.toogleLoading(true)
        if (!this.isLocalCompany) {
          await this.$strapi.$http.$post('/information-requests/', this.form)
        } else {
          await this.$strapi.$http.$post('/meeting-requests/', this.form)
        }
        this.toogleLoading(false)
        this.toogleConfirmation(this.isLocalCompany)
      } catch (e) {
        this.toogleLoading(false)
        this.$buefy.notification.open({
          message: e.message,
          type: 'is-danger',
          duration: 5000,
        })
      }
    },
  },
}
