//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import HomePageCardJobSeekerFair from '~/components/HomePage/HomePageCardJobSeekerFair.vue'
export default {
  components: {
    HomePageCardJobSeekerFair,
  },
  props: {
    jobSeekerFairs: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      test: 0,
      widthScreen: window.innerWidth,
    }
  },
  computed: {
    itemsToShow() {
      if (this.widthScreen > 1400) {
        return 3
      }
      if (this.widthScreen > 768) {
        return 2
      }
      return 1
    },
  },
  methods: {
    info(value) {
      this.test = value
    },
  },
}
