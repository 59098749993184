//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ValidationObserver } from 'vee-validate'
import { FormInput } from '@/components/Form'
import ROLES from '@/constants/roles'
import Button from '~/components/Shared/Button'

export default {
  name: 'InvitationModal',
  components: {
    FormInput,
    ValidationObserver,
    Button,
  },
  props: {
    onSuccess: {
      default: () => {},
      type: Function,
    },
    roleType: {
      type: String,
      default: ROLES.EMPLOYEE,
    },
    value: {
      type: Boolean,
      default: true,
    },
    projectId: {
      type: Number,
      required: true,
    },
    sendInvitation: {
      type: Boolean,
      default: false,
    },
  },
  async fetch() {
    this.$store.commit('loading', true)
    const [{ roles }] = await Promise.all([
      this.$strapi.$http.$get('/users-permissions/roles'),
    ])
    this.role = roles.find((role) => role.type === this.roleType).id
    this.roles = roles
    this.$store.commit('loading', false)
  },
  data() {
    return {
      ROLES,
      roles: [],
      selectedCompany: null,
      radioRole: ROLES.UNEMPLOYED,
      options: {
        languages: [],
        locations: [],
        situationLaboral: [
          {
            value: 'Empleado',
            label: 'Empleado',
          },
        ],
      },
      validated: false,
      form: {
        situationLaboral: '',
        occupation: '',
        user: {
          names: '',
          lastnames: '',
          email: '',
          repeatEmail: '',
        },
        message: null,
      },
      active: this.value,
    }
  },
  computed: {
    formSubmit() {
      const form = {
        ...this.form.user,
      }
      delete form.repeatEmail
      if (form.email) {
        return form
      }
      delete form.email
      return form
    },
  },

  watch: {
    value() {
      this.active = this.value
    },
    form: {
      handler(val) {
        this.validateAndEmitInvalid()
      },
      deep: true,
    },
  },
  methods: {
    close() {
      this.active = !this.active
      this.form = {
        situationLaboral: '',
        occupation: '',
        user: {
          names: '',
          lastnames: '',
          email: '',
          repeatEmail: '',
        },
        message: null,
      }
      this.validated = false
      this.$emit('close')
      this.$emit('input', this.active)
    },
    importMassiveUsers() {
      this.$emit('import-massive-users')
    },
    submit() {
      if (this.form.user.email === '' && this.form.user.repeatEmail === '') {
        this.$emit('user-without-email-confirmation')
      } else {
        this.saveUser()
      }
    },
    async saveUser() {
      this.$store.commit('loading', true)
      try {
        const user = this.formSubmit
        user.project_id = this.projectId

        await this.$repository.invitation.newSingleCreateUsers({
          user,
          sendInvitation: this.sendInvitation,
        })
        this.onSuccess()
        this.$emit('user-created')

        this.close()
      } catch (e) {
        this.$emit('user-with-error', e.message)
      } finally {
        this.$store.commit('loading', false)
      }
    },
    async validateAndEmitInvalid() {
      if (
        (this.form.user.names !== '' && this.form.user.lastnames !== '') ||
        this.validated
      ) {
        await this.$refs.observer.validate()
        this.validated = true
        this.$emit('validation-invalid', this.$refs.observer.flags.invalid)
      }
    },
  },
}
