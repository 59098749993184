import { render, staticRenderFns } from "./RegisterForm.vue?vue&type=template&id=77de2382&scoped=true&"
import script from "./RegisterForm.vue?vue&type=script&lang=js&"
export * from "./RegisterForm.vue?vue&type=script&lang=js&"
import style0 from "./RegisterForm.vue?vue&type=style&index=0&id=77de2382&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77de2382",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FormInput: require('/workspace/components/Form/FormInput.vue').default,FormSelect: require('/workspace/components/Form/FormSelect.vue').default,FormInputDate: require('/workspace/components/Form/FormInputDate.vue').default,FormSelect: require('/workspace/components/Form/FormSelect.vue').default,FormCheckBox: require('/workspace/components/Form/FormCheckBox.vue').default})
