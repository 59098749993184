const Request = require('@/repositories/request')
const qs = require('qs')

class JobAnnouncement extends Request {
  /**
   *
   * @param {int} id
   * @returns {*}
   */
  findOne(id) {
    return this.$strapi['$job-announcements'].findOne(id)
  }

  /**
   *
   * @param {Object} params
   * @return {*}
   */
  findAll(params) {
    const stringifyParams = qs.stringify({ ...params }, { encode: false })
    return this.$strapi['$job-announcements'].find(stringifyParams)
  }

  /**
   *
   * @param {Object} params
   * @return {*}
   */
  count(params) {
    const stringifyParams = qs.stringify({ ...params }, { encode: false })
    return this.$strapi['$job-announcements'].count(stringifyParams)
  }

  /**
   * @param {int} id
   * @param {Object} data
   * @returns {*}
   */
  update(id, data) {
    return this.$strapi['$job-announcements'].update(id, data)
  }

  /**
   * @param {int} id
   * @returns {*}
   */
  delete(id) {
    return this.$strapi['$job-announcements'].delete(id)
  }

  /**
   *
   * @param {Object} params
   * @return {*}
   */
  create(params) {
    return this.$strapi['$job-announcements'].create(params)
  }

  /**
   *
   * @param {int} id
   * @return {Promise<unknown>}
   */
  // TODO this method is non used
  findPotentialWorker(id) {
    return this.$strapi.$http.$get(`/job-announcements/${id}/potential-people`)
  }
}

module.exports = JobAnnouncement
