//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Button from '~/components/Shared/Button.vue'
export default {
  components: { Button },
  methods: {
    togglePilotModalReq() {
      this.$emit('toggle-pilot-model')
    },
  },
}
