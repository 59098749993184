//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Button from '../../Shared/Button.vue'
import ModalWithTitleBar from '~/components/Shared/ModalWithTitleBar.vue'
import SearchOccupations from '~/components/CustomOccupation/SearchOccupations.vue'

export default {
  name: 'AddOccupationModal',
  components: { Button, ModalWithTitleBar, SearchOccupations },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    suggestedOccupations: {
      type: Array,
      default: () => [],
    },
    baseOccupation: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    filteredOccupations: [],
    existOccupations: [],
    selected: null,
    currentOcupationId: null,
    isBaseOccupation: false,
  }),
  methods: {
    async handleTypingSearchOccupation(value) {
      this.handleClean()
      const occupations = await this.$repository.occupation.searchForCustomOccupations(
        {
          term: value,
          custom_occupations: true,
        }
      )
      this.filteredOccupations = occupations.filter(
        (occupation) =>
          ![
            this.baseOccupation.id,
            ...this.suggestedOccupations.map((occupation) => occupation.id),
          ].includes(occupation.id)
      )
      this.existOccupations = occupations.filter((occupation) =>
        [...this.suggestedOccupations]
          .map((occupation) => occupation.id)
          .includes(occupation.id)
      )
      this.isBaseOccupation = occupations
        .map((occupation) => occupation.id)
        .includes(this.baseOccupation.id)
    },
    handleSelectSearchOccupation(selected) {
      if (selected) {
        this.selected = {
          id: selected.id,
          title: selected.title,
        }
      }
    },
    handleClean() {
      this.selected = null
      this.existOccupations = []
      this.isBaseOccupation = false
      this.filteredOccupations = []
    },
    onCancel() {
      this.$emit('cancel')
      this.handleClean()
    },
    onSubmit() {
      this.$emit('submit', this.selected)
      this.handleClean()
    },
  },
}
