//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ValidationObserver } from 'vee-validate'
import { FormInput } from '@/components/Form'
import { rutFilter } from 'vue-dni'
import ModalWithTitleBar from '~/components/Shared/ModalWithTitleBar'
import Button from '~/components/Shared/Button'

export default {
  name: 'ModalEditUser',
  components: {
    Button,
    ModalWithTitleBar,
    ValidationObserver,
    FormInput,
  },
  props: {
    value: {
      type: Boolean,
      default: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      active: this.value,
      form: {
        situationLaboral: '',
        occupation: '',
        user: {
          id: 0,
          names: '',
          lastnames: '',
          email: '',
          repeatEmail: '',
        },
        message: null,
      },
    }
  },
  watch: {
    value() {
      this.active = this.value
    },
    user() {
      this.form.user.id = this.user.id
      this.form.user.names = this.user.names
      this.form.user.lastnames = this.user.lastnames
      this.form.user.email = this.user.email
      this.form.user.repeatEmail = this.user.email
      this.form.user.identification_number = this.user.identification_number
    },
    form: {
      handler(val) {
        this.$nextTick(() => {
          this.form.user.identification_number = rutFilter(
            this.form.user.identification_number
          )
        })
      },
      immediate: false,
      deep: true,
    },
  },
  methods: {
    checkValid(invalid) {
      if (!this.checkIfUserWasModified()) return true
      return invalid
    },
    checkIfUserWasModified() {
      const user = this.form.user
      return (
        user.names !== this.user.names ||
        user.lastnames !== this.user.lastnames ||
        user.email !== this.user.email
      )
    },
    close() {
      this.active = !this.active
      this.cleanForm()
      this.$emit('close')
      this.$emit('input', this.active)
    },
    editUser() {
      this.$emit('edit-user', {
        ...this.form.user,
        username: this.form.user.email,
      })
    },
    cleanForm() {
      this.form.user.id = this.user.id
      this.form.user.names = this.user.names
      this.form.user.lastnames = this.user.lastnames
      this.form.user.email = this.user.email
      this.form.user.repeatEmail = this.user.email
      this.form.user.identification_number = this.user.identification_number
    },
  },
}
