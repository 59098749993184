//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FormInput from '@/components/Form/FormInput'
import FormInputNumber from '@/components/Form/FormInputNumber'
import { ACTION_ENTITY } from '~/constants/core'

export default {
  name: 'JobSeekerRequiredForm',
  components: {
    FormInput,
    FormInputNumber,
  },
  props: {
    occupations: {
      type: Array,
      default: () => [],
    },
    action: {
      type: String,
      default: ACTION_ENTITY.CREATE,
    },
    defaultForm: {
      type: Object,
      default: () => {
        return {
          vacancies: 0,
          occupation: null,
          project_name: '',
          requirements: '',
          active: false,
        }
      },
    },
  },
  data() {
    return {
      form: {
        vacancies: 0,
        occupation: null,
        project_name: '',
        active: false,
      },
      options: {
        status: [
          { label: 'Publicada', value: true },
          { label: 'No Publicada', value: false },
        ],
      },
    }
  },
  computed: {
    canEditStatus() {
      return this.$props.action === ACTION_ENTITY.UPDATE
    },
    formActive() {
      return this.options.status.find(({ value }) => value === this.form.active)
    },
    configEditor() {
      return {
        removePlugins: ['Heading'],
        toolbar: [
          'bold',
          'italic',
          'bulletedList',
          'numberedList',
          'blockQuote',
        ],
      }
    },
  },
  created() {
    if (this.$props.defaultForm) this.form = this.$props.defaultForm
  },
  methods: {
    inputStatus(status) {
      this.form.active = status?.value
    },
  },
}
