const Request = require('@/repositories/request')

class ContentManager extends Request {
  uuidGenerate(queryObject) {
    return this.$strapi.$http.$post(
      `/content-manager/uid/generate`,
      queryObject
    )
  }
}

module.exports = ContentManager
