import { render, staticRenderFns } from "./OpportunitiesList.vue?vue&type=template&id=544a065a&scoped=true&"
import script from "./OpportunitiesList.vue?vue&type=script&lang=js&"
export * from "./OpportunitiesList.vue?vue&type=script&lang=js&"
import style0 from "./OpportunitiesList.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./OpportunitiesList.vue?vue&type=style&index=1&id=544a065a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "544a065a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PotentialWorkerTag: require('/workspace/components/PotentialWorker/PotentialWorkerTag.vue').default})
