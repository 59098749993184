//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    elements: {
      type: Array,
      default: () => {},
    },
    activeClass: {
      type: String,
      default: '',
    },
    handleClick: {
      type: Function,
      default: () => {},
    },
  },
}
