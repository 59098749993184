//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Button from '@/components/Shared/Button.vue'
import SearchEngine from '@/components/Shared/SearchEngine/SearchEngine.vue'
import ModalWithTitleBar from '~/components/Shared/ModalWithTitleBar'
import Chip from '~/components/design-system/Chip.vue'

export default {
  components: {
    ModalWithTitleBar,
    Button,
    Chip,
    SearchEngine,
  },
  props: {
    modal: {
      type: Boolean,
      default: () => false,
    },
    activeSkillsIds: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    filteredSkills: [],
    existSkills: [],
    skillsToAdd: [],
    doneTypingInterval: 1500,
    typingTimer: 0,
    searchLoading: false,
    pattern: '',
  }),
  computed: {
    disabledAddButton() {
      return this.skillsToAdd.filter((skill) => skill.active).length === 0
    },
    groupLabels() {
      return {
        'match-pattern': this.$t(
          'components.searchEngine.groups.skills.byTitle',
          [this.pattern]
        ),
        'not-match-pattern': this.$t(
          'components.searchEngine.groups.skills.byLabels'
        ),
      }
    },
    excludedToSearchSkillIds() {
      return this.skillsToAdd.map(({ id }) => id)
    },
  },
  methods: {
    toggleModal() {
      this.skillsToAdd = []
      this.existSkills = []
      this.$emit('toggle')
    },
    addSkills() {
      const skillsToAdd = this.skillsToAdd.filter((skill) => skill.active)
      this.$emit('add-skills', skillsToAdd)
      this.toggleModal()
    },
    handleChangeSkilToAddState({ id, status }) {
      this.skillsToAdd = this.skillsToAdd.map((skill) => {
        if (skill.id === id) {
          skill.active = status
        }
        return skill
      })
    },
    search(pattern) {
      return this.$repository.skill.searchSkillsByTerm(
        pattern,
        this.excludedToSearchSkillIds
      )
    },
    handleInput(inputSkill) {
      this.pattern = inputSkill
    },
    handleSelectSearchEngine(selected) {
      if (!selected) {
        return
      }
      if (this.skillsToAdd.map(({ id }) => id).includes(selected.id)) {
        return
      }
      const selectedSkill = {
        id: selected.id,
        title: selected.title,
        description: selected.description,
        active: true,
      }
      this.skillsToAdd.push(selectedSkill)
    },
  },
}
