//
//
//
//
//
//

export default {
  props: {
    type: {
      type: String,
      default: 'is-white',
    },
    customClass: {
      type: String,
      default: 'is-light',
    },
  },
}
