//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ModalWithTitleBar from '@/components/Shared/ModalWithTitleBar'
import Button from '@/components/Shared/Button.vue'

export default {
  components: { ModalWithTitleBar, Button },
  props: {
    title: {
      type: String,
      default: '',
    },
    visible: {
      type: Boolean,
      default: false,
    },
    close: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    handleAccept() {
      this.$emit('accept')
    },
  },
}
