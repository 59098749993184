const Request = require('@/repositories/request')
const qs = require('qs')
const { encodeQueryParams } = require('@/util/query')

class JobSeekerFair extends Request {
  /**
   *
   * @param {int} id
   * @returns {*}
   */
  findOne(id) {
    return this.$strapi['$job-seeker-fairs'].findOne(id)
  }

  /**
   *
   * @param {Object} params
   * @return {*}
   */
  findAll(params) {
    const stringifyParams = qs.stringify({ ...params }, { encode: false })
    return this.$strapi['$job-seeker-fairs'].find(stringifyParams)
  }

  getOpportunities(id, params) {
    const query = encodeQueryParams(params)
    return this.$strapi.$http.$get(
      `/job-seeker-fairs/${id}/opportunities?${query}`
    )
  }

  async getReportExcel(id) {
    const file = await this.$strapi.$http.get(
      `/job-seeker-fairs/${id}/report-excel`
    )
    return file.blob()
  }

  getPotentialUsersByOccupation(id, occupationId) {
    const query = encodeQueryParams({ occupationId })
    return this.$strapi.$http.$get(
      `/job-seeker-fairs/${id}/potential-users?${query}`
    )
  }

  /**
   *
   * @param {Object} params
   * @return {*}
   */
  count(params) {
    const stringifyParams = qs.stringify({ ...params }, { encode: false })
    return this.$strapi['$job-seeker-fairs'].count(stringifyParams)
  }

  /**
   * @param {int} id
   * @param {Object} data
   * @returns {*}
   */
  update(id, data) {
    return this.$strapi['$job-seeker-fairs'].update(id, data)
  }

  /**
   *
   * @param {Object} params
   * @return {*}
   */
  create(params) {
    return this.$strapi['$job-seeker-fairs'].create(params)
  }
}

module.exports = JobSeekerFair
