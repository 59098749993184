//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'TitleBar',
  props: {
    titleStack: {
      type: Array,
      default: () => [],
    },
  },
}
