//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    descriptionTooltip: {
      type: String,
      default: undefined,
    },
    handleSelect: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    handleClick() {
      this.$emit('click')
    },
  },
}
