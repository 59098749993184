//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ValidationObserver } from 'vee-validate'
import { rutFilter } from 'vue-dni'
import GENRES from '@/constants/user-genres'
import {
  FormInput,
  FormSelect,
  FormCheckBox,
  FormInputDate,
} from '@/components/Form'
import EDUCATION_LEVELS from '@/constants/education-levels'

export default {
  name: 'RegisterForm',
  components: {
    FormInput,
    FormSelect,
    FormCheckBox,
    FormInputDate,
    ValidationObserver,
  },
  props: {
    regions: {
      type: Array,
      default: () => [],
    },
    communes: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    currentGender: '',
    currentEducationLevel: '',
    form: {
      names: '',
      lastnames: '',
      email: '',
      identification_number: '',
      birthdate: '',
      region: '',
      comune: '',
      password: '',
      confirmation: '',
      terms: undefined,
      subscribed: false,
      confirmed: false,
      provide_data: undefined,
    },
    loading: false,
    genders: [
      {
        id: GENRES.MALE,
        name: 'Masculino',
      },
      {
        id: GENRES.FEMALE,
        name: 'Femenino',
      },
      {
        id: GENRES.OTHERS,
        name: 'Otros',
      },
      {
        id: GENRES.NOT_SPECIFIED,
        name: 'Prefiero no decirlo',
      },
    ],
    educationLevels: [
      {
        id: EDUCATION_LEVELS.BASIC,
        name: 'Educación básica',
      },
      {
        id: EDUCATION_LEVELS.MEDIUM,
        name: 'Educación media (Técnica Profesional y Científico-Humanista)',
      },
      {
        id: EDUCATION_LEVELS.HIGHER,
        name:
          'Educación superior (Centro de Formación Técnica, Institutos Profesionales, Universidades)',
      },
      {
        id: EDUCATION_LEVELS.POSTGRADUATE,
        name: 'Postgrado (Diplomados, Maestrías o Doctorados)',
      },
    ],
  }),
  watch: {
    form: {
      handler(val) {
        this.form.identification_number = rutFilter(
          this.form.identification_number
        )
        this.form.terms = !this.form.terms ? undefined : true
        this.form.provide_data = !this.form.provide_data ? undefined : true
      },
      deep: true,
    },
    currentGender(newValue) {
      this.form.gender = newValue?.id
    },
    currentEducationLevel(newValue) {
      this.form.education_level = newValue?.id
    },
  },
  methods: {
    showTerms() {
      this.$emit('show-terms')
    },
    showPolicyData() {
      this.$emit('show-policy-data')
    },
    onInputRegion(select) {
      this.form.comune = ''
      this.$emit('selectRegion', select)
    },
    submit() {
      this.$emit('submit', this.form)
    },
  },
}
