//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CheckBold from 'vue-material-design-icons/CheckBold'
import Button from '@/components/Shared/Button'

export default {
  components: {
    CheckBold,
    Button,
  },
  props: {
    info: {
      type: Array,
      default: () => {},
    },
  },
}
