import ROLES from '@/constants/roles'
import PERMISSIONS from '@/constants/permissions'
import COUNTRY from '@/constants/country'

export const state = () => ({
  user: {
    id: 0,
    username: '',
    email: '',
    provider: 'local',
    confirmationToken: null,
    confirmed: null,
    blocked: null,
    role: {
      id: 0,
      name: '',
      description: '',
      type: '',
      created_by: null,
      updated_by: null,
    },
    created_by: null,
    updated_by: null,
    created_at: null,
    updated_at: null,
    names: '',
    lastnames: '',
    phone: null,
    birthdate: null,
    address: null,
    language: null,
    residence_country: null,
    origin_country: null,
    company: null,
    identification_number: null,
    subscribed: null,
    policy_data: true,
    provide_data: null,
    visibleCompany: null,
    roleDescription: null,
    skill_morphs: [],
    job_seeker_fair: {
      id: 0,
      name: '',
      description: '',
      start: '',
      end: '',
      created_by: null,
      updated_by: null,
      created_at: '',
      updated_at: '',
      applicant_job_seeker: null,
      active: null,
      requirements: null,
    },
    applicant_job_seeker: null,
    tests: [],
    addresses: [],
  },
  token: {},
})
export const mutations = {
  SET_USER(state, user) {
    state.user = user
  },
  SET_TOKEN(state, token) {
    state.token = token
  },
  SET_TESTS(state, tests) {
    state.user.tests = tests
  },
}
export const getters = {
  isLogged(state) {
    return state.user?.id !== 0
  },
  roleType(state) {
    return state.user?.role?.type
  },
  companyPermissions(state) {
    const permissions = []
    if (state.user?.company?.company_permission?.skills_inventory) {
      permissions.push(PERMISSIONS.SKILLS_INVENTORY)
    }
    if (state.user?.company?.company_permission?.required_occupations) {
      permissions.push(PERMISSIONS.REQUIRED_OCCUPATIONS)
    }
    return permissions
  },
  requiredOccupationsPermission(state) {
    return state.user?.company?.company_permission?.required_occupations
  },
  skillsInventoryPermission(state) {
    return state.user?.company?.company_permission?.skills_inventory
  },
  fullNames(state) {
    return `${state?.user.names} ${state?.user?.lastnames}`
  },
  isRelink(state) {
    return state.user.role?.type === ROLES.RELINK
  },
  isAdminJobSeekerFair(state) {
    return state.user.role?.type === ROLES.ADMIN_UNEMPLOYED
  },
  isCompany(state) {
    return state.user.role?.type === ROLES.COMPANY
  },
  isEmployee(state) {
    return state.user.role?.type === ROLES.EMPLOYEE
  },
  isFreelanceUser(state) {
    return [ROLES.UNEMPLOYED, ROLES.PUBLIC].includes(state.user?.role?.type)
  },
  isUnemployed(state) {
    return state.user.role?.type === ROLES.UNEMPLOYED
  },
  isPublic(state) {
    return state.user.role?.type === ROLES.PUBLIC
  },
  hasCompaniesUser(state) {
    return [ROLES.COMPANY, ROLES.EMPLOYEE].includes(state.user?.role?.type)
  },
  hasJobSeekerFair(state) {
    return [ROLES.UNEMPLOYED, ROLES.ADMIN_UNEMPLOYED].includes(
      state.user?.role?.type
    )
  },
  jobSeekerFair(state) {
    return state?.user?.job_seeker_fair
  },
  hasAddresses(state) {
    return state.user.addresses.length > 0
  },
  showMenuTitle(state, getters) {
    if (getters.hasCompaniesUser || getters.isRelink)
      return state.user?.company?.name
    if (getters.isFreelanceUser) return ''
    if (getters.hasJobSeekerFair) return state.user?.job_seeker_fair?.name
    return null
  },
  hasBasicInfoTest(state) {
    return state.user.names && state.user.lastnames && state.user.birthdate
  },
  isDefaultUser(state, getters) {
    return getters.isEmployee || getters.isFreelanceUser
  },
  isProvideData(state) {
    return state.user.provide_data
  },
  accountEmail(state) {
    return state.user.email
  },
  hasConfirmed(state) {
    return state.user?.confirmed ?? false
  },
  isCompanyNull(state) {
    return state.user.company === null
  },
  isChilean(state) {
    return state.user.residence_country?.name === COUNTRY.CHILE
  },
  isRelinkOrCompany(_, getters) {
    return getters.isCompany || getters.isAdminJobSeekerFair || getters.isRelink
  },
  getLabelProfile(_, getters) {
    if (getters.isRelinkOrCompany) return 'company'
    return getters.isChilean ? 'chilean' : 'foreign'
  },
}
export const actions = {
  login(context, payload) {
    return new Promise((resolve, reject) => {
      this.$repository.security
        .login(payload)
        .then((response) => {
          if (payload.roles.includes(response.user.role.type)) {
            context.commit('SET_TOKEN', response.jwt)
            context.commit('SET_USER', response.user)
            resolve(response)
          }
          const error = { type: 'ROLE INCORRECT' }
          reject(error)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  getUserInfo(context) {
    return new Promise((resolve) => {
      const user = this.$repository.me.getMe()
      context.commit('SET_USER', user)
      resolve(user)
    })
  },
  async fetchUser(context) {
    const user = await this.$repository.me.fetchUser()
    return new Promise((resolve) => {
      context.commit('SET_USER', user)
      resolve(user)
    })
  },
  setTests(context, tests) {
    context.commit('SET_TESTS', tests)
  },
}
