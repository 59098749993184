//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Button from '~/components/Shared/Button'
import ModalWithTitleBar from '~/components/Shared/ModalWithTitleBar'

export default {
  name: 'MassiveImportUserModal',
  components: { Button, ModalWithTitleBar },
  props: {
    value: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      active: this.value,
      file: null,
      message: null,
    }
  },
  computed: {
    hasFile() {
      return !!this.file
    },
  },
  watch: {
    value() {
      this.active = this.value
    },
  },
  methods: {
    close() {
      this.active = !this.active
      this.file = null
      this.$emit('close')
      this.$emit('input', this.active)
    },

    async massValidateUsers({ file, message }) {
      try {
        this.$store.commit('loading', true)
        const formData = new FormData()
        formData.append('massive', file)
        formData.append('message', message)
        const response = await this.$repository.invitation.massValidateUsers(
          formData
        )
        this.file = null
        this.$emit('massive-users-details', response)
      } catch (e) {
        this.$buefy.notification.open({
          message: e.message,
          type: 'is-danger',
          duration: 5000,
        })
      } finally {
        this.$store.commit('loading', false)
      }
    },
  },
}
