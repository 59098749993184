var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pilot-unenmployed"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"pilot-unemployed__carousel"},[_c('b-carousel-list',{attrs:{"data":_vm.jobSeekerFairs,"arrow-hover":false,"items-to-show":_vm.itemsToShow},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var name = ref.name;
var card_image = ref.card_image;
var locations = ref.locations;
var end = ref.end;
var list = ref.list;
var slug = ref.slug;
return [_c('HomePageCardJobSeekerFair',{attrs:{"title":name,"photo":card_image,"locations":locations,"date-end":end,"is-open":list.active,"slug":slug}})]}}]),model:{value:(_vm.test),callback:function ($$v) {_vm.test=$$v},expression:"test"}})],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pilot-unemployed__content"},[_c('div',{staticClass:"content-title"},[_c('h2',[_vm._v("Pilotos de Reconversión Laboral")])]),_vm._v(" "),_c('div',{staticClass:"content-line"}),_vm._v(" "),_c('div',{staticClass:"content-description"},[_vm._v("\n      Nuestros pilotos de Reconversión Laboral, tienen por objetivo atender\n      las necesidades de capacitación de personas desempleadas, con la\n      finalidad de aumentar la posibilidad de reinsertarse laboralmente y de\n      aumentar y mejorar sus competencias laborales, a través de la entrega de\n      cursos de capacitación.\n    ")])])}]

export { render, staticRenderFns }