//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TableEmptyData from '@/components/Shared/Table/TableEmptyData'
import Tag from '@/components/Shared/Tag'

export default {
  name: 'ListInvitationJobSeekerFair',
  components: { TableEmptyData, Tag },
  filters: {
    styleForActiveRow(isActive) {
      if (isActive) {
        return 'is-info is-light'
      }
      return 'is-success is-light'
    },
    textForActiveRow(isActive) {
      if (isActive) {
        return 'Pendiente'
      }
      return 'Aceptada'
    },
    fullNames(user) {
      return `${user?.names} ${user?.lastnames}`
    },
    jobSeekerFairName(user) {
      return user?.job_seeker_fair?.name
    },
  },
  props: {
    invitations: {
      type: Array,
      default: () => [],
    },
    total: {
      type: Number,
      default: 0,
    },
    perPage: {
      default: 10,
      type: Number,
    },
  },
  methods: {
    onPageChange(event) {
      this.$emit('page-change', event)
    },
    getUserData(user) {
      if (!user)
        return {
          names: '',
          lastnames: '',
          email: '',
          job_seeker_fair: { name: '' },
        }
      return { ...user }
    },
    resendInvitation({ id, user }) {
      this.$emit('resend-invitation', { id, email: user?.email })
    },
  },
}
