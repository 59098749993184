//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ListChip from './ListChip.vue'
import SearchSkills from '~/components/Experiences/SearchSkills.vue'
const TYPES = {
  WORK: 'laboral',
  EDUCATION: 'educational',
}

export default {
  components: {
    SearchSkills,
    ListChip,
  },
  props: {
    default: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: () => '',
    },
  },
  async fetch() {
    this.$store.commit('loading', true)
    if (this.default.length > 0) {
      this.freeSkillsHeaderSubtitle = this.$t(
        'freeSkills.header.subtitle_middle_education'
      )
      this.suggestSkills = this.default
    } else {
      this.freeSkillsHeaderSubtitle = this.$t(
        'freeSkills.header.subtitle_default'
      )
      if (this.type === TYPES.WORK) {
        await this.loadWorkSkills()
      }
      if (this.type === TYPES.EDUCATION) {
        await this.loadEducationalSkills()
      }
    }
    this.$store.commit('loading', false)
    this.loading = false
  },
  data: () => ({
    data: [],
    filteredData: [],
    suggestSkills: [],
    selectedSkills: [],
    freeSkillsHeaderSubtitle: '',
    doneTypingInterval: 1500,
    typingTimer: 0,
    searchLoading: false,
  }),
  methods: {
    hasSuggestSkills() {
      return this.suggestSkills.length > 0
    },
    timeoutTyping(value) {
      clearTimeout(this.typingTimer)
      this.searchLoading = true
      this.typingTimer = setTimeout(() => {
        this.handleTyping(value)
      }, this.doneTypingInterval)
    },
    async handleTyping(value) {
      const suggests = await this.$repository.skill.suggest(value)
      this.filteredData = suggests.filter(
        (skill) => !this.selectedSkills.includes(skill.id)
      )
      this.searchLoading = false
    },
    handleSelect(selected) {
      if (selected) {
        this.suggestSkills.push({ ...selected, active: true })
      }
    },
    handleClean() {
      this.filteredData = []
    },
    handleSelectedChips(selectedSkills) {
      this.selectedSkills = selectedSkills
      this.$emit('selected', selectedSkills)
    },
    async loadWorkSkills() {
      const experience = this.$store.state.workingExperience.workingExperience

      const {
        skillsMoreRelevants: skills = [],
      } = await this.$repository.occupation.skillsMoreRelevantsBeta(
        experience.occupation.id,
        experience.id,
        5
      )

      this.suggestSkills = skills
    },
    async loadEducationalSkills() {
      const experienceId = this.$store.state.experience.educationalExperienceId
      const experience = await this.$repository.experienceEducational.findOne(
        experienceId
      )
      const {
        skills_more_relevants: skills = [],
      } = await this.$repository.educationalArea.skillMoreRelevantsBeta(
        experience.area_educational,
        experience.id,
        5
      )
      this.suggestSkills = skills
    },
  },
}
