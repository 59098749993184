//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    buttonTextDesktop: {
      type: String,
      default: '',
    },
    buttonTextMobile: {
      type: String,
      default: '',
    },
    isPerson: {
      type: Boolean,
      default: false,
    },
    autosaved: {
      type: Boolean,
      default: false,
    },
    saving: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    CompanyName() {
      return this.$store.getters['me/showMenuTitle']
    },
  },
  methods: {
    handleClick() {
      this.$emit('click')
    },
  },
  head() {
    return { title: this.title }
  },
}
