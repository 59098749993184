//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ExperienceIncomplete from '@/assets/experiences/experience-incomplete.svg'

export default {
  computed: {
    experienceRegisterImage() {
      return ExperienceIncomplete
    },
  },
}
