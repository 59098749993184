//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    isRowDetailEnabled(row) {
      return row.description
    },
    toggleDetails(row) {
      if (this.isRowDetailEnabled(row)) {
        this.$refs.skillsTable.toggleDetails(row)
      }
    },
    isRowExpanded(row) {
      return this.$refs.skillsTable.isVisibleDetailRow(row)
    },
    getSkillTypeLabel(types) {
      if (types.length > 1) {
        return types.map((type) => `${this.$t(type)}`).join(', ')
      }
      return this.$t(types[0])
    },
  },
}
