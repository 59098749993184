//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AddFreeSkillsWithRecommendations from './AddFreeSkillsWithRecommendations.vue'
import Button from '~/components/Shared/Button.vue'
import SuggestedSkills from '~/components/Experiences/SuggestSkills/SuggestedSkills.vue'
import FreeEngineSkills from '~/components/Experiences/FreeEngineSkills/FreeEngineSkills.vue'
import {
  showSuccessNotification,
  showErrorNotification,
} from '~/util/notifications'

export default {
  components: {
    Button,
    SuggestedSkills,
    FreeEngineSkills,
    AddFreeSkillsWithRecommendations,
  },
  props: {
    experience: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    freeSkills: [],
    suggestSkills: [],
    skillsSelected: [],
    userSkills: [],
    isVisibleModalExit: false,
    isDefaultSetSKills: false,
  }),
  computed: {
    finalSkillSuggest() {
      const suggestSkillsIds = this.suggestSkills.map((skill) => skill.id)
      return this.skillsSelected.filter((skillId) =>
        suggestSkillsIds.includes(skillId)
      )
    },
    finalSkillsFree() {
      const suggestSkillsIds = this.suggestSkills.map((skill) => skill.id)
      return this.skillsSelected.filter(
        (skillId) => !suggestSkillsIds.includes(skillId)
      )
    },
    isValid() {
      return this.skillsSelected.length > 0
    },
  },
  watch: {
    async experience(value) {
      if (value.id) {
        this.isDefaultSetSKills = this.getIsDefaultSetSkills(value)
        this.suggestSkills =
          (await this.getSuggestSkills(value, this.userSkills)) || []
        this.skillsSelected = []
        this.freeSkills = []
      }
    },
  },
  async mounted() {
    this.userSkills = await this.getUserSkills()
  },
  methods: {
    async getSuggestSkills(experience, userSkills) {
      // this method has to be implemented by a component that extends StepTwoBase.vue
    },
    getIsDefaultSetSkills(experience) {
      // this method has to be implemented by a component that extends StepTwoBase.vue
    },
    async getUserSkills() {
      const userId = this.$strapi.user.id
      const skillMorphs = await this.$repository.skillMorph.findAll({
        _where: { user: userId, has_skill: true },
        _limit: -1,
      })
      return [...new Set(skillMorphs.map(({ skill }) => skill))]
    },
    handleChangeFreeChip({ id, status }) {
      this.handleChangeChip({ id, status })
      if (status) {
        const isSkillSuggest = this.suggestSkills.find(
          (suggestSkill) => suggestSkill.id === id
        )
        if (isSkillSuggest) {
          showSuccessNotification(
            this,
            'Ingresaste una habilidad sugerida por Relink.'
          )
        }
        this.freeSkills.push(id)
      } else {
        this.freeSkills = this.freeSkills.filter((skillId) => skillId !== id)
      }
    },
    handleChangeChip({ id, status }) {
      if (status) {
        this.skillsSelected.push(id)
      } else {
        this.skillsSelected = this.skillsSelected.filter(
          (skillId) => skillId !== id
        )
      }
    },
    handleChangeSkillsSelected(selectedSkillIds) {
      this.skillsSelected = [...selectedSkillIds]
    },
    prepareSkillsMorphs() {
      // this method has to be implemented by a component that extends StepTwoBase.vue
    },
    updatedTotalHasSkills() {
      // this method has to be implemented by a component that extends StepTwoBase.vue
    },
    saveSkills() {
      // this method has to be implemented by a component that extends StepTwoBase.vue
    },
    handleBack() {
      this.$emit('back', this.experience)
    },
    async handleNext() {
      try {
        this.$store.commit('loading', true)
        await this.saveSkills()
        await this.updatedTotalHasSkills()
        this.$emit('next', {
          ...this.experience,
          total_has_skills: this.skillsSelected.length,
        })
      } catch (error) {
        showErrorNotification(this)
      } finally {
        this.$store.commit('loading', false)
      }
    },
  },
}
