//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import EmoticonSadOutline from 'vue-material-design-icons/EmoticonSadOutline'
import ProgressIndicator from '@/components/Shared/ProgressIndicator'
export default {
  components: {
    EmoticonSadOutline,
    ProgressIndicator,
  },
  props: {
    actualOpportunities: {
      type: Array,
      default: () => [],
    },
    opportunities: {
      type: Array,
      default: () => [],
    },
    opportunityDetail: {
      type: Function,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: true,
    },
    perPage: {
      type: Number,
      default: 10,
    },
  },
  methods: {
    goToExperiences() {
      this.$router.push('/experiencias')
    },
  },
}
