//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Button from '@/components/Shared/Button'
import ROLES from '@/constants/roles'
const SINGLE_INVITATION = 'single'

export default {
  components: {
    Button,
  },
  props: {
    invitationType: {
      required: true,
      type: String,
    },
    openLink: {
      default: () => {},
      type: Function,
    },
    roleType: {
      type: String,
      default: ROLES.EMPLOYEE,
    },
  },
  computed: {
    content() {
      const modalContent = {}
      switch (this.roleType) {
        case ROLES.EMPLOYEE: {
          modalContent.linkText = this.$t(
            'invitations.successModal.worker.linkText'
          )
          if (this.invitationType === SINGLE_INVITATION) {
            modalContent.text = this.$t(
              'invitations.successModal.worker.single.text'
            )
            modalContent.title = this.$t(
              'invitations.successModal.worker.single.title'
            )
          } else {
            modalContent.text = this.$t(
              'invitations.successModal.worker.massive.text'
            )
            modalContent.title = this.$t(
              'invitations.successModal.worker.massive.title'
            )
          }
          break
        }
        case ROLES.UNEMPLOYED: {
          modalContent.linkText = this.$t(
            'invitations.successModal.candidate.linkText'
          )
          if (this.invitationType === SINGLE_INVITATION) {
            modalContent.text = this.$t(
              'invitations.successModal.candidate.single.text'
            )
            modalContent.title = this.$t(
              'invitations.successModal.candidate.single.title'
            )
          } else {
            modalContent.text = this.$t(
              'invitations.successModal.candidate.massive.text'
            )
            modalContent.title = this.$t(
              'invitations.successModal.candidate.massive.title'
            )
          }
          break
        }
      }
      return modalContent
    },
    urlRedirect() {
      if (this.roleType === ROLES.EMPLOYEE) {
        return '/invitaciones/empleados'
      }
      return '/invitaciones/cesantes'
    },
  },
}
