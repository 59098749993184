//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'FormLabel',
  props: {
    errors: {
      default: () => [],
      type: Array,
    },
    valid: {
      default: false,
      type: Boolean,
    },
    required: {
      default: false,
      type: Boolean,
    },
    label: {
      default: '',
      type: String,
    },
  },
}
