//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ListSkills',
  props: {
    skills: {
      type: Array,
      default: () => {},
    },
  },
}
