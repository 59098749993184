//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      currentStep: 0,
      experience: {},
    }
  },
  methods: {
    onPreviousStep(experience) {
      if (this.currentStep === 0) {
        this.$router.back()
        return
      }
      this.experience = experience || {}
      this.currentStep = this.currentStep - 1
    },

    onNextStep(experience) {
      this.experience = experience
      this.currentStep = this.currentStep + 1
    },

    getStep() {
      return this.currentStep
    },
  },
}
