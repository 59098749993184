//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DashboardSingleInfoCard from './DashboardSingleInfoCard.vue'
import DashboardTopWithTotal from './DashboardTopWithTotal.vue'
import DashboardPieCard from './DashboardPieCard.vue'
import DashboardBarCard from './DashboardBarCard.vue'

export default {
  components: {
    DashboardSingleInfoCard,
    DashboardTopWithTotal,
    DashboardPieCard,
    DashboardBarCard,
  },
  props: {
    singleInfoCards: {
      type: Array,
      default: () => [
        {
          title: 'Solicitud de reunión',
          icon: '/img/icons/calendar.svg',
          url: '/solicitudes/reuniones',
          description: '(Rango de 30 días)',
          value: '...',
        },
        {
          title: 'Personas invitadas',
          icon: '/img/icons/invitation.svg',
          description: '(Rango de 30 días)',
          value: '...',
        },
        {
          title: 'Empresas creadas',
          icon: '/img/icons/company.svg',
          url: '/administradores-empresa',
          description: '(Rango de 30 días)',
          value: '...',
        },
      ],
    },
    pilotData: {
      type: Array,
      default: () => [
        { company: 'Company name', total_employees: 0, total_occupations: 0 },
      ],
    },
    pilotTotal: {
      type: Number,
      default: 0,
    },
    topOccupations: {
      type: Object,
      default: () => {
        return { loaded: false }
      },
    },
    usersByRole: {
      type: Object,
      default: () => {},
    },
    totalUsers: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    changePagePilotCompanies(event) {
      this.$emit('changePagePilotCompanies', event)
    },
    changePageInvitations(event) {
      this.$emit('changePageInvitations', event)
    },
  },
}
