//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    id: {
      type: Number,
      default: null,
    },
    isActive: {
      type: Boolean,
      default: () => false,
    },
    isPointerActive: {
      type: Boolean,
      default: () => true,
    },
    description: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showTooltip: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      active: false,
    }
  },
  created() {
    this.active = this.isActive
  },
  methods: {
    removeChip() {
      this.$emit('remove', this.id)
    },
  },
}
