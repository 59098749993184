//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import UpdatePasswordModal from '~/components/Shared/UpdatePasswordModal.vue'
import QuestionaryCard from '~/components/Questionaries/dashboard/QuestionaryCard.vue'
import { SECTIONS } from '~/constants/questionary'
import { showErrorNotification } from '~/util/notifications'
import { PERMISSION_LABELS } from '~/constants/permission-labels'

export default {
  components: {
    UpdatePasswordModal,
    QuestionaryCard,
  },
  props: {
    user: {
      type: Object,
      default: () => ({}),
    },
  },
  async fetch() {
    this.$store.commit('loading', true)
    try {
      const [
        { enabled: isEnabledInventorySkills },
        { enabled: isEnabledMatchPotential },
        { enabled: isEnabledKitOfSkills },
      ] = await this.$permissions.allow([
        PERMISSION_LABELS.INVENTORY_SKILLS.BASE,
        PERMISSION_LABELS.MATCH_POTENTIAL.BASE,
        PERMISSION_LABELS.PROJECTS.KIT_OF_SKILLS,
      ])
      this.isEnabledInventorySkills = isEnabledInventorySkills
      this.isEnabledMatchPotential = isEnabledMatchPotential
      this.isEnabledKitOfSkills = isEnabledKitOfSkills
      this.activeQuestionary = await this.getActiveQuestionary()
      this.modal.updatePassword = this.$strapi.user.show_change_password_modal

      if (!this.modal.updatePassword) {
        return
      }
      const [invitation] = await this.$repository.invitation.findAll({
        _where: {
          user: this.user.id,
        },
      })
      if (!invitation) {
        return
      }
      this.$repository.invitation.update(invitation.id, { active: false })
    } catch (error) {
      showErrorNotification(this, 'Ocurrió un error')
    } finally {
      this.$store.commit('loading', false)
    }
  },
  data() {
    return {
      modal: {
        updatePassword: false,
        privacyPolicy: false,
      },
      activeQuestionary: null,
      hasActiveQuestionary: false,
      hasModelQuestionary: false,
      modelQuestionary: null,
      isEnabledInventorySkills: false,
    }
  },
  computed: {
    userId() {
      return this.$strapi.user.id
    },
    projectId() {
      if (!this.$strapi.user.project) {
        return null
      }
      return this.$strapi.user.project.id || this.$strapi.user.project
    },
    questionaries() {
      const questionaries = []
      const showBasicCards =
        (!this.hasModelQuestionary && !this.hasModelQuestionary) ||
        (!this.hasModelQuestionary && !!this.activeQuestionary?.is_finish)
      if (showBasicCards && this.isEnabledInventorySkills) {
        questionaries.unshift({
          key: 'goToInventory',
          status: null,
          title: this.$t('dashboard.employee.cardInventory.title'),
          description: this.$t('dashboard.employee.cardInventory.description'),
          actionLabel: this.$t('dashboard.employee.cardInventory.buttonAction'),
          imageSrc: '/img/questionary-project/inventory.svg',
          match: null,
          onClick: () => this.$router.push(`/mi-inventario-habilidades`),
        })
      }
      if (showBasicCards) {
        questionaries.unshift({
          key: 'addSkills',
          status: null,
          title: this.$t('dashboard.employee.cardAddSkills.title'),
          description: this.$t('dashboard.employee.cardAddSkills.description'),
          actionLabel: this.$t('dashboard.employee.cardAddSkills.buttonAction'),
          imageSrc: '/img/questionary-project/experience.svg',
          match: null,
          onClick: () => this.$router.push(`/experiencias`),
        })
      }
      if (this.hasModelQuestionary && this.isEnabledKitOfSkills) {
        questionaries.unshift({
          key: 'modelQuestionary',
          status: this.statusQuestionary(this.modelQuestionary),
          title: this.$t('dashboard.employee.modelQuestionary.title'),
          description: this.$t(
            'dashboard.employee.modelQuestionary.description'
          ),
          actionLabel: this.actionLabel(
            this.modelQuestionary,
            this.hasModelQuestionary
          ),
          imageSrc: '/img/questionary-project/work-experience.svg',
          match: null,
          onClick: async () => await this.handleClickModelQuestionary(),
        })
      }
      if (this.hasActiveQuestionary && this.isEnabledMatchPotential) {
        questionaries.unshift({
          key: 'activeQuestionary',
          status: this.statusQuestionary(this.activeQuestionary),
          title: this.activeQuestionary?.targetOccupation?.title,
          description: this.activeQuestionary?.is_finish
            ? this.$t('dashboard.employee.questionary.description.isFinish')
            : this.$t('dashboard.employee.questionary.description.isNotFinish'),
          actionLabel: this.actionLabel(this.activeQuestionary),
          imageSrc: '/img/questionary-project/occupations.svg',
          match: this.activeQuestionary?.match,
          onClick: async () => await this.handleClickActionQuestionary(),
        })
      }
      return questionaries
    },
  },
  methods: {
    togglePrivacyPolicy() {
      this.modal.privacyPolicy = false
    },
    statusQuestionary(questionary) {
      if (!questionary?.id) {
        return 'in-pending'
      }
      if (questionary?.is_finish) {
        return 'in-complete'
      }
      if (questionary?.id) {
        return 'in-progress'
      }
      return ''
    },
    actionLabel(questionary, hasModelQuestionary) {
      if (!questionary?.id) {
        return this.$t('dashboard.employee.questionary.action.isPending')
      }
      if (questionary?.is_finish && hasModelQuestionary) return
      return questionary?.is_finish
        ? this.$t('dashboard.employee.questionary.action.isFinish')
        : this.$t('dashboard.employee.questionary.action.isNotFinish')
    },
    async getActiveQuestionary() {
      if (!this.projectId) {
        return null
      }
      try {
        const project = await this.$repository.project.getProjectAndQuestionaries()
        if (!project) {
          return null
        }

        const questionary = project.questionaries
        this.modelQuestionary = project.model_questionaries
        this.hasActiveQuestionary = !!project.main_target_occupation_id
        const modelQuestionaryFinish = !!this.modelQuestionary?.is_finish
        this.hasModelQuestionary = project.has_models && !modelQuestionaryFinish

        let targetOccupation = null
        if (project.main_target_occupation_id) {
          targetOccupation = await this.$repository.occupation.findOne(
            project.main_target_occupation_id
          )
        }
        delete project.occupations
        if (!questionary) {
          return {
            project,
            targetOccupation,
          }
        }
        if (questionary?.is_finish || false) {
          questionary.match = await this.getMatch(targetOccupation.id)
        }
        return {
          project,
          targetOccupation,
          ...questionary,
        }
      } catch (error) {
        showErrorNotification(
          this,
          'Ocurrió un error al obtener el cuestionario'
        )
      }
    },
    async handleClickModelQuestionary() {
      let questionary = null
      if (!this.modelQuestionary?.id) {
        questionary = await this.createModelQuestionary()
        if (!questionary.code) {
          showErrorNotification(
            this,
            'Hubo un problema al crear el cuestionario'
          )
        }
      } else {
        questionary = this.modelQuestionary
      }
      this.$router.push(`/mis-cuestionarios/nuevo?code=${questionary.code}`)
    },
    async handleClickActionQuestionary() {
      if (!this.activeQuestionary.id) {
        const questionary = await this.createQuestionary()
        if (questionary?.code || false) {
          this.$router.push(
            `/cuestionarios/instrucciones?code=${questionary.code}`
          )
          return
        }
      }

      if (!this.activeQuestionary?.is_finish) {
        const routes = {
          [SECTIONS.INSTRUCTIONS]: `/cuestionarios/instrucciones?code=${this.activeQuestionary.code}`,
          [SECTIONS.ORIGIN_SELECT]: `/cuestionarios/origen?code=${this.activeQuestionary.code}`,
          [SECTIONS.ORIGIN]: `/cuestionarios/habilidades?code=${this.activeQuestionary.code}`,
          [SECTIONS.TARGET]: `/cuestionarios/habilidades?code=${this.activeQuestionary.code}`,
        }
        this.$router.push(routes[this.activeQuestionary.section])
        return
      }
      this.$router.push(
        `/oportunidades/${this.activeQuestionary.targetOccupation.id}`
      )
    },
    async createQuestionary() {
      try {
        this.$store.commit('loading', true)
        const questionary = await this.$repository.questionary.save({
          user: this.userId,
          project: this.projectId,
          occupation: this.activeQuestionary.targetOccupation.id,
          section: SECTIONS.INSTRUCTIONS,
          is_finish: false,
        })
        return questionary
      } catch (error) {
        showErrorNotification(this, 'Ocurrió un error')
      } finally {
        this.$store.commit('loading', false)
      }
    },
    async createModelQuestionary() {
      try {
        this.$store.commit('loading', true)
        const questionary = await this.$repository.modelQuestionary.save({
          user: this.userId,
          project: this.projectId,
          section: SECTIONS.INSTRUCTIONS,
          page: 1,
          total_pages: 1,
          is_finish: false,
        })
        return questionary
      } catch (error) {
        showErrorNotification(this, 'Ocurrió un error')
      } finally {
        this.$store.commit('loading', false)
      }
    },
    async getMatch(occupationId) {
      try {
        const { match } = await this.$repository.user.getMatchWithOccupation({
          occupationId,
        })
        return match
      } catch (error) {
        showErrorNotification(this, 'Ocurrió un error al obtener el match')
        return null
      }
    },
  },
}
