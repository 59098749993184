//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Button from '@/components/Shared/Button'

export default {
  components: {
    Button,
  },
  props: {
    imgSource: {
      type: String,
      default: '',
    },
    subHeader: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    titleHighlight: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    buttonText: {
      type: String,
      default: '',
    },
    active: {
      type: Boolean,
      default: true,
    },
    route: {
      type: String,
      default: '',
    },
    tooltip: {
      type: String,
      default: null,
    },
  },
  methods: {
    onClick($event) {
      if (this.route) {
        this.$router.push(this.route)
      } else {
        this.$emit('click', $event)
      }
    },
  },
}
