//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import InvitationSelection from '~/components/Invitation/InvitationSelection'
import MassiveForm from '~/components/Invitation/MassiveForm'
import ModalWithTitleBar from '~/components/Shared/ModalWithTitleBar'
import InvitationSuccess from '~/components/Invitation/InvitationSuccess'
import IndividualForm from '~/components/Invitation/IndividualForm'
import ROLES from '~/constants/roles'

const SINGLE_INVITATION = 'single'
const MASSIVE_INVITATION = 'massive'
const MASSIVE_RESPONSE_TYPE = {
  FEEDBACK: 'FEEDBACK',
  FORMAT: 'FORMAT',
}

export default {
  components: {
    ModalWithTitleBar,
    InvitationSelection,
    MassiveForm,
    InvitationSuccess,
    IndividualForm,
  },
  props: {
    value: {
      type: Boolean,
      default: true,
    },
  },
  fetch() {
    this.invitatedRoleType =
      this.$repository.me.getMe().role.type === ROLES.COMPANY
        ? ROLES.EMPLOYEE
        : ROLES.UNEMPLOYED
  },
  data() {
    return {
      actualProps: {},
      actualComponent: null,
      actualTitle: '',
      active: this.value,
      invitatedRoleType: ROLES.EMPLOYEE,
    }
  },
  watch: {
    value() {
      this.active = this.value
    },
    active() {
      if (!this.active) {
        this.resetModal()
      }
    },
  },
  mounted() {
    this.resetModal()
  },
  methods: {
    resetModal() {
      this.actualProps = this.getInvitationSelectionProps()
      this.actualComponent = InvitationSelection
      this.actualTitle = 'Enviar nueva invitación'
    },
    close() {
      this.active = !this.active
      this.$emit('close')
      this.$emit('input', this.active)
    },
    getIndividualFormProps() {
      return {
        onSuccess: () => {
          this.actualProps = this.getInvitationSuccessProps(SINGLE_INVITATION)
          this.actualComponent = InvitationSuccess
          this.$emit('success')
        },
      }
    },
    getInvitationSelectionProps() {
      return {
        massiveButtonText: 'Invitación Masiva',
        individualButtonText: 'Invitación Individual',
        topButtonsText: 'Elige el tipo de invitación que deseas enviar:',
        massiveClick: () => {
          this.actualProps = this.getMassiveFormProps()
          this.actualComponent = MassiveForm
          this.actualTitle = 'Enviar invitación masiva'
        },
        individualClick: () => {
          this.actualProps = this.getIndividualFormProps()
          this.actualComponent = IndividualForm
          this.actualTitle = 'Enviar invitación individual'
        },
      }
    },
    getMassiveFormProps() {
      return {
        errors: this.errors,
        handleMassive: this.handleMassive,
      }
    },
    getInvitationSuccessProps(invitationType) {
      return {
        openLink: () => {
          this.close()
        },
        invitationType,
      }
    },
    handleMassive({ file, message }) {
      this.$store.commit('loading', true)
      const formData = new FormData()
      formData.append('massive', file)
      formData.append('message', message)
      this.$strapi.$http
        .$post('/invitation/massive', formData)
        .then(() => {
          this.actualProps = this.getInvitationSuccessProps(MASSIVE_INVITATION)
          this.actualComponent = InvitationSuccess
          this.actualTitle = 'Invitación masiva enviada exitosamente.'
          this.$emit('success')
        })
        .catch((error) => {
          if (error.response.data.type === MASSIVE_RESPONSE_TYPE.FEEDBACK) {
            this.errors = error.response.data.errors
          }
          if (error.response.data.type === MASSIVE_RESPONSE_TYPE.FORMAT) {
            this.errors = [{ row: 0, message: error.response.data.error }]
          }
          this.actualProps = this.getMassiveFormProps()
          this.actualComponent = MassiveForm
          this.actualTitle = 'Enviar invitación masiva'
        })
        .finally(() => {
          this.$store.commit('loading', false)
        })
    },
  },
}
