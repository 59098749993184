//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    id: {
      type: Number,
      default: null,
    },
  },
  methods: {
    removeChip() {
      this.$emit('remove', this.id)
    },
  },
}
