//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const LAST_STEP = 2
export default {
  props: {
    step: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      currentStep: this.$store.getters['experience/getCurrentStep'],
      currentExperience: {
        id: null,
        detail_experience: '',
        start_date_experience: '',
        end_date_experience: '',
        present: false,
        completed: false,
        experienceSkills: [],
      },
    }
  },
  watch: {
    currentStep(value) {
      if (value === LAST_STEP) {
        this.$store.dispatch('experience/clearOtherExperience')
      }
    },
  },

  mounted() {
    if (this.step > 0) {
      this.currentStep = this.step
    }
    this.currentExperience = {
      ...this.$store.getters['experience/getOtherExperience'],
    }
  },
  methods: {
    onPreviousStep(experience) {
      if (this.currentStep === 0) {
        this.$router.back()
        return
      }
      if (experience) {
        this.currentExperience = experience
      }
      this.currentStep = this.currentStep - 1
      this.$store.dispatch('experience/setCurrentStep', this.currentStep)
    },

    onNextStep() {
      this.currentExperience = {
        ...this.$store.getters['experience/getOtherExperience'],
      }
      this.currentStep = this.currentStep + 1
      this.$store.dispatch('experience/setCurrentStep', this.currentStep)
    },
  },
}
