//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    text: {
      type: String,
      default: '',
    },
    src: {
      type: String,
      default: '',
    },
    hover: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isHover: false,
    }
  },
  computed: {
    imgSource() {
      return this.isHover ? this.hover : this.src
    },
  },
  methods: {
    onClick($event) {
      this.$emit('click', $event)
    },
  },
}
