//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Button from '~/components/Shared/Button'
export default {
  name: 'KanbanAlertMessage',
  components: { Button },
  methods: {
    async goToMyWorkers() {
      await this.$router.push('/mis-trabajadores')
    },
  },
}
