//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Field',
  components: {},
  props: {
    index: {
      default: '',
      type: String,
    },
    item: {
      default: () => {},
      type: Object,
    },
  },
}
