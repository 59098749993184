//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TableEmptyData from '@/components/Shared/Table/TableEmptyData'
import ChevronRight from 'vue-material-design-icons/ChevronRight'
import Tag from '@/components/Shared/Tag'

export default {
  name: 'ListCustomOccupation',
  components: { TableEmptyData, ChevronRight, Tag },
  filters: {
    styleForActiveRow(isActive) {
      if (isActive) {
        return 'is-success'
      }
      return ''
    },
    textForActiveRow(isActive) {
      if (isActive) {
        return 'Publicada'
      }
      return 'Sin Publicar'
    },
  },
  props: {
    onPageChange: {
      default: () => {},
      type: Function,
    },
    data: {
      default: () => [],
      type: Array,
    },
    total: {
      default: 0,
      type: Number,
    },
    perPage: {
      default: 10,
      type: Number,
    },
  },
  data() {
    return {
      isMobile: false,
    }
  },
  computed: {
    isSortable() {
      return !this.isMobile
    },
  },
  mounted() {
    this.checkScreenSize()
    window.addEventListener('resize', this.checkScreenSize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.checkScreenSize)
  },
  methods: {
    openCustomOccupationDetail(occupation) {
      this.$router.push(`/ocupaciones-personalizadas/${occupation.id}`)
    },
    checkScreenSize() {
      this.isMobile = window.innerWidth <= 769
    },
  },
}
