//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SkillConstants from '@/constants/skill'
import Button from '@/components/Shared/Button.vue'
import FilterFormGroup from '@/components/SkillsInventory/Filters/FilterFormGroup'
import FilteredTag from '@/components/SkillsInventory/FilteredTag.vue'

export default {
  name: 'SkillsInventoryFilters',
  components: {
    Button,
    FilterFormGroup,
    FilteredTag,
  },
  props: {
    positions: {
      type: Array,
      default: () => [],
    },
  },
  fetch() {
    this.skillTypes = SkillConstants.skillTypes
    this.skillReuseLevels = SkillConstants.skillReuseLevels
  },
  data() {
    return {
      criteria: '',
      skillTypes: [],
      skillReuseLevels: [],
      selectedSkillTypes: [],
      selectedSkillReuseLevels: [],
      selectedPositions: [],
      filteredSkillTypes: [],
      filteredSkillReuseLevels: [],
      filteredPositions: [],
      doneTypingInterval: 500,
      typingTimer: 0,
      showFilters: false,
    }
  },
  computed: {
    disabledSearchButton() {
      return (
        this.selectedSkillTypes.length > 0 ||
        this.selectedSkillReuseLevels.length > 0 ||
        this.selectedPositions.length > 0
      )
    },
    showFiltersTag() {
      return (
        this.filteredSkillTypes.length > 0 ||
        this.filteredSkillReuseLevels.length > 0 ||
        this.filteredPositions.length > 0
      )
    },
  },
  methods: {
    filterByName() {
      clearTimeout(this.typingTimer)
      this.typingTimer = setTimeout(
        this.findByCriteria,
        this.doneTypingInterval
      )
    },
    findByCriteria() {
      if (this.showFilters) {
        this.onShowFilters()
      }
      this.$emit('search-skill', this.criteria)
    },
    onSearch() {
      this.onShowFilters()
      this.filteredSkillTypes = this.selectedSkillTypes
      this.filteredSkillReuseLevels = this.selectedSkillReuseLevels
      this.filteredPositions = this.selectedPositions
      this.$emit('search-filters', {
        selectedSkillTypes: this.selectedSkillTypes,
        selectedSkillReuseLevels: this.selectedSkillReuseLevels,
        selectedPositions: this.selectedPositions,
      })
    },
    onCloseSkillTypesTag() {
      this.filteredSkillTypes = []
      this.selectedSkillTypes = []
      this.$emit('close-skill-types', this.selectedSkillTypes)
    },
    onCloseSkillReuseLevelsTag() {
      this.filteredSkillReuseLevels = []
      this.selectedSkillReuseLevels = []
      this.$emit('close-reuse-levels', this.selectedSkillReuseLevels)
    },
    onClosePositionsTag() {
      this.filteredPositions = []
      this.selectedPositions = []
      this.$emit('close-positions', this.selectedPositions)
    },
    onShowFilters() {
      this.showFilters = !this.showFilters
    },
  },
}
