//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AnnouncementImg from '~/assets/svg/announcement.svg'
import ModalWithTitleBar from '~/components/Shared/ModalWithTitleBar.vue'
export default {
  name: 'ModalConfirmRegistration',
  components: { AnnouncementImg, ModalWithTitleBar },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toggleModal() {
      this.$emit('toggle')
    },
  },
}
