//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { TYPES } from '@/constants/project-types'
import { PERMISSION_LABELS } from '@/constants/permission-labels'
import Button from '~/components/Shared/Button'

export default {
  name: 'ProjectServicesDetail',
  components: {
    Button,
  },
  filters: {
    truncate(text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix
      } else {
        return text
      }
    },
  },
  props: {
    project: {
      type: Object,
      default: () => ({}),
    },
  },
  async fetch() {
    const [
      { enabled: isEnabledPotentialMatch },
      { enabled: isEnabledInventorySkills },
    ] = await this.$permissions.allow([
      PERMISSION_LABELS.MATCH_POTENTIAL.BASE,
      PERMISSION_LABELS.INVENTORY_SKILLS.BASE,
    ])
    this.isEnabledPotentialMatch = isEnabledPotentialMatch
    this.isEnabledInventorySkills = isEnabledInventorySkills
    this.showResultProject = !!(
      isEnabledInventorySkills || isEnabledPotentialMatch
    )
  },
  data: () => ({
    isEnabledPotentialMatch: false,
    isEnabledInventorySkills: false,
    showResultProject: false,
  }),
  computed: {
    mainTargetOccupation() {
      return this.project.occupations.find(
        (occupation) => occupation.id === this.project.main_target_occupation_id
      )
    },
    targetOccupations() {
      return this.project.occupations.filter(
        (occupation) => occupation.id !== this.project.main_target_occupation_id
      )
    },
    mainTargetOccupationTitle() {
      return this.mainTargetOccupation?.title ?? ''
    },
    isModelProject() {
      return this.project.type === TYPES.MODELS
    },
  },
  methods: {
    goToOccupation(id) {
      this.$router.push(`/mis-proyectos/${this.project.id}/ocupaciones/${id}`)
    },
    goToInventorySkills() {
      this.$router.push(
        `/mis-proyectos/inventario-habilidades?code=${this.project.code}`
      )
    },
    goToModelResults() {
      this.$router.push(`/mis-proyectos/${this.project.id}/resultados-modelo`)
    },
  },
}
