//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ValidationObserver } from 'vee-validate'
import FormGroup from '@/components/Form/FormGroup.vue'
import ProjectFormSelect from '@/components/Form/ProjectFormSelect.vue'
import { mapFields } from '@/util/helpers'
import { getObjectives, getModalities, getFieldByKey } from '@/util/project'
import { mapGetters } from 'vuex'
import NewProjectsWizardButtons from '@/components/Projects/NewProjectWizardButtons.vue'
import ObjectiveSection from '@/components/Projects/NewProjectSteps/StepConfiguration/ObjectiveSection.vue'

export default {
  name: 'StepOne',
  components: {
    ValidationObserver,
    ProjectFormSelect,
    FormGroup,
    NewProjectsWizardButtons,
    ObjectiveSection,
  },
  async fetch() {
    this.objectives = getObjectives(this.$t('projects.new.steps.first'))
    this.locationsList = await this.$repository.location.find({
      _limit: -1,
      _where: {
        type: 'region',
        code_contains: 'CHL',
      },
    })
  },
  data() {
    return {
      objectives: [],
      locationsList: [],
    }
  },
  computed: {
    ...mapFields({
      fields: ['name', 'description', 'locations', 'modality'],
      module: 'project',
      base: 'baseProject',
      mutation: 'SET_PROJECT_ATTRIBUTE',
    }),
    ...mapGetters({
      getBaseProject: 'project/getBaseProject',
    }),
    modalities() {
      return getModalities(this.$t('projects.new.steps.first'))
    },
    getPlaceholder() {
      if (this.getBaseProject.objective) {
        return getFieldByKey(
          this.getBaseProject.objective?.value,
          this.objectives
        ).placeholder
      }
      return ''
    },
  },
  methods: {
    handleBack() {
      this.$emit('back')
    },
    handleContinue() {
      this.$emit('next')
    },
  },
}
