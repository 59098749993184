var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"suggest"},[_c('div',{staticClass:"suggest__search"},[_c('Multiselect',{staticClass:"is-primary",attrs:{"multiple":true,"options":_vm.employeesToSelect,"close-on-select":false,"clear-on-select":true,"preserve-search":true,"placeholder":_vm.$t('projects.modalAddUsers.tabs.search.multiselect.placeholder'),"show-labels":false,"label":"name","track-by":"id","show-pointer":false,"tag-color":"red"},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var option = ref.option;
return [_c('div',{staticClass:"multiselect-option user-option"},[_c('div',{class:("option__text " + (option.project ? '' : 'option__text--complete'))},[_vm._v("\n            "+_vm._s(option.name)+"\n          ")]),_vm._v(" "),(option.project)?_c('div',{staticClass:"option__info"},[_vm._v("\n            "+_vm._s(option.project === _vm.getProjectId
                ? _vm.$t(
                    'projects.modalAddUsers.tabs.search.multiselect.option.tagInProject'
                  )
                : _vm.$t(
                    'projects.modalAddUsers.tabs.search.multiselect.option.tagInOtherProject'
                  ))+"\n          ")]):_vm._e()])]}},{key:"noResult",fn:function(){return [_vm._v("\n        "+_vm._s(_vm.$t('projects.modalAddUsers.tabs.search.multiselect.noOptions'))+"\n      ")]},proxy:true}]),model:{value:(_vm.selectedEmployees),callback:function ($$v) {_vm.selectedEmployees=$$v},expression:"selectedEmployees"}})],1),_vm._v(" "),_c('div',{staticClass:"suggest__users"},[_c('div',{staticClass:"users__instruction"},[_c('h3',[_vm._v("\n        "+_vm._s(_vm.$t('projects.modalAddUsers.tabs.search.instructions.title'))+"\n      ")]),_vm._v(" "),_c('p',[_vm._v("\n        "+_vm._s(_vm.$t('projects.modalAddUsers.tabs.search.instructions.description'))+"\n      ")])]),_vm._v(" "),_c('SuggestedEmployees',{attrs:{"suggested-employees":_vm.suggestedEmployees},on:{"select-all":_vm.handleAllSelectUser,"select-user":_vm.handleSelectUser}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }