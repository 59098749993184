//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
    itemChecked: {
      type: Array,
      default: () => [],
    },
    fieldCheck: {
      type: String,
      default: () => {
        return 'name'
      },
    },
    valueCheck: {
      type: String,
      default: () => {
        return 'active'
      },
    },
    tooltipKey: {
      type: String,
      default: () => {
        return 'description'
      },
    },
    showTooltip: {
      type: Boolean,
      default: () => {
        return false
      },
    },
  },
  data() {
    return {
      checkBoxValue: null,
    }
  },
  computed: {
    getCheckedItemLabel() {
      const dynamicLabel =
        this.item[this.fieldCheck].length > 10
          ? this.item[this.fieldCheck].split(' ').join('-')
          : this.item[this.fieldCheck]
      return `custom-checkbox-${dynamicLabel}`
    },
    computedCheckBoxName() {
      return this.item[this.fieldCheck]
    },
    computedCheckBoxChecked: {
      get() {
        if (!this.itemChecked.includes(this.item[this.fieldCheck])) {
          return false
        }
        return this.item[this.valueCheck]
      },
      set(value) {
        this.checkBoxValue = value
      },
    },
  },
  methods: {
    checkOption() {
      this.$emit('checked', {
        value: this.computedCheckBoxName,
        checked: !this.computedCheckBoxChecked,
      })
    },
  },
}
