//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import lastEvaluateMatchMixin from '@/mixins/lastEvalutateMatchMixin'
import Button from '@/components/Shared/Button.vue'
import AddOccupationModal from './AddOccupationModal.vue'
import RemoveOccupationModal from './RemoveOccupationModal.vue'
import CustomOccupationSuggestedSkills from './CustomOccupationSuggestedSkills.vue'
import FreeSkills from '~/components/Experiences/FreeSkills/FreeSkills.vue'
import { getUniqueSkillsSortedByFrequency } from '~/util/entity/skills/getUniqueSkillsSortedByFrequency'
import { showSuccessNotification } from '~/util/notifications'
import HISTORIC_CUSTOMIZATION_CONSTANTS from '~/constants/historic-customizations'

const { ACTION_TYPES, ENTITY_TYPES } = HISTORIC_CUSTOMIZATION_CONSTANTS

export default {
  name: 'CustomOccupationAddSkills',
  components: {
    AddOccupationModal,
    RemoveOccupationModal,
    CustomOccupationSuggestedSkills,
    FreeSkills,
    Button,
  },
  mixins: [lastEvaluateMatchMixin],
  props: {
    occupation: {
      type: Object,
      default: () => {},
    },
    suggestedOccupationsBase: {
      type: Array,
      default: () => {},
    },
  },
  data() {
    return {
      suggestedOccupations: [],
      selectedSkills: [],
      freeSkills: [],
      historicLogs: [],

      showAddModal: false,
      showRemoveModal: false,
      removeId: null,
    }
  },
  computed: {
    isPublished() {
      return this.occupation.active
    },
    occupationSkillIds() {
      return this.occupation.skills
        .map((skillGroup) => skillGroup.skills)
        .flat()
        .map((skill) => skill.id)
    },
    suggestedSkills() {
      const skills = this.suggestedOccupations
        .map(
          (occupation) =>
            occupation.skills.find((skill) => skill.skillType === 'Obligatorio')
              .skills
        )
        .flat()
        .filter(
          (skill) =>
            !this.occupationSkillIds.some((skillId) => skillId === skill.id)
        )
      return getUniqueSkillsSortedByFrequency(skills)
    },
  },
  watch: {
    suggestedOccupationsBase() {
      this.suggestedOccupations = [...this.suggestedOccupationsBase]
    },
  },
  methods: {
    goBack() {
      this.$router.push(
        `/ocupaciones-personalizadas/${this.occupation.id}?tab=skills`
      )
    },
    toggleAddModal() {
      this.showAddModal = !this.showAddModal
    },
    toggleRemoveModal(id) {
      this.removeId = id
      this.showRemoveModal = !this.showRemoveModal
    },
    handleRemoveChip(id) {
      this.suggestedOccupations = this.suggestedOccupations.filter(
        (occupation) => occupation.id !== id
      )
      this.selectedSkills = this.selectedSkills.filter((skillId) =>
        this.suggestedSkills.find((suggestSkill) => suggestSkill.id === skillId)
      )
      this.selectedSkills.forEach((selectedSkill) => {
        if (this.freeSkills.find((freeSkill) => freeSkill === selectedSkill)) {
          this.freeSkills = this.freeSkills.filter(
            (freeSkill) => freeSkill !== selectedSkill
          )
        }
      })
      this.selectedSkills = [...this.selectedSkills, ...this.freeSkills]
      this.showRemoveModal = !this.showRemoveModal
    },
    handleChangeSkillStatus({ id, status }) {
      if (status) {
        this.selectedSkills.push(id)
      } else {
        this.selectedSkills = this.selectedSkills.filter(
          (skillId) => skillId !== id
        )
      }
    },
    handleChangeFreeChip({ id, status }) {
      this.handleChangeSkillStatus({ id, status })
      if (status) {
        const isSkillSuggest = this.suggestedSkills.find(
          (suggestSkill) => suggestSkill.id === id
        )
        if (isSkillSuggest) {
          showSuccessNotification(
            this,
            'Ingresaste una habilidad sugerida por Relink.'
          )
        }
        this.freeSkills.push(id)
      } else {
        this.freeSkills = this.freeSkills.filter((skillId) => skillId !== id)
      }
    },
    async onSubmit({ id }) {
      const occupation = await this.$repository.occupation.findOne(id, [
        'skills.skills',
      ])
      this.suggestedOccupations.push(occupation)
      this.toggleAddModal()
    },
    async addSelectedSkills() {
      this.$store.commit('loading', true)

      const addLogs = this.selectedSkills.map((selectedSkill) => {
        return {
          primary_entity: ENTITY_TYPES.OCCUPATION,
          primary_entity_id: this.occupation.id,
          secondary_entity: ENTITY_TYPES.SKILL,
          secondary_entity_id: selectedSkill,
          action: ACTION_TYPES.ADD,
          status: false,
        }
      })
      const pendingLogs = this.selectedSkills.map((selectedSkill) => {
        return {
          primary_entity: ENTITY_TYPES.OCCUPATION,
          primary_entity_id: this.occupation.id,
          secondary_entity: ENTITY_TYPES.SKILL,
          secondary_entity_id: selectedSkill,
          action: ACTION_TYPES.PENDING,
          status: true,
        }
      })
      await this.$repository.historicCustomization.saveLogs([
        ...addLogs,
        ...pendingLogs,
      ])
      if (this.isPublished) {
        this.evaluateMatchOccupation(this.occupation.id)
      }

      this.$store.commit('loading', false)
      this.goBack()
    },
  },
}
