export const calculateARPText = (score, results) => {
  if (!score || score <= 13) {
    return results.LOW
  }
  if (score >= 14 && score <= 19) {
    return results.MEDIUM_LOW
  }
  if (score >= 20 && score <= 25) {
    return results.MEDIUM
  }
  if (score >= 26 && score <= 32) {
    return results.MEDIUM_HIGH
  }
  if (score >= 33 && score <= 38) {
    return results.HIGH
  }
  if (score >= 39) {
    return results.VERY_HIGH
  }
}
