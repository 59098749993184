//
//
//
//
//
//
//
//
//
//

import AsideMenu from '@/components/AsideMenu'
import FooterBar from '@/components/FooterBar'
import NavBar from '@/components/NavBar'
import LoadingSpinner from '@/components/Shared/LoadingSpinner'
import menus from '@/layouts/menu'
import ROLES from '@/constants/roles'
import { shouldPassTheRouteInAdminRelink } from '@/util/menu'
import { PERMISSION_LABELS } from '@/constants/permission-labels'

export default {
  name: 'Dashboard',
  middleware: ['maint', 'auth', 'permissions'],
  components: {
    FooterBar,
    AsideMenu,
    NavBar,
    LoadingSpinner,
  },
  data() {
    return {
      menu: [],
      flag: false,
      showFooter: false,
      permissions: [],
    }
  },
  async created() {
    await this.setPermissions()
    this.menu = menus
      .filter(this.hasPermission)
      .filter(this.hasCompanyPermissions)
      .filter(this.hasCompanyValueAdminRelink)
      .map((menu) => {
        if (!Array.isArray(menu.children)) return menu

        if (menu.children.length === 0) return menu

        menu.children = menu.children
          .filter(this.hasPermission)
          .filter(this.hasCompanyPermissions)
        return menu
      })

    if (this.$store.getters['me/roleType'] === ROLES.ADMIN_UNEMPLOYED) {
      this.menu = this.menu.filter((menu) => menu.label !== 'Mi perfil')
    }

    if (
      this.$store.getters['me/isRelink'] &&
      !this.$store.getters['me/isCompanyNull']
    ) {
      this.menu = this.menu.filter((menu) => menu.to !== '/salir')
    }
  },
  mounted() {
    document.documentElement.classList.add('has-aside-left')
    document.documentElement.classList.add('has-aside-mobile-transition')
    document.documentElement.classList.add('has-navbar-fixed-top')
    document.documentElement.classList.add('has-aside-expanded')
  },
  methods: {
    hasPermission(menu) {
      return (
        (menu.roles || []).length === 0 ||
        menu.roles.includes(this.$store.getters['me/roleType'])
      )
    },
    hasCompanyPermissions(menu) {
      return (
        (menu.permissions || []).length === 0 ||
        menu.permissions.some(
          (permission) =>
            this.permissions.includes(permission) ||
            (this.$store.getters['me/roleType'] === ROLES.RELINK &&
              !this.$strapi.user.company)
        )
      )
    },
    hasCompanyValueAdminRelink(menu) {
      return shouldPassTheRouteInAdminRelink(
        menu,
        this.$store.getters['me/roleType'],
        this.$store.getters['me/isCompanyNull']
      )
    },
    async setPermissions() {
      this.permissions = (
        await this.$permissions.allow([
          PERMISSION_LABELS.INVENTORY_SKILLS.BASE,
          PERMISSION_LABELS.MATCH_POTENTIAL.BASE,
        ])
      )
        .filter((permission) => permission.enabled)
        .map((permission) => permission.service)
    },
  },
  head() {
    const i18nSeo = this.$nuxtI18nSeo()
    return {
      htmlAttrs: {
        lang: 'es',
        ...i18nSeo.htmlAttrs,
      },
      meta: i18nSeo.meta,
      link: i18nSeo.link,
    }
  },
}
