//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import EXP_TYPES from '@/constants/experience-types'
import SimpleModal from '~/components/Experiences/SimpleModal'
import {
  showSuccessNotification,
  showErrorNotification,
} from '~/util/notifications'

export default {
  name: 'DeleteExperienceModal',
  components: {
    SimpleModal,
  },
  props: {
    modal: {
      type: Object,
      default: () => {},
    },
    id: {
      type: Number,
      default: null,
    },
    isMyExperience: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toggleModal() {
      this.$emit('toggle')
    },
    async deleteExperience() {
      this.$store.commit('loading', true)
      const method = this.experienceType()
      if (this.isMyExperience && method) {
        try {
          await this.$repository[method].delete(this.id)
          showSuccessNotification(
            this,
            this.$t('experience.detail.actions.deleteNotification')
          )
          this.$router.push('/experiencias')
        } catch (e) {
          showErrorNotification(this, this.$t('misc.errors.genericError'))
        } finally {
          this.$store.commit('loading', false)
        }
      } else {
        showErrorNotification(this, this.$t('misc.errors.genericError'))
      }
      this.$store.commit('loading', false)
    },
    experienceType() {
      switch (this.modal.type) {
        case EXP_TYPES.WORK_TYPE:
          return 'experienceWork'
        case EXP_TYPES.EDUCATION_TYPE:
          return 'experienceEducational'
        default:
          return undefined
      }
    },
  },
}
