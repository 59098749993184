const config = {
  tests: {
    keyclouding: process.env.keycloudingUrl,
  },
  contactEmail: process.env.contactEmail,
  isMaint: process.env.isMaint,
  strapiUrl: process.env.strapiUrl,
  gcsBaseurl: process.env.gcsBaseurl,
  flagKeyclouding: process.env.flagKeyclouding,
  flagResendInvitation: process.env.flagResendInvitation,
}

export default config
