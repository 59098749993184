//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MessageWithoutHeader from '~/components/Shared/MessageWithoutHeader'
import DashboardCard from '~/components/Dashboard/DashboardCard.vue'

export default {
  components: {
    DashboardCard,
    MessageWithoutHeader,
  },
  props: {
    steps: {
      type: Array,
      default: () => [],
    },
    incompleteMessage: {
      type: String,
      default: 'dashboard.incomplete.missingSteps.default',
    },
  },
}
