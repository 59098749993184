import { TYPES } from './project-types'

export const SECTIONS = {
  INSTRUCTIONS: 'INSTRUCTIONS',
  ORIGIN_SELECT: 'ORIGIN_SELECT',
  ORIGIN: 'ORIGIN',
  TARGET: 'TARGET',
  FINISH: 'FINISH',
  MODEL_SKILLS: 'MODEL_SKILLS',
}

export const SUBSECTIONS = {
  SKILLS: 'SKILLS',
  KNOWLEDGES: 'KNOWLEDGES',
  REUSE_COMMONS: 'REUSE_COMMONS',
  REUSE_SPECIFICS: 'REUSE_SPECIFICS',
}

export const SECTION_FLOW = {
  [TYPES.RESKILLING]: [
    SECTIONS.INSTRUCTIONS,
    SECTIONS.ORIGIN_SELECT,
    SECTIONS.ORIGIN,
    SECTIONS.TARGET,
    SECTIONS.FINISH,
  ],
  [TYPES.UPSKILLING]: [
    SECTIONS.INSTRUCTIONS,
    SECTIONS.ORIGIN_SELECT,
    SECTIONS.ORIGIN,
    SECTIONS.TARGET,
    SECTIONS.FINISH,
  ],
  [TYPES.SELFDIAGNOSIS]: [
    SECTIONS.INSTRUCTIONS,
    SECTIONS.ORIGIN_SELECT,
    SECTIONS.ORIGIN,
    SECTIONS.FINISH,
  ],
  [TYPES.MODELS]: [
    SECTIONS.INSTRUCTIONS,
    SECTIONS.MODEL_SKILLS,
    SECTIONS.FINISH,
  ],
}
export const SUBSECTIONS_SKILLS_FLOW = {
  [TYPES.RESKILLING]: {
    [SECTIONS.ORIGIN]: [SUBSECTIONS.SKILLS, SUBSECTIONS.KNOWLEDGES],
    [SECTIONS.TARGET]: [SUBSECTIONS.REUSE_COMMONS, SUBSECTIONS.REUSE_SPECIFICS],
  },
  [TYPES.UPSKILLING]: {
    [SECTIONS.ORIGIN]: [SUBSECTIONS.SKILLS, SUBSECTIONS.KNOWLEDGES],
    [SECTIONS.TARGET]: [SUBSECTIONS.REUSE_SPECIFICS, SUBSECTIONS.REUSE_COMMONS],
  },
  [TYPES.SELFDIAGNOSIS]: {
    [SECTIONS.ORIGIN]: [SUBSECTIONS.SKILLS, SUBSECTIONS.KNOWLEDGES],
  },
}
export const SKILLS_KEYS_BY_SUBSECTIONS = {
  [SUBSECTIONS.SKILLS]: 'origin_skills',
  [SUBSECTIONS.KNOWLEDGES]: 'origin_knowledges',
  [SUBSECTIONS.REUSE_COMMONS]: 'target_reuse_commons',
  [SUBSECTIONS.REUSE_SPECIFICS]: 'target_reuse_specifics',
}
