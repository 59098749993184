//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Button from '@/components/Shared/Button.vue'

export default {
  components: {
    Button,
  },
  props: {
    invalidNext: {
      type: Boolean,
      default: () => false,
    },
    invalidOmit: {
      type: Boolean,
      default: () => false,
    },
    continueButtonText: {
      type: String,
      default: () => null,
    },
  },
  methods: {
    handleOmit() {
      this.$emit('omit')
    },
    handleContinue() {
      this.$emit('next')
    },
    handleBack() {
      this.$emit('back')
    },
  },
}
