//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ValidationObserver, extend } from 'vee-validate'
import { confirmed } from 'vee-validate/dist/rules'

import FormInput from '~/components/Form/FormInput'
import ROLES from '~/constants/roles'

extend('confirmed', {
  ...confirmed,
  message: 'Las contraseñas no coinciden',
})

export default {
  components: {
    ValidationObserver,
    FormInput,
  },
  props: {
    invitation: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      password: '',
      confirmation: '',
    }
  },
  methods: {
    async submit() {
      try {
        await this.$strapi.$http.$post(
          `/invitations/${this.$props.invitation.code}/create-password`,
          { password: this.password }
        )
        this.$buefy.toast.open({
          message: this.$t('misc.messages.success'),
          type: 'is-success',
          queue: false,
          duration: 3000,
        })
        const loginForm = {
          identifier: this.invitation.user.email,
          password: this.password,
          roles: [
            ROLES.RELINK,
            ROLES.ADMIN_UNEMPLOYED,
            ROLES.COMPANY,
            ROLES.EMPLOYEE,
            ROLES.PUBLIC,
            ROLES.UNEMPLOYED,
          ],
        }
        await this.$store.dispatch('me/login', loginForm)
        await this.$router.push('/inicio')
      } catch (e) {
        this.$buefy.toast.open({
          message: e.message,
          type: 'is-danger',
          queue: false,
          duration: 3000,
        })
      }
    },
  },
}
