//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ProfileMissingSteps from '~/components/Dashboard/ProfileMissingSteps'

const STEPS = ['experience']
export default {
  components: {
    ProfileMissingSteps,
  },
  props: {
    user: {
      type: Object,
      default: () => ({}),
    },
  },
  async fetch() {
    this.$store.commit('loading', true)
    this.stepProgress = await this.getProgress()
    this.stepsIncomplete = STEPS.filter(this.isIncomplete)
    this.registerComplete = this.stepsIncomplete.length === 0
    this.$emit('complete', this.registerComplete)
    this.$store.commit('loading', false)
  },
  data() {
    return {
      stepProgress: {},
      routes: {
        profile: '/perfil',
        experience: '/experiencias',
        tests: '/tests',
      },
      stepsIncomplete: [],
      registerComplete: false,
      opportunities: [],
    }
  },
  methods: {
    isIncomplete(field) {
      return this.stepProgress[field] < 100
    },
    async getProgress() {
      const experienceProgress = await this.checkExperienceProgress()
      return {
        experience: Math.floor(experienceProgress),
      }
    },
    async checkExperienceProgress() {
      const {
        educationalCount,
        workCount,
        otherCount,
      } = await this.$repository.user.countExperiences(this.user.id)
      return educationalCount + workCount + otherCount > 0 ? 100 : 0
    },
  },
}
