import { getOriginSkills, getTargetSkills } from './handleSkills'
import { getOriginTotalPages, getStatus } from './handlePage'
import {
  SECTIONS,
  SUBSECTIONS,
  SUBSECTIONS_SKILLS_FLOW,
  SKILLS_KEYS_BY_SUBSECTIONS,
} from '~/constants/questionary'

const getPreQuestionaryState = (questionary) => {
  const newQuestionary = {
    ...questionary,
    section: SECTIONS.ORIGIN_SELECT,
    subsection: null,
    page: null,
    total_page: null,
  }
  return {
    questionary: newQuestionary,
    skills: [],
    statusSection: { isStart: null, isEnd: null },
    statusSubsection: { isStart: null, isEnd: null },
  }
}
const handleBackOriginSkills = (questionary, projectType) => {
  if (questionary.page === 1) {
    return getPreQuestionaryState(questionary)
  }
  const newQuestionary = {
    ...questionary,
    page: questionary.page - 1,
  }
  return {
    questionary: newQuestionary,
    skills: getOriginSkills(newQuestionary, projectType),
    ...getStatus(newQuestionary, projectType),
  }
}

const handleBackOriginKnowledges = (questionary, projectType) => {
  const firstPageSection = questionary.origin_skills.total_page + 1
  if (questionary.page === firstPageSection) {
    const newQuestionary = {
      ...questionary,
      page: questionary.page - 1,
      subsection: SUBSECTIONS.SKILLS,
    }
    return {
      questionary: newQuestionary,
      skills: getOriginSkills(newQuestionary, projectType),
      ...getStatus(newQuestionary, projectType),
    }
  }
  const newQuestionary = {
    ...questionary,
    page: questionary.page - 1,
  }
  return {
    questionary: newQuestionary,
    skills: getOriginSkills(newQuestionary, projectType),
    ...getStatus(newQuestionary, projectType),
  }
}

const handleBackTarget = (questionary, projectType) => {
  const firstSubsection =
    SUBSECTIONS_SKILLS_FLOW[projectType][questionary.section][0]
  if (firstSubsection === questionary.subsection && questionary.page === 1) {
    const newQuestionary = {
      ...questionary,
      section: SECTIONS.ORIGIN,
      subsection: SUBSECTIONS.KNOWLEDGES,
      total_page: getOriginTotalPages(questionary, projectType),
      page: getOriginTotalPages(questionary, projectType),
    }
    return {
      questionary: newQuestionary,
      skills: getOriginSkills(newQuestionary, projectType),
      ...getStatus(newQuestionary, projectType),
    }
  }

  const pageStartSubsection =
    questionary[SKILLS_KEYS_BY_SUBSECTIONS[firstSubsection]].total_page + 1
  if (questionary.page === pageStartSubsection) {
    const newQuestionary = {
      ...questionary,
      page: questionary.page - 1,
      subsection: firstSubsection,
    }
    return {
      questionary: newQuestionary,
      skills: getTargetSkills(newQuestionary, projectType),
      ...getStatus(newQuestionary, projectType),
    }
  }

  const newQuestionary = {
    ...questionary,
    page: questionary.page - 1,
  }
  return {
    questionary: newQuestionary,
    skills: getTargetSkills(newQuestionary, projectType),
    ...getStatus(newQuestionary, projectType),
  }
}

export const handleBack = (questionary, projectType) => {
  if (questionary.section === SECTIONS.ORIGIN) {
    return questionary.subsection === SUBSECTIONS.SKILLS
      ? handleBackOriginSkills(questionary, projectType)
      : handleBackOriginKnowledges(questionary, projectType)
  }

  if (questionary.section === SECTIONS.TARGET) {
    return handleBackTarget(questionary, projectType)
  }
}
