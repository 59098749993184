import { render, staticRenderFns } from "./DashboardJobSeekerFair.vue?vue&type=template&id=74bf340f&"
import script from "./DashboardJobSeekerFair.vue?vue&type=script&lang=js&"
export * from "./DashboardJobSeekerFair.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DashboardOpportunityList: require('/workspace/components/Dashboard/DashboardOpportunityList.vue').default})
