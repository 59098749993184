import PROJECT_CONSTANTS from '@/constants/projects'
import { TYPES } from '@/constants/project-types'
import { SECTIONS } from '~/constants/questionary'

const NUMBER_OF_STEP_TO_CREATE_PROJECT = 5

export const visibleSectionFirstProjectDashboard = (projects) => {
  if (projects.length === 0) {
    return true
  }
  return projects.length === 1 && projects[0].current_step < 4
}

export const getPercentageProgressCreateProject = (project) => {
  if (project === undefined) {
    return 0
  }
  return Math.floor(
    ((project.current_step + 1) * 100) / NUMBER_OF_STEP_TO_CREATE_PROJECT
  )
}

export const getPercentageProgressInProgressProject = (project) => {
  if (project.users.length === 0) return 0
  let usersWithFinishedQuestionaries = 0
  project.users.forEach((user) => {
    const activeQuestionary =
      user.questionaries.find(
        (questionary) =>
          questionary.project === project.id &&
          questionary.section === SECTIONS.FINISH
      ) || null
    const modelQuestionary =
      user.model_questionaries.find(
        (questionary) =>
          questionary.project === project.id &&
          questionary.section === SECTIONS.FINISH
      ) || null
    if (activeQuestionary) usersWithFinishedQuestionaries++
    if (modelQuestionary) usersWithFinishedQuestionaries++
  })

  let numQuiz = 0
  if (project.has_models) numQuiz++
  if (project.main_target_occupation_id) numQuiz++

  const expectQuestionaries = project.users.length * numQuiz

  return Math.floor(
    (usersWithFinishedQuestionaries / expectQuestionaries) * 100
  )
}

const getTimeInSecondsSinceLastChange = (lastUpdatedAt) => {
  if (lastUpdatedAt === null) {
    return 0
  }
  const now = new Date()
  return Math.abs(lastUpdatedAt.getTime() - now.getTime()) / 1000
}

export const isReadyToSync = (state) => {
  return (
    getTimeInSecondsSinceLastChange(state.lastUpdatedAt) > 3 &&
    !state.sync &&
    !state.saving
  )
}

export const isInvalidToCreate = (project) => {
  const { id, type } = project
  return !id && !type
}

export const buildFormattedBaseProject = (baseProject) => {
  const project = {
    ...baseProject,
    objective: baseProject.objective ? baseProject.objective?.value : null,
    main_target_occupation_id: baseProject.main_target_occupation_id || null,
    modality: baseProject.modality ? baseProject.modality?.value : null,
    name: baseProject.name || 'Proyecto sin titulo',
  }
  if (baseProject.type === TYPES.SELFDIAGNOSIS) {
    project.origin_entities = []
  }
  return project
}

export const isWizardFinished = (project) => {
  return (
    project.current_step === 4 &&
    project.status === PROJECT_CONSTANTS.ACTIVE.value
  )
}

export const getObjectives = (lang) => {
  return [
    {
      value: PROJECT_CONSTANTS.INTERN,
      name: lang.objectives.intern,
      placeholder: lang.form.description.pholders.intern,
    },
    {
      value: PROJECT_CONSTANTS.TALENT,
      name: lang.objectives.talent,
      placeholder: lang.form.description.pholders.talent,
    },
    {
      value: PROJECT_CONSTANTS.UNBUNDLING,
      name: lang.objectives.unbundling,
      placeholder: lang.form.description.pholders.unbundling,
    },
    {
      value: PROJECT_CONSTANTS.OTHERS,
      name: lang.objectives.others,
      placeholder: lang.form.description.pholders.others,
    },
  ]
}

export const getObjectiveDescription = (objective, lang) => {
  if (objective === null || objective === '') return null
  const OBJECTIVE_DESCRIPTION = {
    [PROJECT_CONSTANTS.INTERN]: lang.intern.description,
    [PROJECT_CONSTANTS.TALENT]: lang.talent.description,
    [PROJECT_CONSTANTS.UNBUNDLING]: lang.unbundling.description,
    [PROJECT_CONSTANTS.OTHERS]: lang.others.description,
  }
  return OBJECTIVE_DESCRIPTION[objective]
}

export const getModalities = (lang) => {
  return [
    {
      value: PROJECT_CONSTANTS.PRESENTIAL,
      name: lang.modalities.presential,
    },
    {
      value: PROJECT_CONSTANTS.REMOTE,
      name: lang.modalities.remote,
    },
    {
      value: PROJECT_CONSTANTS.HYBRID,
      name: lang.modalities.hybrid,
    },
  ]
}

export const getFieldByKey = (value, items) => {
  return value ? items.find((item) => item.value === value) : null
}
