//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Button from '~/components/Shared/Button'
export default {
  components: {
    Button,
  },
  methods: {
    leave() {
      this.$emit('leave')
    },

    cancel() {
      this.$emit('cancel')
    },
  },
}
