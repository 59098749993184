//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations } from 'vuex'
import ROLES from '@/constants/roles'
import EmoticonSadOutline from 'vue-material-design-icons/EmoticonSadOutline'
import DashboardCard from '~/components/Dashboard/DashboardCard'

const NUM_OPPORTUNITIES = 4

export default {
  components: {
    DashboardCard,
    EmoticonSadOutline,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  async fetch() {
    try {
      this.$store.commit('loading', true)
      this.opportunities = await this.fetchOpportunities()
    } catch (e) {
      this.$buefy.notification.open({
        message: e.message,
        type: 'is-danger',
        autoclose: true,
        duration: 10000,
      })
    } finally {
      this.$store.commit('loading', false)
    }
  },
  data() {
    return {
      opportunities: [],
      showSeeMore: true,
    }
  },
  computed: {
    userRole() {
      return this.user.role.type
    },
    subHeader() {
      return this.userRole === ROLES.COMPANY
        ? this.$t('dashboard.complete.companySubHeader')
        : ''
    },
    seeMoreLink() {
      if (this.userRole === ROLES.COMPANY) {
        return '/empresas/ocupaciones-requeridas'
      }
      if (this.userRole === ROLES.EMPLOYEE) {
        return '/oportunidades'
      }
      if (this.userRole === ROLES.ADMIN_UNEMPLOYED) {
        return '/ocupaciones-requeridas/cesantes'
      }
      return '/oportunidades/reconversion-laboral'
    },
  },
  methods: {
    ...mapMutations({
      setOpportunity: 'opportunity/setOpportunity',
      setOccupation: 'createOccupation/setOccupation',
    }),
    async fetchPublicOpportunities() {
      return await this.$repository.user.getMyOpportunitiesWork({
        limit: NUM_OPPORTUNITIES,
        sort_distance: true,
      })
    },
    async fetchCompanyOpportunities() {
      return await this.$repository.me.getCompanyOpportunitiy({
        _limit: NUM_OPPORTUNITIES,
      })
    },
    async fetchAdminUnemployedOpportunities() {
      return await this.$repository.jobSeekerFair.getOpportunities(
        this.user.job_seeker_fair.id || this.user.job_seeker_fair,
        {
          _limit: NUM_OPPORTUNITIES,
        }
      )
    },
    async fetchOpportunities() {
      if (this.userRole === ROLES.COMPANY) {
        return this.fetchCompanyOpportunities()
      }
      if (this.userRole === ROLES.ADMIN_UNEMPLOYED) {
        return this.fetchAdminUnemployedOpportunities()
      }
      return await this.fetchPublicOpportunities()
    },
    subtitle(opportunity) {
      if ([ROLES.COMPANY, ROLES.ADMIN_UNEMPLOYED].includes(this.userRole)) {
        return this.$t('dashboard.complete.companySkills', [
          opportunity.matches,
        ])
      }

      return this.$t('dashboard.complete.match', [
        Math.round(opportunity.distance),
      ])
    },
    titleHighlight(opportunity) {
      return opportunity.project_name ? `"${opportunity.project_name}"` : ''
    },
    async onOpportunityClick(opportunity) {
      if (this.userRole === ROLES.COMPANY) {
        await this.$router.push(
          `/empresas/ocupacion-requerida/${opportunity.id}`
        )
        return
      }
      if (this.userRole === ROLES.ADMIN_UNEMPLOYED) {
        const opportunities = await this.$repository.jobAnnouncement.findAll({
          _limit: 1,
          _where: {
            id: opportunity.id,
          },
        })
        await this.$store.dispatch(
          'jobSeekerFairOccupationRequired/changeData',
          opportunities[0]
        )
        await this.$router.push('/ocupaciones-requeridas/cesantes/detalle')
        return
      }
      this.setOpportunity(opportunity)
      if (this.userRole === ROLES.EMPLOYEE) {
        await this.$router.push(`/oportunidades/${opportunity.id}`)
        return
      }
      await this.$router.push(
        `/oportunidades/reconversion-laboral/${opportunity.id}`
      )
    },
    goToExperiences() {
      this.$router.push('/experiencias')
    },
  },
}
