//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    id: {
      type: [Number, String],
      default: null,
    },
    isActive: {
      type: Boolean,
      default: () => false,
    },
    isPointerActive: {
      type: Boolean,
      default: () => true,
    },
    description: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      active: false,
    }
  },
  watch: {
    active(value) {
      this.$emit('selected', { id: this.id, status: value })
    },
    isActive(value) {
      this.active = value
    },
  },
  created() {
    this.active = this.isActive
  },
  methods: {
    changeStatus() {
      if (!this.disabled) {
        this.active = !this.active
        this.$emit('change', { id: this.id, status: this.active })
      }
    },
  },
}
