const Request = require('@/repositories/request')
const qs = require('qs')
class HistoricCustomization extends Request {
  /**
   *
   * @param {int} id
   * @param params
   * @returns {*}
   */
  findOne(id, params) {
    const query = qs.stringify(params, { encode: false })
    return this.$strapi.$http.$get(`/historic-customizations/${id}?${query}`)
  }

  /**
   *
   * @param {Object} params
   * @return {*}
   */
  findAll(params) {
    const stringifyParams = qs.stringify({ ...params }, { encode: false })
    return this.$strapi['$historic-customizations'].find(stringifyParams)
  }

  /**
   * @param {Array} data
   * @returns {*}
   */
  saveLogs(data) {
    return this.$strapi.$http.$post(`/historic-customizations/save-logs`, data)
  }
}

module.exports = HistoricCustomization
