//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import moment from 'moment'
import config from '~/config'
import RelinkLogoGray from '~/assets/relink-logo-gray.svg'

export default {
  name: 'FooterBar',
  components: {
    RelinkLogoGray,
  },
  data() {
    return {
      contactEmail: config.contactEmail,
    }
  },
  computed: {
    year() {
      return moment().format('YYYY')
    },
    ...mapState(['project']),
    isFreelanceUser() {
      return (
        this.$route.path === '/perfil' &&
        this.$store.getters['me/isFreelanceUser']
      )
    },
  },
}
