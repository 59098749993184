import { render, staticRenderFns } from "./CustomOccupationFormResume.vue?vue&type=template&id=4934af70&scoped=true&"
import script from "./CustomOccupationFormResume.vue?vue&type=script&lang=js&"
export * from "./CustomOccupationFormResume.vue?vue&type=script&lang=js&"
import style0 from "./CustomOccupationFormResume.vue?vue&type=style&index=0&id=4934af70&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4934af70",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FormInput: require('/workspace/components/Form/FormInput.vue').default})
