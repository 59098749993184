//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ValidationObserver } from 'vee-validate'
import ModalWithTitleBar from '@/components/Shared/ModalWithTitleBar'
import { FormInput } from '@/components/Form'
import Button from '@/components/Shared/Button'
import ColoredNotification from '@/components/Shared/ColoredNotification.vue'

export default {
  name: 'ResetPasswordForm',
  components: {
    FormInput,
    ValidationObserver,
    Button,
    ColoredNotification,
    ModalWithTitleBar,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    notification: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      form: {
        email: null,
      },
    }
  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
    submit() {
      this.$emit('submit', this.form)
    },
  },
}
