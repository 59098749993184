//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SearchEngine from '@/components/Shared/SearchEngine/SearchEngine.vue'
import SuggestedSetSkills from './SuggestSkills/SuggestedSetSkills.vue'
import ListChip from './ListChip.vue'
export default {
  name: 'AddFreeSkillsWithRecommendations',
  components: {
    SearchEngine,
    ListChip,
    SuggestedSetSkills,
  },
  props: {
    suggestSkills: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      pattern: '',
      searchedSkills: [],

      selectedSkillIds: [],
    }
  },
  computed: {
    groupLabels() {
      return {
        'match-pattern': this.$t(
          'components.searchEngine.groups.skills.byTitle',
          [this.pattern]
        ),
        'not-match-pattern': this.$t(
          'components.searchEngine.groups.skills.byLabels'
        ),
      }
    },
    totalSkillsSelected() {
      return this.selectedSkillIds.length
    },
    searchedSkillsWithStatus() {
      return this.searchedSkills.map((skill) => {
        if (this.selectedSkillIds.includes(skill.id)) {
          return { ...skill, active: true }
        }
        return { ...skill, active: false }
      })
    },
    excludedToSearchSkillIds() {
      return this.searchedSkills.map(({ id }) => id)
    },
  },
  methods: {
    search(pattern) {
      return this.$repository.skill.searchSkillsByTerm(
        pattern,
        this.excludedToSearchSkillIds
      )
    },
    handleInput(inputSkill) {
      this.pattern = inputSkill
    },
    handleChangeChip({ id, status }) {
      if (status) {
        this.selectedSkillIds = [...new Set([id, ...this.selectedSkillIds])]
      } else {
        this.selectedSkillIds = this.selectedSkillIds.filter(
          (skillId) => skillId !== id
        )
      }
      this.handleSkillSelected()
    },
    handleSelectSearchEngine(selected) {
      if (!selected) {
        return
      }
      if (this.searchedSkills.map(({ id }) => id).includes(selected.id)) {
        return
      }
      const selectedSkill = {
        id: selected.id,
        title: selected.title,
        active: true,
      }
      this.selectedSkillIds = [
        ...new Set([selected.id, ...this.selectedSkillIds]),
      ]
      this.searchedSkills = [...this.searchedSkills, selectedSkill]
      this.handleSkillSelected()
    },
    handleSkillSelected() {
      this.$emit('selected', this.selectedSkillIds)
    },
  },
}
