//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import _ from 'underscore'
import Button from '../../components/Shared/Button'
import ROLES from '~/constants/roles'

export default {
  name: 'LoginForm',
  components: { Button },
  data() {
    return {
      form: {
        identifier: null,
        password: null,
        roles: [
          ROLES.RELINK,
          ROLES.ADMIN_UNEMPLOYED,
          ROLES.COMPANY,
          ROLES.EMPLOYEE,
          ROLES.PUBLIC,
          ROLES.UNEMPLOYED,
        ],
      },
    }
  },
  methods: {
    async submit() {
      try {
        await this.$store.dispatch('me/login', this.form)
        await this.$router.push('/inicio')
      } catch (e) {
        const idsOfMessageError = _.toArray(
          _.flatten(
            (e.response?.data?.message || []).map((message) =>
              message.messages.map(({ id }) => id)
            )
          )
        )
        let message = this.$t('misc.messages.error')
        if (idsOfMessageError.length > 0) {
          message = idsOfMessageError
            .map((message) => {
              const keyTranslate = `api.errors.${message}`
              const translate = this.$t(`api.errors.${message}`)
              if (keyTranslate !== translate) return translate

              return this.$t('login.passwordIncorrect')
            })
            .join(',')
        }

        this.$buefy.notification.open({
          message,
          type: 'is-danger',
          duration: 5000,
        })
      }
    },
  },
}
