//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AddExperienceButton from '@/components/Experiences/AddExperienceButton'
import Hero from '@/components/Shared/Hero'

export default {
  components: {
    AddExperienceButton,
    Hero,
  },
  props: {
    onWorkClick: {
      type: Function,
      default: () => {},
    },
    onEducationClick: {
      type: Function,
      default: () => {},
    },
    onOtherClick: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      isHover: false,
    }
  },
}
