import ROLES from '@/constants/roles'

export const shouldPassTheRouteInAdminRelink = (
  menu,
  currentRol,
  hasCompanyValueInNull
) => {
  if (currentRol !== ROLES.RELINK) {
    return true
  }

  if (hasCompanyValueInNull) {
    return (
      (menu.roles || []).length === 0 ||
      (menu.roles?.includes(ROLES.RELINK) && menu.label === '') ||
      (menu.roles?.includes(ROLES.RELINK) &&
        !menu.roles?.includes(ROLES.COMPANY))
    )
  }

  return (menu.roles || []).length === 0 || menu.roles?.includes(ROLES.COMPANY)
}
