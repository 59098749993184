//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    system: {
      type: String,
      default: '',
    },
    value: {
      type: String || Number,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
  },
  fetch() {
    if (this.type === 'percentage') {
      this.valueConfig = Number(this.value)
    } else {
      this.valueConfig = this.value
    }
  },
  data: () => ({
    valueConfig: '',
  }),
  watch: {
    valueConfig(value) {
      value = value || 0
      this.$emit('change', { system: this.system, value })
    },
  },
}
