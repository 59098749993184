//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Button from '@/components/Shared/Button'

export default {
  name: 'InstructiveSection',
  components: {
    Button,
  },
  props: {
    subtitle: {
      type: String,
      default: '',
    },
  },
  computed: {
    questionarySubtitle() {
      return this.subtitle || this.$t('questionaries.instructive.subtitle')
    },
  },
  methods: {
    handleClick() {
      this.$emit('next')
    },
  },
}
