//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    field: {
      type: String,
      default: 'title',
    },
  },

  data() {
    return {
      isModeEdit: false,
      value: this.item[this.field],
    }
  },
  methods: {
    handleEditMode() {
      this.$emit('edit-mode', { isModeEdit: !this.isModeEdit, item: this.item })
      this.isModeEdit = !this.isModeEdit
    },
    handleDelete() {
      this.$emit('delete', this.item)
    },
    handleConfirm() {
      const newItem = {
        ...this.item,
        [this.field]: this.value,
      }
      this.$emit('edit', newItem)
      this.handleEditMode()
    },
  },
}
