//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Button from '~/components/Shared/Button.vue'
import config from '~/config'
const TOTAL_LOCATIONS_VISIBLE = 6
export default {
  components: {
    Button,
  },
  props: {
    title: {
      type: String,
      default: 'Nombre de piloto',
    },
    isOpen: {
      type: Boolean,
      default: true,
    },
    locations: {
      type: Array,
      default: () => [],
    },
    slug: {
      type: String,
      default: 'slug-piloto',
    },
    photo: {
      type: Object,
      default: () => {},
    },
    dateEnd: {
      type: String,
      default: '',
    },
  },
  data: () => {
    return {
      baseUrl: config.gcsBaseurl,
    }
  },
  computed: {
    srcImage() {
      return this.photo
        ? `${this.baseUrl}${this.photo.url}`
        : require(`~/assets/images/landing/card-job-seeker-fair-default.png`)
    },
    locationsVisible() {
      if (!this.locations) {
        return ''
      }
      const locations = this.locations.slice(0, TOTAL_LOCATIONS_VISIBLE)
      let locationText = locations.reduce((locationsText, location) => {
        locationsText += `${location.name}, `
        return locationsText
      }, '')
      locationText = locationText.substring(0, locationText.length - 2)
      return this.locations.length > TOTAL_LOCATIONS_VISIBLE
        ? locationText + '...'
        : locationText
    },
    statusLabel() {
      return this.isOpen
        ? this.$t('jobSeekerFair.status.open')
        : this.$t('jobSeekerFair.status.close')
    },
  },
}
