const Request = require('@/repositories/request')
const qs = require('qs')

class EducationalArea extends Request {
  /**
   *
   * @param {int} id
   * @returns {*}
   */
  findOne(id) {
    return this.$strapi['$educational-areas'].findOne(id)
  }

  /**
   *
   * @param {Object} params
   * @returns {*}
   */
  find(params) {
    const stringifyParams = qs.stringify({ ...params })
    return this.$strapi.$http.$get(`/educational-areas?${stringifyParams}`)
  }

  /**
   *
   * @param {Object} params
   * @return {*}
   */
  findAll(params) {
    const stringifyParams = qs.stringify({ ...params }, { encode: false })
    return this.$strapi['$educational-areas'].find(stringifyParams)
  }

  /**
   *
   * @param {int} id
   * @returns {*}
   */
  skillMoreRelevantsById(id) {
    return this.$strapi.$http.$get(
      `/educational-areas/${id}/skill-more-relevants`
    )
  }

  /**
   *
   * @param {int} id
   * @returns {*}
   */
  skillMoreRelevantsBeta(id, experienceId = 0, total) {
    return this.$strapi.$http.$get(
      `/educational-areas/${id}/${experienceId}/${
        total > 0 ? total : 30
      }/skill-more-relevants`
    )
  }
}

module.exports = EducationalArea
