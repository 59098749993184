//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  getPercentageProgressCreateProject,
  getPercentageProgressInProgressProject,
} from '@/util/project'
import PROJECT from '@/constants/projects'
import CompanyProjectsSearchBar from '~/components/Dashboard/Company/CompanyProjectsSearchBar.vue'
export default {
  components: {
    CompanyProjectsSearchBar,
  },
  async fetch() {
    try {
      this.$store.commit('loading', true)
      this.projects = await this.$repository.project.findAll({
        company: this.$strapi.user.company.id,
        populate: [
          'users',
          'users.questionaries',
          'users.model_questionaries',
          'users.experience_works',
          'users.experience_educationals',
          'users.experience_others',
        ],
      })
    } catch (e) {
      this.$buefy.notification.open({
        message: e.message,
        type: 'is-danger',
        autoclose: true,
        duration: 10000,
      })
    } finally {
      this.$store.commit('loading', false)
    }
  },
  data() {
    return {
      filters: {
        status: '',
        text: '',
      },
      projects: [],
    }
  },
  computed: {
    projectsCards() {
      const filteredProjects = this.filterProjects(this.projects)
      const orderedProjects = this.orderProjects(filteredProjects)
      return this.formattedProjectsCards(orderedProjects)
    },
  },
  methods: {
    goToProject(project) {
      if (project.status === PROJECT.DRAFT.value) {
        this.$router.push(`/mis-proyectos/nuevo?code=${project.code}`)
      } else {
        this.$router.push(`/mis-proyectos/${project.id}`)
      }
    },
    handleChangeFilterStatus(status) {
      this.filters.status = status
    },
    handleChangeFilterText(text) {
      this.filters.text = text
    },
    getClassByStatus(status) {
      return PROJECT[status].class
    },
    getNameByStatus(status) {
      return PROJECT[status].name
    },
    filterProjects(projects) {
      return projects.filter((project) => {
        return (
          this.isMatchFilterText(project.name, this.filters.text) &&
          this.isMatchFilterStatus(project.status, this.filters.status)
        )
      })
    },
    isMatchFilterText(projectName, filterText) {
      const name = projectName ? projectName.toLowerCase() : ''
      const filter = filterText.toLowerCase()

      return filterText === '' || name.includes(filter)
    },
    isMatchFilterStatus(projectStatus, filterStatus) {
      return (
        filterStatus === '' ||
        filterStatus === null ||
        projectStatus === filterStatus
      )
    },
    orderProjects(filteredProjects) {
      return filteredProjects.sort(
        (project, otherProject) =>
          new Date(otherProject.created_at) - new Date(project.created_at)
      )
    },
    formattedProjectsCards(projects) {
      return projects.map((project) => {
        const { subtitleText, buttonText } = this.formattedSubtitleAndButton(
          project
        )
        return {
          ...project,
          subtitleText,
          buttonText,
        }
      })
    },
    formattedSubtitleAndButton(project) {
      if (project.status === PROJECT.DRAFT.value) {
        const progressPercentage = getPercentageProgressCreateProject(project)
        const statusText = 'Estado de creación'
        return {
          subtitleText: `${statusText}: <span class="highlighted">${progressPercentage}%</span>`,
          buttonText: 'Continuar',
        }
      }

      if (project.status === PROJECT.ACTIVE.value) {
        const progressPercentage = getPercentageProgressInProgressProject(
          project
        )
        const statusText = 'Estado de avance'
        return {
          subtitleText: `${statusText}: <span class="highlighted">${progressPercentage}%</span>`,
          buttonText: 'Ver Proyecto',
        }
      }

      return {
        subtitleText: '',
        buttonText: 'Ver proyecto',
      }
    },
  },
}
