import EDUCATIONAL_TYPES from '~/constants/educational-types'
const getOccupationSkills = async (repository, occupationId) => {
  const occupationSkills = await repository.findOne(occupationId, {
    populate: ['skills.skills'],
  })
  const skills = occupationSkills.skills
    .map((skillGroup) => skillGroup.skills)
    .flat()
    .filter((skill) => !skill.is_deprecated)
  return skills
}

const getAreaEducationalSkills = async (repository, areaEducationalId) => {
  const educationalAreaSkills = await repository.findOne(areaEducationalId, {
    populate: ['skill'],
  })
  return educationalAreaSkills.skills
}

const getSpecialtySkills = async (repository, specialtyId) => {
  const educationalSpecialtySkills = await repository.findOne(specialtyId, {
    populate: ['skill'],
  })
  return educationalSpecialtySkills.skills
}

const getDefaultSkillSet = async (repository, userSkills) => {
  const MAX_DEFAULT_SKILLS = 15
  const [defaultSkillSet] = await repository.find({
    _where: {
      name: 'DEFAULT',
    },
    populate: ['skill_set_details.skill'],
  })
  return defaultSkillSet.skill_set_details
    .filter((skillSetDetail) => !userSkills.includes(skillSetDetail.skill.id))
    .sort((a, b) => b.connectivity - a.connectivity)
    .map((skillSetDetail) => skillSetDetail.skill)
    .slice(0, MAX_DEFAULT_SKILLS)
}

export const getSuggestSkillsForExperienceWork = async (
  repositories,
  experience,
  userSkills
) => {
  let skills = []
  if (experience.occupation && experience.occupation.id) {
    skills = await getOccupationSkills(
      repositories.occupation,
      experience.occupation.id
    )
    skills = skills.filter((skill) => !userSkills.includes(skill.id))
  } else {
    skills = await getDefaultSkillSet(repositories.skillSet, userSkills)
  }
  return skills
}

export const getSuggestSkillsForExperienceEducational = async (
  repositories,
  experience,
  userSkills
) => {
  if (experience.occupation) {
    const occupationSkills = await getOccupationSkills(
      repositories.occupation,
      experience.occupation.id
    )
    return occupationSkills.filter((skill) => !userSkills.includes(skill.id))
  }

  if (
    EDUCATIONAL_TYPES.TECHNICAL_COLLEGE !== experience.educational_type.name
  ) {
    const skills = await getDefaultSkillSet(repositories.skillSet, userSkills)
    return skills
  }

  if (experience.area_educational) {
    const educationAreaSkills = await getAreaEducationalSkills(
      repositories.educationalArea,
      experience.area_educational.id
    )
    return educationAreaSkills.filter((skill) => !userSkills.includes(skill.id))
  }
  if (experience.specialty) {
    const educationSpecialtySkills = await getSpecialtySkills(
      repositories.specialty,
      experience.specialty.id
    )
    return educationSpecialtySkills.filter(
      (skill) => !userSkills.includes(skill.id)
    )
  }
  return []
}

export const getSuggestSkillsForExperienceOthers = async (
  repositories,
  userSkills
) => {
  const skills = await getDefaultSkillSet(repositories.skillSet, userSkills)
  return skills
}
