//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ModalWithTitleBar from '@/components/Shared/ModalWithTitleBar'
import { ValidationObserver } from 'vee-validate'
import ROLES from '@/constants/roles'
import Button from '@/components/Shared/Button'

export default {
  name: 'FinishProjectModal',
  components: {
    ValidationObserver,
    ModalWithTitleBar,
    Button,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    project: {
      type: Object,
      default: () => ({}),
    },
    roleType: {
      type: String,
      default: ROLES.EMPLOYEE,
    },
  },
  async fetch() {
    this.form.message = await this.project.custom_invitation
  },
  data() {
    return {
      form: {
        message: '',
      },
    }
  },
  methods: {
    async preview() {
      const role = this.roleType
      const customMessage = this.form.message
      const companyName = this.$repository.me.getMe().company?.name
      const jobSeekerFair = this.$repository.me.getMe().job_seeker_fair?.name

      const response = await this.$repository.invitation.previewMail({
        role,
        customMessage,
        companyName,
        jobSeekerFair,
      })
      window.open(response.url, '_blank')
    },
    onEdit() {
      const projectData = {
        ...this.project,
        custom_invitation: this.form.message,
      }
      this.$emit('edit', projectData)
    },
    close() {
      this.$emit('close')
    },
  },
}
