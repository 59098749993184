//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Field from '@/components/Perfil/Field'
import CustomButton from '@/components/Perfil/Button'
export default {
  components: {
    Field,
    CustomButton,
  },
  props: {
    title: {
      default: '',
      type: String,
    },
    model: {
      default: () => [],
      type: Array,
    },
  },
}
