//
//
//
//
//
//
//
//
//
//
//
//

import config from '~/config'
export default {
  props: {
    title: { type: String, default: 'Título de la ocupación' },
    photo: { type: Object, default: () => {} },
  },
  data: () => {
    return {
      baseUrl: config.gcsBaseurl,
    }
  },
  computed: {
    srcImage() {
      return this.photo
        ? `${this.baseUrl}${this.photo.url}`
        : require(`~/assets/images/landing/card-occupation-default.png`)
    },
  },
}
