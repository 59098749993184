//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ChartBar from '../Charts/ChartBar.vue'
const COLORS = ['#EF4A81', '#2CD5C4', '#297FB8', '#8D44AD', '#FFDD57']
export default {
  components: {
    ChartBar,
  },
  props: {
    leyend: {
      type: Array,
      default: () => [],
    },
    dataY: {
      type: Array,
      default: () => [],
    },
    series: {
      type: Array,
      default: () => [],
    },
    totalUsers: {
      type: Number,
      default: 0,
    },
  },
  data: () => {
    return { colors: COLORS, leyendShow: true }
  },
  mounted() {
    if (window.innerWidth < 500) {
      this.leyendShow = false
    }
  },
}
