import { render, staticRenderFns } from "./DetailSkillModal.vue?vue&type=template&id=094c1a4b&scoped=true&"
import script from "./DetailSkillModal.vue?vue&type=script&lang=js&"
export * from "./DetailSkillModal.vue?vue&type=script&lang=js&"
import style0 from "./DetailSkillModal.vue?vue&type=style&index=0&id=094c1a4b&lang=scss&scoped=true&"
import style1 from "./DetailSkillModal.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "094c1a4b",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FormInput: require('/workspace/components/Form/FormInput.vue').default})
