const Request = require('@/repositories/request')
const qs = require('qs')
const { encodeQueryParams } = require('~/util/query')
class Occupation extends Request {
  /**
   *
   * @param {int} id
   * @param params
   * @returns {*}
   */
  findOne(id, params) {
    const query = qs.stringify(params, { encode: false })
    return this.$strapi.$http.$get(`/occupations/${id}?${query}`)
  }

  findOneByHierarchy(id, params) {
    const query = qs.stringify(params, { encode: false })
    return this.$strapi.$http.$get(`/occupations-with-hierarchy/${id}?${query}`)
  }

  suggestOccupations(request) {
    return this.$strapi.$http.$post('/occupations/suggest', request)
  }

  searchOccupations(term) {
    const query = qs.stringify({ term }, { encode: false })
    return this.$strapi.$http.$get(`/occupations/search?${query}`)
  }

  searchForCustomOccupations(request) {
    return this.$strapi.$http.$post(
      '/occupations/search-for-custom-occupations',
      request
    )
  }

  occupationsOfSameHierarchy(id) {
    return this.$strapi.$http.$get(
      `/occupations/${id}/occupations-of-same-hierarchy`
    )
  }

  /**
   *
   * @param {Object} params
   * @return {*}
   */
  findAll(params) {
    const stringifyParams = qs.stringify({ ...params }, { encode: false })
    return this.$strapi.$occupations.find(stringifyParams)
  }

  searchForProjects(params) {
    const stringifyParams = qs.stringify({ ...params }, { encode: false })
    return this.$strapi.$http.$get(
      `/occupations/search-for-projects?${stringifyParams}`
    )
  }

  /**
   *
   * @param {Object} params
   * @return {*}
   */
  getMetaOccupations() {
    return this.$strapi.$http.$get(`/occupations/meta`)
  }

  /**
   *
   * @param {Object} params
   * @return {*}
   */
  count(params) {
    const stringifyParams = qs.stringify({ ...params }, { encode: false })
    return this.$strapi.$occupations.count(stringifyParams)
  }

  /**
   *
   * @param params
   * @returns {*}
   */
  create(params) {
    return this.$strapi.$occupations.create(params)
  }

  /**
   * @param {int} id
   * @param {Object} data
   * @returns {*}
   */
  update(id, data) {
    return this.$strapi.$occupations.update(id, data)
  }

  /**
   * @param {int} id
   * @returns {*}
   */
  delete(id) {
    return this.$strapi.$occupations.delete(id)
  }

  /**
   *
   * @param {int[]} ids
   * @returns {*}
   */
  findAnyWithIds(ids) {
    return this.$strapi.$http.$get(
      `/occupations?${encodeQueryParams({ id: ids })}`
    )
  }

  /**
   * @param {int} companyId
   * @param {int} occupationId
   * @returns {Promise<any>}
   */
  searchOccupationPotentialWorkers(companyId, occupationId) {
    const query = encodeQueryParams({ company: companyId })
    return this.$strapi.$http.$get(
      `/occupations/${occupationId}/potential-workers?${query}`
    )
  }

  /**
   * @param {int} companyId
   * @param {int} occupationId
   * @returns {Promise<int>}
   */
  countOccupationPotentialWorkers(companyId, occupationId) {
    const query = encodeQueryParams({ company: companyId })
    return this.$strapi.$http.$get(
      `/occupations/${occupationId}/potential-workers/count?${query}`
    )
  }

  /**
   * @returns {Promise<Blob>}
   * @param {id} occupationId
   */
  async occupationReportPdf(occupationId) {
    const file = await this.$strapi.$http.get(
      `/occupations/${occupationId}/report/pdf`
    )

    return file.blob()
  }

  /**
   * @param {id} id
   * @param {Object} params
   * @param {string} params.project_name
   * @param {int} params.vacancies
   * @param {int} params.companyId
   * @param {string} params.publisherName
   * @returns {Promise<Blob>}
   */
  async downloadReportCompanyOccupationById(id, params = {}) {
    const file = await this.$strapi.$http.post(
      `/occupations/${id}/company-occupations/report`,
      params
    )

    return file.blob()
  }

  /**
   *
   * @returns {Promise<Blob>}
   */
  async occcupationsReportXls() {
    const file = await this.$strapi.$http.get(
      `/occupations/reports/excel?meta=1`
    )
    return file.blob()
  }

  async getTopRequiredOccupations() {
    const data = await this.$strapi.$http.get(
      '/occupations/top-required-occupations'
    )
    return await data.json()
  }

  skillsMoreRelevantsBeta(id, experience = 0, total) {
    return this.$strapi.$http.$get(
      `/occupations/${id}/${experience}/${
        total > 0 ? total : 30
      }/skill-more-relevants`
    )
  }

  async getMetaAndCustomOccupation() {
    const data = await this.$strapi.$http.get('/occupations/meta-and-custom')
    return data.json()
  }

  getOccupationsForLibrary(params) {
    const query = qs.stringify({ ...params })
    return this.$strapi.$http.$get(
      `/occupations/search-ocuppation-library?${query}`
    )
  }

  async getOccupationHierarchy(params) {
    const stringifyParams = qs.stringify({ ...params })
    return await this.$strapi.$http.$get(
      `/occupations/search-hierarchy-ocuppation?${stringifyParams}`
    )
  }
}

module.exports = Occupation
