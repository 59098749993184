//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TableEmptyData from '@/components/Shared/Table/TableEmptyData'
import ROLES from '@/constants/roles'
export default {
  name: 'ListJobSeekerFair',
  filters: {
    countUnemployed(users) {
      if (!Array.isArray(users)) return 0
      return users.filter((user) => user?.role?.type === ROLES.UNEMPLOYED)
        .length
    },
  },
  components: { TableEmptyData },
  props: {
    onPageChange: {
      default: () => {},
      type: Function,
    },
    data: {
      default: () => [],
      type: Array,
    },
    total: {
      default: 0,
      type: Number,
    },
    perPage: {
      default: 10,
      type: Number,
    },
  },
  methods: {
    toggleActive(isActive, jobSeekerFair) {
      this.$emit('toggleActive', { isActive, jobSeekerFair })
    },
  },
}
