//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ExperienceWithSkillsConfirmation from '@/components/Experiences/ExperienceWithSkillsConfirmation.vue'
import ExperienceWithoutSkillsConfirmation from '@/components/Experiences/ExperienceWithoutSkillsConfirmation.vue'
import Button from '@/components/Shared/Button.vue'

export default {
  components: {
    ExperienceWithSkillsConfirmation,
    ExperienceWithoutSkillsConfirmation,
    Button,
  },
  props: {
    experience: {
      type: Object,
      default: () => null,
    },
  },
  computed: {
    experienceHasSkills() {
      return this.experience.total_has_skills > 0
    },
  },
}
