//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { SemipolarSpinner } from 'epic-spinners'

export default {
  components: {
    SemipolarSpinner,
  },
}
