//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ListSkillGroupHierarchy',
  props: {
    hierarchies: {
      type: Array,
      default: () => {},
    },
  },
}
