//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'SkillsEmptyState',
  props: {
    main: {
      type: String,
      default: '',
    },
  },
  computed: {
    mainCopy() {
      return (
        this.main ||
        this.$t('questionaryProject.validateSkills.emptyState.main')
      )
    },
  },
}
