//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CheckMultiLevelItem from '~/components/Shared/CheckMultiLevelItem'
export default {
  components: {
    CheckMultiLevelItem,
  },
  props: {
    elements: {
      type: Array,
      default: () => [],
    },
  },
  data: () => {
    return {
      checkedAll: false,
    }
  },
  methods: {
    changeMinLevel(value) {
      this.$emit('change', value)
    },
  },
}
