//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import KanbanBoardConstants from '@/constants/kanban-board'
import KanbanInsightCard from './KanbanInsightCard.vue'
export default {
  components: {
    KanbanInsightCard,
  },
  props: {
    cards: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      barCategories: {
        essentiality: {
          essential: 0,
          optional: 0,
          pendient: 0,
        },
        tipology: {
          skill: 0,
          knowledge: 0,
        },
        reusability: {
          cross: 0,
          intersectoral: 0,
          sector: 0,
          occupation: 0,
        },
      },
    }
  },
  computed: {
    formattedInsights() {
      const barCategories = this.counterInsightCategories(this.cards)

      const totalEssentiality =
        barCategories.essentiality.essential +
        barCategories.essentiality.optional +
        barCategories.essentiality.pendient

      const totalTipology =
        barCategories.tipology.skill + barCategories.tipology.knowledge

      const totalReusability =
        barCategories.reusability.cross +
        barCategories.reusability.intersectoral +
        barCategories.reusability.sector +
        barCategories.reusability.occupation

      return [
        {
          title: 'Esencialidad',
          bar: [
            {
              title: 'Esencial',
              value: this.calculatePercentage(
                barCategories.essentiality.essential,
                totalEssentiality
              ),
              color: KanbanBoardConstants.insightsBarColor.red,
            },
            {
              title: 'Optativa',
              value: this.calculatePercentage(
                barCategories.essentiality.optional,
                totalEssentiality
              ),
              color: KanbanBoardConstants.insightsBarColor.green,
            },
            {
              title: 'Pendiente',
              value: this.calculatePercentage(
                barCategories.essentiality.pendient,
                totalEssentiality
              ),
              color: KanbanBoardConstants.insightsBarColor.bone,
            },
          ],
        },
        {
          title: 'Tipología',
          bar: [
            {
              title: 'Habilidad',
              value: this.calculatePercentage(
                barCategories.tipology.skill,
                totalTipology
              ),
              color: KanbanBoardConstants.insightsBarColor.red,
            },
            {
              title: 'Conocimiento',
              value: this.calculatePercentage(
                barCategories.tipology.knowledge,
                totalTipology
              ),
              color: KanbanBoardConstants.insightsBarColor.bone,
            },
          ],
        },
        {
          title: 'Nivel de reusabilidad',
          bar: [
            {
              title: 'Transversal',
              value: this.calculatePercentage(
                barCategories.reusability.cross,
                totalReusability
              ),
              color: KanbanBoardConstants.insightsBarColor.red,
            },
            {
              title: 'Intersectorial',
              value: this.calculatePercentage(
                barCategories.reusability.intersectoral,
                totalReusability
              ),
              color: KanbanBoardConstants.insightsBarColor.green,
            },
            {
              title: 'Sector',
              value: this.calculatePercentage(
                barCategories.reusability.sector,
                totalReusability
              ),
              color: KanbanBoardConstants.insightsBarColor.bone,
            },
            {
              title: 'Ocupación',
              value: this.calculatePercentage(
                barCategories.reusability.occupation,
                totalReusability
              ),
              color: KanbanBoardConstants.insightsBarColor.blue,
            },
          ],
        },
      ]
    },
  },
  methods: {
    showInsights() {
      this.$emit('show-insights', this.showInsights)
    },

    counterInsightCategories(cards) {
      const essentiality = this.counterEssentialityInsights(cards)
      const tipology = this.counterTipologyInsights(cards)
      const reusability = this.counterReusabilityInsights(cards)
      return {
        essentiality,
        tipology,
        reusability,
      }
    },

    counterEssentialityInsights(cards) {
      const essentiality = {
        essential: 0,
        optional: 0,
        pendient: 0,
      }
      cards.forEach((card) => {
        if (card.skillType === 'Obligatorio') {
          essentiality.essential++
        }
        if (card.skillType === 'Opcional') {
          essentiality.optional++
        }
        if (card.skillType === 'Pendiente' && !card.isDeleted) {
          essentiality.pendient++
        }
      })
      return essentiality
    },

    counterTipologyInsights(cards) {
      const tipology = {
        skill: 0,
        knowledge: 0,
      }
      cards.forEach((card) => {
        if (card.typeOfSkill === 'Habilidad' && !card.isDeleted) {
          tipology.skill++
        }

        if (card.typeOfSkill === 'Conocimiento' && !card.isDeleted) {
          tipology.knowledge++
        }
      })
      return tipology
    },

    counterReusabilityInsights(cards) {
      const reusability = {
        cross: 0,
        intersectoral: 0,
        sector: 0,
        occupation: 0,
      }
      cards.forEach((card) => {
        if (card.reuse_level === 'Transversal' && !card.isDeleted) {
          reusability.cross++
        }

        if (card.reuse_level === 'Intersectorial' && !card.isDeleted) {
          reusability.intersectoral++
        }

        if (card.reuse_level === 'Específica del sector' && !card.isDeleted) {
          reusability.sector++
        }

        if (
          card.reuse_level === 'Específica de la ocupación' &&
          !card.isDeleted
        ) {
          reusability.occupation++
        }
      })
      return reusability
    },
    calculatePercentage(value, total) {
      if (total === 0) return 0
      return Number(((value / total) * 100).toFixed(1))
    },
  },
}
