import { render, staticRenderFns } from "./ObjectiveSection.vue?vue&type=template&id=f2d80134&scoped=true&"
import script from "./ObjectiveSection.vue?vue&type=script&lang=js&"
export * from "./ObjectiveSection.vue?vue&type=script&lang=js&"
import style0 from "./ObjectiveSection.vue?vue&type=style&index=0&id=f2d80134&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f2d80134",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FormLabel: require('/workspace/components/Form/FormLabel.vue').default,FormInput: require('/workspace/components/Form/FormInput.vue').default})
