//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ValidationObserver } from 'vee-validate'
import ROLES from '@/constants/roles'
import { MESSAGE_TYPE } from '~/constants/core'
import ModalWithTitleBar from '~/components/Shared/ModalWithTitleBar'

export default {
  components: {
    ValidationObserver,
    ModalWithTitleBar,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    roleType: {
      type: String,
      default: ROLES.EMPLOYEE,
    },
    emailTemplate: {
      type: String,
      default: '',
    },
    userIds: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      form: {
        message: '',
      },
      confirmedModal: false,
      isLoading: false,
    }
  },
  methods: {
    clean() {
      this.form.message = ''
    },
    toggleModalResendMassive() {
      this.clean()
      this.$emit('close')
    },
    closeConfirmedModal() {
      this.confirmedModal = !this.confirmedModal
    },
    async preview() {
      const role = this.roleType
      const customMessage = this.form.message
      const companyName = this.$repository.me.getMe().company?.name
      const jobSeekerFair = this.$repository.me.getMe().job_seeker_fair?.name

      const response = await this.$repository.invitation.previewReminderMail({
        role,
        customMessage,
        companyName,
        jobSeekerFair,
        emailTemplate: this.emailTemplate,
      })
      window.open(response.url, '_blank')
    },
    async submit() {
      this.isLoading = true
      const body = this.getQueryInfo()
      try {
        if (this.$store.state.emails.resendAll) {
          await this.$repository.invitation.sendInvitationByUserIds(body)
        } else {
          await this.$repository.invitation.sendInvitationByUserIds(body)
        }
        this.confirmedModal = true
      } catch (e) {
        const message = e.response.data.map(
          (e) => `
            Ocurrió un error con la invitación ${e.id} de ${
            e.email ? e.email : ''
          }: ${e.error}
          `
        )
        await this.$store.dispatch('core/showMessage', {
          message,
          type: MESSAGE_TYPE.DANGER,
        })
      }
      this.isLoading = false
      this.clean()
      this.$emit('confirmed')
    },
    getQueryInfo() {
      const body = {}
      body.message = this.form.message
      body.users = this.userIds
      body.isReminder = true
      body.emailTemplate = this.emailTemplate
      return body
    },
  },
}
