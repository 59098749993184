//
//
//
//
//

import Login from '@/pages/login'
export default {
  components: { Login },
  head() {
    const i18nSeo = this.$nuxtI18nSeo()
    return {
      htmlAttrs: {
        lang: 'es',
        ...i18nSeo.htmlAttrs,
      },
      meta: i18nSeo.meta,
      link: i18nSeo.link,
    }
  },
}
