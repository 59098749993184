import { render, staticRenderFns } from "./RegisterFormLocalFinalStep.vue?vue&type=template&id=216657d2&scoped=true&"
import script from "./RegisterFormLocalFinalStep.vue?vue&type=script&lang=js&"
export * from "./RegisterFormLocalFinalStep.vue?vue&type=script&lang=js&"
import style0 from "./RegisterFormLocalFinalStep.vue?vue&type=style&index=0&id=216657d2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "216657d2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FormSelect: require('/workspace/components/Form/FormSelect.vue').default,FormInput: require('/workspace/components/Form/FormInput.vue').default,FormCheckBox: require('/workspace/components/Form/FormCheckBox.vue').default,Form: require('/workspace/components/Form/index.js').default})
