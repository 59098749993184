//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ORDER_OPTIONS from '~/constants/order-options'
import FilterSelect from '~/components/Shared/FilterSelect.vue'
export default {
  components: { FilterSelect },
  data: () => ({
    options: [
      {
        id: 1,
        label: 'Por defecto',
        value: null,
      },
      {
        id: 2,
        label: ORDER_OPTIONS.AZ.label,
        value: ORDER_OPTIONS.AZ.value,
      },
      {
        id: 3,
        label: ORDER_OPTIONS.ZA.label,
        value: ORDER_OPTIONS.ZA.value,
      },
    ],
  }),
  methods: {
    handleSelect(value) {
      this.$emit('select', value)
    },
  },
}
