import { render, staticRenderFns } from "./InvitationModal.vue?vue&type=template&id=6c6f4b7d&scoped=true&"
import script from "./InvitationModal.vue?vue&type=script&lang=js&"
export * from "./InvitationModal.vue?vue&type=script&lang=js&"
import style0 from "./InvitationModal.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./InvitationModal.vue?vue&type=style&index=1&id=6c6f4b7d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c6f4b7d",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FormInput: require('/workspace/components/Form/FormInput.vue').default})
