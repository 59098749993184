//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import lastEvaluateMatchMixin from '@/mixins/lastEvalutateMatchMixin'
import {
  getCurrentStatus,
  handleNext,
} from '~/util/entity/questionary/handleNext'
import { handleBack } from '~/util/entity/questionary/handleBack'
import { SUBSECTIONS, SECTIONS } from '~/constants/questionary'
import SKILL_MORPHS from '~/constants/skill-morphs'
import { showErrorNotification } from '~/util/notifications'
import ModalNextStepQuestionary from '~/components/QuestionaryProject/Modals/ModalNextStepQuestionary'
import COMPANY from '~/constants/company'
import { TYPES } from '~/constants/project-types'

export default {
  components: {
    ModalNextStepQuestionary,
  },
  mixins: [lastEvaluateMatchMixin],
  async fetch() {
    try {
      this.$store.commit('loading', true)
      const code = this.$route.query.code
      const [questionary, userSkillIds] = await Promise.all([
        this.getQuestionaryByCode(code),
        this.getUserSkillsIds(),
      ])
      this.questionary = questionary
      this.userSkillIds = userSkillIds

      this.validateSection(this.questionary.section)
      this.currentStep = this.getCurrentStep(this.questionary)
      const { statusSection, statusSubsection, skills } = this.getStatus(
        this.questionary
      )
      this.skills = skills
      this.statusSection = statusSection
      this.statusSubsection = statusSubsection

      this.texts = this.getTexts()
    } catch (error) {
      showErrorNotification(this, 'Ocurrió un error')
    } finally {
      this.$store.commit('loading', false)
    }
  },
  data() {
    return {
      currentStep: 0,
      statusSection: {
        isStart: null,
        isNotStart: null,
      },
      statusSubsection: {
        isStart: null,
        isNotStart: null,
      },
      questionary: {},
      texts: {},
      skills: [],
      userSkillIds: [],
      showNextStepQuestionaryModal: false,
    }
  },
  computed: {
    userId() {
      return this.$strapi.user.id
    },
    isCompanyTypeCollege() {
      return this.$strapi.user.company?.type === COMPANY.TYPE.COLLEGE
    },
    title() {
      return this.getTitle(
        this.texts,
        this.questionary.subsection,
        this.statusSubsection.isStart
      )
    },
    description() {
      return this.getDescription(
        this.texts,
        this.questionary.subsection,
        this.statusSubsection.isStart
      )
    },
    mainTargetOccupationId() {
      return this.questionary?.project?.main_target_occupation_id || 0
    },
    isVisibleTargetStep() {
      if (!this.questionary.project) {
        return true
      }
      return [TYPES.UPSKILLING, TYPES.RESKILLING].includes(
        this.questionary.project.type
      )
    },
  },
  methods: {
    getCurrentStep(questionary) {
      const stepKeys = {
        [SECTIONS.ORIGIN]: 0,
        [SECTIONS.TARGET]: 1,
        [SECTIONS.FINISH]: 2,
      }
      return stepKeys[questionary.section] || 0
    },
    changeToSecondStep() {
      this.changeToNextStep()
      this.togleNextStepQuestionaryModal()
    },
    togleNextStepQuestionaryModal() {
      this.showNextStepQuestionaryModal = !this.showNextStepQuestionaryModal
    },
    next() {
      this.currentStep = this.currentStep + 1
    },
    previous() {
      if (this.currentStep === 0) {
        this.$router.push(`/cuestionarios/origen?code=${this.questionary.code}`)
        return
      }
      this.currentStep = this.currentStep - 1
    },
    async onPreviousStep() {
      const {
        questionary,
        statusSection,
        statusSubsection,
        skills,
      } = handleBack(this.questionary, this.questionary.project.type)

      this.questionary = questionary
      this.statusSection = statusSection
      this.statusSubsection = statusSubsection
      this.skills = skills

      await this.updateQuestionary(this.questionary)

      if (
        this.statusSection.isEnd ||
        this.questionary.section === SECTIONS.ORIGIN_SELECT
      ) {
        this.previous()
      }
    },
    async onNextStep() {
      if (
        this.questionary.section === SECTIONS.ORIGIN &&
        this.statusSection.isEnd &&
        this.questionary.project.type !== TYPES.SELFDIAGNOSIS
      ) {
        this.togleNextStepQuestionaryModal()
      } else {
        await this.changeToNextStep()
      }
    },
    async changeToNextStep() {
      const {
        questionary,
        statusSection,
        statusSubsection,
        skills,
      } = handleNext(
        this.questionary,
        this.questionary.project.type,
        this.userSkillIds
      )

      this.questionary = questionary
      this.statusSection = statusSection
      this.statusSubsection = statusSubsection
      this.skills =
        this.questionary.section !== SECTIONS.FINISH ? skills : this.skills

      await this.updateQuestionary(this.questionary)

      if (this.statusSection.isStart) {
        this.next()
      }
      if (this.questionary.section === SECTIONS.FINISH) {
        this.evaluateMatchUser(this.questionary.user)
        this.next()
      }
    },
    async getQuestionaryByCode(code) {
      const [questionary] = await this.$repository.questionary.get({
        code,
        populate: ['project'],
      })
      if (questionary.project.deleted_at !== null) {
        showErrorNotification(this, 'El proyecto fue eliminado')
        this.$router.push('/inicio')
      }
      return questionary
    },
    getStatus(questionary) {
      const { statusSection, statusSubsection, skills } = getCurrentStatus(
        questionary,
        questionary.project.type
      )
      return {
        statusSection,
        statusSubsection,
        skills,
      }
    },
    getTexts() {
      return {
        [SUBSECTIONS.SKILLS]: this.$t(
          'questionaryProject.validateSkills.subsections.skills'
        ),
        [SUBSECTIONS.KNOWLEDGES]: this.$t(
          'questionaryProject.validateSkills.subsections.knowledges'
        ),
        [SUBSECTIONS.REUSE_COMMONS]: this.$t(
          'questionaryProject.validateSkills.subsections.reuseCommons'
        ),
        [SUBSECTIONS.REUSE_SPECIFICS]: this.$t(
          'questionaryProject.validateSkills.subsections.reuseSpecifics'
        ),
      }
    },
    getTitle(texts, subsection, isStartSubSection) {
      if (!texts || !subsection) {
        return ''
      }
      if (this.isCompanyTypeCollege) {
        return isStartSubSection
          ? texts[subsection].isStart.titleCollege
          : texts[subsection].isNotStart.title
      }
      return isStartSubSection
        ? texts[subsection].isStart.title
        : texts[subsection].isNotStart.title
    },
    getDescription(texts, subsection, isStartSubSection) {
      if (!texts || !subsection) {
        return ''
      }
      return isStartSubSection
        ? texts[subsection].isStart.description
        : texts[subsection].isNotStart.description
    },
    async updateQuestionary(questionary) {
      await this.$repository.questionary.update(questionary.id, {
        page: questionary.page,
        total_page: questionary.total_page,
        section: questionary.section,
        subsection: questionary.subsection,
        is_finish: questionary.section === SECTIONS.FINISH,
      })
    },
    validateSection(section) {
      if (SECTIONS.INSTRUCTIONS === section) {
        this.$router.push(
          `/cuestionarios/instrucciones?code=${this.questionary.code}`
        )
      }
      if (SECTIONS.ORIGIN_SELECT === section) {
        this.$router.push(`/cuestionarios/origen?code=${this.questionary.code}`)
      }
      if (section === SECTIONS.FINISH) {
        this.$router.push(`/cuestionarios?code=${this.questionary.code}`)
      }
    },
    async getUserSkillsIds() {
      const skillMorphs = await this.$repository.skillMorph.findAll({
        user: this.userId,
        has_skill: true,
        _limit: -1,
        populate: ['skill'],
      })
      return skillMorphs.map((skillMorph) => skillMorph.skill.id)
    },
    async handleChangeItem({ id, status }) {
      try {
        if (status) {
          await this.addSkill(id)
        } else {
          await this.deleteSkill(id)
        }
      } catch (error) {
        showErrorNotification(this, 'Ocurrió un error')
      }
    },
    async addSkill(id) {
      await this.$repository.skillMorph.save({
        skill: {
          id,
        },
        user: this.userId,
        related_id: this.questionary.id,
        related_type: SKILL_MORPHS.ENTITY_TYPES.QUESTIONARIES,
        origin_type: SKILL_MORPHS.ORIGIN_TYPES.SUGGEST,
      })
      this.userSkillIds.push(id)
    },
    async deleteSkill(id) {
      const skillMorphToRemove = {
        skill: id,
        user: this.userId,
        related_id: this.questionary.id,
        related_type: SKILL_MORPHS.ENTITY_TYPES.QUESTIONARIES,
      }
      await this.$repository.skillMorph.remove(skillMorphToRemove)
      this.userSkillIds = this.userSkillIds.filter((skillId) => skillId !== id)
    },
  },
}
