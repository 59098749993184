//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Question from './Question.vue'

export default {
  components: {
    Question,
  },
  props: {
    id: {
      type: String,
      default: 'section-questions',
    },
    title: {
      type: String,
      default: '',
    },
    questions: {
      type: Array,
      default: () => {},
    },
  },
}
