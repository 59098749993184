//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ListChip from '~/components/Experiences/ListChip.vue'
import SearchSkills from '~/components/Experiences/SearchSkills.vue'

export default {
  components: {
    SearchSkills,
    ListChip,
  },
  props: {
    title: {
      type: String,
      default: () => 'components.skills.freeSkills.title',
    },
    type: {
      type: String,
      default: () => '',
    },
    excludedSkills: {
      type: Array,
      default: () => [],
    },
    selectedSkills: {
      type: Array,
      default: () => [],
    },
    suggestSkills: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    data: [],
    optionsSkills: [],
    existSkills: [],
    skills: [],
    doneTypingInterval: 1500,
    typingTimer: 0,
    searchLoading: false,
  }),
  computed: {
    showSkills() {
      return this.skills.map((skill) => ({
        ...skill,
        active: this.selectedSkills.includes(skill.id),
      }))
    },
  },
  methods: {
    timeoutTyping(value) {
      clearTimeout(this.typingTimer)
      this.searchLoading = true
      this.typingTimer = setTimeout(() => {
        this.handleTyping(value)
      }, this.doneTypingInterval)
    },
    async handleTyping(value) {
      this.handleCleaning()
      const suggests = await this.$repository.skill.suggest(value)
      this.optionsSkills = suggests.filter(
        (skill) =>
          !this.selectedSkills.includes(skill.id) &&
          !this.excludedSkills.includes(skill.id) &&
          !this.skills.map((skill) => skill.id).includes(skill.id)
      )
      this.existSkills = suggests.filter((skill) =>
        [
          ...this.selectedSkills,
          ...this.skills.map((skill) => skill.id),
        ].includes(skill.id)
      )
      this.searchLoading = false
    },
    handleCleaning() {
      this.existSkills = []
      this.optionsSkills = []
    },
    handleSelect(selected) {
      if (selected) {
        this.optionsSkills = []
        this.existSkills = []
        if (!this.isSuggestedSkill(selected.id)) {
          this.skills = [...this.skills, { ...selected, active: true }]
        }
        this.$emit('change', { id: selected.id, status: true })
      }
    },
    handleChangeChip({ id, status }) {
      this.$emit('change', { id, status })
    },
    isSuggestedSkill(skillId) {
      const suggestedSkillIds = this.suggestSkills.map((skill) => skill.id)
      return suggestedSkillIds.includes(skillId)
    },
  },
}
