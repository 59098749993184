import { render, staticRenderFns } from "./UserOpportunitiesSection.vue?vue&type=template&id=287f55e1&"
import script from "./UserOpportunitiesSection.vue?vue&type=script&lang=js&"
export * from "./UserOpportunitiesSection.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DashboardSection: require('/workspace/components/Dashboard/DashboardSection.vue').default})
