//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Chip from '@/components/design-system/Chip.vue'
import Button from '@/components/Shared/Button.vue'
import OccupationChip from './OccupationChip.vue'

export default {
  name: 'CustomOccupationSuggestedSkills',
  components: { OccupationChip, Chip, Button },
  props: {
    title: {
      type: String,
      default: () => '',
    },
    suggestedOccupations: {
      type: Array,
      default: () => [],
    },
    skills: {
      type: Array,
      default: () => [],
    },
    activeSkillsIds: {
      type: Array,
      default: () => [],
    },
    externalSelected: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    defaultQtySkillsVisible: 15,
    quantitySkillVisible: 15,
  }),
  computed: {
    isEmpty() {
      return this.skills.length === 0
    },
    lastExternalSelectedSkillId() {
      return this.externalSelected.length > 0
        ? this.externalSelected.at(-1)
        : null
    },
    visibleSkills() {
      let visibleSkills = [...this.skills]

      if (this.lastExternalSelectedSkillId) {
        const externalSelectedSkills = this.skills.filter(({ id }) =>
          this.externalSelected.includes(id)
        )
        const notExternalSelectedSkills = this.skills.filter(
          ({ id }) => !this.externalSelected.includes(id)
        )
        visibleSkills = [
          ...externalSelectedSkills,
          ...notExternalSelectedSkills,
        ]
      }
      return visibleSkills.slice(0, this.quantitySkillVisible)
    },
    isExpanded() {
      return this.skills.length <= this.quantitySkillVisible
    },
    quantitySkillNotVisible() {
      return this.skills.length - this.quantitySkillVisible
    },
    suggestedSkillsUnselected() {
      const suggestedSkills = this.skills.filter(
        (skill) => !this.activeSkillsIds.includes(skill.id)
      )
      return suggestedSkills.length
    },
  },
  methods: {
    handleRemoveChip(id) {
      this.$emit('remove', id)
    },
    toggleAddModal() {
      this.$emit('showModal')
    },
    isActive(skillId) {
      return this.activeSkillsIds.includes(skillId)
    },
    handleChangeSkillStatus({ id, status }) {
      this.$emit('change', { id, status })
    },
    expand() {
      this.quantitySkillVisible = this.skills.length
    },
    shrink() {
      this.quantitySkillVisible = this.defaultQtySkillsVisible
    },
  },
}
