//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'StatsCard',
  props: {
    title: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    stat: {
      type: String,
      default: '0',
    },
    description: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
  },
}
