//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ModalWithTitleBar from '~/components/Shared/ModalWithTitleBar.vue'
import Button from '~/components/Shared/Button'
export default {
  components: {
    ModalWithTitleBar,
    Button,
  },
  props: {
    occupation: {
      type: Object,
      default: () => {},
    },
    addedSkills: {
      type: Array,
      default: () => [],
    },
    originSkills: {
      type: Array,
      default: () => [],
    },
    deletedSkills: {
      type: Array,
      default: () => [],
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    formattedAddedSkills() {
      const addedSkills = this.addedSkills.map((addedSkill) => addedSkill.title)
      return {
        total: addedSkills.length,
        skills: addedSkills.join(', '),
      }
    },

    formattedOriginSkills() {
      const originSkills = this.originSkills.map(
        (originSkill) => originSkill.title
      )
      return {
        total: originSkills.length,
        skills: originSkills.join(', '),
      }
    },

    formattedOriginDeletedSkills() {
      const deletedSkills = this.deletedSkills.map((skill) => skill.title)
      return {
        total: this.deletedSkills.length,
        skills: [...deletedSkills].join(', '),
      }
    },
  },
  methods: {
    publish() {
      this.$emit('publish')
    },

    cancel() {
      this.$emit('cancel')
    },
  },
}
