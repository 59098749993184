//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapFields } from '@/util/helpers'
import { mapGetters, mapActions } from 'vuex'
import PROJECT_CONSTANTS from '@/constants/projects'
import Finder from '@/components/Shared/Finder/Finder.vue'
import COMPANY from '@/constants/company'
import OptionOrigin from '~/components/Shared/Finder/OptionOrigin.vue'
import { ORIGIN_ENTITIES } from '~/constants/origin-entities.js'

export default {
  components: {
    OptionOrigin,
    Finder,
  },
  props: {
    type: {
      type: String,
      default: null,
    },
    defaultOccupationsMetaOptions: {
      type: Array,
      default: () => [],
    },
    origins: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      form: {
        occupation: null,
      },
    }
  },
  computed: {
    ...mapFields({
      fields: ['objective', 'objective_detail'],
      module: 'project',
      base: 'baseProject',
      mutation: 'SET_PROJECT_ATTRIBUTE',
    }),
    ...mapGetters({
      getBaseProject: 'project/getBaseProject',
    }),
    showDiagnosis() {
      return (
        this.getBaseProject.type === PROJECT_CONSTANTS.SELFDIAGNOSIS.value ||
        this.type === PROJECT_CONSTANTS.SELFDIAGNOSIS.value
      )
    },
    companyType() {
      return this.$strapi.user.company.type
    },
    isCollegeCompany() {
      return this.companyType === COMPANY.TYPE.COLLEGE
    },
    isAutodiagnosis() {
      return this.getBaseProject.type === PROJECT_CONSTANTS.SELFDIAGNOSIS.value
    },
    defaultOccupations() {
      return this.filterOccupations(this.defaultOccupationsMetaOptions)
    },
    preSelected() {
      return this.origins
        .filter(
          (origin) => origin.entity_type === ORIGIN_ENTITIES.OCCUPATION_ENTITY
        )
        .map((origin) => ({ id: origin.entity_id }))
    },
  },
  created() {
    this.form.occupation = this.getDefaultOccupation()
  },
  methods: {
    ...mapActions({
      setProjectAttribute: 'project/setProjectAttribute',
    }),

    selectOccupation(occupation) {
      this.setProjectAttribute({
        key: 'main_target_occupation_id',
        value: occupation?.id,
      })
      this.setProjectAttribute({
        key: 'occupations',
        value: occupation ? [occupation] : [],
      })
      this.form.occupation = occupation
    },
    getDefaultOccupation() {
      if (!this.getBaseProject.main_target_occupation_id) return null
      return this.getBaseProject.occupations.find(
        (occupation) =>
          occupation.id === this.getBaseProject.main_target_occupation_id
      )
    },
    async searchTarget(text) {
      const hideMapha = true
      const onlyCustomOccupations =
        this.isAutodiagnosis && this.isCollegeCompany
      const occupations = await this.$repository.project.searchTarget(
        text,
        hideMapha,
        onlyCustomOccupations
      )
      return this.filterOccupations(occupations)
    },
    filterOccupations(occupations) {
      if (this.isAutodiagnosis && this.isCollegeCompany) {
        return occupations.filter((occupation) => !!occupation.is_custom)
      }
      return occupations
    },
  },
}
