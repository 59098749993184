//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  methods: {
    handleClick() {
      this.$emit('toogle')
    },
  },
}
