//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    numberLogosDesktop: {
      type: Number,
      default: 8,
    },
    numberLogosMobile: {
      type: Number,
      default: 2,
    },
    indicator: {
      type: Boolean,
      default: true,
    },
    logos: {
      type: Array,
      default: () => {
        return [
          {
            src: '/img/logos/sence.svg',
            srcColor: '/img/logos/sence_color.svg',
            alt: 'logo sence',
          },
          {
            src: '/img/logos/subtrabajo.png',
            srcColor: '/img/logos/subtrabajo_color.png',
            alt: 'logo subtrabajo',
          },
          {
            src: '/img/logos/otic-sofofa.svg',
            srcColor: '/img/logos/otic-sofofa_color.svg',
            alt: 'logo otic-sofofa',
          },
          {
            src: '/img/logos/sofofa.svg',
            srcColor: '/img/logos/sofofa_color.svg',
            alt: 'logo sofofa',
          },
          {
            src: '/img/logos/bid.svg',
            srcColor: '/img/logos/bid_color.svg',
            alt: 'logo bid',
          },
        ]
      },
    },
  },
  data() {
    return {
      widthScreen: window.innerWidth,
    }
  },
}
