const Request = require('@/repositories/request')

class CompanyType extends Request {
  /**
   *
   * @param params
   * @returns {*}
   */
  find(params) {
    return this.$strapi['$company-types'].find(params)
  }

  async findByValuedOrCreate(value) {
    const search = await this.find({ value })
    if (search.length > 0) return search[0]

    const params = { value, name: value }
    return this.$strapi['$company-types'].create(params)
  }
}

module.exports = CompanyType
