//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CloseThick from 'vue-material-design-icons/CloseThick'
export default {
  components: {
    CloseThick,
  },
  props: {
    title: {
      default: '',
      type: String,
    },
    close: {
      default: () => {},
      type: Function,
    },
  },
}
