//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DashboardCard from '~/components/Dashboard/DashboardCard'

export default {
  components: {
    DashboardCard,
  },
  props: {
    opportunities: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    handleClickOpportunity(opportunity) {
      this.$router.push(opportunity.link)
    },
  },
}
