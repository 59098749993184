export default {
  DOCTORATE: 'Doctorado',
  MASTER: 'Máster',
  ADVANCED_TECHNICIAN: 'Técnico Superior',
  ACADEMIC: 'Universitario',
  DIPLOMAT: 'Diplomado',
  COURSE: 'Curso',
  WORKSHOP: 'Taller',
  TECHNICAL_COLLEGE: 'Técnico Medio / Colegio Técnico',
  MIDDLE_EDUCATION: 'Educación media / secundaria / bachillerato',
}
