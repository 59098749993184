import * as Sentry from '@sentry/vue'
import Vue from 'vue'

export default (ctx) => {
  Sentry.init({
    Vue,
    dsn: process.env.sentryDsn,
    integrations: [
      Sentry.browserTracingIntegration({ router: ctx.app.router }),
      Sentry.replayIntegration(),
      Sentry.browserProfilingIntegration(),
    ],
    environment: process.env.sentryEnvironment,
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/relink\.com/,
      /^https:\/\/www\.relink\.com/,
      /^https:\/\/app\.relink\.com/,
      /^https:\/\/staging\.relink\.com/,
    ],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    profilesSampleRate: 1.0,
  })
}
