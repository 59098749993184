//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SKILL_TYPES from '@/constants/skill-types'
export default {
  props: {
    card: {
      type: Object,
      required: true,
    },
    cardChipIdentifier: {
      type: String,
      default: null,
    },
    cardSelectOptions: {
      type: Array,
      default: null,
    },
    cardClickable: {
      type: Boolean,
      default: false,
    },
    cardSelectIdentifier: {
      type: String,
      default: null,
    },
    cardActions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showDropdown: false,
      selectedOption: this.card[this.cardSelectIdentifier],
    }
  },
  computed: {
    validOptionSelected() {
      return [SKILL_TYPES.MANDATORY, SKILL_TYPES.OPTIONAL].includes(
        this.selectedOption
      )
    },
  },
  mounted() {
    document.addEventListener('click', (e) => {
      if (!this.$el.contains(e.target) && this.showDropdown) {
        this.showDropdown = false
      }
    })
  },
  beforeDestroy() {
    document.removeEventListener('click', this.close)
  },
  methods: {
    getCardValue(key) {
      return key ? this.card[key] : ''
    },
    updateSelectedOption() {
      this.$emit('update-select', {
        ...this.card,
        skillType: this.selectedOption,
      })
    },
    toggleDropdown() {
      this.showDropdown = !this.showDropdown
    },
    handleClickOnCard(event) {
      if (
        !(
          event.target.closest('.card-select') ||
          event.target.closest('.card-menu') ||
          event.target.closest('.card-menu-container')
        ) &&
        this.cardClickable
      ) {
        this.$emit('card-click', this.card)
      }
    },
    deleteCard() {
      this.$emit('delete', this.card)
      this.toggleDropdown()
    },
    restoreCard() {
      this.$emit('restore', this.card)
      this.toggleDropdown()
    },
  },
}
