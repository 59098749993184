//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CustomCheckBoxItem from '@/components/Shared/CustomCheckBox/CustomCheckBoxItem.vue'
export default {
  components: {
    CustomCheckBoxItem,
  },
  props: {
    dataGroup: {
      type: Array,
      default: () => [],
    },
    fieldCheck: {
      type: String,
      default: () => {
        return 'name'
      },
    },
    valueCheck: {
      type: String,
      default: () => {
        return 'active'
      },
    },
    multipleCheck: {
      type: Boolean,
      default: () => {
        return false
      },
    },
    tooltipKey: {
      type: String,
      default: () => {
        return 'description'
      },
    },
    showTooltip: {
      type: Boolean,
      default: () => {
        return false
      },
    },
  },
  computed: {
    elementsChecked: {
      get() {
        return this.dataGroup
          .filter((item) => item[this.valueCheck])
          .map((item) => item[this.fieldCheck])
      },
      set(value) {
        this.itemsChecked = value
      },
    },
  },
  methods: {
    updateCheck(event) {
      if (this.multipleCheck) {
        this.updateMultipleCheck(event)
      } else {
        this.updateSingleCheck(event)
      }
      this.$emit('checked', this.getDataToEmit(event))
    },
    updateSingleCheck(event) {
      if (event.checked) {
        this.elementsChecked = [event.value]
      } else {
        this.elementsChecked = []
      }
    },
    updateMultipleCheck(event) {
      if (event.checked) {
        this.elementsChecked = [...this.elementsChecked, event.value]
      } else {
        this.elementsChecked = this.elementsChecked.filter(
          (item) => item !== event.value
        )
      }
    },
    getDataToEmit(event) {
      const dataGroupChecked = this.dataGroup.find(
        (item) => item[this.fieldCheck] === event.value
      )
      return {
        item: dataGroupChecked,
        status: event.checked,
      }
    },
  },
}
