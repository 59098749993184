import { render, staticRenderFns } from "./HomePagePilotUnemployed.vue?vue&type=template&id=f2d84f88&scoped=true&"
import script from "./HomePagePilotUnemployed.vue?vue&type=script&lang=js&"
export * from "./HomePagePilotUnemployed.vue?vue&type=script&lang=js&"
import style0 from "./HomePagePilotUnemployed.vue?vue&type=style&index=0&id=f2d84f88&lang=scss&scoped=true&"
import style1 from "./HomePagePilotUnemployed.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f2d84f88",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HomePageCardJobSeekerFair: require('/workspace/components/HomePage/HomePageCardJobSeekerFair.vue').default})
