//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// TODO: REFACTOR, separar en 3 componentes distintos, uno por cada tipo de experiencia
import { matchStringIgnoreCase } from '@/util/string'
import Tag from '@/components/Shared/Tag'
import {
  EXPERIENCE_WORK,
  EXPERIENCE_EDUCATION,
  EXPERIENCE_OTHER,
} from '@/util/const/experience.const'

import EXPERIENCE_STATUS from '~/constants/experience-status'
import FocusSelect from '~/components/Shared/FocusSelect'

const TYPES = []
TYPES[EXPERIENCE_STATUS.COMPLETED] = 'is-success'
TYPES[EXPERIENCE_STATUS.INCOMPLETED] = 'is-danger'

const MESSAGES = []
MESSAGES[EXPERIENCE_STATUS.COMPLETED] = 'experience.messages.status.completed'
MESSAGES[EXPERIENCE_STATUS.INCOMPLETED] =
  'experience.messages.status.uncompleted'

export default {
  components: {
    FocusSelect,
    Tag,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    descriptionField: {
      type: String,
      required: true,
    },
    organizationField: {
      type: String,
      default: '',
    },
    alternativeField: {
      type: String,
      default: '',
    },
    basicEducationField: {
      type: String,
      default: '',
    },
    studyNameField: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      STATUS_OPTIONS: [
        {
          type: 'completed',
          label: this.translateStatus(true),
        },
        { type: 'incomplete', label: this.translateStatus(false) },
      ],
      query: '',
      statusQuery: null,
      isSelectFocused: false,
      types: {
        WORK: EXPERIENCE_WORK,
        EDUCATION: EXPERIENCE_EDUCATION,
        OTHER: EXPERIENCE_OTHER,
      },
    }
  },
  computed: {
    filteredData() {
      return this.data.filter((exp) => {
        return this.filterDescription(exp) && this.filterStatus(exp)
      })
    },
    organizationTag() {
      if (this.type === this.types.EDUCATION) {
        return this.$t('experience.educational.form.educationalType')
      } else if (this.type === this.types.WORK) {
        return this.$t('experience.table.fields.organization')
      } else {
        return this.$t('experience.table.fields.someField')
      }
    },
  },
  methods: {
    showDescriptionField(value) {
      if (typeof value === 'string') return value
      return (value || {}).title
    },
    mapStatus(status) {
      return status ? this.STATUS_OPTIONS[0] : this.STATUS_OPTIONS[1]
    },
    filterDescription(exp) {
      const description =
        this.type === this.types.OTHER
          ? exp[this.descriptionField] ?? ''
          : exp[this.descriptionField]?.title ?? ''
      const studyName = exp[this.alternativeField] ?? ''
      const organization = this.getOrganizationName(exp) ?? ''
      return (
        matchStringIgnoreCase(description, this.query) ||
        matchStringIgnoreCase(organization, this.query) ||
        matchStringIgnoreCase(studyName, this.query)
      )
    },
    filterStatus(exp) {
      return (
        !this.statusQuery ||
        this.mapStatus(exp.completed).type === this.statusQuery
      )
    },
    getOrganizationName(row) {
      const name = row[this.organizationField]
      return typeof name === 'string' ? name : name?.name
    },
    onClick(event) {
      this.$emit('click', event)
    },
    translateStatus(isCompleted, status) {
      if (!status) {
        return isCompleted
          ? this.$t('experience.messages.status.completed')
          : this.$t('experience.messages.status.uncompleted')
      }
      return this.$t(MESSAGES[status])
    },
    typeTag(isCompleted, status) {
      if (!status) {
        return isCompleted ? 'is-success' : 'is-danger'
      }
      return TYPES[status]
    },
  },
}
