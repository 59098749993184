//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import InitialState from '@/components/CustomOccupation/NewCustomOccupationSteps/InitialState.vue'
import SearchFilters from '@/components/CustomOccupation/SearchFilters.vue'
import ListviewOccupations from '@/components/CustomOccupation/ListviewOccupations.vue'

export default {
  components: {
    InitialState,
    SearchFilters,
    ListviewOccupations,
  },
  props: {
    defaultOccupation: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    buttonDisabled: true,
    isInitialState: true,
    occupation: {},
    occupations: [],
    currentPage: 0,
    occupationSkills: {},
  }),
  methods: {
    async filterByTerm(filters) {
      this.buttonDisabled = true
      if (filters.term.length === 0) {
        this.isInitialState = true
      }
      if (filters.term.length > 3) {
        this.$store.commit('loading', true)
        this.occupations = await this.$repository.occupation.searchForCustomOccupations(
          {
            term: filters.term,
            custom_occupations: true,
            origin: filters.origin,
          }
        )
        this.isInitialState = false
        this.currentPage = 1
        this.$store.commit('loading', false)
      }
    },
    onOccupationChecked(checkedList) {
      if (checkedList.length > 0) {
        this.buttonDisabled = false
        this.occupation = {
          title: '',
          description: checkedList[0].description,
          skills: [],
          base_occupation: checkedList[0],
        }
      } else {
        this.buttonDisabled = true
        this.occupation = {}
      }
    },
    onChangePage(page) {
      this.currentPage = page
    },
    onHover(occupationSkills) {
      this.occupationSkills = occupationSkills
    },
    handleNext() {
      this.occupation.skills = this.occupationSkills[
        this.occupation.base_occupation.id
      ]
      this.$emit('next', { ...this.defaultOccupation, ...this.occupation })
    },
  },
}
