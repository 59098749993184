import FormInput from './FormInput.vue'
import FormSelect from './FormSelect.vue'
import FormCheckBox from './FormCheckBox.vue'
import ValidWatcher from './ValidWatcher.vue'
import FormInputDate from './FormInputDate.vue'
import FormDatePicker from './FormDatePicker.vue'
export {
  FormInput,
  FormSelect,
  FormCheckBox,
  ValidWatcher,
  FormInputDate,
  FormDatePicker,
}
