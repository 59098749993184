//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ValidationObserver } from 'vee-validate'
import NewProjectsWizardButtons from '@/components/Projects/NewProjectWizardButtons.vue'
import { mapGetters } from 'vuex'
import PROJECT_CONSTANTS from '@/constants/projects'
import TypeSection from './TypeSection.vue'
import ProfileSection from './ProfileSection.vue'
import OriginSection from './OriginSection.vue'

export default {
  name: 'StepOne',
  components: {
    ValidationObserver,
    NewProjectsWizardButtons,
    TypeSection,
    ProfileSection,
    OriginSection,
  },
  props: {
    permissions: {
      type: Object,
      required: true,
    },
  },
  async fetch() {
    this.$store.commit('loading', true)
    const hideMapha = true
    const [defaultOriginOptions, defaultOccupationOptions] = await Promise.all([
      this.searchOrigins('', hideMapha),
      this.$repository.project.searchTarget('', hideMapha),
    ])
    this.defaultOriginOptions = defaultOriginOptions
    this.defaultOccupationOptions = defaultOccupationOptions
    this.$store.commit('loading', false)
  },
  data() {
    return {
      showProjectOccupation: null,
      defaultOccupationOptions: [],
      defaultOriginOptions: [],
      isOriginsEditMode: false,
      origins: [],
    }
  },
  computed: {
    ...mapGetters({
      getBaseProject: 'project/getBaseProject',
    }),
    showOccupation() {
      return (
        (this.getBaseProject.type &&
          this.getBaseProject.type !== PROJECT_CONSTANTS.MODELS.value) ||
        (this.showProjectOccupation &&
          this.showProjectOccupation !== PROJECT_CONSTANTS.MODELS.value)
      )
    },
    showRoles() {
      return (
        this.getBaseProject.type !== PROJECT_CONSTANTS.SELFDIAGNOSIS.value ||
        this.showProjectOccupation !== PROJECT_CONSTANTS.SELFDIAGNOSIS.value
      )
    },
    isVisibleOriginSection() {
      return (
        this.getBaseProject.type &&
        ![
          PROJECT_CONSTANTS.SELFDIAGNOSIS.value,
          PROJECT_CONSTANTS.MODELS.value,
        ].includes(this.getBaseProject.type)
      )
    },
    companyId() {
      return this.$strapi.user.company.id
    },
    companyType() {
      return this.$strapi.user.company.type
    },
    invalid() {
      const {
        type,
        main_target_occupation_id: occupationId,
      } = this.getBaseProject
      return (
        (!type || !occupationId || this.isOriginsEditMode) &&
        type !== PROJECT_CONSTANTS.MODELS.value
      )
    },
  },
  methods: {
    handleBack() {
      this.$emit('back')
    },
    handleContinue() {
      this.$emit('next')
    },
    setProjectOccupation(type) {
      this.showProjectOccupation = type
    },
    async searchOrigins(text) {
      return await this.$repository.project.searchOrigins({
        text,
        companyId: this.companyId,
        companyType: this.companyType,
        hideMapha: true,
      })
    },
    handleOriginsEditMode(value) {
      this.isOriginsEditMode = value
    },
    handleOrigins(origins) {
      this.origins = origins
    },
  },
}
