//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Button from '~/components/Shared/Button.vue'

export default {
  components: {
    Button,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    image: {
      type: Object,
      default: () => ({
        src: '',
        alt: '',
      }),
    },
    isInverted: {
      type: Boolean,
      default: false,
    },
    cta: {
      type: Object,
      default: () => ({
        text: 'action',
        link: '/',
      }),
    },
  },
}
