//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    title: {
      type: String,
      default: 'Title Card',
    },
    url: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '/img/icons/calendar.svg',
    },
    value: {
      type: String,
      default: '...',
    },
    description: {
      type: String,
      default: 'Description card',
    },
  },
}
