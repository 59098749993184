//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import PROJECT_CONSTANTS from '@/constants/projects'
import CardSelect from '@/components/Shared/CardSelectTwo.vue'

export default {
  name: 'TypeStepSection',
  components: { CardSelect },
  props: {
    permissions: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      options: [
        {
          value: PROJECT_CONSTANTS.RESKILLING.value,
          title: this.$t(
            'projects.new.steps.configuration.sections.type.reskilling'
          ),
          description: this.$t(
            'projects.new.steps.configuration.sections.type.detail_reskilling'
          ),
          descriptionTooltip: this.$t(
            'projects.new.steps.configuration.sections.type.tooltip_reskilling'
          ),
          iconPath: '/img/icons/reskilling.svg',
        },
        {
          value: PROJECT_CONSTANTS.UPSKILLING.value,
          title: this.$t(
            'projects.new.steps.configuration.sections.type.upskilling'
          ),
          description: this.$t(
            'projects.new.steps.configuration.sections.type.detail_upskilling'
          ),
          descriptionTooltip: this.$t(
            'projects.new.steps.configuration.sections.type.tooltip_upskilling'
          ),
          iconPath: '/img/icons/upskilling.svg',
        },
        {
          value: PROJECT_CONSTANTS.SELFDIAGNOSIS.value,
          title: this.$t(
            'projects.new.steps.configuration.sections.type.selfdiagnosis'
          ),
          description: this.$t(
            'projects.new.steps.configuration.sections.type.detail_selfdiagnosis'
          ),
          iconPath: '/img/icons/selfdiagnosis.svg',
        },
        {
          value: PROJECT_CONSTANTS.MODELS.value,
          title: this.$t(
            'projects.new.steps.configuration.sections.type.models'
          ),
          description: this.$t(
            'projects.new.steps.configuration.sections.type.detail_models'
          ),
          iconPath: '/img/icons/models.svg',
          descriptionTooltip: this.$t(
            'projects.new.steps.configuration.sections.type.tooltip_models'
          ),
        },
      ],
    }
  },
  computed: {
    validServices() {
      return this.skillsInventory || this.matchPotential
    },
    ...mapGetters({
      getBaseProject: 'project/getBaseProject',
    }),
  },
  methods: {
    handleSelect(type) {
      this.$emit('update-type', type)
    },
    isActive(type) {
      return this.getBaseProject.type === type
    },
    isDisabled(type) {
      return !{
        [PROJECT_CONSTANTS.MODELS.value]: this.permissions?.kitOfSkillsEnabled,
        [PROJECT_CONSTANTS.RESKILLING.value]: this.permissions
          ?.reskillingEnabled,
        [PROJECT_CONSTANTS.UPSKILLING.value]: this.permissions
          ?.upskillingEnabled,
        [PROJECT_CONSTANTS.SELFDIAGNOSIS.value]: this.permissions
          ?.selfDiagnosisEnabled,
      }[type]
    },
  },
}
