import { render, staticRenderFns } from "./SkillsInventoryCharts.vue?vue&type=template&id=300ba4c2&scoped=true&"
import script from "./SkillsInventoryCharts.vue?vue&type=script&lang=js&"
export * from "./SkillsInventoryCharts.vue?vue&type=script&lang=js&"
import style0 from "./SkillsInventoryCharts.vue?vue&type=style&index=0&id=300ba4c2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "300ba4c2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SkillsInventoryChart: require('/workspace/components/SkillsInventory/Charts/SkillsInventoryChart.vue').default})
