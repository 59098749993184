//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'GraphicCard',
  components: {
    BarChart: () => import('./Graphics/BarChart.vue'),
    PieChart: () => import('./Graphics/PieChart.vue'),
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    /**
     * Follow Echart bar and pie options samples
     */
    option: {
      type: Object,
      default: () => ({}),
    },
    /**
     * Tooltip label
     */
    label: {
      type: String,
      default: '',
    },
    tooltipType: {
      type: String,
      default: 'is-primary',
    },
    chartType: {
      type: String,
      default: 'bar',
    },
    chartId: {
      type: String,
      default: 'chart',
    },
    chartHeight: {
      type: String,
      default: '400px',
    },
  },
  data() {
    return {
      myChart: null,
    }
  },
  computed: {
    chartComponent() {
      return this.chartType === 'pie' ? 'PieChart' : 'BarChart'
    },
  },
}
