//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ROLES from '@/constants/roles'
import QuizProgressList from '@/components/Projects/Detail/Tabs/QuizProgressList.vue'
import Button from '@/components/Shared/Button'
import FilterFormGroup from '@/components/SkillsInventory/Filters/FilterFormGroup'
import FilteredTag from '@/components/SkillsInventory/FilteredTag'
import { mapActions, mapState } from 'vuex'
import { SECTIONS } from '~/constants/questionary'

export default {
  name: 'QuizProgressTab',
  components: {
    QuizProgressList,
    Button,
    FilterFormGroup,
    FilteredTag,
  },
  props: {
    project: {
      type: Object,
      default: () => ({}),
    },
  },
  async fetch() {
    await this.getEmployeesInProject()
  },
  data: () => ({
    table: {
      filters: {
        _sort: 'created_at:DESC',
        populate: ['user.role'],
        _where: {},
        _limit: 10,
        _start: 0,
      },
      filtersPending: {
        _where: { active: true },
      },
      data: [],
      total: 0,
      totalPending: 0,
    },
    showFilters: false,
    filters: {
      names: '',
      currentQuestionary: [],
      modelQuestionary: [],
    },
    selectedFilters: {
      currentQuestionary: [],
      modelQuestionary: [],
    },
    options: {
      status: [
        {
          label: 'Aceptada',
          key: false,
        },
        {
          label: 'Pendiente',
          key: true,
        },
      ],
    },
  }),
  computed: {
    ...mapState({
      employeesInProject: (state) => state.project.employeesInProject,
    }),
    defaultFilter() {
      return {
        'user.role.type': ROLES.EMPLOYEE,
        'user.company': this.$store.state.me.user.company?.id,
      }
    },
    showFiltersTag() {
      return (
        this.selectedFilters.currentQuestionary.length > 0 ||
        this.selectedFilters.modelQuestionary.length > 0
      )
    },
    existsModelQuestionary() {
      return !!this.project.has_models
    },
    existsActiveQuestionary() {
      return !!this.project.main_target_occupation_id
    },
    calculateDataWithFilters() {
      const data = this.employeesInProject.filter((item) => {
        item.disabled = this.isButtonDisabled(item)
        let toAdd = true
        if (this.filters.names !== '') {
          const name = item.names.toLowerCase()
          const lastName = item.lastnames.toLowerCase()
          const username = item.username.toLowerCase()
          const filter = this.filters.names.toLowerCase()
          if (
            !name.includes(filter) &&
            !lastName.includes(filter) &&
            !username.includes(filter)
          ) {
            toAdd = false
          }
        }
        if (this.selectedFilters.currentQuestionary.length > 0) {
          const completeness = item.currentQuestionary?.section

          // Iterate over each currentQuestionary filter
          this.selectedFilters.currentQuestionary.forEach((progressFilter) => {
            if (!progressFilter.value && completeness === SECTIONS.FINISH) {
              toAdd = false
            } else if (
              progressFilter.value &&
              completeness !== SECTIONS.FINISH
            ) {
              toAdd = false
            }
          })
        }
        if (this.selectedFilters.modelQuestionary.length > 0) {
          const completeness = item.modelQuestionary?.section

          // Iterate over each modelQuestionary filter
          this.selectedFilters.modelQuestionary.forEach((progressFilter) => {
            if (!progressFilter.value && completeness === SECTIONS.FINISH) {
              toAdd = false
            } else if (
              progressFilter.value &&
              completeness !== SECTIONS.FINISH
            ) {
              toAdd = false
            }
          })
        }
        return toAdd
      })

      const total = data.length
      const totalPending = data.filter(
        (employee) =>
          (employee.currentQuestionary?.section !== SECTIONS.FINISH ||
            employee.modelQuestionary?.section !== SECTIONS.FINISH) &&
          !employee.disabled
      ).length

      return { data, total, totalPending }
    },
  },
  created() {
    this.table.filters._where = this.defaultFilter
    this.table.filtersPending._where = {
      ...this.table.filtersPending._where,
      ...this.defaultFilter,
    }
  },
  methods: {
    ...mapActions({
      getEmployeesInProjectAction: 'project/getEmployeesInProject',
    }),
    onSearch() {
      this.selectedFilters.currentQuestionary = this.filters.currentQuestionary
      this.selectedFilters.modelQuestionary = this.filters.modelQuestionary
      this.onShowFilters()
    },
    onShowFilters() {
      this.showFilters = !this.showFilters
    },
    onCloseCurrentQuestionaryTag() {
      this.filters.currentQuestionary = []
      this.selectedFilters.currentQuestionary = []
    },
    onCloseModelQuestionaryTag() {
      this.filters.modelQuestionary = []
      this.selectedFilters.modelQuestionary = []
    },
    async getEmployeesInProject() {
      this.$store.commit('loading', true)
      await this.getEmployeesInProjectAction(this.$route.params.id)
      this.$store.commit('loading', false)
    },
    async onPageChange(page) {
      this.table.filters._start =
        this.table.filters._limit * page - this.table.filters._limit
      await this.getEmployeesInProject()
    },
    async updateAfterDelete() {
      await this.getEmployeesInProject()
    },
    isButtonDisabled(row) {
      if (
        this.existsActiveQuestionary &&
        (!row.currentQuestionary ||
          row.currentQuestionary.section !== SECTIONS.FINISH)
      ) {
        return false
      }
      if (
        this.existsModelQuestionary &&
        (!row.modelQuestionary ||
          row.modelQuestionary.section !== SECTIONS.FINISH)
      ) {
        return false
      }
      return true
    },
  },
}
