//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Button from '~/components/Shared/Button'
export default {
  components: {
    Button,
  },
  props: {
    companies: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    text() {
      const formatter = new Intl.ListFormat('es', {
        style: 'long',
        type: 'conjunction',
      })
      return this.$t('customOccupationDetail.detail.modalConfirmPublish.text', [
        formatter.format(this.companies.map(({ name }) => name)),
      ])
    },
  },
  methods: {
    finalize() {
      this.$emit('finalize')
    },

    close() {
      this.$emit('close')
    },
  },
}
