//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AsideMenuList from '@/components/AsideMenuList'

export default {
  name: 'AsideMenu',
  components: { AsideMenuList },
  props: {
    menu: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    menuClick(item) {},
  },
}
