//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ModalWithTitleBar from '@/components/Shared/ModalWithTitleBar'
import Button from '@/components/Shared/Button'
import { ValidationObserver } from 'vee-validate'
import PROJECT_CONSTANTS from '@/constants/projects'
import FormAutocomplete from '~/components/Form/FormAutocomplete.vue'
import FormSelect from '~/components/Form/FormSelect.vue'
import {
  showSuccessNotification,
  showErrorNotification,
} from '~/util/notifications'

export default {
  components: {
    FormSelect,
    ModalWithTitleBar,
    Button,
    ValidationObserver,
    FormAutocomplete,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    metaOccupations: {
      type: Array,
      default: () => [],
    },
    projects: {
      type: Array,
      default: () => [],
    },
    occupationSearchLoading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    selectedOccupation: null,
    selectedProject: null,
  }),
  computed: {
    activeProjects() {
      return this.projects.filter(
        (project) => project.status === PROJECT_CONSTANTS.ACTIVE.value
      )
    },
  },
  methods: {
    handleClose() {
      this.$emit('close')
    },
    clean() {
      this.selectedOccupation = null
      this.selectedProject = null
    },
    async handleSave() {
      try {
        const currentOccupationIds = this.selectedProject.occupations.map(
          ({ id }) => id
        )
        if (!currentOccupationIds.includes(this.selectedOccupation.id)) {
          this.$store.commit('loading', true)
          await this.saveProjectWithNewOccupation()
          showSuccessNotification(
            this,
            this.$t(
              'companies.requiredOccupations.createRequiredOccupation.messages.success'
            )
          )
        } else {
          showSuccessNotification(
            this,
            this.$t(
              'companies.requiredOccupations.createRequiredOccupation.messages.registered'
            )
          )
        }
      } catch (error) {
        showErrorNotification(
          this,
          this.$t(
            'companies.requiredOccupations.createRequiredOccupation.messages.error'
          )
        )
      } finally {
        this.$store.commit('loading', false)
        this.$router.push(
          `/mis-proyectos/${this.selectedProject.id}/ocupaciones/${this.selectedOccupation.id}`
        )
        this.clean()
        this.handleClose()
      }
    },
    async saveProjectWithNewOccupation() {
      const occupations = [
        ...new Set([
          ...this.selectedProject.occupations.map(({ id }) => id),
          this.selectedOccupation.id,
        ]),
      ]
      await this.$repository.project.update(this.selectedProject.id, {
        occupations,
      })
    },
  },
}
