//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    question: { type: String, default: '' },
    answers: { type: Array, default: () => {} },
  },
}
