//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Button from '@/components/Shared/Button'
import PROJECT from '@/constants/projects'
export default {
  components: {
    Button,
  },
  data: () => ({
    text: '',
    status: null,
  }),
  computed: {
    statusOptions() {
      return [
        {
          value: PROJECT.DRAFT.value,
          text: PROJECT.DRAFT.name,
        },
        {
          value: PROJECT.ACTIVE.value,
          text: PROJECT.ACTIVE.name,
        },
        {
          value: PROJECT.FINISHED.value,
          text: PROJECT.FINISHED.name,
        },
      ]
    },
  },
  methods: {
    handleInputText(value) {
      this.$emit('change-text', value)
    },
    handleSelectStatus(selectOption) {
      const value = selectOption === null ? null : selectOption.value
      this.$emit('change-status', value)
    },
    handleClickCreateProjectButton() {
      this.$router.push('/mis-proyectos/nuevo')
    },
    resetText() {
      this.text = ''
    },
  },
}
