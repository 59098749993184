var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ValidationObserver',{ref:"observer",staticClass:"form-experience",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c('div',{staticClass:"step-container"},[_c('div',{staticClass:"form-container"},[_c('h4',{staticClass:"title is-4"},[_vm._v("\n          "+_vm._s(_vm.$t('experience.others.form.header'))+"\n        ")]),_vm._v(" "),_c('ValidationProvider',{staticClass:"form-input",attrs:{"rules":"required|min:4","name":_vm.$t('experience.others.form.detail'),"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("\n              "+_vm._s(_vm.$t('experience.others.form.detail'))),_c('b-tooltip',{attrs:{"multilined":"","type":"is-light","label":_vm.$t('experience.register.others.form.tooltipDescription')}},[_c('b-icon',{attrs:{"icon":"information-outline","size":"is-small"}}),_vm._v(":\n              ")],1)]},proxy:true}],null,true)},[_vm._v(" "),_c('b-input',{attrs:{"placeholder":_vm.$t('experience.others.form.placeholder.detail')},model:{value:(_vm.experience.detail_experience),callback:function ($$v) {_vm.$set(_vm.experience, "detail_experience", $$v)},expression:"experience.detail_experience"}})],1)]}}],null,true)}),_vm._v(" "),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('FormDatePicker',{attrs:{"rules":"required","label":_vm.$t('experience.others.form.startDate'),"locale":"es","max-date":new Date(),"placeholder":"Ej. 20/02/1990","icon":"calendar-today","trap-focus":"","editable":""},model:{value:(_vm.experience.start_date_experience),callback:function ($$v) {_vm.$set(_vm.experience, "start_date_experience", $$v)},expression:"experience.start_date_experience"}})],1),_vm._v(" "),_c('div',{staticClass:"column"},[_c('FormDatePicker',{attrs:{"rules":_vm.experience.present ? '' : 'required',"label":_vm.$t('experience.others.form.endDate'),"locale":"es","placeholder":"Ej. 20/02/1990","icon":"calendar-today","trap-focus":"","disabled":_vm.experience.present,"min-date":_vm.experience.start_date_experience == ''
                  ? new Date()
                  : _vm.experience.start_date_experience,"editable":""},on:{"input":_vm.changeEndDateExperience},model:{value:(_vm.experience.end_date_experience),callback:function ($$v) {_vm.$set(_vm.experience, "end_date_experience", $$v)},expression:"experience.end_date_experience"}})],1),_vm._v(" "),_c('div',{staticClass:"column column--centered"},[_c('div',{staticClass:"field"},[_c('b-checkbox',{model:{value:(_vm.experience.present),callback:function ($$v) {_vm.$set(_vm.experience, "present", $$v)},expression:"experience.present"}},[_vm._v("\n                "+_vm._s(_vm.$t('experience.laboral.form.present'))+"\n              ")])],1)])])],1),_vm._v(" "),_c('NavigationWizardExperience',{attrs:{"prev-button-text":_vm.prevText,"prev-button-enabled":true,"next-button-text":_vm.nextText,"next-button-enabled":valid},on:{"next":_vm.saveChanges,"previous":_vm.goBack}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }