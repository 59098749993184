import { render, staticRenderFns } from "./HomePageMain.vue?vue&type=template&id=39ff1bac&scoped=true&"
import script from "./HomePageMain.vue?vue&type=script&lang=js&"
export * from "./HomePageMain.vue?vue&type=script&lang=js&"
import style0 from "./HomePageMain.vue?vue&type=style&index=0&id=39ff1bac&lang=scss&scoped=true&"
import style1 from "./HomePageMain.vue?vue&type=style&index=1&id=39ff1bac&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39ff1bac",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HomePageMainItem: require('/workspace/components/HomePage/HomePageMainItem.vue').default})
