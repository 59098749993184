//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import KanbanBoardConstants from '@/constants/kanban-board'
export default {
  props: {
    title: {
      type: String,
      default: null,
    },
    bar: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    formattedColor(color, type) {
      if (color === KanbanBoardConstants.insightsBarColor.red) {
        return '#f11e64'
      }

      if (color === KanbanBoardConstants.insightsBarColor.green) {
        return '#2cd5c4'
      }

      if (
        color === KanbanBoardConstants.insightsBarColor.bone &&
        type === 'bar'
      ) {
        return '#dbdbdb'
      }

      if (
        color === KanbanBoardConstants.insightsBarColor.bone &&
        type === 'text'
      ) {
        return '#363636'
      }

      if (color === KanbanBoardConstants.insightsBarColor.blue) {
        return '#3273dc'
      }
    },
  },
}
