//
//
//
//

export default {
  props: {
    data: {
      type: Array,
      default: () => [
        { value: 1048, name: 'A' },
        { value: 735, name: 'B' },
        { value: 580, name: 'C' },
      ],
    },
    title: {
      type: String,
      default: 'Ocupaciones Top',
    },
    colors: { type: Array, default: () => [] },
  },
  data() {
    return {
      chart: null,
    }
  },
  computed: {
    option() {
      return {
        tooltip: {
          trigger: 'item',
        },
        series: [
          {
            name: this.title,
            type: 'pie',
            radius: ['55%', '75%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center',
            },
            emphasis: {
              label: {
                show: false,
              },
            },
            labelLine: {
              show: false,
            },
            color: this.colors,
            data: this.data,
          },
        ],
      }
    },
  },
  watch: {
    data(val) {
      this.chart.clear()
      this.chart.setOption(this.option)
    },
  },
  mounted() {
    this.initChart()
  },
  methods: {
    initChart() {
      const chart = this.$echarts.init(this.$refs.chartPie)
      chart.setOption(this.option)
      this.chart = chart
    },
  },
}
