//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ChevronLeft from 'vue-material-design-icons/ChevronLeft'
import ChevronRight from 'vue-material-design-icons/ChevronRight'

export default {
  components: {
    ChevronRight,
    ChevronLeft,
  },
  props: {
    back: {
      type: Function,
      default: () => {},
    },
    forth: {
      type: Function,
      default: () => {},
    },
    applySelected: {
      type: Boolean,
      default: false,
    },
    doNotApplySelected: {
      type: Boolean,
      default: false,
    },
    apply: {
      type: Function,
      default: () => {},
    },
    doNotApply: {
      type: Function,
      default: () => {},
    },
    backDisabled: {
      type: Boolean,
      default: false,
    },
    forthDisabled: {
      type: Boolean,
      default: false,
    },
    title: {
      default: '',
      type: String,
    },
    description: {
      default: '',
      type: String,
    },
  },
  mounted() {
    window.addEventListener('keypress', this.handleKeyPress)
  },
  beforeDestroy() {
    window.removeEventListener('keypress', this.handleKeyPress)
  },
  methods: {
    handleKeyPress(event) {
      if (event.key.toLowerCase() === 'y') {
        this.apply()
      }
      if (event.key.toLowerCase() === 'n') {
        this.doNotApply()
      }
    },
  },
}
