//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Button from '~/components/Shared/Button.vue'
export default {
  components: {
    Button,
  },
  props: {
    prevButtonText: {
      type: String,
      default: 'Anterior',
    },
    prevButtonEnabled: {
      type: Boolean,
      default: true,
    },
    nextButtonText: {
      type: String,
      default: 'Siguiente',
    },
    nextButtonEnabled: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    nextAction() {
      this.$emit('next')
    },
    prevAction() {
      this.$emit('previous')
    },
  },
}
