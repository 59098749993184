import moment from 'moment'
import { extend, configure } from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
import { rutValidator } from 'vue-dni'

const subtractYears = (date, years) => {
  return moment(date).subtract(years, 'years')._d
}
const MIN_AGE_PERMITED = 18
const minimunDate = subtractYears(new Date(), MIN_AGE_PERMITED)

Object.keys(rules).forEach((rule) => {
  // eslint-disable-next-line import/namespace
  extend(rule, rules[rule])
})
extend('checkRut', (indentificationNumber) => {
  if (rutValidator(indentificationNumber)) {
    return true
  }
  return 'El RUT no es válido'
})

extend('checkTrue', (booleanValue) => {
  if (booleanValue === true) {
    return true
  }
  return ''
})

extend('checkDate', (date) => {
  const formattedDate = moment(date, 'DD/MM/YYYY')
  if (date.length === 10 && formattedDate._isValid) {
    return true
  }
  return 'la fecha no es válida'
})

extend('maxDateToday', (date) => {
  const formattedDate = moment(date, 'DD/MM/YYYY')
  if (formattedDate._isValid && formattedDate._d <= new Date()) {
    return true
  }
  return 'la fecha no es válida'
})

extend('verifyAge', (date) => {
  const formattedDate = moment(date, 'DD/MM/YYYY')
  if (formattedDate._isValid && formattedDate._d <= minimunDate) {
    return true
  }
  return `Debes ser mayor de ${MIN_AGE_PERMITED} años`
})

extend('verifyPhone', (phoneNumber) => {
  const phoneInternationalRegExp =
    '[+](9[976]\\d|8[987530]\\d|6[987]\\d|5[90]\\d|42\\d|3[875]\\d|2[98654321]\\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\\d{1,14}$'

  if (!phoneNumber.match(phoneInternationalRegExp)) {
    return 'El número de teléfono no es válido. (Formato ej. +56999999999, se debe incluir +.)'
  }
  if (phoneNumber.match(phoneInternationalRegExp)[0] !== phoneNumber) {
    return 'El número de teléfono no es válido. (Formato ej. +56999999999, se debe incluir +.)'
  }
  if (phoneNumber.substring(0, 3) !== '+56') {
    return 'El número de teléfono no es válido. (Formato ej. +56999999999, se debe incluir +.)'
  }
  if (phoneNumber.length !== 12) {
    return 'El número de teléfono no es válido. (Formato ej. +56999999999, revisar la cantidad de digitos.)'
  }
  return true
})

extend('twoWords', (value) => {
  if (value.trim().split(/\s+/).length < 2) {
    return 'El campo debe contener al menos dos palabras'
  }
  return true
})

// with typescript
for (const [rule, validation] of Object.entries(rules)) {
  extend(rule, {
    ...validation,
  })
}

export default function VeeValidatePlugin({ app }) {
  configure({
    defaultMessage: (_, values) =>
      app.i18n.t(`validations.messages.${values._rule_}`, values),
  })
}
