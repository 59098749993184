//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    search: {
      type: Function,
      default: () => [],
    },
    field: {
      type: String,
      default: () => {
        return 'name'
      },
    },
    doneTypingInterval: {
      type: Number,
      default: () => 700,
    },
    cleanAfterSelect: {
      type: Boolean,
      default: () => true,
    },
    excludedItems: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: () => '',
    },
    minCharacterToSearch: {
      type: Number,
      default: () => 4,
    },
    defaultOptions: {
      type: Array,
      default: () => [],
    },
    currentValue: {
      type: Object,
      default: null,
    },
    icon: {
      type: String,
      default: () => '',
    },
    dropdownPosition: {
      type: String,
      default: () => 'auto',
    },
  },
  data() {
    return {
      value: '',
      options: [],
      loading: false,
      timeoutTyping: null,
      selectedValue: undefined,
    }
  },
  computed: {
    availableOptions() {
      const excludedIds = this.excludedItems.map((item) => item.id)
      if (this.options?.length === 0)
        return this.defaultOptions.filter(
          (option) => !excludedIds.includes(option.id)
        )
      return this.options.filter((option) => !excludedIds.includes(option.id))
    },
    defaultPlaceholder() {
      return this.$t('commons.finder.placeholder', [this.minCharacterToSearch])
    },
    isSuccess() {
      return !this.cleanAfterSelect && !!this.currentValue && !!this.value
    },
  },
  watch: {
    currentValue(value) {
      if (this.currentValue) {
        this.value = this.currentValue[this.field]
      }
    },
  },
  created() {
    if (this.currentValue) {
      this.value = this.currentValue[this.field]
    }
  },
  methods: {
    handleSelect(selected) {
      if (!selected) return
      this.selectedValue = selected[this.field]
      this.$emit('select', selected)
      if (this.cleanAfterSelect) this.reset()
    },
    cleanSelection() {
      this.selectedValue = undefined
      !this.cleanAfterSelect && this.$emit('select', null)
    },
    handleInput(value) {
      if (value === '') {
        this.cleanSelection()
      }
      if (value !== this.selectedValue) {
        this.evaluateTimeoutTyping(value)
      }
    },
    async handleSearch(value) {
      if (!value || value.length < this.minCharacterToSearch) {
        this.options = []
        return
      }
      this.loading = true
      this.options = await this.search(value)
      this.loading = false
    },
    evaluateTimeoutTyping(value) {
      clearTimeout(this.timeoutTyping)
      this.timeoutTyping = setTimeout(() => {
        this.handleSearch(value)
      }, this.doneTypingInterval)
    },
    reset() {
      this.value = ''
      this.options = []
    },
  },
}
