//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    message: {
      type: String,
      default:
        '¡Ups! No se encontraron sugerencias para tu búsqueda. Te recomendamos:',
    },
    suggestions: {
      type: Array,
      default: () => [
        'Comprueba que todas las palabras estén escritas correctamente',
        'Intenta con diferentes palabras clave',
        'Utiliza palabras claves más generales',
        'Es posible que tus usuarios no cuenten con esta habilidad',
      ],
    },
  },
}
