//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      currentStep: 0,
      occupation: {},
    }
  },
  methods: {
    onPreviousStep(occupation) {
      if (this.currentStep === 0) {
        this.$router.back()
        return
      }
      this.occupation = occupation || {}
      this.currentStep = this.currentStep - 1
    },

    onNextStep(occupation) {
      this.occupation = occupation
      this.currentStep = this.currentStep + 1
    },

    onChangeCompany(occupation) {
      this.occupation = occupation
    },

    getStep() {
      return this.currentStep
    },
  },
}
