//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    pilot: {
      type: Object,
      default: () => ({
        name: '',
        description: '',
        requirements: '',
        start: '',
        end: '',
        locations: '',
      }),
    },
  },
}
