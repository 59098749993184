//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PlusCircleOutline from 'vue-material-design-icons/PlusCircleOutline'
import AddWorkExp from '~/assets/experiences/add-work-experience.svg'
import AddWorkExpHighlight from '~/assets/experiences/add-work-experience-hl.svg'
import AddEducationExp from '~/assets/experiences/add-education-experience.svg'
import AddEducationExpHighlight from '~/assets/experiences/add-education-experience-hl.svg'
import AddOtherExp from '~/assets/experiences/add-other-experience.svg'
import AddOtherExpHighlight from '~/assets/experiences/add-other-experience-hl.svg'

const ICONS = {
  education: {
    regular: AddEducationExp,
    hover: AddEducationExpHighlight,
  },
  work: {
    regular: AddWorkExp,
    hover: AddWorkExpHighlight,
  },
  other: {
    regular: AddOtherExp,
    hover: AddOtherExpHighlight,
  },
}

export default {
  components: {
    PlusCircleOutline,
  },
  props: {
    onClick: {
      type: Function,
      default: () => {},
    },
    text: {
      type: String,
      default: '',
    },
    texth: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      required: true,
      validator: (val) => ['work', 'education', 'other'].includes(val),
    },
  },
  data() {
    return {
      isHover: false,
    }
  },
  computed: {
    iconSvg() {
      const selector = this.isHover ? 'hover' : 'regular'
      return ICONS[this.type][selector]
    },
  },
  methods: {
    handleClick() {
      this.$store.dispatch('experience/resetCurrentStep')
      this.onClick()
    },
  },
}
