import { render, staticRenderFns } from "./CompanyOpportunitiesSection.vue?vue&type=template&id=c942c012&scoped=true&"
import script from "./CompanyOpportunitiesSection.vue?vue&type=script&lang=js&"
export * from "./CompanyOpportunitiesSection.vue?vue&type=script&lang=js&"
import style0 from "./CompanyOpportunitiesSection.vue?vue&type=style&index=0&id=c942c012&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c942c012",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DashboardCard: require('/workspace/components/Dashboard/DashboardCard.vue').default})
