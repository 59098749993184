//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  computed: {
    isLoggin() {
      return !!this.$strapi.user
    },
  },
  mounted() {
    this.validateEmail(this.$route.query.confirmation)
  },
  methods: {
    toggleModalLogin() {
      this.showModalLogin = !this.showModalLogin
    },
    goToPanel() {
      this.$router.push('/inicio')
    },
    goToRegister() {
      this.$router.push('/registro')
    },
    goToMessage() {
      this.$router.push({
        path: '/empresas',
        hash: '#messages',
      })
    },
    async validateEmail(codeConfirmation) {
      if (codeConfirmation) {
        try {
          await this.$strapi.$http.$get(
            `/auth/email-confirmation?confirmation=${codeConfirmation}`
          )
          this.$buefy.notification.open({
            message: 'Se ha confirmado su cuenta correctamente',
            type: 'is-success',
            duration: 5000,
          })
          this.toggleModalLogin()
        } catch (error) {
          this.$buefy.notification.open({
            message:
              'Ha ocurrido un error en la confirmación, por favor intente más tarde',
            type: 'is-danger',
            duration: 5000,
          })
        }
      }
    },
  },
}
