//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Button from '~/components/Shared/Button.vue'
export default {
  components: {
    Button,
  },
  props: {
    sectors: {
      type: Array,
      default: () => {},
    },
  },
}
