//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ValidationObserver } from 'vee-validate'
import ModalWithTitleBar from '@/components/Shared/ModalWithTitleBar'
import Button from '~/components/Shared/Button'

export default {
  name: 'CustomOccupationModalForm',
  components: {
    ValidationObserver,
    ModalWithTitleBar,
    Button,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    companies: {
      type: Array,
      default: () => [],
    },
    metaOccupations: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      query: '',
      occupations: [],
      form: {
        name: null,
        description: null,
        company: null,
        occupation: null,
      },
    }
  },
  computed: {
    occupationOptions() {
      return this.query.length === 0 ? this.metaOccupations : this.occupations
    },
  },
  methods: {
    async findOccupationsByQuery(query) {
      this.query = query
      if (this.query.length > 0) {
        this.occupations = await this.$repository.occupation.suggestOccupations(
          { term: this.query, custom_occupations: true }
        )
      }
    },
    onSelectOccupation(selected) {
      if (selected) this.form.description = selected.description
    },
    clear() {
      this.form.name = null
      this.form.description = null
      this.form.company = null
      this.form.occupation = null
    },
    onToggle() {
      this.clear()
      this.$emit('toggle')
    },
    onCancel() {
      this.clear()
      this.$emit('cancel')
    },
    onSubmit() {
      this.$emit('submit', this.form)
    },
  },
}
