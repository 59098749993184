//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment'
import DashboardCardTitle from './DashboardCardTitle.vue'

export default {
  components: {
    DashboardCardTitle,
  },
  filters: {
    dateFormat(value) {
      return moment(value).format('DD/MM/YYYY')
    },
  },
  props: {
    dataTable: {
      type: Array,
      default: () => [
        {
          id: 1,
          company: 'Company name',
          total_employees: '0',
          total_occupations: '0',
        },
      ],
    },
    total: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      columns: [
        { field: 'company', label: '' },
        { field: 'total_person', label: 'Personas', centered: true },
        { field: 'total_occupation', label: 'Ocupaciones', centered: true },
      ],
    }
  },
  methods: {
    changePage(event) {
      this.$emit('changePagePilotCompanies', event)
    },
  },
}
