//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      isFocused: false,
    }
  },
  methods: {
    onFocus() {
      this.isFocused = true
      this.$emit('focus')
    },
    onBlur() {
      this.isFocused = false
      this.$emit('blur')
    },
    onInput(value) {
      this.$emit('input', value)
    },
  },
}
