//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ValidationProvider } from 'vee-validate'
import FormLabel from '~/components/Form/FormLabel'

export default {
  name: 'FormInputDate',
  components: {
    FormLabel,
    ValidationProvider,
  },
  props: {
    vid: {
      type: String,
      default: '',
    },
    rules: {
      type: [Object, String],
      default: '',
    },
    value: {
      type: null,
      default: null,
    },
  },
  data: () => ({
    init: false,
    innerValue: '',
    cursorPosition: 0,
  }),
  watch: {
    innerValue(newVal) {
      this.$emit('input', newVal)
      this.init = true
    },
    value(newVal) {
      this.innerValue = newVal
      this.init = true
    },
  },
  created() {
    if (this.value) {
      this.innerValue = this.value
    }
  },
  methods: {
    onDateInput(event) {
      const input = event.target
      let value = input.value
      this.cursorPosition = input.selectionStart

      value = value.replace(/\D/g, '')
      let formattedValue = ''
      let positionFix = 0

      if (value.length > 0) {
        formattedValue += value.substring(0, 2)
      }
      if (value.length > 2) {
        formattedValue += '/' + value.substring(2, 4)
        positionFix = 1
      }
      if (value.length > 4) {
        formattedValue += '/' + value.substring(4, 8)
        positionFix = 1
      }
      input.value = formattedValue
      this.innerValue = formattedValue
      this.restoreCursorPosition(positionFix)
    },
    restoreCursorPosition(positionFix) {
      this.$nextTick(() => {
        const input = this.$refs.dateInput.$el.querySelector('input')
        let newPosition = this.cursorPosition
        if (input.value.length - this.cursorPosition <= 1) {
          if (input.value.length < 9) {
            newPosition += positionFix
          }
        }
        input.setSelectionRange(newPosition, newPosition)
      })
    },
  },
}
