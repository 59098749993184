//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ValidationObserver } from 'vee-validate'
import ModalWithTitleBar from '@/components/Shared/ModalWithTitleBar'
import Button from '~/components/Shared/Button'

export default {
  name: 'CustomOccupationModalForm',
  components: {
    ValidationObserver,
    ModalWithTitleBar,
    Button,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    isVisibleConfirmation: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      suggest: '',
    }
  },
  computed: {
    message() {
      return this.isVisibleConfirmation
        ? this.$t('components.skills.modalSuggestSkill.confirmation')
        : this.$t('components.skills.modalSuggestSkill.description')
    },
  },
  methods: {
    onCancel() {
      this.suggest = ''
      this.$emit('cancel')
    },
    onSubmit() {
      this.$emit('submit', {
        title: this.suggest,
      })
    },
  },
}
