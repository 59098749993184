//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MatchChart from './MatchChart.vue'
import Button from '~/components/Shared/Button.vue'
import TagStatus from '~/components/Shared/TagStatus.vue'

export default {
  components: {
    Button,
    TagStatus,
    MatchChart,
  },
  props: {
    questionary: {
      type: Object,
      default: null,
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    status: {
      type: String,
      default: '',
    },
    imageSrc: {
      type: String,
      default: '',
    },
    actionLabel: {
      type: String,
      default: '',
    },
    match: {
      type: Number,
      default: null,
    },
  },
  computed: {
    isShowMatch() {
      return !!this.match
    },
  },
  methods: {
    handleClick() {
      this.$emit('click', this.questionary)
    },
  },
}
