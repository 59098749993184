//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Button from '~/components/Shared/Button'
import ModalWithTitleBar from '~/components/Shared/ModalWithTitleBar'

export default {
  name: 'ConfirmRegisterUserWithoutEmailModal',
  components: { Button, ModalWithTitleBar },
  props: {
    value: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      active: this.value,
    }
  },
  watch: {
    value() {
      this.active = this.value
    },
  },
  methods: {
    async finalize() {
      await this.$router.push('/mis-trabajadores')
    },

    close() {
      this.active = !this.active
      this.$emit('close')
      this.$emit('input', this.active)
    },
  },
}
