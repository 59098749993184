//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ModalWithTitleBar from '~/components/Shared/ModalWithoutTitle'
import Button from '~/components/Shared/Button.vue'
import config from '~/config'
export default {
  components: {
    ModalWithTitleBar,
    Button,
  },
  props: {
    showModal: {
      type: Boolean,
      default: false,
    },
    toggleModal: {
      type: Function,
      default: () => {},
    },
    roleCorrect: {
      type: String,
      default: '',
    },
    labelButton: {
      type: String,
      default: '',
    },
    to: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      contactEmail: config.contactEmail,
    }
  },
}
