//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'SearchFilters',
  props: {
    isInitialState: {
      type: Boolean,
      default: true,
    },
    buttonDisabled: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    term: '',
    origin: undefined,
    doneTypingInterval: 500,
    typingTimer: 0,
    options: {
      origins: [
        { label: 'Ocupaciones Esco', value: 'ESCO' },
        { label: 'Ocupaciones Meta', value: 'Curadoria Relink' },
        { label: 'Ocupaciones Personalizadas', value: 'Custom' },
      ],
    },
  }),
  computed: {
    searchFilter() {
      const options = [{ label: 'Restablecer Valores', value: undefined }]
      return options.concat(this.options.origins)
    },
  },
  methods: {
    setTimer() {
      this.origin = undefined
      clearTimeout(this.typingTimer)
      this.typingTimer = setTimeout(this.filterByTerm, this.doneTypingInterval)
    },
    filterByTerm() {
      this.$emit('search', { term: this.term, origin: this.origin })
    },
    handleNext() {
      this.$emit('next')
    },
  },
}
