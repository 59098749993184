//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions } from 'vuex'
import Finder from '~/components/Shared/Finder/Finder.vue'
import ModalWithTitleBar from '~/components/Shared/ModalWithTitleBar.vue'
import Button from '~/components/Shared/Button.vue'
import OptionOrigin from '~/components/Shared/Finder/OptionOrigin.vue'
import ListChip from '~/components/Experiences/ListChip.vue'
import { showErrorNotification } from '~/util/notifications'
import { ORIGIN_ENTITIES } from '~/constants/origin-entities.js'

export default {
  components: { ModalWithTitleBar, Button, Finder, OptionOrigin, ListChip },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    preSelectedOrigins: {
      type: Array,
      default: () => [],
    },
    project: {
      type: Object,
      default: () => ({}),
    },
    defaultOriginOptions: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    selectedOrigins: [],
  }),
  computed: {
    isValidOrigins() {
      return this.selectedOrigins.length > 0
    },
    excludedItems() {
      const formattedOrigins = this.preSelectedOrigins.map((origin) => ({
        id: `${
          origin.entity_type === ORIGIN_ENTITIES.OCCUPATION_ENTITY
            ? ORIGIN_ENTITIES.OCCUPATION_ABR
            : ORIGIN_ENTITIES.SPECIALTY_ABR
        }-${origin.entity_id}`,
      }))
      return [...formattedOrigins, ...this.selectedOrigins]
    },
    companyType() {
      return this.$strapi.user.company.type
    },
    companyId() {
      return this.$strapi.user.company.id
    },
    projectId() {
      return this.project.id
    },
  },
  methods: {
    ...mapActions({
      sync: 'project/sync',
    }),
    toggle() {
      this.$emit('toogle')
    },
    handleSelectOrigin(selected) {
      this.selectedOrigins = [
        ...this.selectedOrigins,
        { ...selected, active: true },
      ]
    },
    handleChangeSelectedOrigins(selected) {
      this.selectedOrigins = this.selectedOrigins.map((origin) => {
        if (origin.id === selected.id) {
          return {
            ...origin,
            active: selected.status,
          }
        }
        return origin
      })
    },
    async searchOrigins(text) {
      return await this.$repository.project.searchOrigins({
        text,
        companyId: this.companyId,
        companyType: this.companyType,
        hideMapha: true,
      })
    },
    async saveOrigins(origins) {
      try {
        if (!this.projectId) {
          await this.sync({ baseProject: this.project, forceSync: true })
        }
        const originPromises = origins.map((origin) => {
          const projectOriginEntity = {
            ...origin,
            entity_custom_name: origin.title,
            project: this.projectId,
          }

          return this.$repository.projectOriginEntities.create(
            projectOriginEntity
          )
        })
        return await Promise.all(originPromises)
      } catch (e) {
        showErrorNotification(this, this.$t('misc.errors.genericError'))
        return []
      }
    },
    async handleAddClick() {
      const origins = await this.saveOrigins(
        this.selectedOrigins.filter((origin) => origin.active)
      )
      this.$emit('add', origins)
      this.selectedOrigins = []
    },
  },
}
