//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'CardToolbar',
  props: {
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
}
