//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CompanyProjectsSection from '@/components/Dashboard/Company/CompanyProjectsSection'
import {
  visibleSectionFirstProjectDashboard,
  getPercentageProgressCreateProject,
} from '@/util/project'
import PROJECT from '@/constants/projects'
import ROLES from '~/constants/roles'
import MessageWithoutHeader from '~/components/Shared/MessageWithoutHeader.vue'
import DashboardCard from '~/components/Dashboard/DashboardCard.vue'

const STEPS = ['project']

export default {
  components: {
    DashboardCard,
    CompanyProjectsSection,
    MessageWithoutHeader,
  },
  props: {
    user: {
      type: Object,
      default: () => ({}),
    },
  },
  async fetch() {
    this.$store.commit('loading', true)

    const [companyData] = await this.$repository.company.findAll({
      id: this.user.company.id,
      populate: ['projects', 'projects.occupations'],
    })
    const projects = companyData.projects.filter(
      (project) => project.deleted_at === null
    )
    this.company = { ...companyData, projects }

    this.stepProgress = this.getProgressProject(this.company.projects[0])
    this.stepsIncomplete = STEPS.filter((step) => !this.isComplete(step))

    this.$emit('complete', this.registerComplete)

    this.isLoading = false
    this.$store.commit('loading', false)
  },
  data() {
    return {
      stepProgress: {},
      routes: {
        invitation: '/invitaciones/empleados',
        occupation: '/empresas/ocupaciones-requeridas',
      },
      stepsIncomplete: [],
      isLoading: true,
      company: {},
    }
  },
  computed: {
    registerComplete() {
      return this.stepsIncomplete.length === 0
    },
    isVisibleSectionFirstProject() {
      return visibleSectionFirstProjectDashboard(this.company.projects)
    },
    subtitleProjectStep() {
      return this.$t(`dashboard.incomplete.progress`, [
        getPercentageProgressCreateProject(this.company.projects[0]),
      ])
    },
    buttonProject() {
      const project = this.company.projects[0]
      if (!project) return 'Crear proyecto'
      return project.status === PROJECT.DRAFT.value
        ? 'Continuar'
        : 'Ver proyecto'
    },
    titleFirstCard() {
      const project = this.company.projects[0]
      return project
        ? project.name
        : this.$t(`dashboard.incomplete.titles.project`)
    },
  },
  methods: {
    isComplete(field) {
      return this.stepProgress[field] === 100
    },
    getProgressProject() {
      const [project] = this.company.projects
      return {
        project: getPercentageProgressCreateProject(project),
      }
    },
    goToProject() {
      const project = this.company.projects[0]
      if (project) {
        this.$router.push(`/mis-proyectos/nuevo?code=${project.code}`)
      } else {
        this.$router.push(`/mis-proyectos/nuevo`)
      }
    },
    async checkInvitationProgress() {
      const count = await this.$repository.user.count({
        company: this.userCompanyId,
        'role.type': ROLES.EMPLOYEE,
      })
      return count > 0 ? 100 : 0
    },
    async checkOccupationProgress() {
      const count = await this.$repository.companyOccupation.count({
        company: this.userCompanyId,
      })
      return count > 0 ? 100 : 0
    },
  },
}
