//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    type: {
      type: String,
      required: true,
      default: 'info',
    },
    message: {
      type: String,
      required: true,
    },
  },
  computed: {
    bgClass() {
      return `bg-${this.type}`
    },
    colorClass() {
      return `color-${this.type}`
    },
    alertIcon() {
      const ALERT_ICONS = {
        info: { icon: 'information.svg', alt: 'icon information' },
      }
      return ALERT_ICONS[this.type] || ALERT_ICONS.info
    },
  },
}
