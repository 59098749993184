//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Chip from '@/components/design-system/Chip.vue'
export default {
  name: 'ListChip',
  components: {
    Chip,
  },
  props: {
    chips: {
      type: Array,
      default: () => [],
    },
    field: {
      type: String,
      default: () => 'title',
    },
    totalChipsShow: {
      type: Number,
      default: () => 0,
    },
    compress: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      chipsSelected: [],
      totalVisiblesChips: this.totalChipsShow,
      compressed: true,
    }
  },
  computed: {
    chipsVisibles() {
      if (!this.compress || !this.compressed) {
        return this.chips
      }
      return this.chips.slice(-this.totalVisiblesChips)
    },
  },
  methods: {
    chipSelected(chip) {
      if (chip.status) {
        this.chipsSelected.push(chip.id)
      } else {
        this.chipsSelected = this.chipsSelected.filter(
          (chipRemove) => chipRemove !== chip.id
        )
      }
      this.chipsSelected = [...new Set(this.chipsSelected)]
      this.$emit('selected', this.chipsSelected)
    },
    isShowChipsCompress() {
      return (
        (this.chips.length - this.chipsVisibles.length > 0 && this.compress) ||
        (this.compress && !this.compressed)
      )
    },
    toogleChipsDetail() {
      this.compressed = !this.compressed
    },
    messageCompressButton() {
      return this.compressed
        ? `${this.chips.length - this.chipsVisibles.length}`
        : 'Ver menos'
    },
    handleChipChange({ id, status }) {
      this.$emit('change', { id, status })
    },
  },
}
