const Request = require('@/repositories/request')
const qs = require('qs')
const { encodeQueryParams } = require('~/util/query')
class Skill extends Request {
  findOne(id, params) {
    const stringifyParams = qs.stringify({ ...params }, { encode: false })
    return this.$strapi.$http.$get(`/skills/${id}?${stringifyParams}`)
  }

  /**
   *
   * @param {Object} params
   * @return {*}
   */
  findAll(params) {
    const stringifyParams = qs.stringify({ ...params }, { encode: false })
    return this.$strapi.$skills.find(stringifyParams)
  }

  /**
   * @returns {Promise<Array>}
   * @param {string} term
   */
  suggest(term) {
    return this.$strapi.$http.$post('/skills/suggest', { term })
  }

  /**
   * @returns {Promise<Array>}
   * @param {integer} id
   * @param {integer} quantity
   */
  getSimilars({ id, quantity }) {
    return this.$strapi.$http.$get(
      `/skills/similar/${id}`,
      quantity ? encodeQueryParams({ quantity }) : {}
    )
  }

  /**
   * @returns {Promise<Array>}
   * @param {integer} companyId
   * @param {object} queryObject
   */
  findByCompanyId(id, queryObject) {
    const query = qs.stringify(queryObject, { encode: false })
    return this.$strapi.$http.$get(`/skills/company/${id}?${query}`)
  }

  /**
   * @returns {Promise<Array>}
   * @param {integer} workerId
   * @param {object} queryObject
   */
  findByWorkerId(id, queryObject) {
    const query = qs.stringify(queryObject, { encode: false })
    return this.$strapi.$http.$get(`/skills/worker/${id}?${query}`)
  }

  /**
   * @returns {Promise<Array>}
   * @param {integer} id
   * @param {integer} quantity
   */
  searchSkillsByTerm(term, excludedSkillIds = []) {
    return this.$strapi.$http.$get(
      `/skills/search-skill?term=${term}&excludedSkillIds=${excludedSkillIds}`
    )
  }

  getSkillStats(queryObject) {
    const query = qs.stringify(queryObject, { encode: false })
    return this.$strapi.$http.$get(`/skills/stats/?${query}`)
  }

  getProjectSkillStats(projectId) {
    return this.$strapi.$http.$get(`/projects/${projectId}/skill-stats`)
  }

  findByProjectId(projectId, queryObject) {
    const query = qs.stringify(queryObject, { encode: false })
    return this.$strapi.$http.$get(
      `/projects/${projectId}/skill-totals?${query}`
    )
  }

  getDetails(projectId, skillId) {
    return this.$strapi.$http.$get(
      `/projects/${projectId}/skill-detail?skill_id=${skillId}`
    )
  }
}

module.exports = Skill
