//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FormLabel from '@/components/Form/FormLabel'

export default {
  name: 'FormList',
  components: {
    FormLabel,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
}
