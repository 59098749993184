//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TagBadge from '~/components/Shared/TagBadge'

export default {
  components: { TagBadge },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    isActiveClick: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    classSkillsWrapper() {
      return this.isActiveClick
        ? 'skills-wrapper space-between'
        : 'skills-wrapper center'
    },
  },
  methods: {
    onRowClick(row) {
      if (this.isActiveClick) {
        this.$emit('click', row)
      }
    },
  },
}
