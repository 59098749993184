const defaultWorkerId = 0

export const state = () => ({
  workerId: defaultWorkerId,
})

export const mutations = {
  setWorkerId(state, data) {
    state.workerId = data
  },
  clearWorkerId(state) {
    state.workerId = defaultWorkerId
  },
}
