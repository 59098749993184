//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    suggestedEmployees: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    isAllSuggestedUsersSelected: false,
  }),
  watch: {
    isAllSuggestedUsersSelected(selected) {
      this.$emit('select-all', selected)
    },
  },
  methods: {
    handleSelectUser({ user, value }) {
      this.$emit('select-user', { user, value })
    },
  },
}
