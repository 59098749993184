//
//
//
//
//
//
//
//
//

export default {
  props: {
    title: {
      default: '',
      type: String,
    },
    click: {
      default: () => {},
      type: Function,
    },
    icon: {
      type: String,
      default: 'chevron-right',
    },
  },
}
