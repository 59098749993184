
import lastEvaluateMatchMixin from '@/mixins/lastEvalutateMatchMixin'
import StepTwoBase from '@/components/Experiences/StepTwoBase.vue'
import skillMorphsTypes from '~/constants/skill-morphs'
import { getSuggestSkillsForExperienceWork } from '~/util/entity/experience'
const { ENTITY_TYPES, ORIGIN_TYPES } = skillMorphsTypes
export default {
  name: 'StepTwoWorks',
  extends: StepTwoBase,
  mixins: [lastEvaluateMatchMixin],
  methods: {
    getSuggestSkills(experience, userSkills) {
      return getSuggestSkillsForExperienceWork(
        this.$repository,
        experience,
        userSkills
      )
    },
    prepareSkillsMorphs() {
      const suggestSkillIds = this.suggestSkills.map((skill) => skill.id)
      return this.skillsSelected.map((skill) => ({
        related_type: ENTITY_TYPES.EXPERIENCE_WORKS,
        related_id: this.experience.id,
        user: this.$strapi.user.id,
        skill,
        has_skill: true,
        origin_type: suggestSkillIds.includes(skill)
          ? ORIGIN_TYPES.SUGGEST
          : ORIGIN_TYPES.FREE,
      }))
    },
    updatedTotalHasSkills() {
      return this.$repository.experienceWork.updateTotalHasSkills(
        this.experience.id,
        {
          total_has_skills: this.skillsSelected.length,
        }
      )
    },
    async saveSkills() {
      const skillMorphs = this.prepareSkillsMorphs()

      if (skillMorphs.length === 0) return

      const userId = this.$strapi.user.id

      const savedSkills = await this.$repository.skillMorph.saveMassive(
        userId,
        this.experience?.id,
        ENTITY_TYPES.EXPERIENCE_WORKS,
        skillMorphs
      )
      this.evaluateMatchUser(userId)
      return savedSkills
    },
    getIsDefaultSetSkills(experience) {
      return !experience.occupation
    },
  },
}
