const Request = require('@/repositories/request')
const qs = require('qs')

class Location extends Request {
  /**
   *
   * @param {Object} params
   * @returns {*}
   */
  find(params) {
    const stringifyParams = qs.stringify({ ...params })
    return this.$strapi.$http.$get(`/locations?${stringifyParams}`)
  }

  findCountries() {
    return this.$strapi.$http.$get('/locations/american-countries')
  }

  findCitiesByCountry(params) {
    const stringifyParams = qs.stringify({ ...params })
    return this.$strapi.$http.$get(`/locations/search?${stringifyParams}`)
  }
}

module.exports = Location
