//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    title: {
      type: String,
      default: 'Dashboard card title',
    },
  },
  methods: {
    backPage() {
      this.$emit('changePage', -1)
    },
    nextPage() {
      this.$emit('changePage', 1)
    },
  },
}
