const Request = require('@/repositories/request')
const qs = require('qs')

class SkillMorph extends Request {
  /**
   *
   * @param {int} id
   * @returns {*}
   */
  findOne(id) {
    return this.$strapi['$skill-morphs'].findOne(id)
  }

  /**
   *
   * @param {Object} params
   * @return {*}
   */
  findAll(params) {
    const stringifyParams = qs.stringify({ ...params }, { encode: false })
    return this.$strapi['$skill-morphs'].find(stringifyParams)
  }

  /**
   *
   * @param {Object} params
   * @return {*}
   */
  count(params) {
    const stringifyParams = qs.stringify({ ...params }, { encode: false })
    return this.$strapi['$skill-morphs'].count(stringifyParams)
  }

  /**
   * @param {int} id
   * @param {Object} data
   * @returns {*}
   */
  update(id, data) {
    return this.$strapi['$skill-morphs'].update(id, data)
  }

  /**
   *
   * @param {Object} params
   * @return {*}
   */
  create(params) {
    return this.$strapi['$skill-morphs'].create(params)
  }

  /**
   *
   * @param {Object} data
   * @return {*}
   */
  save(data) {
    return this.$strapi.$http.$post(`/skill-morphs/save`, data)
  }

  saveMassive(userId, relatedId, relatedType, data) {
    return this.$strapi.$http.$post(`/skill-morphs/save-massive`, {
      userId,
      relatedId,
      relatedType,
      data,
    })
  }

  /**
   * @param {int} id
   * @returns {*}
   */
  delete(id) {
    return this.$strapi['$skill-morphs'].delete(id)
  }

  /**
   *
   * @param {Object} data
   * @return {*}
   */
  remove(data) {
    return this.$strapi.$http.$post(`/skill-morphs/remove`, data)
  }
}

module.exports = SkillMorph
