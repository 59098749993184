//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ValidationObserver } from 'vee-validate'
import { FormCheckBox, FormInput } from '@/components/Form'
import Finder from '~/components/Shared/Finder/Finder.vue'
export default {
  components: {
    ValidationObserver,
    Finder,
    FormCheckBox,
    FormInput,
  },
  props: {
    countryId: {
      type: Number,
      required: true,
    },
  },
  async fetch() {
    this.defaultCities = await this.$repository.location.findCitiesByCountry({
      countryId: this.countryId,
      term: '',
    })
  },
  data() {
    return {
      form: {},
      term: '',
      defaultCities: [],
    }
  },
  watch: {
    form: {
      handler(val) {
        this.form.terms = !this.form.terms ? undefined : true
        this.form.provide_data = !this.form.provide_data ? undefined : true
      },
      deep: true,
    },
  },
  methods: {
    submit() {
      this.$emit('register', this.form)
    },
    selectCity(city) {
      this.form.city = city
    },
    async searchCities(term) {
      const foundedCities = await this.$repository.location.findCitiesByCountry(
        {
          countryId: this.countryId,
          term,
        }
      )
      return foundedCities
    },
    showTerms() {
      this.$emit('show-terms')
    },
    showPolicyData() {
      this.$emit('show-policy-data')
    },
  },
}
