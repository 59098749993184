//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import EmptyDataIcon from '~/assets/svg/empty-data.svg'

export default {
  name: 'TopSkillsSection',
  components: {
    EmptyDataIcon,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    columns() {
      return [
        {
          label: 'Habilidad',
          field: 'skill_name',
          sortable: true,
          cellClass: 'table-cells',
        },
        {
          label: 'Nº Usuarios',
          field: 'skill_count',
          sortable: true,
          centered: true,
        },
      ]
    },
  },
}
