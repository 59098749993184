//
//
//
//
//
//

import Tag from '~/components/Shared/Tag'
export default {
  components: {
    Tag,
  },
  props: {
    status: {
      default: 'pending',
      type: String,
    },
    customClass: {
      default: 'test',
      type: String,
    },
  },
  computed: {
    statusText() {
      switch (this.status) {
        case 'accepted':
          return this.$t('invitations.status.accepted')
        case 'rejected':
          return this.$t('invitations.status.rejected')
        case 'completed':
          return this.$t('invitations.status.completed')
        case 'incompleted':
          return this.$t('invitations.status.incompleted')
        case 'pending':
        default:
          return this.$t('invitations.status.pending')
      }
    },
    statusType() {
      switch (this.status) {
        case 'accepted':
          return 'is-success'
        case 'rejected':
          return 'is-danger'
        case 'completed':
          return 'is-success'
        case 'incompleted':
          return 'is-danger'
        case 'pending':
        default:
          return 'is-warning'
      }
    },
  },
}
