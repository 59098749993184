//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Button from '@/components/Shared/Button'
export default {
  components: { Button },
  props: {
    index: {
      default: '',
      type: String,
    },
    item: {
      default: () => {},
      type: Object,
    },
  },
}
