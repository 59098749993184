//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ValidationProvider } from 'vee-validate'
import Multiselect from 'vue-multiselect'
import FormLabel from '@/components/Form/FormLabel'
export default {
  name: 'ProjectFormSelect',
  components: {
    FormLabel,
    ValidationProvider,
    Multiselect,
  },
  props: {
    vid: {
      type: String,
      default: '',
    },
    rules: {
      type: [Object, String],
      default: '',
    },
    value: {
      type: null,
      default: null,
    },
    labelField: {
      type: String,
      default: '',
    },
    focusPrevent: {
      type: Function,
      default: () => {},
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    innerValue: '',
  }),
  watch: {
    value(newVal) {
      this.innerValue = newVal
    },
  },
  created() {
    if (this.value) {
      this.innerValue = this.value
    }
  },
  methods: {
    onOpen() {
      this.$emit('open')
    },
    onClose() {
      this.$emit('close')
    },
    deactivate() {
      this.$refs.multiselect.deactivate()
      this.$refs.provider.validate()
    },
    onSearch(q) {
      this.$emit('search-change', q)
    },
    onTag(value) {
      this.$emit('tag', value)
    },
    onInput(value) {
      this.$emit('input', value)
    },
  },
}
