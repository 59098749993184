export const showSuccessNotification = (context, message, duration = 1500) => {
  context.$buefy.notification.open({
    message,
    type: 'is-success',
    autoclose: true,
    position: 'is-top-right',
    duration,
  })
}

export const showErrorNotification = (
  context,
  message = 'Ocurrió un error',
  duration = 1500
) => {
  context.$buefy.notification.open({
    message,
    type: 'is-danger',
    autoclose: true,
    position: 'is-top-right',
    duration,
  })
}
