const Request = require('@/repositories/request')
const { encodeQueryParams } = require('@/util/query')
const qs = require('qs')
class User extends Request {
  find(params) {
    const stringifyParams = qs.stringify({ ...params }, { encode: false })
    return this.$strapi.$users.find(stringifyParams)
  }

  findPopulate(params) {
    const stringifyParams = qs.stringify({ ...params }, { encode: false })
    return this.$strapi.$http.$get(`/users/populate?${stringifyParams}`)
  }

  getMatchWithOccupation(params) {
    const stringifyParams = qs.stringify({ ...params }, { encode: false })
    return this.$strapi.$http.$get(`/users/match-occupation?${stringifyParams}`)
  }

  /**
   *
   * @param {int} id
   * @returns {*}
   */
  findOne(id) {
    return this.$strapi.$users.findOne(id)
  }

  /**
   *
   * @param params
   * @returns {params}
   */
  create(params) {
    return this.$strapi.$users.create(params)
  }

  /**
   *
   * @param {int} userId
   * @returns {*}
   */
  getOpportunitiesWorkByUserId(userId) {
    return this.$strapi.$http.$get(`/users/${userId}/opportunities-works`)
  }

  getOpportunitiesMetaByUserId(userId, limit) {
    const query = encodeQueryParams({ limit })
    return this.$strapi.$http.$get(
      `/users/${userId}/opportunities-meta?${query}`
    )
  }

  getUnemployeeOpportunitiesByUserId(userId, limit) {
    const query = encodeQueryParams({ limit })
    return this.$strapi.$http.$get(
      `/users/${userId}/unemployee-opportunities?${query}`
    )
  }

  getOpportunitiesWorkByEmployeeId(userId, limit) {
    const query = encodeQueryParams({ limit })
    return this.$strapi.$http.$get(
      `/users/${userId}/employee-opportunities?${query}`
    )
  }

  getCourseinformationByOccupationIds(userId, occupationIds) {
    return this.$strapi.$http.$post(`/users/${userId}/courses`, {
      occupationIds,
    })
  }

  getOpportunityDetail(userId, occupationId) {
    const query = encodeQueryParams({ occupationId })
    return this.$strapi.$http.$get(
      `/users/${userId}/opportunity-detail?${query}`
    )
  }

  /**
   *
   * @param {Object} params
   * @returns {*}
   */
  getMyOpportunitiesWork(params) {
    const query = encodeQueryParams(params)
    return this.$strapi.$http.$get(`/me/oportunities-works?${query}`)
  }

  /**
   *
   * @returns {Promise<unknown>}
   * @param id
   * @param params
   */
  update(id, params) {
    return this.$strapi.$users.update(id, params)
  }

  /**
   *
   * @returns {Promise<unknown>}
   */
  getExperienceWorks() {
    return this.$strapi.$http.$get('/me/experience-works')
  }

  /**
   *
   * @param {int} userId
   * @returns {Promise<any>}
   */
  getUserLatestExperienceWork(userId) {
    return this.$strapi.$http.$get(`/users/${userId}/latest-experience-work`)
  }

  countExperiences(userId) {
    return this.$strapi.$http.$get(`/users/${userId}/count-experiences`)
  }

  /**
   * @param {string} data.currentPassword
   * @param {string} data.newPassword
   * @param {string} data.confirmPassword
   * @returns {Promise<any>}
   */
  changePassword(data) {
    return this.$strapi.$http.$post('/me/change-password', data)
  }

  /**
   *
   * @returns {Promise<unknown>}
   */
  getTotalUsersByRole() {
    return this.$strapi.$http.$get('/users/total-by-role')
  }

  /**
   *
   * @param {Object} params
   * @return {*}
   */
  count(params) {
    const stringifyParams = qs.stringify({ ...params }, { encode: false })
    return this.$strapi.$users.count(stringifyParams)
  }

  /**
   *
   * @returns {Promise<unknown>}
   */
  getRoleByEmail(params) {
    const stringifyParams = qs.stringify({ ...params })
    return this.$strapi.$http.$get(`/users/role-by-email?${stringifyParams}`)
  }

  /**
   *
   * @returns {Promise<unknown>}
   */
  removeUsersOfProject(body) {
    return this.$strapi.$http.$put(`/users/remove-users-of-project`, body)
  }

  /**
   *
   * @returns {Promise<unknown>}
   */
  emailExistsValidation(email) {
    return this.$strapi.$http.$get(`/users/${email}/validation`)
  }
}

module.exports = User
