//
//
//
//

import SkillStats from './Charts/SkillStats.vue'
export default {
  components: {
    SkillStats,
  },
  async fetch() {
    try {
      this.isLoading = true
      this.skillStats = await this.$repository.skill.getSkillStats()
    } catch (error) {
      this.handleError(error, this.$t('skillsInventory.error.skillStats'))
    } finally {
      this.isLoading = false
    }
  },
  data: () => ({
    isLoading: true,
    skillStats: {
      totalsByAge: [],
      totalsByGender: [],
    },
  }),
}
