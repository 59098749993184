const Request = require('@/repositories/request')
const qs = require('qs')
class ProjectOriginEntities extends Request {
  create(params) {
    return this.$strapi['$projects-origin-entities'].create(params)
  }

  find(params) {
    const stringifyParams = qs.stringify({ ...params }, { encode: false })
    return this.$strapi['$projects-origin-entities'].find(stringifyParams)
  }

  update(id, data) {
    return this.$strapi['$projects-origin-entities'].update(id, data)
  }

  delete(id) {
    return this.$strapi['$projects-origin-entities'].delete(id)
  }
}

module.exports = ProjectOriginEntities
