//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Multiselect from 'vue-multiselect'
import { mapGetters } from 'vuex'
import SuggestedEmployees from './SuggestedEmployees.vue'
export default {
  components: {
    Multiselect,
    SuggestedEmployees,
  },
  props: {
    project: {
      type: Object,
      default: () => ({ id: 0 }),
    },
    employees: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    selectedEmployees: [],
    employeesToSelect: [],
  }),
  computed: {
    ...mapGetters({
      getBaseProject: 'project/getBaseProject',
    }),
    selectedEmployeesIds() {
      return this.selectedEmployees.map((user) => user.id)
    },
    suggestedEmployees() {
      return this.employeesToSelect.filter((user) => !user.project).slice(0, 10)
    },
    getProjectId() {
      const id = this.$route.params.id
        ? this.$route.params.id
        : this.getBaseProject.id
      return Number(id)
    },
  },
  watch: {
    selectedEmployees() {
      this.employeesToSelect = this.employeesToSelect.map((user) => ({
        ...user,
        selected: this.selectedEmployeesIds.includes(user.id),
      }))
      this.$emit('change-users-to-add', this.selectedEmployees)
    },
  },
  mounted() {
    this.employeesToSelect = this.employees.map((user) => ({
      ...user,
      name: `${user.names} ${user.lastnames}`,
      selected: false,
      $isDisabled: user.project,
    }))
  },
  methods: {
    handleSelectUser({ user, value }) {
      if (value) {
        this.selectedEmployees.push({ ...user, selected: true })
      } else {
        this.selectedEmployees = this.selectedEmployees.filter(
          (userFound) => userFound.id !== user.id
        )
      }
    },
    handleAllSelectUser(selected) {
      this.suggestedEmployees.forEach((user) => {
        const isFounded = this.selectedEmployeesIds.includes(user.id)
        if (selected) {
          !isFounded && this.selectedEmployees.push(user)
        } else {
          isFounded &&
            this.selectedEmployees.splice(
              this.selectedEmployeesIds.indexOf(user.id),
              1
            )
        }
      })
    },
  },
}
