//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import _ from 'underscore'
import moment from 'moment'
import ROLES from '@/constants/roles'
import Button from '~/components/Shared/Button.vue'
import RegisterFormFirstStep from '~/components/Register/RegisterFormFirstStep.vue'
import RegisterFormLocalFinalStep from '~/components/Register/RegisterFormLocalFinalStep.vue'
import RegisterFormForeignFinalStep from '~/components/Register/RegisterFormForeignFinalStep.vue'
import ModalPolicyData from '~/components/PolicyData/ModalPolicyData'
import ModalPrivacyPolicy from '~/components/PolicyData/ModalPrivacyPolicy.vue'
import ModalConfirmRegistration from '~/components/Register/ModalConfirmRegistration.vue'
export default {
  name: 'RegisterFormV2',
  middleware: ['user-validate'],
  components: {
    RegisterFormFirstStep,
    RegisterFormLocalFinalStep,
    RegisterFormForeignFinalStep,
    Button,
    ModalPolicyData,
    ModalPrivacyPolicy,
    ModalConfirmRegistration,
  },
  props: {
    countries: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      currentStep: 0,
      stepBaseInfo: {},
      platformCountry: 'Chile',
      modal: {
        confirmation: false,
        privacyPolicy: false,
        policyData: false,
      },
    }
  },
  methods: {
    nextStep(data) {
      this.currentStep = 1
      this.stepBaseInfo = data
    },
    async previousStep() {
      if (this.currentStep === 0) {
        await this.$router.push('/')
      } else {
        this.currentStep = 0
      }
    },
    async saveUser(stepFinalInfo) {
      this.$store.commit('loading', true)
      const { roles = [] } = await this.$repository.role.getAll()
      const currentRole = roles.find((role) => role.type === ROLES.UNEMPLOYED)
      try {
        await this.$repository.security.register({
          ...this.stepBaseInfo,
          ...stepFinalInfo,
          birthdate: moment(this.stepBaseInfo.birthdate, 'DD/MM/YYYY'),
          username: this.stepBaseInfo.email,
          role: currentRole.id,
          residence_country: this.stepBaseInfo.country.id,
          city: stepFinalInfo?.city?.name,
        })
        this.toggleConfirmationModal()
      } catch (e) {
        const idsOfMessageError = _.toArray(
          _.flatten(
            (e.response?.data?.message || []).map((message) =>
              message.messages.map(({ id }) => id)
            )
          )
        )
        let message = this.$t('misc.messages.error')
        if (idsOfMessageError.length > 0) {
          message = idsOfMessageError
            .map((message) => {
              const keyTranslate = `api.errors.${message}`
              const translate = this.$t(`api.errors.${message}`)
              if (keyTranslate !== translate) return translate

              return this.$t('register.errors.general')
            })
            .join(',')
        }

        this.$buefy.notification.open({
          message,
          type: 'is-danger',
          duration: 5000,
        })
      } finally {
        this.$store.commit('loading', false)
      }
    },
    toggleConfirmationModal() {
      if (this.modal.confirmation) this.redirectToLogin()
      this.modal.confirmation = !this.modal.confirmation
    },
    redirectToLogin() {
      this.$strapi.user = null
      this.$router.push('/login/persona')
    },
    togglePolicyDataModal() {
      this.modal.policyData = !this.modal.policyData
    },
    togglePrivacyPolicyModal() {
      this.modal.privacyPolicy = !this.modal.privacyPolicy
    },
  },
}
