//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'CompanySkillsInventoryCard',
  props: {
    employeesCount: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    skillsinventorysubtitle() {
      return this.$t('dashboard.complete.skillsInventory', [
        this.employeesCount,
      ])
    },
  },
  methods: {
    handleClickSkillsInventory() {
      this.$router.push('/mis-proyectos/inventario-habilidades')
    },
  },
}
