//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Button from '@/components/Shared/Button'
import { ValidationObserver } from 'vee-validate'
import ROLES from '@/constants/roles'
import { mapGetters, mapActions } from 'vuex'
import ModalWithTitleBar from '~/components/Shared/ModalWithTitleBar'

export default {
  components: {
    Button,
    ValidationObserver,
    ModalWithTitleBar,
  },
  props: {
    value: {
      type: Boolean,
      default: true,
    },
    roleType: {
      type: String,
      default: ROLES.EMPLOYEE,
    },
  },
  async fetch() {
    this.form.message = await this.getBaseProject.custom_invitation
  },
  data() {
    return {
      form: {
        message: '',
      },
      active: this.value,
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters({
      getBaseProject: 'project/getBaseProject',
    }),
  },
  watch: {
    value() {
      this.active = this.value
    },
  },
  methods: {
    ...mapActions({
      setProjectAttribute: 'project/setProjectAttribute',
    }),
    close() {
      this.active = !this.active
      this.$emit('close')
    },
    async preview() {
      const role = this.roleType
      const customMessage = this.form.message
      const companyName = this.$repository.me.getMe().company?.name
      const jobSeekerFair = this.$repository.me.getMe().job_seeker_fair?.name

      const response = await this.$repository.invitation.previewMail({
        role,
        customMessage,
        companyName,
        jobSeekerFair,
      })
      window.open(response.url, '_blank')
    },
    async submit() {
      this.isLoading = true
      await this.setProjectAttribute({
        key: 'custom_invitation',
        value: this.form.message,
      })
      await this.$store.dispatch('core/showMessage', {
        message: `<p>Ajustes guardados exitosamente</p>`,
      })
      this.isLoading = false
      this.close()
    },
  },
}
