//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ORDER_OPTIONS from '~/constants/order-options'
import Chip from '~/components/design-system/Chip.vue'
import Button from '~/components/Shared/Button.vue'
import SuggestedSkillsEmptySkills from '~/components/Experiences/SuggestSkills/SuggestedSkillsEmptySkills.vue'
import SuggestedSkillsHeader from '~/components/Experiences/SuggestSkills/SuggestedSkillsHeader.vue'
export default {
  components: {
    Chip,
    Button,
    SuggestedSkillsEmptySkills,
    SuggestedSkillsHeader,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    skills: {
      type: Array,
      default: () => [],
    },
    activeSkillsIds: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    externalSelected: {
      type: Array,
      default: () => [],
    },
    edit: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    defaultQtySkillsVisible: 15,
    quantitySkillVisible: 15,
    order: 'test',
  }),
  computed: {
    isEmpty() {
      return this.skills.length === 0
    },
    lastExternalSelectedSkillId() {
      return this.externalSelected.length > 0
        ? this.externalSelected.at(-1)
        : null
    },
    visibleSkills() {
      let visibleSkills = [...this.skills]

      if (this.lastExternalSelectedSkillId) {
        const externalSelectedSkills = this.skills.filter(({ id }) =>
          this.externalSelected.includes(id)
        )
        const notExternalSelectedSkills = this.skills.filter(
          ({ id }) => !this.externalSelected.includes(id)
        )
        visibleSkills = [
          ...externalSelectedSkills,
          ...notExternalSelectedSkills,
        ]
      }

      const orderedSkills = this.orderSkills(visibleSkills, this.order)
      return orderedSkills.slice(0, this.quantitySkillVisible)
    },

    quantitySkillNotVisible() {
      return this.skills.length - this.quantitySkillVisible
    },
    isExpanded() {
      return this.skills.length <= this.quantitySkillVisible
    },
    experienceSkillsSelected() {
      const experienceSkillsSelected = this.skills.filter((skill) =>
        this.activeSkillsIds.includes(skill.id)
      )
      return experienceSkillsSelected.length
    },
    suggestedSkillsUnselected() {
      const suggestedSkills = this.skills.filter(
        (skill) => !this.activeSkillsIds.includes(skill.id)
      )
      return suggestedSkills.length
    },
  },
  methods: {
    isActive(skillId) {
      return this.activeSkillsIds.includes(skillId)
    },
    expand() {
      this.quantitySkillVisible = this.skills.length
    },
    shrink() {
      this.quantitySkillVisible = this.defaultQtySkillsVisible
    },
    handleChangeSkillStatus({ id, status }) {
      this.$emit('change', { id, status })
    },
    toggle() {
      this.$emit('toggle')
    },
    handleSelectOrder(value) {
      this.order = value
    },
    orderSkills(skills, typeOrder) {
      if (typeOrder === ORDER_OPTIONS.AZ.value) {
        return this.orderSkillsByTitle(skills, true)
      }
      if (typeOrder === ORDER_OPTIONS.ZA.value) {
        return this.orderSkillsByTitle(skills, false)
      }
      return skills
    },
    orderSkillsByTitle(skills, isAscend) {
      return skills.sort((a, b) =>
        isAscend
          ? a.title.localeCompare(b.title)
          : -1 * a.title.localeCompare(b.title)
      )
    },
  },
}
