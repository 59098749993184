const defaultOccupation = {
  id: 0,
  vacancies: 0,
}

export const state = () => ({
  occupation: defaultOccupation,
})

export const mutations = {
  setOccupation(state, data) {
    state.occupation = data
  },
  clearOccupation(state) {
    state.occupation = defaultOccupation
  },
}
