//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import NewProjectsWizardButtons from '@/components/Projects/NewProjectWizardButtons.vue'
import { mapGetters, mapActions } from 'vuex'
import { capitalizeWord } from '@/util/string'
import PROJECT_CONSTANTS from '@/constants/projects'
import { TYPES } from '@/constants/project-types'
import FormList from '@/components/Form/FormList.vue'

export default {
  name: 'StepFour',
  components: {
    NewProjectsWizardButtons,
    FormList,
  },
  async fetch() {
    await this.setCurrentOrigins()
  },
  data() {
    return {
      origins: [],
    }
  },
  computed: {
    ...mapGetters({
      getBaseProject: 'project/getBaseProject',
    }),
    projectId() {
      return this.getBaseProject.id
    },
    getProjectType() {
      return this.$t(
        `projects.new.steps.first.types.${this.getBaseProject.type.toLowerCase()}`
      )
    },
    getTargetOccupationLabel() {
      return this.getBaseProject.type === TYPES.SELFDIAGNOSIS
        ? this.$t('projects.new.steps.fourth.form.target.selfdiagnosis')
        : this.$t('projects.new.steps.fourth.form.target.others')
    },
    getTargetOccupationValue() {
      return this.getBaseProject.occupations[0]?.title
    },
    showOriginEntities() {
      return (
        this.origins.length > 0 &&
        this.getBaseProject.type &&
        this.getBaseProject.type !== TYPES.SELFDIAGNOSIS
      )
    },
    getOriginEntities() {
      return this.origins.map((origin) => {
        return { id: origin.id, name: origin.entity_custom_name }
      })
    },
    showObjectiveDetail() {
      return this.getBaseProject.objective?.value === PROJECT_CONSTANTS.OTHERS
    },
    locationsList() {
      const names = this.getBaseProject.locations.map((location) => {
        return location.name
      })
      return names.join(', ')
    },
    occupationsList() {
      const titles = this.getBaseProject.occupations.map((occupation) => {
        return capitalizeWord(occupation.title)
      })
      return titles.join(', ')
    },
    formattedSkillPacks() {
      return this.getBaseProject.has_models
        ? this.formatSkillPacks(this.getBaseProject.skill_packs)
        : []
    },
  },
  watch: {
    getBaseProject: {
      async handler(val) {
        if (val.current_step === 3) {
          await this.setCurrentOrigins()
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      setProjectAttribute: 'project/setProjectAttribute',
    }),
    handleBack() {
      this.$emit('back')
    },
    async handleContinue() {
      this.$store.commit('loading', true)
      this.setProjectAttribute({
        key: 'status',
        value: PROJECT_CONSTANTS.ACTIVE.value,
      })
      this.$repository.invitation.sendAllProjectInvitation({
        project_id: this.getBaseProject.id,
        custom_message:
          this.getBaseProject.custom_invitation &&
          this.getBaseProject.custom_invitation.trim() !== ''
            ? this.getBaseProject.custom_invitation
            : false,
        reminder: false,
      })
      await this.$repository.project.save({
        id: this.getBaseProject.id,
        status: PROJECT_CONSTANTS.ACTIVE.value,
      })
      this.$store.commit('loading', false)
      this.$emit('next')
    },
    async setCurrentOrigins() {
      this.origins = await this.$repository.projectOriginEntities.find({
        project: this.projectId,
      })
    },
    formatSkillPacks(skillPacks) {
      return skillPacks.map((skillPack) => {
        return {
          id: skillPack.id,
          name: skillPack?.name ?? '',
          tags: skillPack.models,
        }
      })
    },
  },
}
