//
//
//
//
//
//

export default {
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  head() {
    return { title: this.title }
  },
}
