var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-table',{staticClass:"potential-workers-table has-shadow",attrs:{"data":_vm.dataSort,"per-page":10,"aria-next-label":_vm.$t('misc.pagination.next'),"aria-previous-label":_vm.$t('misc.pagination.previous'),"aria-page-label":_vm.$t('misc.pagination.title'),"aria-current-label":_vm.$t('misc.pagination.current'),"hoverable":""}},[_c('b-table-column',{attrs:{"label":"Perfil Persona"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var potentialWorker = ref.row;
return [_c('div',{staticClass:"profile-cell"},[_c('avatar',{staticClass:"profile-avatar",attrs:{"photo-url":potentialWorker.photo}}),_vm._v(" "),_c('div',{staticClass:"personal-info-data"},[_c('p',{staticClass:"person-names"},[_vm._v("\n            "+_vm._s(_vm._f("fullNames")(potentialWorker))+"\n          ")]),_vm._v(" "),(_vm.canShowLatestWork(potentialWorker))?_c('p',{staticClass:"person-title"},[_vm._v("\n            "+_vm._s(_vm._f("latestWork")(potentialWorker))+"\n            "+_vm._s(_vm._f("userWorkStatus")(potentialWorker))+"\n          ")]):_vm._e()])],1)]}}])}),_vm._v(" "),_c('b-table-column',{attrs:{"label":_vm.$t('requiredOccupations.tables.potentialWorkers.record')},scopedSlots:_vm._u([{key:"header",fn:function(ref){return [_c('b-tooltip',{attrs:{"multilined":"","label":_vm.$t('requiredOccupations.tables.potentialWorkers.recordTooltip')}},[_vm._v(_vm._s(_vm.$t('requiredOccupations.tables.potentialWorkers.record'))+"\n          "),_c('b-icon',{attrs:{"icon":"information-outline","size":"is-small"}})],1)]}},{key:"default",fn:function(ref){
var potentialWorker = ref.row;
return [_c('div',{staticClass:"record-cell"},[_c('div',{staticClass:"tags-column"},[_c('div',{staticClass:"record-item"},[_c('b-tag',{attrs:{"rounded":"","type":"is-primary"}},[_vm._v("\n                "+_vm._s(_vm.getExperiencesTotal(potentialWorker))+"\n              ")])],1),_vm._v(" "),_c('div',{staticClass:"record-item"},[_c('b-tag',{attrs:{"rounded":"","type":"is-tertiary"}},[_vm._v("\n                "+_vm._s(potentialWorker.skill_morphs.length || 0)+"\n              ")])],1)]),_vm._v(" "),_c('div',{staticClass:"labels-column"},[_c('div',{staticClass:"record-item"},[_c('p',{staticClass:"record-item-label"},[_vm._v("\n                "+_vm._s(_vm.$t(
                    'requiredOccupations.tables.potentialWorkers.experiences'
                  ))+"\n              ")])]),_vm._v(" "),_c('div',{staticClass:"record-item"},[_c('p',{staticClass:"record-item-label"},[_vm._v("\n                "+_vm._s(_vm.$t('requiredOccupations.tables.potentialWorkers.skills'))+"\n              ")])])])])]}}])}),_vm._v(" "),_c('b-table-column',{attrs:{"label":_vm.$t('requiredOccupations.tables.potentialWorkers.match')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var potentialWorker = ref.row;
return [_c('div',{staticClass:"match-cell"},[_c('div',{staticClass:"match-cell-info"},[_c('radial-progress-bar',{attrs:{"diameter":100,"completed-steps":Math.round(potentialWorker.match),"total-steps":100,"start-color":"#ef4a81","stop-color":"#ef4a81","inner-stroke-color":"#F5F5F5","stroke-width":5,"inner-stroke-width":5}},[_c('p',{staticClass:"value-distance"},[_vm._v("\n              "+_vm._s(potentialWorker.match.toFixed(1))+"%\n            ")])]),_vm._v(" "),_c('div',{staticClass:"match-info"},[_c('p',{staticClass:"match-title"},[_vm._v(_vm._s(potentialWorker.occupation.title))]),_vm._v(" "),_c('p',{staticClass:"match-skills",domProps:{"innerHTML":_vm._s(_vm.skillCountText(potentialWorker.occupation.title))}}),_vm._v(" "),_c('p',{staticClass:"more-matches-link"},[_c('nuxt-link',{attrs:{"to":("/mis-trabajadores/" + (potentialWorker.id))}},[_vm._v("\n                "+_vm._s(_vm.$t(
                    'requiredOccupations.tables.potentialWorkers.moreMatches'
                  ))+"\n              ")])],1)])],1),_vm._v(" "),_c('nuxt-link',{attrs:{"to":("/mis-trabajadores/" + (potentialWorker.id))}},[_c('b-icon',{attrs:{"icon":"chevron-right","size":"is-medium","color":"secondary"}})],1)],1)]}}])}),_vm._v(" "),_c('template',{slot:"empty"},[_c('TableEmptyData')],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }