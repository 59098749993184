//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment'
import DashboardCardTitle from './DashboardCardTitle'
export default {
  components: {
    DashboardCardTitle,
  },
  filters: {
    dateFormat(value) {
      return moment(value).format('DD/MM/YYYY')
    },
  },
  props: {
    dataTable: {
      type: Array,
      default: () => [
        {
          id: 1,
          company: 'Company name',
          created_at: '2021-03-30',
          total_invitations: '0',
        },
      ],
    },
  },
  computed: {
    isEmpty() {
      return this.dataTable.length === 0
    },
  },
  methods: {
    changePage(event) {
      this.$emit('changePageInvitation', event)
    },
  },
}
