const Request = require('@/repositories/request')

class Match extends Request {
  evaluateMatch(body) {
    return this.$strapi.$http.$post('/matchs/evaluate-match', body)
  }

  evaluateMatchModels(body) {
    return this.$strapi.$http.$post('/matchs/models', body)
  }
}

module.exports = Match
