//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    title: {
      type: String,
      default: null,
    },
    tooltipMessage: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
  },
}
