//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ModalWithTitleBar from '~/components/Shared/ModalWithTitleBar'
import Button from '~/components/Shared/Button'
export default {
  components: {
    ModalWithTitleBar,
    Button,
  },
  data: () => {
    return {
      pilotModalTitle: 'Confirmación requisitos piloto',
    }
  },
  methods: {
    goToRegister() {
      this.$router.push('/registro')
    },
    togglePilotModalReq() {
      this.$emit('toggle-pilot-model')
    },
  },
}
