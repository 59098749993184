//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ValidationObserver } from 'vee-validate'
import FormGroup from '@/components/Form/FormGroup'
import ModalWithTitleBar from '@/components/Shared/ModalWithTitleBar'
import ProjectFormSelect from '@/components/Form/ProjectFormSelect'
import PROJECT_CONSTANTS from '@/constants/projects'
import { TYPES } from '@/constants/project-types'
import { getObjectives, getFieldByKey, getModalities } from '@/util/project'
import Button from '~/components/Shared/Button'

export default {
  name: 'ProjectEditModal',
  components: {
    ValidationObserver,
    FormGroup,
    ProjectFormSelect,
    ModalWithTitleBar,
    Button,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    titleModal: {
      type: String,
      default: '',
    },
    project: {
      type: Object,
      default: () => {},
    },
    projectType: {
      type: String,
      default: '',
    },
    targetOccupationTitle: {
      type: String,
      default: '',
    },
  },
  async fetch() {
    this.objectives = getObjectives(this.$t('projects.new.steps.first'))
    this.modalities = getModalities(this.$t('projects.new.steps.first'))
    this.locationsList = await this.$repository.location.find({
      _limit: -1,
      _where: {
        type: 'region',
        code_contains: 'CHL',
      },
    })
  },
  data() {
    return {
      objectives: [],
      locationsList: [],
      name: null,
      objective: null,
      modality: null,
      objective_detail: null,
      description: null,
      locations: [],
      modalities: [],
    }
  },
  computed: {
    getPlaceholder() {
      if (this.objective) {
        return getFieldByKey(this.objective?.value, this.objectives).placeholder
      }
      return ''
    },
    showObjectiveDetail() {
      return this.objective?.value === PROJECT_CONSTANTS.OTHERS
    },
    targetOccupationLabel() {
      return this.project.type === TYPES.SELFDIAGNOSIS
        ? this.$t(
            'projects.new.steps.first.form.targetOccupation.alternativeLabel'
          )
        : this.$t('projects.new.steps.first.form.targetOccupation.label')
    },
    noChanges() {
      return (
        JSON.stringify({
          name: this.project.name,
          objective: this.project.objective,
          objective_detail: this.project.objective_detail,
          description: this.project.description,
          locations: this.project.locations,
          modality: this.project.modality,
        }) ===
        JSON.stringify({
          name: this.name,
          objective: this.objective?.value,
          objective_detail: this.objective_detail,
          description: this.description,
          locations: this.locations,
          modality: this.modality?.value,
        })
      )
    },
    hasModels() {
      return this.project?.has_models
    },
    isNotModelType() {
      return this.project?.type !== TYPES.MODELS
    },
  },
  watch: {
    visible(val) {
      if (val) {
        this.name = this.project.name
        this.objective = getFieldByKey(this.project.objective, this.objectives)
        this.modality = getFieldByKey(this.project.modality, this.modalities)
        this.objective_detail = this.project.objective_detail
        this.description = this.project.description
        this.locations = this.project.locations
      }
    },
  },
  methods: {
    onToggle() {
      this.$emit('toggle')
    },
    onDelete() {
      this.$emit('delete')
    },
    onSubmit() {
      this.$emit('edit', {
        id: this.project.id,
        name: this.name,
        objective: this.objective.value,
        objective_detail: this.objective_detail || '',
        description: this.description,
        locations: this.locations,
        modality: this.modality.value,
      })
    },
  },
}
