import { getOriginSkills, getTargetSkills } from './handleSkills'
import { isSuccessFilterTarget } from './handleFilter'
import {
  getTargetTotalPages,
  getOriginTotalPages,
  getStatus,
} from './handlePage'
import {
  SECTIONS,
  SUBSECTIONS_SKILLS_FLOW,
  SUBSECTIONS,
  SKILLS_KEYS_BY_SUBSECTIONS,
} from '~/constants/questionary'
import { TYPES } from '~/constants/project-types'

export const handleNext = (questionary, projectType, userSkills = []) => {
  if (questionary.section === SECTIONS.ORIGIN) {
    return handleNextOrigin(questionary, projectType)
  }

  if (questionary.section === SECTIONS.TARGET) {
    return handleNextTarget(questionary, projectType, userSkills)
  }

  // only when sections from ORIGIN_SELECT to ORIGIN
  return getStartQuestionaryState(questionary, projectType)
}

const handleNextOrigin = (questionary, projectType) => {
  return questionary.subsection === SUBSECTIONS.SKILLS
    ? handleNextOriginSkills(questionary, projectType)
    : handleNextOriginKnowledges(questionary, projectType)
}

const handleNextOriginSkills = (questionary, projectType) => {
  if (questionary.page === questionary.origin_skills.total_page) {
    const newQuestionary = {
      ...questionary,
      page: questionary.page + 1,
      subsection: SUBSECTIONS.KNOWLEDGES,
    }
    return {
      questionary: newQuestionary,
      skills: getOriginSkills(newQuestionary),
      ...getStatus(newQuestionary, projectType),
    }
  }

  const newQuestionary = {
    ...questionary,
    page: questionary.page + 1,
  }
  return {
    questionary: newQuestionary,
    skills: getOriginSkills(newQuestionary),
    ...getStatus(newQuestionary, projectType),
  }
}

const handleNextOriginKnowledges = (questionary, projectType) => {
  if (
    projectType === TYPES.SELFDIAGNOSIS &&
    questionary.page === questionary.total_page
  ) {
    return getFinishQuestionaryState(questionary)
  }

  if (questionary.page === questionary.total_page) {
    const subsection = SUBSECTIONS_SKILLS_FLOW[projectType][SECTIONS.TARGET][0]
    const newQuestionary = {
      ...questionary,
      page: 1,
      total_page: getTargetTotalPages(questionary),
      section: SECTIONS.TARGET,
      subsection,
    }
    return {
      questionary: newQuestionary,
      skills: getTargetSkills(newQuestionary, projectType),
      ...getStatus(newQuestionary, projectType),
    }
  }

  const newQuestionary = {
    ...questionary,
    page: questionary.page + 1,
  }
  return {
    questionary: newQuestionary,
    skills: getOriginSkills(newQuestionary),
    ...getStatus(newQuestionary, projectType),
  }
}

const handleNextTarget = (questionary, projectType, userSkills) => {
  const startSubsection =
    SUBSECTIONS_SKILLS_FLOW[projectType][SECTIONS.TARGET][0]
  if (questionary.subsection === startSubsection) {
    return questionary.subsection === SUBSECTIONS.REUSE_COMMONS
      ? handleNextTargetInStartSubsection(
          questionary,
          projectType,
          userSkills,
          SKILLS_KEYS_BY_SUBSECTIONS.REUSE_COMMONS,
          SUBSECTIONS.REUSE_SPECIFICS
        )
      : handleNextTargetInStartSubsection(
          questionary,
          projectType,
          userSkills,
          SKILLS_KEYS_BY_SUBSECTIONS.REUSE_SPECIFICS,
          SUBSECTIONS.REUSE_COMMONS
        )
  }
  return handleNextTargetInEndSubsection(questionary, projectType)
}

const handleNextTargetInStartSubsection = (
  questionary,
  projectType,
  userSkills,
  skillsGroupKey,
  nextSubsection
) => {
  if (questionary.page < questionary[skillsGroupKey].total_page) {
    const newQuestionary = {
      ...questionary,
      page: questionary.page + 1,
    }
    return {
      questionary: newQuestionary,
      skills: getTargetSkills(newQuestionary, projectType),
      ...getStatus(newQuestionary, projectType),
    }
  }

  if (isSuccessFilterTarget(questionary, projectType, userSkills)) {
    const newQuestionary = {
      ...questionary,
      subsection: nextSubsection,
      page: questionary.page + 1,
    }
    return {
      questionary: newQuestionary,
      skills: getTargetSkills(newQuestionary, projectType),
      ...getStatus(newQuestionary, projectType),
    }
  }

  return getFinishQuestionaryState(questionary)
}

const handleNextTargetInEndSubsection = (questionary, projectType) => {
  if (questionary.page < questionary.total_page) {
    const newQuestionary = {
      ...questionary,
      page: questionary.page + 1,
    }
    return {
      questionary: newQuestionary,
      skills: getTargetSkills(newQuestionary, projectType),
      ...getStatus(newQuestionary, projectType),
    }
  }
  return getFinishQuestionaryState(questionary)
}

const getStartQuestionaryState = (questionary, projectType) => {
  const newQuestionary = {
    ...questionary,
    section: SECTIONS.ORIGIN,
    subsection: SUBSECTIONS.SKILLS,
    page: 1,
    total_page: getOriginTotalPages(questionary),
  }
  return {
    questionary: newQuestionary,
    skills: getOriginSkills(newQuestionary),
    ...getStatus(newQuestionary, projectType),
  }
}
const getFinishQuestionaryState = (questionary) => {
  const newQuestionary = {
    ...questionary,
    section: SECTIONS.FINISH,
    subsection: null,
    page: null,
    total_page: null,
  }
  return {
    questionary: newQuestionary,
    skills: [],
    statusSection: { isStart: null, isEnd: null },
    statusSubsection: { isStart: null, isEnd: null },
  }
}

export const getCurrentStatus = (questionary, projectType) => {
  const skills =
    questionary.section === SECTIONS.ORIGIN
      ? getOriginSkills(questionary, projectType)
      : getTargetSkills(questionary, projectType)

  return {
    questionary,
    skills,
    ...getStatus(questionary, projectType),
  }
}
