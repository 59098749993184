//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import _ from 'underscore'
import { ValidationObserver } from 'vee-validate'
import { FormInput, FormSelect, FormInputDate } from '@/components/Form'
import GENRES from '@/constants/user-genres'

export default {
  components: {
    FormInput,
    FormSelect,
    FormInputDate,
    ValidationObserver,
  },
  props: {
    countries: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      currentGender: '',
      form: {
        names: '',
        lastnames: '',
        email: '',
        birthdate: '',
        country: '',
        terms: null,
        provide_data: null,
        subscribed: false,
      },
      genders: [
        {
          id: GENRES.MALE,
          name: 'Masculino',
        },
        {
          id: GENRES.FEMALE,
          name: 'Femenino',
        },
        {
          id: GENRES.OTHERS,
          name: 'Otros',
        },
        {
          id: GENRES.NOT_SPECIFIED,
          name: 'Prefiero no decirlo',
        },
      ],
      storeEmailErrorFunction: null,
    }
  },
  watch: {
    currentGender(newValue) {
      this.form.gender = newValue?.id
    },
  },
  methods: {
    storeSetCustomErrors(setCustomErrors) {
      this.storeEmailErrorFunction = setCustomErrors
    },
    async submit() {
      this.$store.commit('loading', true)
      try {
        const response = await this.$repository.user.emailExistsValidation(
          this.form.email
        )
        if (response) {
          this.storeEmailErrorFunction([this.$t('register.errors.emailExists')])
          this.$buefy.notification.open({
            message: this.$t('register.errors.emailExists'),
            type: 'is-danger',
            duration: 5000,
          })
        } else {
          this.$emit('next-step', this.form)
        }
      } catch (e) {
        const idsOfMessageError = _.toArray(
          _.flatten(
            (e.response?.data?.message || []).map((message) =>
              message.messages.map(({ id }) => id)
            )
          )
        )
        let message = this.$t('misc.messages.error')
        if (idsOfMessageError.length > 0) {
          message = idsOfMessageError
            .map((message) => {
              const keyTranslate = `api.errors.${message}`
              const translate = this.$t(`api.errors.${message}`)
              if (keyTranslate !== translate) return translate

              return this.$t('register.errors.general')
            })
            .join(',')
        }

        this.$buefy.notification.open({
          message,
          type: 'is-danger',
          duration: 5000,
        })
      } finally {
        this.$store.commit('loading', false)
      }
    },
  },
}
