export default {
  AZ: {
    label: 'A - Z',
    value: 'AZ',
  },
  ZA: {
    label: 'Z - A',
    value: 'ZA',
  },
}
