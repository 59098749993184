//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapMutations } from 'vuex'
import EmoticonSadOutline from 'vue-material-design-icons/EmoticonSadOutline'
import DashboardCard from '~/components/Dashboard/DashboardCard'

const NUM_OPPORTUNITIES = 4
export default {
  components: {
    DashboardCard,
    EmoticonSadOutline,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  async fetch() {
    try {
      this.$store.commit('loading', true)
      this.opportunities = await this.fetchTrainnningOpportunities()
    } catch (e) {
      this.$buefy.notification.open({
        message: e.message,
        type: 'is-danger',
        autoclose: true,
        duration: 10000,
      })
    } finally {
      this.$store.commit('loading', false)
    }
  },
  data() {
    return {
      opportunities: [],
      showSeeMore: true,
    }
  },
  computed: {
    seeMoreLink() {
      return '/oportunidades/pilotos/'
    },
  },
  methods: {
    ...mapMutations({
      setOpportunity: 'opportunity/setOpportunity',
      setOccupation: 'createOccupation/setOccupation',
    }),
    async fetchTrainnningOpportunities() {
      return await this.$repository.me.getTrainningOpportunities({
        _limit: NUM_OPPORTUNITIES,
        _sort: 'distance:DESC',
      })
    },
    subtitle(opportunity) {
      return `Piloto:<br/> <strong class="color-tertiary">${opportunity.job_seeker_fair.name}</strong>`
    },
    titleHighlight(opportunity) {
      return opportunity.project_name ? `"${opportunity.project_name}"` : ''
    },
    async onOpportunityClick(opportunity) {
      await this.setOpportunity(opportunity)
      this.$router.push(
        `/oportunidades/pilotos/${opportunity.job_seeker_fair.id}/${opportunity.id}`
      )
    },
    goToExperiences() {
      this.$router.push('/experiencias')
    },
  },
}
