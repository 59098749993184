//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    type: {
      type: String,
      default: 'is-warning',
    },
    icon: {
      type: String,
      default: null,
    },
  },
}
