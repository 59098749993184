//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ValidationObserver } from 'vee-validate'
import { FormInput, FormSelect } from '@/components/Form'
import NewOccupationWizardButtons from '@/components/CustomOccupation/NewOccupationWizardButtons.vue'

export default {
  name: 'StepTwo',
  components: {
    ValidationObserver,
    FormInput,
    FormSelect,
    NewOccupationWizardButtons,
  },
  props: {
    occupation: {
      type: Object,
      default: () => ({
        base_occupation: {
          title: '',
        },
      }),
    },
  },
  data: () => ({
    companies: [],
  }),
  computed: {
    titlePlaceholder() {
      return this.occupation.base_occupation
        ? this.$t('customOccupations.new.steps.second.form.titlePlaceholder', [
            this.occupation.base_occupation.title,
          ])
        : ''
    },
  },
  async mounted() {
    this.companies = await this.$repository.company.findLevel()
  },
  methods: {
    onInput() {
      this.$emit('change', this.occupation)
    },
    handleBack() {
      this.$emit('back', this.occupation)
    },
    handleNext() {
      this.$emit('next', this.occupation)
    },
  },
}
