//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    keyAttribute: {
      type: String,
      default: 'id',
    },
    valueAttribute: {
      type: String,
      default: 'value',
    },
    labelAttribute: {
      type: String,
      default: 'label',
    },
    icon: {
      type: String,
      default: 'filter',
    },
  },
  data: () => ({
    innerValue: null,
  }),
  watch: {
    innerValue(value) {
      this.$emit('select', value)
    },
  },
}
