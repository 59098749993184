//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import NewOccupationWizardButtons from '@/components/CustomOccupation/NewOccupationWizardButtons.vue'
import CustomOccupationFormResume from '@/components/CustomOccupation/CustomOccupationFormResume.vue'
import OccupationDetail from '~/components/CustomOccupation/OccupationDetail.vue'

export default {
  name: 'StepThree',
  components: {
    NewOccupationWizardButtons,
    CustomOccupationFormResume,
    OccupationDetail,
  },
  props: {
    occupation: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      exclude_base_skills: false,
    }
  },
  methods: {
    handleBack() {
      this.$emit('back', this.occupation)
    },
    async handleFinish() {
      this.$store.commit('loading', true)
      const request = {
        title: this.occupation.title,
        description: this.occupation.description,
        is_custom: true,
        base_occupation: this.occupation.base_occupation.id,
        active_companies: [this.occupation.company.id],
        exclude_base_skills: this.exclude_base_skills,
      }
      const response = await this.$repository.occupation.create(request)
      await this.$repository.cache.removeByLabel('occupations')
      this.$store.commit('loading', false)
      this.$router.push(`/ocupaciones-personalizadas/${response.id}`)
    },
  },
}
