export default {
  INTERN: 'INTERN',
  TALENT: 'TALENT',
  UNBUNDLING: 'UNBUNDLING',
  OTHERS: 'OTHERS',

  PRESENTIAL: 'PRESENTIAL',
  REMOTE: 'REMOTE',
  HYBRID: 'HYBRID',

  RESKILLING: { name: 'Reskilling', value: 'RESKILLING' },
  UPSKILLING: { name: 'Upskilling', value: 'UPSKILLING' },
  SELFDIAGNOSIS: { name: 'Autodiagnóstico', value: 'SELFDIAGNOSIS' },
  MODELS: { name: 'Modelos', value: 'MODELS' },

  DRAFT: { name: 'En creación', class: '', value: 'DRAFT' },
  ACTIVE: { name: 'En curso', class: 'is-success', value: 'ACTIVE' },
  FINISHED: { name: 'Finalizado', class: 'is-danger', value: 'FINISHED' },

  TYPES: {
    RESKILLING: 'RESKILLING',
    UPSKILLING: 'UPSKILLING',
    SELFDIAGNOSIS: 'SELFDIAGNOSIS',
    MODELS: 'MODELS',
  },
  FINALSTEP: 4,
}
