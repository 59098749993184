import { render, staticRenderFns } from "./MatchPotentialFilters.vue?vue&type=template&id=f3a06300&scoped=true&"
import script from "./MatchPotentialFilters.vue?vue&type=script&lang=js&"
export * from "./MatchPotentialFilters.vue?vue&type=script&lang=js&"
import style0 from "./MatchPotentialFilters.vue?vue&type=style&index=0&id=f3a06300&lang=scss&scoped=true&"
import style1 from "./MatchPotentialFilters.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f3a06300",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {FormSelect: require('/workspace/components/Form/FormSelect.vue').default})
