import {
  SECTIONS,
  SUBSECTIONS_SKILLS_FLOW,
  SUBSECTIONS,
  SKILLS_KEYS_BY_SUBSECTIONS,
} from '~/constants/questionary'

export const getTotalPage = (questionary) => {
  if (questionary.section === SECTIONS.ORIGIN) {
    return (
      questionary.origin_skills.total_page +
      questionary.origin_knowledges.total_page
    )
  }
  if (questionary.section === SECTIONS.TARGET) {
    return (
      questionary.target_reuse_commons.total_page +
      questionary.target_reuse_specifics.total_page
    )
  }
  return null
}
export const getOriginTotalPages = (questionary) => {
  return (
    questionary.origin_skills.total_page +
    questionary.origin_knowledges.total_page
  )
}
export const getTargetTotalPages = (questionary) => {
  return (
    questionary.target_reuse_commons.total_page +
    questionary.target_reuse_specifics.total_page
  )
}
export const isFinishSubSection = (questionary, projectType) => {
  if (
    questionary.section === SECTIONS.ORIGIN &&
    questionary.subsection === SUBSECTIONS.SKILLS
  ) {
    return questionary.page === questionary.origin_skills.total_page
  }
  if (
    questionary.section === SECTIONS.ORIGIN &&
    questionary.subsection === SUBSECTIONS.KNOWLEDGES
  ) {
    return questionary.page === questionary.total_page
  }
  const startTargetSubsection =
    SUBSECTIONS_SKILLS_FLOW[projectType][SECTIONS.TARGET][0]
  if (
    questionary.section === SECTIONS.TARGET &&
    questionary.subsection === startTargetSubsection
  ) {
    return (
      questionary.page ===
      questionary[SKILLS_KEYS_BY_SUBSECTIONS[startTargetSubsection]].total_page
    )
  }
  return questionary.page === questionary.total_page
}

export const isStartSubSection = (questionary, projectType) => {
  if (
    questionary.section === SECTIONS.ORIGIN &&
    questionary.subsection === SUBSECTIONS.SKILLS
  ) {
    return questionary.page === 1
  }
  if (
    questionary.section === SECTIONS.ORIGIN &&
    questionary.subsection === SUBSECTIONS.KNOWLEDGES
  ) {
    return questionary.page === questionary.origin_skills.total_page + 1
  }
  const startTargetSubsection =
    SUBSECTIONS_SKILLS_FLOW[projectType][SECTIONS.TARGET][0]
  if (
    questionary.section === SECTIONS.TARGET &&
    questionary.subsection === startTargetSubsection
  ) {
    return questionary.page === 1
  }
  const startPage =
    questionary[SKILLS_KEYS_BY_SUBSECTIONS[startTargetSubsection]].total_page +
    1
  return questionary.page === startPage
}
export const getStatus = (questionary, projectType) => {
  return {
    statusSection: {
      isStart: questionary.page === 1,
      isEnd: questionary.page === questionary.total_page,
    },
    statusSubsection: {
      isStart: isStartSubSection(questionary, projectType),
      isEnd: isFinishSubSection(questionary, projectType),
    },
  }
}
