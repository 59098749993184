//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    skillMorphs: {
      type: Array,
      default: () => [],
    },
    employees: {
      type: Array,
      default: () => [],
    },
    roleDescriptions: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    totalSkills() {
      return this.getTotalSkills(this.skillMorphs)
    },
    totalEmployees() {
      return this.employees.length
    },
    totalJobPositions() {
      return this.roleDescriptions.length
    },
    infoCards() {
      return [
        {
          title: 'Cantidad de habilidades',
          icon: '/img/icons/kpi-skills.svg',
          description: 'Total Empresa',
          value: this.totalSkills.toString(),
        },
        {
          title: 'Cantidad de trabajadores',
          icon: '/img/icons/kpi-employees.svg',
          url: '/mis-trabajadores',
          description: 'Total Empresa',
          value: this.totalEmployees.toString(),
        },
        {
          title: 'Cantidad de Cargos',
          icon: '/img/icons/kpi-job-positions.svg',
          description: 'Total Empresa',
          value: this.totalJobPositions.toString(),
        },
      ]
    },
  },
  methods: {
    getTotalSkills(skillMorphs) {
      const uniqueSkills = this.getUniqueSkills(skillMorphs)
      return uniqueSkills.length
    },
    getUniqueSkills(skillMorphs) {
      return [
        ...new Set(
          skillMorphs.map((skillMorph) => {
            return skillMorph.skill.id || skillMorph.skill
          })
        ),
      ]
    },
  },
}
