//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    percentage: {
      type: Number,
      default: 100,
      validator(value) {
        return value > -1 && value < 101
      },
    },
    type: {
      type: String,
      default: 'is-primary',
    },
  },
}
