//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { calculateARPText } from '@/util/entity/test'
import { mapMutations } from 'vuex'

import RadialProgressBar from 'vue-radial-progress'
import TableEmptyOccupations from '@/components/Shared/Table/TableEmptyOccupations.vue'
import Avatar from '../Perfil/Avatar.vue'
import Tag from '~/components/Shared/Tag.vue'
import MODE_REGISTER from '~/constants/mode_registers'

const TYPE_TAG_MODE_REGISTER = {}
TYPE_TAG_MODE_REGISTER[MODE_REGISTER.RELINK] = 'is-danger'
TYPE_TAG_MODE_REGISTER[MODE_REGISTER.INVITATION] = 'is-info'

export default {
  components: {
    Avatar,
    RadialProgressBar,
    TableEmptyOccupations,
    Tag,
  },
  filters: {
    fullNames({ user }) {
      return `${user?.names}  ${user?.lastnames}`
    },
    latestWork({ user }) {
      return user?.latestWork?.occupation?.title
    },
    userWorkStatus({ user }) {
      return this.isCurrentlyEmployed(user)
        ? this.$t('requiredOccupations.tables.potentialWorkers.employed')
        : this.$t('requiredOccupations.tables.potentialWorkers.unemployed')
    },
    typeTag(modeRegister) {
      return (
        TYPE_TAG_MODE_REGISTER[modeRegister] ||
        TYPE_TAG_MODE_REGISTER[MODE_REGISTER.RELINK]
      )
    },
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      perPage: 10,
      page: 1,
    }
  },
  computed: {
    dataSort() {
      return this.data
        .filter(() => true)
        .sort((a, b) => b.opportunity.distance - a.opportunity.distance)
    },
  },
  methods: {
    canShowLatestWork(user) {
      return !!user?.latestWork?.id
    },
    ...mapMutations({
      setWorkerId: 'potential-worker/setWorkerId',
    }),
    calculateARPText,
    isCurrentlyEmployed(user) {
      return user?.latestWork?.end_date_work === null || user?.company
    },
    skillCountText(opportunity) {
      return this.$t('opportunities.detail.skillCount', [
        opportunity.skillsItHas || opportunity.skillsItHas.length,
        opportunity.skills || opportunity.skills.length,
      ])
    },
    goToWorkerDetail(user) {
      this.setWorkerId(user.id)
      this.$router.push('/piloto-cesantes/persona-potencial')
    },
    modeRegister(mode) {
      return mode
        ? this.$t(`modeRegister.${mode}`)
        : this.$t(`modeRegister.${MODE_REGISTER.RELINK}`)
    },
  },
}
