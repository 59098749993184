//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Button from '@/components/Shared/Button'
import Chip from '@/components/design-system/Chip.vue'
import SkillsEmptyState from './SkillsEmptyState.vue'
export default {
  components: {
    Button,
    Chip,
    SkillsEmptyState,
  },
  props: {
    questionary: {
      type: Object,
      default: () => {},
    },
    skills: {
      type: Array,
      default: () => [],
    },
    page: {
      type: Number,
      default: 1,
    },
    totalPage: {
      type: Number,
      default: 1,
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    userSkillIds: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    skillsWithStatus() {
      return this.skills.map((skill) => ({
        ...skill,
        active: this.userSkillIds.includes(skill.id),
      }))
    },
  },
  methods: {
    handleChipChange({ id, status }) {
      this.$emit('change', { id, status })
    },
    handleBack() {
      this.$emit('back')
    },
    handleNext() {
      this.$emit('next')
    },
  },
}
