//
//
//
//

export default {
  props: {
    inverse: {
      type: Boolean,
      default: false,
    },
    leyend: {
      type: Array,
      default: () => [
        'Total',
        'Empresas',
        'Empleados',
        'Desempleados',
        'Publico',
      ],
    },
    dataY: {
      type: Array,
      default: () => [
        {
          value: 'Marzo, 2021',
          textStyle: {
            color: '#4A4A4A;',
            fontWeight: 'bold',
            fontSize: 11,
          },
        },
        {
          value: 'Abril, 2021',
          textStyle: {
            color: '#4A4A4A;',
            fontWeight: 'bold',
            fontSize: 11,
          },
        },
        {
          value: 'Mayo, 2021',
          textStyle: {
            color: '#4A4A4A;',
            fontWeight: 'bold',
            fontSize: 11,
          },
        },
      ],
    },
    series: {
      type: Array,
      default: () => [
        {
          name: 'Total',
          type: 'bar',
          data: [18203, 23489, 29034],
        },
        {
          name: 'Empresas',
          type: 'bar',
          data: [18203, 23489, 29034],
        },
        {
          name: 'Empleados',
          type: 'bar',
          data: [18203, 23489, 29034],
        },
        {
          name: 'Desempleados',
          type: 'bar',
          data: [18203, 23489, 29034],
        },
        {
          name: 'Publico',
          type: 'bar',
          data: [18203, 23489, 29034],
        },
      ],
    },
    colors: { type: Array, default: () => [] },
    title: {
      type: String,
      default: () => 'title',
    },
    subtitle: {
      type: String,
      default: () => 'subtitle',
    },
    leyendShow: {
      type: Boolean,
      default: () => true,
    },
  },
  data() {
    return {
      chart: null,
    }
  },
  computed: {
    option() {
      return {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            // Use axis to trigger tooltip
            type: 'shadow', // 'shadow' as default; can also be 'line' or 'shadow'
          },
        },
        title: {
          text: this.title,
          subtext: this.subtitle,
          subtextStyle: {
            color: '#4A4A4A',
            fontSize: 25,
            fontFamily: 'Roboto',
          },
          textStyle: {
            fontSize: 13,
            color: '#939393',
            fontFamily: 'Roboto',
          },
        },
        legend: {
          show: this.leyendShow,
          orient: 'horizontal',
          data: this.leyend,
          right: true,
          textStyle: {
            fontSize: 12,
            color: '#939393',
            fontFamily: 'Roboto',
          },
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          type: 'value',
        },
        yAxis: {
          type: 'category',
          data: this.dataY,
          inverse: this.inverse,
        },
        color: this.colors,
        series: this.series,
      }
    },
  },
  watch: {
    dataY(val) {
      this.chart.clear()
      this.chart.setOption(this.option)
    },
  },
  mounted() {
    this.initChart()
  },
  methods: {
    initChart() {
      const chart = this.$echarts.init(this.$refs.chartBar)
      chart.setOption(this.option)
      chart.resize('100%', '100%')
      this.chart = chart
    },
  },
}
