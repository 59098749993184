//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SearchEngine from '@/components/Shared/SearchEngine/SearchEngine.vue'
import ListChip from '~/components/Experiences/ListChip.vue'

export default {
  components: {
    ListChip,
    SearchEngine,
  },
  props: {
    title: {
      type: String,
      default: () => 'components.skills.freeSkills.title',
    },
    type: {
      type: String,
      default: () => '',
    },
    excludedSkills: {
      type: Array,
      default: () => [],
    },
    selectedSkills: {
      type: Array,
      default: () => [],
    },
    suggestSkills: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    data: [],
    skills: [],
    pattern: '',
  }),
  computed: {
    showSkills() {
      return this.skills.map((skill) => ({
        ...skill,
        active: this.selectedSkills.includes(skill.id),
      }))
    },
    groupLabels() {
      return {
        'match-pattern': this.$t(
          'components.searchEngine.groups.skills.byTitle',
          [this.pattern]
        ),
        'not-match-pattern': this.$t(
          'components.searchEngine.groups.skills.byLabels'
        ),
      }
    },
    excludedToSearchSkillIds() {
      return this.skills.map((skill) => skill.id)
    },
  },
  methods: {
    handleSelect(selected) {
      if (selected) {
        if (!this.isSuggestedSkill(selected.id)) {
          this.skills = [...this.skills, { ...selected, active: true }]
        }
        this.$emit('change', { id: selected.id, status: true })
      }
    },
    handleChangeChip({ id, status }) {
      this.$emit('change', { id, status })
    },
    isSuggestedSkill(skillId) {
      const suggestedSkillIds = this.suggestSkills.map((skill) => skill.id)
      return suggestedSkillIds.includes(skillId)
    },
    search(pattern) {
      return this.$repository.skill.searchSkillsByTerm(
        pattern,
        this.excludedToSearchSkillIds
      )
    },
    handleInput(input) {
      this.pattern = input
    },
  },
}
