//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SkillsOrderSelect from '~/components/Experiences/SuggestSkills/SkillsOrderSelect.vue'
export default {
  components: { SkillsOrderSelect },
  props: {
    title: {
      type: String,
      default: '',
    },
    skills: {
      type: Array,
      default: () => [],
    },
    suggestedSkillsUnselected: {
      type: Number,
      default: () => 0,
    },
    experienceSkillsSelected: {
      type: Number,
      default: () => 0,
    },
    edit: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    order: null,
  }),
  methods: {
    toggle() {
      this.$emit('toggle')
    },
    handleSelectOrder(value) {
      this.$emit('select-order', value)
    },
  },
}
