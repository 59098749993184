import Permission from '@/repositories/permission'

export default (ctx, inject) => {
  const permissionRepository = new Permission(ctx)
  const permissions = {
    allow: async (service) => {
      if (!service || (service || []).length === 0) {
        return []
      }
      const services = Array.isArray(service) ? service : [service]
      const permissions = await permissionRepository.find(services)
      const result = services.map((serviceLabel) => {
        const permission = permissions.find(
          (permission) => permission.service === serviceLabel
        )
        return {
          service: serviceLabel,
          enabled: permission ? permission.enabled : false,
        }
      })
      if (result.length === 1) {
        return Array.isArray(service) ? result : result[0]
      }
      return result
    },
    allowByProject: async (projectId, services) => {
      if (!services || (services || []).length === 0) {
        return []
      }
      const permissions = await permissionRepository.allowByProject(
        projectId,
        services
      )
      const result = services.map((serviceLabel) => {
        const permission = permissions.find(
          (permission) => permission.service === serviceLabel
        )
        return {
          service: serviceLabel,
          enabled: permission ? permission.enabled : false,
        }
      })
      return result
    },
    findAll: async () => {
      const permissions = await permissionRepository.findAll()
      return permissions.map((permission) => ({
        service: permission.service,
        enabled: permission.enabled,
      }))
    },
  }
  inject('permissions', permissions)
  ctx.$permissions = permissions
}
