const Request = require('@/repositories/request')
class Suggestion extends Request {
  /**
   *
   * @param params
   * @returns {*}
   */
  create(params) {
    return this.$strapi.$suggestions.create(params)
  }
}

module.exports = Suggestion
