const Request = require('@/repositories/request')
const qs = require('qs')

class EducationalType extends Request {
  /**
   *
   * @param {int} id
   * @returns {*}
   */
  findOne(id) {
    return this.$strapi['$educational-types'].findOne(id)
  }

  /**
   *
   * @param {Object} params
   * @return {*}
   */
  findAll(params) {
    const stringifyParams = qs.stringify({ ...params }, { encode: false })
    return this.$strapi['$educational-types'].find(stringifyParams)
  }
}

module.exports = EducationalType
