import { render, staticRenderFns } from "./CompanySkillsInventoryCard.vue?vue&type=template&id=11d3b6ff&"
import script from "./CompanySkillsInventoryCard.vue?vue&type=script&lang=js&"
export * from "./CompanySkillsInventoryCard.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DashboardCard: require('/workspace/components/Dashboard/DashboardCard.vue').default})
