var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"skill-container"},[_c('div',{staticClass:"skill-case"},[_c('div',{staticClass:"skill-title"},[_c('h3',{staticClass:"title is-3"},[_vm._v("\n        "+_vm._s(_vm.title)+"\n      ")])]),_vm._v(" "),_c('div',{staticClass:"columns is-mobile"},[_c('div',{staticClass:"column flex-all-center"},[(!_vm.backDisabled)?_c('chevron-left',{attrs:{"size":48},on:{"click":_vm.back}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"column is-8"},[_c('p',[_vm._v("\n          "+_vm._s(_vm.description)+"\n        ")])]),_vm._v(" "),_c('div',{staticClass:"column flex-all-center"},[(!_vm.forthDisabled)?_c('chevron-right',{attrs:{"size":48},on:{"click":_vm.forth}}):_vm._e()],1)]),_vm._v(" "),_c('div',{staticClass:"columns is-mobile skill-buttons-container"},[_c('div',{staticClass:"column left-button-container"},[_c('b-button',{class:{
            'is-success': true,
            'is-light': true,
            'is-selected-skill--success': _vm.applySelected,
          },attrs:{"icon-left":"check-bold","rounded":""},on:{"click":_vm.apply}},[_vm._v("\n          "+_vm._s(_vm.$t('experience.register.applied'))+"\n        ")])],1),_vm._v(" "),_c('div',{staticClass:"column right button-container"},[_c('b-button',{class:{
            'is-danger': true,
            'is-light': true,
            'is-selected-skill--danger': _vm.doNotApplySelected,
          },attrs:{"icon-left":"close-thick","rounded":""},on:{"click":_vm.doNotApply}},[_vm._v("\n          "+_vm._s(_vm.$t('experience.register.notApplied'))+"\n        ")])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }