//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ValidationProvider } from 'vee-validate'
import FormLabel from '~/components/Form/FormLabel'

export default {
  name: 'FormInput',
  components: {
    FormLabel,
    ValidationProvider,
  },
  props: {
    vid: {
      type: String,
      default: '',
    },
    rules: {
      type: [Object, String],
      default: '',
    },
    value: {
      type: null,
      default: null,
    },
    ignoreValidGreen: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    innerValue: '',
    init: false,
  }),
  watch: {
    innerValue(newVal) {
      this.$emit('input', newVal)
      this.init = true
    },
    value(newVal) {
      this.innerValue = newVal
      this.init = true
    },
  },
  created() {
    if (this.value) {
      this.innerValue = this.value
    }
  },
  mounted() {
    this.$emit('set-custom-errors', this.setErrors)
  },
  methods: {
    iconRightClick(event) {
      this.$emit('icon-right-click', event)
    },
    onBlur(event) {
      this.$emit('blur', event)
    },
    setErrors(listErrors) {
      this.$refs.provider.applyResult({
        errors: listErrors,
        valid: false,
        failedRules: {},
      })
    },
  },
}
