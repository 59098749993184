//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ChartPie from '../Charts/ChartPie.vue'
import DashboardCardTitle from './DashboardCardTitle.vue'

const COLORS = {
  background: [
    '#EF4A81',
    '#2CD5C4',
    '#DBDBDB',
    '#297FB8',
    '#8D44AD',
    '#FFDD57',
    '#E84C3D',
    '#16A086',
    '#F39C11',
  ],
  fonts: [
    '#FFFFFF',
    '#FFFFFF',
    '#4A4A4A',
    '#FFFFFF',
    '#FFFFFF',
    '#4A4A4A',
    '#FFFFFF',
    '#FFFFFF',
    '#FFFFFF',
  ],
}

const PER_PAGE = 3

export default {
  components: {
    DashboardCardTitle,
    ChartPie,
  },
  filters: {
    percent(value) {
      return Math.round(value) + '%'
    },
  },
  props: {
    dataTable: {
      type: Array,
      default: () => [],
    },
    dataChart: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      page: 1,
    }
  },

  computed: {
    dataTableCurrentPage() {
      const INDEX_START = (this.page - 1) * PER_PAGE
      const INDEX_END = INDEX_START + PER_PAGE
      return this.dataTable.slice(INDEX_START, INDEX_END)
    },
    colorsChart() {
      return COLORS.background
    },
    isEmpty() {
      return this.dataChart.length === 0
    },
  },
  methods: {
    styleBadge(rowNumber) {
      const INDEX = (this.page - 1) * PER_PAGE + rowNumber
      return `
      background-color:${COLORS.background[INDEX]};
      color:${COLORS.fonts[INDEX]}`
    },
    changePage(value) {
      const PAGE = this.page + value
      const PAGE_END = Math.ceil(this.dataTable.length / PER_PAGE)
      if (PAGE >= 1 && PAGE <= PAGE_END) {
        this.page = PAGE
      }
    },
  },
}
