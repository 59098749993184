//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    title: { type: String, default: '' },
    subtitle: { type: [String, Object], default: '' },
    isTitleBold: { type: Boolean, default: false },
  },
  methods: {
    stripHtml(value) {
      const div = document.createElement('div')
      div.innerHTML = value
      return div.textContent || div.textContent || ''
    },
  },
  head() {
    const title = this.$props.title
    if (title) {
      return { title: this.stripHtml(title) }
    }
    return {}
  },
}
