//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ListChip from './ListChip.vue'
import SearchSkills from '~/components/Experiences/SearchSkills.vue'

export default {
  components: {
    SearchSkills,
    ListChip,
  },
  props: {
    type: {
      type: String,
      default: () => '',
    },
    userSkills: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    data: [],
    filteredData: [],
    suggestSkills: [],
    selectedSkills: [],
    existSkills: [],
    doneTypingInterval: 1500,
    typingTimer: 0,
    searchLoading: false,
  }),
  methods: {
    hasSuggestSkills() {
      return this.suggestSkills.length > 0
    },
    timeoutTyping(value) {
      clearTimeout(this.typingTimer)
      this.searchLoading = true
      this.typingTimer = setTimeout(() => {
        this.handleTyping(value)
      }, this.doneTypingInterval)
    },
    async handleTyping(value) {
      const suggests = await this.$repository.skill.suggest(value)
      this.filteredData = suggests.filter(
        (skill) =>
          !this.selectedSkills.includes(skill.id) &&
          !this.userSkills.some((userSkill) => userSkill.id === skill.id)
      )
      this.existSkills = suggests.filter((skill) =>
        [
          ...this.selectedSkills,
          ...this.userSkills.map((skill) => skill.id),
        ].includes(skill.id)
      )
      this.searchLoading = false
    },
    handleSelect(selected) {
      if (selected) {
        this.cleanInactiveSkills()
        this.suggestSkills.push({ ...selected, active: true })
        if (!this.selectedSkills.includes(selected.id)) {
          this.selectedSkills.push(selected.id)
        }
        this.loadSimilarSkills(selected.id)
        this.$emit('selected', this.selectedSkills)
      }
      this.existSkills = []
    },
    handleClean() {
      this.filteredData = []
      this.existSkills = []
    },
    handleSelectedChips(selectedSkills) {
      this.suggestSkills = this.suggestSkills.map((skill) => {
        const active = selectedSkills.includes(skill.id)
        return { ...skill, active }
      })
      this.selectedSkills = selectedSkills
      this.$emit('selected', selectedSkills)
    },
    cleanInactiveSkills() {
      this.suggestSkills = this.suggestSkills.filter((skill) =>
        this.selectedSkills.includes(skill.id)
      )
    },
    async loadSimilarSkills(id) {
      const similarSkills = await this.$repository.skill.getSimilars({ id })

      if (similarSkills.length === 0) {
        return
      }

      const suggestSkillIds = this.suggestSkills.map((skill) => skill.id)

      const newSuggestSkills = similarSkills
        .filter((skill) => !suggestSkillIds.includes(skill.id))
        .map((skill) => ({
          ...skill,
          active: false,
        }))

      this.suggestSkills = [...this.suggestSkills, ...newSuggestSkills]
    },
  },
}
