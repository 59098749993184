import { executeOnlyLastCall } from '@/util/helpers'
import CONSUMER_ENTITIES_CONSTANTS from '~/constants/consumers-entities'

const TIME_TO_SUBMIT_REQUEST = 10

export default {
  data: () => ({
    timerId: null,
  }),
  methods: {
    evaluateOnlyLastMatchUser(userId) {
      this.timerId = executeOnlyLastCall(
        this.timerId,
        () => this.evaluateMatchUser(userId),
        TIME_TO_SUBMIT_REQUEST
      )
    },
    evaluateOnlyLastMatchOccupation(occupationId) {
      this.timerId = executeOnlyLastCall(
        this.timerId,
        () => this.evaluateMatchOccupation(occupationId),
        TIME_TO_SUBMIT_REQUEST
      )
    },
    evaluateMatchModelUser(userId, isProjectId) {
      this.$repository.match.evaluateMatchModels({
        id: userId,
        isProjectId: !!isProjectId || false,
      })
    },
    evaluateMatchUser(userId) {
      this.$repository.match.evaluateMatch({
        entity_id: userId,
        entity_type: CONSUMER_ENTITIES_CONSTANTS.USER,
      })
      this.evaluateMatchModelUser(userId)
    },
    evaluateMatchOccupation(occupationId) {
      this.$repository.match.evaluateMatch({
        entity_id: occupationId,
        entity_type: CONSUMER_ENTITIES_CONSTANTS.OCCUPATION,
      })
    },
  },
}
