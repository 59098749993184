//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Chip from '~/components/design-system/Chip.vue'
import Button from '~/components/Shared/Button.vue'
import SuggestedSkillsEmptySkills from '~/components/Experiences/SuggestSkills/SuggestedSkillsEmptySkills.vue'
export default {
  components: {
    Chip,
    Button,
    SuggestedSkillsEmptySkills,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    skills: {
      type: Array,
      default: () => [],
    },
    activeSkillsIds: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    externalSelected: {
      type: Array,
      default: () => [],
    },
    edit: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    defaultQtySkillsVisible: 5,
    quantitySkillVisible: 5,
  }),
  computed: {
    isEmpty() {
      return this.skills.length === 0
    },
    lastExternalSelectedSkillId() {
      return this.externalSelected.length > 0
        ? this.externalSelected.at(-1)
        : null
    },
    visibleSkills() {
      return this.skills.slice(0, this.quantitySkillVisible)
    },

    quantitySkillNotVisible() {
      return this.skills.length - this.quantitySkillVisible
    },
    isExpanded() {
      return this.skills.length <= this.quantitySkillVisible
    },
  },
  methods: {
    isActive(skillId) {
      return this.activeSkillsIds.includes(skillId)
    },
    expand() {
      this.quantitySkillVisible = this.skills.length
    },
    shrink() {
      this.quantitySkillVisible = this.defaultQtySkillsVisible
    },
    handleChangeSkillStatus({ id, status }) {
      this.$emit('change', { id, status })
    },
    toggle() {
      this.$emit('toggle')
    },
  },
}
