//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    label: {
      type: String,
      default: () => '',
    },
    data: {
      type: Array,
      default: () => [],
    },
    minCharacterSearch: {
      type: Number,
      default: () => 2,
    },
    addSuggestEnabled: {
      type: Boolean,
      default: () => false,
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
  },
  data: () => ({
    name: '',
    isActive: false,
  }),
  methods: {
    handleSelect(selected) {
      this.$emit('selected', selected)
    },
    handleTyping(value) {
      if (value.length >= this.minCharacterSearch) {
        this.isActive = true
        this.$emit('typing', value)
      } else {
        this.$emit('cleaning', value)
        this.isActive = false
      }
    },
    handleSuggestSkill() {
      this.$emit('suggest')
    },
  },
}
