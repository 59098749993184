//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AddSkillModal from '@/components/Experiences/AddSkillModal.vue'
import DeleteSkillModal from '@/components/Experiences/DeleteSkillModal.vue'
import SuggestedSkills from '~/components/Experiences/SuggestSkills/SuggestedSkills.vue'
import SKILL_MORPHS from '~/constants/skill-morphs'
import {
  showErrorNotification,
  showSuccessNotification,
} from '~/util/notifications'

export default {
  components: { AddSkillModal, DeleteSkillModal, SuggestedSkills },
  props: {
    experience: {
      type: Object,
      default: null,
    },
  },
  async fetch() {
    this.userSkillMorphs = await this.getUserSkillsMorphs(this.$strapi.user.id)
    const userSkillValidatedIds = this.userSkillMorphs.map(
      ({ skill }) => skill.id
    )

    this.userSkillValidatedIds = [...new Set(userSkillValidatedIds)]

    this.experienceSkills = this.getValidatedSkillsOfExperience(
      this.experience.id
    )
    this.suggestedSkills = await this.getSuggestSkills(
      this.experience,
      userSkillValidatedIds
    )
  },
  data: () => ({
    userSkillValidatedIds: [],
    experienceSkills: [],
    suggestedSkills: [],
    deleteSkillId: null,
    userSkillMorphs: [],
    modal: {
      addSkill: false,
      deleteSkill: false,
    },
  }),
  methods: {
    toggleAddSkillModal() {
      this.modal.addSkill = !this.modal.addSkill
    },
    toogleDeleteSkillModal() {
      this.modal.deleteSkill = !this.modal.deleteSkill
    },
    getValidatedSkillsOfExperience() {
      // this method has to be implemented by a component that extends ExperienceSkillsBase.vue
    },
    async getSuggestSkills() {
      // this method has to be implemented by a component that extends ExperienceSkillsBase.vue
    },
    updateUserSkills({ id, status }) {
      if (status) {
        this.userSkillValidatedIds.push(id)
      } else {
        this.userSkillValidatedIds = this.userSkillValidatedIds.filter(
          (skillId) => skillId !== id
        )
      }
    },
    handleCancelDeleteSkill() {
      this.updateUserSkills({ id: this.deleteSkillId, status: true })
      this.deleteSkillId = null
      this.toogleDeleteSkillModal()
    },
    async handleConfirmDeleteSkill() {
      this.$store.commit('loading', true)
      try {
        await this.deleteSkill(this.deleteSkillId)
        await this.updateTotalHasSkills(-1)
        this.toogleDeleteSkillModal()
        this.deleteSkillId = null
        showSuccessNotification(this, 'Habilidad eliminada')
      } catch (error) {
        showErrorNotification(this, 'Ocurrió un error')
      } finally {
        this.$store.commit('loading', false)
      }
    },
    async handleChangeChip({ id, status }) {
      if (status) {
        await this.addSkill(id)
      } else {
        this.deleteSkillId = id
        this.toogleDeleteSkillModal()
      }
      this.updateUserSkills({ id, status })
    },
    addSkill() {
      // this method has to be implemented by a component that extends ExperienceSkillsBase.vue
    },
    deleteSkill() {
      // this method has to be implemented by a component that extends ExperienceSkillsBase.vue
    },
    saveSkills() {
      // this method has to be implemented by a component that extends ExperienceSkillsBase.vue
    },
    updateTotalHasSkills() {
      // this method has to be implemented by a component that extends ExperienceSkillsBase.vue
    },
    async addSkillsFromModal(skillsToAdd) {
      const suggestedSkillsIds = [
        ...this.experienceSkills,
        ...this.suggestedSkills,
      ].map((suggestedSkill) => suggestedSkill.id)

      const skillMorphsToAdd = await this.saveSkills(
        skillsToAdd,
        suggestedSkillsIds
      )

      skillMorphsToAdd.forEach((skillMorph) => {
        if (skillMorph.origin_type === SKILL_MORPHS.ORIGIN_TYPES.FREE) {
          this.experienceSkills.push(skillMorph.skill)
        }
        this.updateUserSkills({ id: skillMorph.skill.id, status: true })
      })
    },
    async getUserSkillsMorphs(userId) {
      const skillMorphs = await this.$repository.skillMorph.findAll({
        _where: { user: userId, has_skill: true },
        populate: ['skill'],
        _limit: -1,
      })
      return skillMorphs
    },
  },
}
