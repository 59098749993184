import { render, staticRenderFns } from "./DashboardTopWithTotal.vue?vue&type=template&id=47d836c3&scoped=true&"
import script from "./DashboardTopWithTotal.vue?vue&type=script&lang=js&"
export * from "./DashboardTopWithTotal.vue?vue&type=script&lang=js&"
import style0 from "./DashboardTopWithTotal.vue?vue&type=style&index=0&id=47d836c3&lang=scss&scoped=true&"
import style1 from "./DashboardTopWithTotal.vue?vue&type=style&index=1&lang=scss&"
import style2 from "./DashboardTopWithTotal.vue?vue&type=style&index=2&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47d836c3",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {DashboardCardTitle: require('/workspace/components/Dashboard/DashboardCardTitle.vue').default})
