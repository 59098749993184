//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Notification',
  data() {
    return {
      isDismissed: false,
    }
  },
  methods: {
    dismiss() {
      this.isDismissed = true
      this.$buefy.snackbar.open({
        message: 'Dismissed',
        queue: false,
      })
    },
  },
}
