//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ModalWithTitleBar from '@/components/Shared/ModalWithTitleBar'
import AnnouncementImg from '~/assets/svg/announcement.svg'

export default {
  components: {
    AnnouncementImg,
    ModalWithTitleBar,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    goHome() {
      this.$emit('cancel')
    },
  },
}
