//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Button from '@/components/Shared/Button'
import config from '~/config'
export default {
  components: {
    Button,
  },
  data() {
    return {
      contactEmail: config.contactEmail,
    }
  },

  methods: {
    backToHome() {
      if (this.$strapi.user) {
        this.$router.push('/inicio')
      } else {
        this.$router.push('/')
      }
    },
  },
}
