//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LogosSection from '../Shared/LogosSection.vue'
export default {
  components: {
    LogosSection,
  },
}
