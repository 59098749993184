//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import OccupationDetail from '~/components/CustomOccupation/OccupationDetail.vue'
export default {
  name: 'ListviewOccupations',
  components: {
    OccupationDetail,
  },
  filters: {
    truncate(text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix
      } else {
        return text
      }
    },
  },
  props: {
    occupations: {
      type: Array,
      default: () => [],
    },
    currentPage: {
      type: Number,
      default: () => 1,
    },
  },
  data() {
    return {
      perPage: 10,
      checkedRows: [],
      occupationSkills: {},
    }
  },
  computed: {
    calculate() {
      return this.currentPage ===
        Math.ceil(this.occupations.length / this.perPage) &&
        this.occupations.length % this.perPage !== 0
        ? `${this.occupations.length % this.perPage}`
        : `${this.perPage}`
    },
  },
  methods: {
    async onCheckOccupation(checkedList, row) {
      if (checkedList.length > 0) {
        checkedList.splice(0, checkedList.length)
        checkedList.push(row)
      }
      await this.getOccupationSkills(row)
      this.$emit('checked', checkedList)
    },
    onChangePage(page) {
      this.$emit('page-change', page)
    },
    async getOccupationSkills(row) {
      if (this.occupationSkills[row.id]) return
      this.occupationSkills[row.id] = await this.getSkillsByOccupation(row.id)
      this.$emit('hover', this.occupationSkills)
    },
    async getSkillsByOccupation(occupationId) {
      const occupation = await this.$repository.occupation.findOne(
        occupationId,
        ['skills.skills']
      )
      return occupation.skills.map(({ skills }) => skills).flat()
    },
    async toggleDetails(row) {
      this.$store.commit('loading', true)
      await this.getOccupationSkills(row)
      this.$refs.occupationTable.toggleDetails(row)
      this.$store.commit('loading', false)
    },
    isRowExpanded(row) {
      return this.$refs.occupationTable.isVisibleDetailRow(row)
    },
  },
}
