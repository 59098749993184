//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'TableEmptyData',
}
