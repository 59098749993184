const ACTION_TYPES = {
  ADD: 'ADD',
  ORIGIN: 'ORIGIN',
  REMOVE: 'REMOVE',
  REMOVE_ADD: 'REMOVE_ADD',
  REMOVE_ORIGIN: 'REMOVE_ORIGIN',
  PENDING: 'PENDING',
}

const ENTITY_TYPES = {
  OCCUPATION: 'OCCUPATION',
  SKILL: 'SKILL',
}

export default {
  ACTION_TYPES,
  ENTITY_TYPES,
}
