//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { matchStringIgnoreCase } from '@/util/string'
import ProgressIndicator from '@/components/Shared/ProgressIndicator'
import PotentialWorkerTag from './PotentialWorkerTag'
export default {
  components: {
    ProgressIndicator,
    PotentialWorkerTag,
  },
  props: {
    opportunities: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: null,
    },
    searcher: {
      type: Boolean,
      default: false,
    },
    titleTooltip: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      query: null,
    }
  },
  computed: {
    filteredOpportunities() {
      if (!this.query) {
        return this.opportunities
      }
      return this.opportunities.filter((opportunity) => {
        const name = opportunity.name || ''
        return matchStringIgnoreCase(name, this.query)
      })
    },
  },
  methods: {
    isRowDetailEnabled(row) {
      return row.description
    },
    toggleDetails(row) {
      if (this.isRowDetailEnabled(row)) {
        this.$refs.jobMatchesTable.toggleDetails(row)
      }
    },
    isRowExpanded(row) {
      return this.$refs.jobMatchesTable.isVisibleDetailRow(row)
    },
    handleTypeQuery(query) {
      this.query = query
    },
  },
}
