//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      contactEmail: 'contacto@relink.cl',
    }
  },
  computed: {
    showFooter() {
      return !this.$store.getters['me/isRelink']
    },
  },
}
