//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TagBadge from '~/components/Shared/TagBadge'
import Avatar from '~/components/Perfil/Avatar'
import { getAllExperiences } from '~/util/entity/user'

export default {
  components: { Avatar, TagBadge },
  props: {
    person: {
      type: Object,
      default: () => ({}),
    },
    position: {
      type: String,
      default: '',
    },
    statusLabel: {
      type: String,
      default: '',
    },
  },
  computed: {
    fullNames() {
      return `${this.person.names} ${this.person.lastnames}`
    },
    totalSkills() {
      return [...new Set(this.person.skills.map(({ skill }) => skill.id))]
        .length
    },
    totalExperiences() {
      return getAllExperiences(this.person).length
    },
    totalQuestionaries() {
      return (
        (this.person.questionaries?.length || 0) +
        (this.person.model_questionaries?.length || 0)
      )
    },
    getProjectName() {
      return this.person?.project?.name || 'Sin Proyecto'
    },
  },
  methods: {
    positionTooltip(text) {
      if (text.length > 200) {
        return 'is-left'
      }
      return 'is-top'
    },
  },
}
