//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import ModalAddOrigin from './ModalAddOrigin.vue'
import Button from '~/components/Shared/Button.vue'
import EditableItem from '~/components/Shared/EditableItem.vue'
import { showErrorNotification } from '~/util/notifications'
import { ORIGIN_ENTITIES } from '~/constants/origin-entities.js'
export default {
  components: {
    Button,
    ModalAddOrigin,
    EditableItem,
  },
  props: {
    defaultOriginOptions: {
      type: Array,
      default: () => [],
    },
  },
  async fetch() {
    await this.setCurrentOrigins()
  },
  data() {
    return {
      isShowModalAddOrigin: false,
      origins: [],
      itemIdsInEditMode: [],
    }
  },

  computed: {
    ...mapGetters({
      getBaseProject: 'project/getBaseProject',
    }),
    projectId() {
      return this.getBaseProject.id
    },
    preSelected() {
      if (!this.getBaseProject.main_target_occupation_id) {
        return this.origins
      }
      return [
        ...this.origins,
        {
          entity_id: this.getBaseProject.main_target_occupation_id,
          entity_type: ORIGIN_ENTITIES.OCCUPATION_ENTITY,
        },
      ]
    },
  },
  watch: {
    origins: {
      handler(value) {
        this.$emit('update-origins', value)
      },
      deep: true,
    },
  },
  methods: {
    toggleModalAddOrigin() {
      this.isShowModalAddOrigin = !this.isShowModalAddOrigin
    },
    handleAddOrigins(origins) {
      this.origins = [...this.origins, ...origins]
      this.toggleModalAddOrigin()
    },
    async handleUpdateOrigin(origin) {
      try {
        await this.$repository.projectOriginEntities.update(origin.id, origin)
        this.origins = this.origins.map((item) => {
          if (item.id === origin.id) {
            return origin
          }
          return item
        })
        this.handleEditMode({ isModeEdit: false, item: origin })
      } catch (e) {
        showErrorNotification(this, this.$t('misc.errors.genericError'))
      }
    },
    async handleRemoveOrigin(origin) {
      try {
        await this.$repository.projectOriginEntities.delete(origin.id)
        this.origins = this.origins.filter(({ id }) => id !== origin.id)
        this.handleEditMode({ isModeEdit: false, item: origin })
      } catch (e) {
        showErrorNotification(this, this.$t('misc.errors.genericError'))
      }
    },
    async setCurrentOrigins() {
      if (this.projectId) {
        this.origins = await this.$repository.projectOriginEntities.find({
          project: this.projectId,
        })
      }
    },
    handleEditMode({ isModeEdit, item }) {
      if (isModeEdit) {
        this.itemIdsInEditMode.push(item.id)
      } else {
        this.itemIdsInEditMode = this.itemIdsInEditMode.filter(
          (id) => id !== item.id
        )
      }
      this.$emit('is-some-edit-mode', this.itemIdsInEditMode.length > 0)
    },
  },
}
