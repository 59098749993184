//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LandingCardOccupation from '~/components/Landing/LandingCardOccupation.vue'
export default {
  components: { LandingCardOccupation },
  props: {
    jobAnnouncements: {
      type: Array,
      default: () => [],
    },
  },
  data: () => {
    return {
      isVisibleControls: true,
    }
  },
  methods: {
    scrollTo(element, scrollPixels, duration) {
      const scrollPos = element.scrollLeft
      if (
        !(
          (scrollPos === 0 || scrollPixels > 0) &&
          (element.clientWidth + scrollPos === element.scrollWidth ||
            scrollPixels < 0)
        )
      ) {
        const startTime =
          'now' in window.performance ? performance.now() : new Date().getTime()

        // eslint-disable-next-line no-inner-declarations
        function scroll(timestamp) {
          const timeElapsed = timestamp - startTime
          const progress = Math.min(timeElapsed / duration, 1)
          element.scrollLeft = scrollPos + scrollPixels * progress
          if (timeElapsed < duration) {
            window.requestAnimationFrame(scroll)
          }
        }
        window.requestAnimationFrame(scroll)
      }
    },
    back() {
      const content = this.$refs.carousel
      this.scrollTo(content, -250, 300)
    },
    next() {
      const content = this.$refs.carousel
      this.scrollTo(content, +250, 300)
    },
  },
}
