const Request = require('@/repositories/request')
class CompanyConfigs extends Request {
  getConfigsByCompany(companyId) {
    return this.$strapi.$http.get(`/company-configs/by-company/${companyId}`)
  }

  getConfigsByJobSeekerFair(jobSeekerFairId) {
    return this.$strapi.$http.get(
      `/company-configs/by-job-seeker-fair/${jobSeekerFairId}`
    )
  }
}

module.exports = CompanyConfigs
