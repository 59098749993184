//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    value: {
      type: Number,
      default: null,
    },
    placeholder: {
      type: String,
      default: 'Selecciona un proyecto',
    },
    projects: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    project: null,
  }),
  watch: {
    value() {
      if (this.value) {
        this.project = this.projects.find(
          (project) => project.id === this.value
        )
      }
    },
  },
  methods: {
    handleChange(value) {
      this.$emit('change', value)
    },
  },
}
