//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'HeroBar',
  props: {
    hasRightVisible: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
  },
  computed: {
    titleStack() {
      return [this.$t('dashboard.title'), this.$props.title]
    },
  },
  head() {
    return {
      title: this.$props.title,
    }
  },
}
