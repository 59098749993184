//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    info: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    hasLotOfText(text) {
      return text.length > 150
    },
  },
}
