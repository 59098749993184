import {
  isReadyToSync,
  buildFormattedBaseProject,
  isInvalidToCreate,
} from '@/util/project'
import { FINALSTEP } from '~/constants/projects'

export const state = () => ({
  baseProject: {
    id: null,
    name: null,
    objective: null,
    description: null,
    locations: [],
    modality: null,
    current_step: 0,
    current_sub_step: null,
    match_potential: false,
    skills_inventory: false,
    occupations: [],
    status: null,
    company: null,
    main_target_occupation_id: null,
    type: null,
    has_models: false,
    skill_packs: [],
  },
  totalUsers: 0,
  saving: false,
  lastUpdatedAt: null,
  sync: false,
  creator: null,

  custom_invitation: null,

  showEditModal: false,
  showDeleteModal: false,
  employeesInProject: [],
})

export const getters = {
  getBaseProject(state) {
    return state.baseProject
  },
  isSaving(state) {
    return state.saving
  },
  isSync(state) {
    return state.sync
  },
  getShowEditModal(state) {
    return state.showEditModal
  },
  getShowDeleteModal(state) {
    return state.showDeleteModal
  },
  getTotalUsers(state) {
    return state.totalUsers
  },
}

export const mutations = {
  INIT_BASE_PROJECT(state, data) {
    state.lastUpdatedAt = new Date()
    state.sync = true
    state.baseProject = data
  },
  CLEAN_BASE_PROJECT(state) {
    state.baseProject = {
      id: null,
      name: null,
      objective: null,
      description: null,
      locations: [],
      modality: null,
      current_step: 0,
      current_sub_step: null,
      match_potential: false,
      skills_inventory: false,
      occupations: [],
      status: null,
      company: null,
      main_target_occupation_id: null,
      type: null,
      totalUsers: 0,
      has_models: false,
      skill_packs: [],
    }
    state.saving = false
    state.lastUpdatedAt = null
    state.sync = false
    state.creator = null

    state.custom_invitation = null

    state.showEditModal = false
    state.showDeleteModal = false
  },
  SET_PROJECT_ATTRIBUTE(state, data) {
    if (state.baseProject[data.key] !== data.value) {
      state.lastUpdatedAt = new Date()
      state.sync = false
      state.baseProject[data.key] = data.value
    }
  },
  SET_SAVING(state, data) {
    state.saving = data
  },
  SET_SYNC(state, data) {
    state.sync = data
  },
  UPDATE_ATTRIBUTES(state, savedProject) {
    state.baseProject.id = savedProject.id
    if (state.baseProject.current_step !== savedProject.current_step) {
      state.baseProject.current_step = savedProject.current_step
    }
  },
  SET_SHOW_EDIT_MODAL(state, data) {
    state.showEditModal = data
  },
  SET_DELETE_EDIT_MODAL(state, data) {
    state.showDeleteModal = data
  },
  SET_TOTAL_USERS(state, data) {
    state.totalUsers = data
  },
  SET_EMPLOYEES_IN_PROJECT(state, data) {
    state.employeesInProject = data
    state.employeesInProject.total = data.length
    state.employeesInProject.totalPending = data.filter(
      (employee) =>
        employee.show_change_password_modal === true &&
        !employee.autogenerated_email
    ).length
  },
}

export const actions = {
  initBaseProject(context, payload) {
    context.commit('INIT_BASE_PROJECT', payload)
  },
  cleanBaseProject(context) {
    context.commit('CLEAN_BASE_PROJECT')
  },
  setProjectAttribute(context, payload) {
    context.commit('SET_PROJECT_ATTRIBUTE', payload)
  },
  setSaving(context, saving) {
    context.commit('SET_SAVING', saving)
  },
  setSync(context, sync) {
    context.commit('SET_SYNC', sync)
  },
  async sync(context, { baseProject, forceSync }) {
    if (isInvalidToCreate(baseProject)) {
      return true
    }
    if (isReadyToSync(context.state) || forceSync) {
      context.commit('SET_SAVING', true)
      try {
        const savedProject = await this.$repository.project.save(
          buildFormattedBaseProject(baseProject)
        )
        if (
          baseProject.has_models === true &&
          baseProject.current_step === FINALSTEP
        ) {
          await this.$repository.match.evaluateMatchModels({
            id: savedProject.id,
            isProjectId: true,
          })
        }
        context.commit('UPDATE_ATTRIBUTES', savedProject)
        context.commit('SET_SYNC', true)
      } catch (error) {
        context.dispatch(
          'core/errorMessage',
          'Se ha encontrado un error en el sistema.',
          { root: true }
        )
      } finally {
        context.commit('SET_SAVING', false)
      }
    }
  },
  setShowEditModal(context, showEditModal) {
    context.commit('SET_SHOW_EDIT_MODAL', showEditModal)
  },
  setShowDeleteModal(context, showDeleteModal) {
    context.commit('SET_DELETE_EDIT_MODAL', showDeleteModal)
  },
  setTotalUsers(context, totalUsers) {
    context.commit('SET_TOTAL_USERS', totalUsers)
  },
  async getEmployeesInProject(context, projectId) {
    try {
      const employees = await this.$repository.project.getEmployeesAndInvitationByProject(
        projectId
      )
      context.commit('SET_EMPLOYEES_IN_PROJECT', employees)
      context.commit('SET_TOTAL_USERS', employees.length)
    } catch (error) {
      context.dispatch(
        'core/errorMessage',
        'Se ha encontrado un error en el sistema.',
        { root: true }
      )
    }
  },
}
