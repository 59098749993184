var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"questionary-skills-wizard"},[_c('div',{staticClass:"container is-fluid"},[_c('b-steps',{attrs:{"animated":true,"rounded":true,"size":"is-small","has-navigation":false},model:{value:(_vm.currentStep),callback:function ($$v) {_vm.currentStep=$$v},expression:"currentStep"}},[_c('b-step-item',{attrs:{"step":"1","icon":"head-lightbulb-outline","label":_vm.$t('questionaryProject.validateSkills.wizard.step1')}},[_vm._t("step-origin",null,null,{
              questionary: _vm.questionary,
              onPreviousStep: _vm.onPreviousStep,
              onNextStep: _vm.onNextStep,
              currentStep: _vm.currentStep,
              skills: _vm.skills,
              title: _vm.title,
              description: _vm.description,
              handleChangeItem: _vm.handleChangeItem,
              userSkillIds: _vm.userSkillIds,
            })],2),_vm._v(" "),(_vm.isVisibleTargetStep)?_c('b-step-item',{attrs:{"step":"2","icon":"lightbulb","label":_vm.$t('questionaryProject.validateSkills.wizard.step2')}},[_vm._t("step-target",null,null,{
              questionary: _vm.questionary,
              onPreviousStep: _vm.onPreviousStep,
              onNextStep: _vm.onNextStep,
              currentStep: _vm.currentStep,
              skills: _vm.skills,
              title: _vm.title,
              description: _vm.description,
              handleChangeItem: _vm.handleChangeItem,
              userSkillIds: _vm.userSkillIds,
            })],2):_vm._e(),_vm._v(" "),_c('b-step-item',{attrs:{"step":"3","icon":"checkbox-marked-circle-outline","label":_vm.$t('questionaryProject.validateSkills.wizard.step3')}},[_vm._t("step-final",null,null,{ mainTargetOccupationId: _vm.mainTargetOccupationId })],2)],1)],1),_vm._v(" "),_c('ModalNextStepQuestionary',{attrs:{"title":"Siguiente etapa","visible":_vm.showNextStepQuestionaryModal,"close":_vm.togleNextStepQuestionaryModal},on:{"accept":_vm.changeToSecondStep}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }