//
//
//
//
//
//
//
//
//
//
//

import { splitMessageByPattern } from '~/util/string'
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    pattern: {
      type: String,
      default: '',
    },
  },
  computed: {
    splittedMessage() {
      return splitMessageByPattern(this.value, this.pattern)
    },
  },
}
