//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
export default {
  name: 'ShowUserProfile',
  filters: {
    locationName(address) {
      return address?.location?.parent?.parent?.name
    },
    countryName(user) {
      return user?.company?.country?.name
    },
  },
  props: {
    user: {
      type: Object,
      default: () => ({
        names: '',
        lastnames: '',
        position: '',
        gender: '',
        statusLaboral: '',
        email: '',
        phone: '',
        birthdate: undefined,
        identification_number: '',
        address: '',
        company: undefined,
        roleDescription: '',
      }),
    },
    genders: {
      type: Array,
      default: () => [],
    },
    educationLevels: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters('me', [
      'isChilean',
      'isCompany',
      'isRelink',
      'getLabelProfile',
      'isRelinkOrCompany',
      'isFreelanceUser',
      'isEmployee',
    ]),
    showCompany() {
      return !!this.user.company
    },
    showRoleOrPosition() {
      return (
        !this.isFreelanceUser && !this.isEmployee && !this.isRelinkOrCompany
      )
    },
    showLaboralSituation() {
      return !this.isFreelanceUser && !this.isRelinkOrCompany
    },
    showIdentifierNumber() {
      return !this.isRelinkOrCompany || this.showCompany
    },
    isVisibleForGeneralUsers() {
      return this.isEmployee || this.isFreelanceUser
    },
  },
  methods: {
    getGenderName(gender) {
      const currentGender = this.genders.find(({ id }) => id === gender)
      return currentGender ? currentGender.name : ''
    },
    getEducationLevel() {
      if (this.isChilean === false) {
        return this.user.education || ''
      }

      const educationLevel = this.user.education_level
      const currentEducationLevel = this.educationLevels.find(
        ({ id }) => id === educationLevel
      )
      return currentEducationLevel ? currentEducationLevel.name : ''
    },
  },
}
