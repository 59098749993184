//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { FormInput } from '@/components/Form'

export default {
  name: 'ShowCompanyDemo',
  components: { FormInput },
  props: {
    form: {
      type: Object,
      default: () => {
        return {
          form: {
            company: {
              name: '',
            },
            user: {
              names: '',
              lastnames: '',
              email: '',
            },
          },
        }
      },
    },
  },
  methods: {
    copyEmail() {
      this.$copyText(this.form.user?.email)
      this.$buefy.snackbar.open({
        message: this.$t('misc.messages.copiedToClipboard'),
        duration: 1500,
        position: 'is-bottom',
      })
    },
  },
}
