//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FormExperienceEducation from '@/components/Experiences/FormExperienceEducation'
import Button from '~/components/Shared/Button.vue'
import { showErrorNotification } from '~/util/notifications'
import EDUCATIONAL_AREAS from '~/constants/educational-areas'
import EDUCATIONAL_TYPES from '~/constants/educational-types'

export default {
  components: {
    FormExperienceEducation,
    Button,
  },
  props: {
    defaultExperience: {
      type: Object,
      default: () => {},
    },
  },
  async fetch() {
    const [middleEducation] = await this.$repository.educationalArea.find({
      _where: { name: EDUCATIONAL_AREAS.COMMON_PLAN },
    })
    this.middleEducation = middleEducation
  },
  data: () => ({
    isValid: false,
    experience: {},
    middleEducation: null,
  }),
  computed: {
    isMiddleEducation() {
      return (
        this.experience.educational_type.name ===
        EDUCATIONAL_TYPES.MIDDLE_EDUCATION
      )
    },
    isEnabledOccupation() {
      return ![
        EDUCATIONAL_TYPES.MIDDLE_EDUCATION,
        EDUCATIONAL_TYPES.TECHNICAL_COLLEGE,
      ].includes(this.experience.educational_type.name)
    },
    isValidAll() {
      if (!this.experience.educational_type) return false
      if (
        [
          EDUCATIONAL_TYPES.MIDDLE_EDUCATION,
          EDUCATIONAL_TYPES.TECHNICAL_COLLEGE,
        ].includes(this.experience.educational_type.name)
      ) {
        return this.isValid
      }
      return (
        this.isValid &&
        (this.experience.occupation || this.experience.custom_occupation)
      )
    },
  },
  methods: {
    handleChangeValue(experience) {
      this.experience = experience
    },
    handleChangeForm(valid) {
      this.isValid = valid
    },
    handleBack() {
      this.$emit('back')
    },
    prepareExperience() {
      return {
        ...this.experience,
        user: this.$strapi.user.id,
        area_educational: this.isMiddleEducation
          ? { id: this.middleEducation.id }
          : this.experience.area_educational,
        occupation: this.isEnabledOccupation
          ? this.experience.occupation
          : null,
      }
    },
    async saveExperience() {
      const experienceForSave = this.prepareExperience()
      const experience = experienceForSave.id
        ? await this.$repository.experienceEducational.update(
            experienceForSave.id,
            experienceForSave
          )
        : await this.$repository.experienceEducational.create(experienceForSave)
      return experience
    },
    async handleNext() {
      try {
        this.$store.commit('loading', true)
        const { id } = await this.saveExperience()
        const experience = await this.$repository.experienceEducational.findOne(
          id,
          {
            populate: [
              'area_educational',
              'specialty',
              'occupation',
              'educational_type',
              'status',
            ],
          }
        )
        this.$emit('next', experience)
      } catch (error) {
        showErrorNotification(this)
      } finally {
        this.$store.commit('loading', false)
      }
    },
  },
}
