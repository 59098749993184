//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ProjectAutocomplete',
  props: {
    occupations: {
      type: Array,
      default: () => [],
    },
    selectedOccupations: {
      type: Array,
      default: () => [],
    },
    minCharacterSearch: {
      type: Number,
      default: () => 2,
    },
    placeholder: {
      type: String,
      default: () => '',
    },
  },
  data: () => ({
    innerValue: '',
  }),
  computed: {
    filteredOccupations() {
      return this.occupations.filter(
        (occupation) =>
          !this.selectedOccupations.some(
            (selectedOccupation) => selectedOccupation.id === occupation.id
          ) &&
          occupation.title
            .toString()
            .toLowerCase()
            .includes(this.innerValue.toLowerCase())
      )
    },
  },
  methods: {
    handleSelect(selected) {
      if (selected) this.$emit('selected', selected)
      this.innerValue = ''
    },
  },
}
