//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SkillItem from '@/components/Opportunities/SkillItem'
import MessageWithoutHeader from '../Shared/MessageWithoutHeader.vue'
import Button from '~/components/Shared/Button.vue'
import EXPERIENCE_STATUS from '~/constants/experience-status'

export default {
  name: 'YourSkills',
  components: {
    SkillItem,
    MessageWithoutHeader,
    Button,
  },
  props: {
    searchValue: {
      type: String,
      default: '',
    },
    differenceSkill: {
      type: Number,
      default: 0,
    },
    renderedSkills: {
      type: Array,
      default: () => [],
    },
    hasSkillWithouhtValidate: {
      type: Boolean,
      default: false,
    },
    hasSkillRevalidate: {
      type: Boolean,
      default: false,
    },
    allSkills: {
      type: Array,
      default: () => [],
    },
    continueSkill: {
      type: Function,
      default: () => {},
    },
    experienceStatus: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      modal: {
        isSkillModalActive: false,
        record: {},
      },
    }
  },
  computed: {
    isVisibleWithouhtSkill() {
      return this.allSkills.length === 0 && this.differenceSkill === 0
    },
    isVisibleIncompleted() {
      return (
        this.experienceStatus === EXPERIENCE_STATUS.INCOMPLETED &&
        this.differenceSkill > 0
      )
    },
    isVisibleRevalidate() {
      return this.experienceStatus === EXPERIENCE_STATUS.FOR_VALIDATE
    },
  },
  methods: {
    hiddenModal() {
      this.modal = {
        isSkillModalActive: false,
        record: {},
      }
    },
    showDetailSkill(skill) {
      this.modal = {
        isSkillModalActive: true,
        record: skill,
      }
    },
  },
}
