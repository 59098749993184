//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CompanySkillsSection from '@/components/SkillsInventory/CompanySkillsSection.vue'
import DetailSkillModal from '@/components/SkillsInventory/modals/DetailSkillModal.vue'
import handleErrorMixin from '@/mixins/handleErrorMixin'
import ProjectSkillStats from './ProjectSkillStats.vue'
export default {
  components: {
    ProjectSkillStats,
    CompanySkillsSection,
    DetailSkillModal,
  },
  mixins: [handleErrorMixin],
  props: {
    projectId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      showDetailSkillModal: false,
      selectedSkill: null,
    }
  },
  methods: {
    toogleModalDetailSkill() {
      if (this.showDetailSkillModal) {
        this.selectedSkill = null
      }
      this.showDetailSkillModal = !this.showDetailSkillModal
    },
    async handleClickDetailSkill(request) {
      try {
        this.$store.commit('loading', true)
        const skill = await this.$repository.skill.getDetails(
          this.projectId,
          request.skillId
        )
        if (!skill) {
          throw new Error('Skill not found')
        }
        this.selectedSkill = {
          ...skill,
          users: skill.users.map((user) => ({
            ...user,
            roleDescription: user.cargo,
          })),
        }
        this.toogleModalDetailSkill()
      } catch (error) {
        this.handleError(error, this.$t('skillsInventory.error.skillDetail'))
      } finally {
        this.$store.commit('loading', false)
      }
    },
  },
}
