import { PERMISSION_LABELS } from '../constants/permission-labels'

const PERMISSION_PAGES = {
  // example
  // '/experiencias/laboral/:id?': [],

  // company
  '/mis-proyectos/inventario-habilidades': [
    PERMISSION_LABELS.INVENTORY_SKILLS.BASE,
  ],
  '/empresas/potencial-de-match': [PERMISSION_LABELS.MATCH_POTENTIAL.BASE],

  // employee
  '/mi-inventario-habilidades': [PERMISSION_LABELS.INVENTORY_SKILLS.BASE],
  '/oportunidades': [PERMISSION_LABELS.MATCH_POTENTIAL.BASE],
}
export default async function (context) {
  if (!context.$strapi.user.company) {
    return
  }
  const pagePattern = context.route.matched[0]?.path || ''
  // Habilitar para ver en consola el pattern de la página que quieres agregar a PERMISSION_PAGES
  // console.log(pagePattern)
  const requiredPermissionsToGoPage = PERMISSION_PAGES[pagePattern] || []
  const permissions = await context.$permissions.allow(
    requiredPermissionsToGoPage
  )
  if (permissions.some(({ enabled }) => !enabled)) {
    context.redirect('/inicio')
  }
}
