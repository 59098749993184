import _ from 'underscore'
import { capitalizeWord } from './string'

export const sanitizeOccupations = (occupations, withoutParentMessage) => {
  const groupBy = _.groupBy(
    occupations,
    (item) => item?.occupation_group?.name || withoutParentMessage
  )

  return Object.keys(groupBy)
    .map((keyGroupBy) => {
      return {
        label: capitalizeWord(keyGroupBy),
        options: groupBy[keyGroupBy],
      }
    })
    .sort((a, b) => a.label.localeCompare(b.label))
    .map((item) => {
      item.options = item.options
        .map((item) => {
          const { title, ...others } = item
          return { title: capitalizeWord(title), ...others }
        })
        .sort((a, b) => (a.title || '').localeCompare(b.title || ''))
      return item
    })
}
export const flattenSkills = (occupation) =>
  _.toArray(
    _.flatten(
      occupation.skills.map((skills) => {
        return skills.skills.map((skill) => {
          return { ...skill, type: skills.skillType }
        })
      })
    )
  )
