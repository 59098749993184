//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import CompanySkillsInventoryTable from '@/components/SkillsInventory/tables/CompanySkillsInventoryTable'
import SkillsInventoryFinder from '~/components/SkillsInventory/Filters/SkillsInventoryFinder'

export default {
  name: 'CompanySkillsSection',
  components: { SkillsInventoryFinder, CompanySkillsInventoryTable },
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    dataHandler: {
      type: Function,
      required: true,
    },
    entityId: {
      type: Number,
      required: true,
    },
  },
  async fetch() {
    await this.getSkillsByCompanyInformation()
  },
  data() {
    return {
      skills: [],
      page: 1,
      perPage: 10,
      total: 0,
      criteria: '',
      sort: {
        field: 'total_users',
        order: 'DESC',
      },
    }
  },
  computed: {
    ...mapState(['loading']),
    positions() {
      return [].map((role) => {
        return {
          name: role,
        }
      })
    },
  },
  watch: {
    selectedProject() {
      this.reset()
      this.getSkillsByCompanyInformation()
    },
  },
  methods: {
    reset() {
      this.page = 1
      this.perPage = 10
      this.total = 0
      this.criteria = ''
    },
    async getSkillsByCompanyInformation() {
      this.$store.commit('loading', true)
      const response = await this.dataHandler(this.entityId, {
        per_page: this.perPage,
        page: this.page,
        criteria: this.criteria.length >= 3 ? this.criteria : undefined,
        sort_field: this.sort.field,
        sort_order: this.sort.order,
      })
      this.skills = response.skills
      this.total = response.total
      this.$store.commit('loading', false)
    },
    async onPageChange(page) {
      this.page = page
      await this.getSkillsByCompanyInformation()
    },
    onViewSkill(skillId) {
      this.$emit('view-skill', {
        skillId,
        positions: this.selectedPositions,
      })
    },
    async onSearchByCriteria(criteria) {
      this.criteria = criteria
      if (this.criteria.length >= 3 || this.criteria.length === 0) {
        this.page = 1
        await this.getSkillsByCompanyInformation()
      }
    },
    async onSort({ field, order }) {
      this.sort = { field, order }
      await this.getSkillsByCompanyInformation()
    },
  },
}
