//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Button from '@/components/Shared/Button.vue'
import ModalWithTitleBar from '~/components/Shared/ModalWithTitleBar'

export default {
  name: 'ConfirmRegisterUserWithEmailModal',
  components: { ModalWithTitleBar, Button },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    async viewedModal() {
      this.$store.commit('loading', true)
      try {
        await this.$repository.me.update({
          show_privacy_policy_modal: false,
        })
        window.location.reload()
      } catch (error) {
        this.$buefy.notification.open({
          message:
            'No se pudo actualizar la visibilidad del modal de política de privacidad',
          type: 'is-danger',
          duration: 5000,
        })
      } finally {
        this.$store.commit('loading', false)
      }
    },
    toggleModal() {
      this.$emit('close')
    },
  },
}
