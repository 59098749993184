//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TableEmptyData from '@/components/Shared/Table/TableEmptyData'
import Tag from '@/components/Shared/Tag'

export default {
  name: 'InvitationList',
  components: { TableEmptyData, Tag },
  filters: {
    styleForActiveRow(isActive) {
      if (isActive) {
        return 'is-info is-light'
      }
      return 'is-success is-light'
    },
    textForActiveRow(isActive) {
      if (isActive) {
        return 'Pendiente'
      }
      return 'Aceptada'
    },
    fullNames(user) {
      return `${user?.names} ${user?.lastnames}`
    },
    showEmail(user) {
      return user?.email
    },
  },
  props: {
    onPageChange: {
      default: () => {},
      type: Function,
    },
    data: {
      default: () => [],
      type: Array,
    },
    total: {
      default: 0,
      type: Number,
    },
    perPage: {
      default: 10,
      type: Number,
    },
  },
  methods: {
    resendInvitation({ id, user }) {
      this.$emit('resend-invitation', { id, email: user?.email })
    },
  },
}
