//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Button from '~/components/Shared/Button.vue'
export default {
  name: 'MyQuestionaries',
  components: {
    Button,
  },
  data() {
    return {
      questionaries: [
        {
          id: 1,
          name: 'Test',
          description: 'Test description',
          status: 'active',
        },
      ],
    }
  },
}
