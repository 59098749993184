//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ValidationObserver } from 'vee-validate'
import ROLES from '@/constants/roles'
import { MESSAGE_TYPE } from '~/constants/core'
import ModalWithTitleBar from '~/components/Shared/ModalWithTitleBar'
import InvitationSuccess from '~/components/Invitation/InvitationSuccess'

export default {
  components: {
    ValidationObserver,
    ModalWithTitleBar,
    InvitationSuccess,
  },
  props: {
    value: {
      type: Boolean,
      default: true,
    },
    roleType: {
      type: String,
      default: ROLES.EMPLOYEE,
    },
  },
  data() {
    return {
      form: {
        message: '',
      },
      active: this.value,
      confirmedModal: false,
      isLoading: false,
    }
  },
  watch: {
    value() {
      this.active = this.value
    },
  },
  methods: {
    close() {
      this.active = !this.active
      this.$emit('close')
      this.form.message = ''
      this.$store.commit('emails/setResendAll', false)
    },
    closeConfirmedModal() {
      this.confirmedModal = !this.confirmedModal
    },
    async preview() {
      const role = this.roleType
      const customMessage = this.form.message
      const companyName = this.$repository.me.getMe().company?.name
      const jobSeekerFair = this.$repository.me.getMe().job_seeker_fair?.name

      const response = await this.$repository.invitation.previewMail({
        role,
        customMessage,
        companyName,
        jobSeekerFair,
      })
      window.open(response.url, '_blank')
    },
    async submit() {
      this.isLoading = true
      const body = this.getQueryInfo()
      try {
        if (this.$store.state.emails.resendAll) {
          await this.$repository.invitation.massResendAllInvitations(body)
        } else {
          await this.$repository.invitation.massResendInvitations(body)
        }
        await this.$store.dispatch('core/showMessage', {
          message: `<p>Invitaciones reenviadas exitosamente</p>`,
        })
        this.confirmedModal = true
      } catch (e) {
        const message = e.response.data.map(
          (e) => `
          Ocurrió un error con la invitación ${e.id} de ${
            e.email ? e.email : ''
          }: ${e.error}
        `
        )
        await this.$store.dispatch('core/showMessage', {
          message,
          type: MESSAGE_TYPE.DANGER,
        })
      }
      this.isLoading = false
      this.close()
    },
    getQueryInfo() {
      const body = {}
      body.customMessage = this.form.message
      if (!this.$store.state.emails.resendAll) {
        body.ids = this.$store.getters['emails/resendEmails'].map((c) => c.id)
      }
      return body
    },
  },
}
