import * as Sentry from '@sentry/vue'
import { showErrorNotification } from '@/util/notifications'
export default {
  methods: {
    handleError(error, message) {
      // eslint-disable-next-line no-console
      console.error(error)
      Sentry.captureMessage(error)
      showErrorNotification(this, message)
    },
  },
}
