const Request = require('@/repositories/request')
const qs = require('qs')

class CompanyOccupation extends Request {
  /**
   *
   * @param {Object} params
   * @returns {*}
   */
  find(params) {
    const stringifyParams = qs.stringify({ ...params })
    return this.$strapi.$http.$get(`/company-occupations?${stringifyParams}`)
  }

  /**
   *
   * @param {Object} params
   * @returns {*}
   */
  findOne(id, params) {
    const query = qs.stringify(params, { encode: false })
    return this.$strapi.$http.$get(`/company-occupations/${id}?${query}`)
  }

  getPotentialWorkerById(id) {
    return this.$strapi.$http.$get(
      `/company-occupations/${id}/potential-workers`
    )
  }

  /**
   *
   * @param {int} id
   * @returns {*}
   */
  deleteOne(id) {
    return this.$strapi['$company-occupations'].delete(id)
  }

  /**
   *
   * @param {int} id
   * @param {Object} companyOccupation
   * @returns {*}
   */
  updateOne(id, companyOccupation) {
    return this.$strapi['$company-occupations'].update(id, companyOccupation)
  }

  /**
 *
 * @param {{_where: Object,
    _limit: int,
    _start: int}} params
 * @returns {*}
 */
  count(params) {
    const stringifyParams = qs.stringify({ ...params })
    return this.$strapi['$company-occupations'].count(stringifyParams)
  }

  /**
   *
   * @param {int} id
   * @returns {Promise<*>}
   */
  async downloadReportById(id) {
    const file = await this.$strapi.$http.get(
      `/company-occupations/${id}/report`
    )
    return file.blob()
  }
}

module.exports = CompanyOccupation
