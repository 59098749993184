import { render, staticRenderFns } from "./AsideMenu.vue?vue&type=template&id=571c57ca&scoped=true&"
import script from "./AsideMenu.vue?vue&type=script&lang=js&"
export * from "./AsideMenu.vue?vue&type=script&lang=js&"
import style0 from "./AsideMenu.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./AsideMenu.vue?vue&type=style&index=1&id=571c57ca&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "571c57ca",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AsideMenuList: require('/workspace/components/AsideMenuList.vue').default})
