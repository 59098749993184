//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Hero from '@/components/Shared/Hero'
import Button from '@/components/Shared/Button'

export default {
  components: {
    Hero,
    Button,
  },
  props: {
    title: {
      default: '',
      type: String,
    },
    body: {
      default: '',
      type: String,
    },
    secondaryButtonText: {
      default: '',
      type: String,
    },
    secondaryButtonClick: {
      default: () => {},
      type: Function,
    },
    primaryButtonText: {
      default: '',
      type: String,
    },
    primaryButtonClick: {
      default: () => {},
      type: Function,
    },
  },
}
