//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CheckMultiLevelItem from '~/components/Shared/CheckMultiLevelItem'
export default {
  name: 'CheckMultiLevelItem',
  components: {
    CheckMultiLevelItem,
  },
  props: {
    element: {
      type: Object,
      default: () => {},
    },
    checkedChild: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
      open: false,
      isChecked: false,
      isCheckedChild: false,
    }
  },
  watch: {
    checkedChild(value) {
      this.isChecked = value
    },
    isChecked(value) {
      this.isCheckedChild = value
      if (!this.element.children) {
        this.$emit('change', { ...this.element, checked: this.isChecked })
      }
    },
  },
  methods: {
    toogle() {
      this.open = !this.open
    },
    changeMinValue(value) {
      this.$emit('change', value)
    },
  },
}
