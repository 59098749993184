export const state = () => ({
  resendEmails: [],
  resendAll: false,
})

export const mutations = {
  setResendEmails(state, data) {
    state.resendEmails = data
  },
  clearResendEmails(state) {
    state.resendEmails = []
  },
  setResendAll(state, data) {
    state.resendAll = data
  },
}

export const getters = {
  resendEmails(state) {
    return state.resendEmails
  },

  resendAll(state) {
    return state.resendAll
  },
}
