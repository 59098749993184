import { TYPES } from '~/constants/project-types'
const FILTER_PERCENT_RESKILLING = 20
const FILTER_PERCENT_UPSKILLING = 40

export const isSuccessFilterTarget = (questionary, projectType, userSkills) => {
  const skillsPreviousSubsection = questionary.target_reuse_skills
  const match = getMatch(userSkills, skillsPreviousSubsection)

  if (projectType === TYPES.RESKILLING) {
    return match > FILTER_PERCENT_RESKILLING
  }

  if (projectType === TYPES.UPSKILLING) {
    return match <= FILTER_PERCENT_UPSKILLING
  }

  return false
}

function getMatch(skillsIds = [], targetSkills = []) {
  const targetSkillsIds = new Set([
    ...targetSkills.map((targetSkill) => targetSkill.id),
  ])
  const intersectionSkills = skillsIds.filter((skill) =>
    targetSkillsIds.has(skill)
  )
  return targetSkillsIds.size > 0
    ? (intersectionSkills.length * 100) / targetSkillsIds.size
    : 0
}
