//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import config from '~/config'
export default {
  name: 'TextInformativeModal',
  data() {
    return {
      contactEmail: config.contactEmail,
    }
  },
}
