const skillTypes = [
  {
    skill_type: 'skill/competence',
    name: 'Habilidad',
  },
  {
    skill_type: 'knowledge',
    name: 'Conocimiento',
  },
]
const skillEsenciality = [
  {
    code: 'P',
    name: 'Pendiente',
  },
  {
    code: 'E',
    name: 'Esencial',
  },
  {
    code: 'O',
    name: 'Optativa',
  },
]

const insightsBarColor = {
  red: 'RED',
  green: 'GREEN',
  bone: 'BONE',
  blue: 'BLUE',
}

export default { skillTypes, skillEsenciality, insightsBarColor }
