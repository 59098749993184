//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { FormSelect } from '@/components/Form'
import { sanitizeOccupations } from '@/util/occupation'
export default {
  components: {
    FormSelect,
  },
  props: {
    label: {
      type: String,
      default: () => '',
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    defaultMetaOccupations: {
      type: Array,
      default: () => null,
    },
  },
  async fetch() {
    if (!this.defaultMetaOccupations) {
      await this.fetchMetaOccupations()
    } else {
      this.metaOccupations = this.defaultMetaOccupations
    }
  },
  data() {
    return {
      metaOccupations: [],
      occupations: [],
      title: '',
      doneTypingInterval: 1500,
      typingTimer: 0,
      searchLoading: false,
    }
  },
  computed: {
    noResults() {
      return this.title.length > 1 && this.occupations.length === 0
    },
    occupationOptions() {
      return this.title.length === 0
        ? sanitizeOccupations(
            this.metaOccupations,
            this.$t('occupations.messages.withoutParent')
          )
        : this.occupations
    },
    groupLabels() {
      return this.title.length > 0 ? null : 'label'
    },
    groupValues() {
      return this.title.length > 0 ? null : 'options'
    },
  },
  methods: {
    timeoutTyping(value) {
      clearTimeout(this.typingTimer)
      this.searchLoading = true
      this.typingTimer = setTimeout(() => {
        this.findOccupationsByTitle(value)
      }, this.doneTypingInterval)
    },
    async fetchMetaOccupations() {
      const metaOccupations = await this.$repository.occupation.getMetaOccupations()
      this.metaOccupations = metaOccupations
    },
    async findOccupationsByTitle(title) {
      this.title = title
      if (title.length > 2) {
        this.occupations = await this.$repository.occupation.suggestOccupations(
          { term: title }
        )
      }
      this.searchLoading = false
    },
    getInputValue(value) {
      this.$emit('input', value)
    },
    selectOnOpen() {
      this.$emit('open')
    },
    selectOnClose() {
      this.$emit('close')
    },
  },
}
