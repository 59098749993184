//
//
//
//
//
//

export default {
  props: {
    message: {
      type: String,
      default: null,
    },
    enableAddNew: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    handleClick() {
      this.$emit('click')
    },
  },
}
