const levenshtein = require('fast-levenshtein')

export const capitalizeWord = (word = '') => {
  return word.charAt(0).toUpperCase() + word.slice(1)
}

export const stringWithCommaToArray = (words) => {
  return (words || '').split(',').map((word) => word.trim())
}

export const normalizeString = (str) => {
  return (str || '').normalize('NFD').replace(/[\u0300-\u036F]/g, '')
}

export const matchStringIgnoreCase = (str, q) => {
  return normalizeString(str).match(new RegExp(normalizeString(q), 'i'))
}

export const separateThousands = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
}

export const isNumber = (str) => {
  return !isNaN(str)
}

export const toBoolean = (str) => {
  return (str || '').toLowerCase() === 'true'
}

export const levenshteinDistance = (firstWord, secondWord) => {
  return levenshtein.get(firstWord, secondWord)
}

export const removeAccents = (str) => {
  return (str || '').normalize('NFD').replace(/[\u0300-\u036F]/g, '')
}

export const isMatch = (searchPhrase, textTarget) => {
  const textTargetNormalized = removeAccents(textTarget)
  const searchPhraseNormalized = removeAccents(searchPhrase)
  const MAX_LEVENSHTEIN_DISTANCE = 5
  return (
    textTargetNormalized.includes(searchPhraseNormalized) ||
    levenshteinDistance(searchPhraseNormalized, textTargetNormalized) <
      MAX_LEVENSHTEIN_DISTANCE ||
    levenshteinDistance(
      searchPhraseNormalized,
      textTargetNormalized.substring(0, searchPhraseNormalized.length)
    ) < MAX_LEVENSHTEIN_DISTANCE
  )
}

export const truncateText = (str, length = 35) => {
  if (str === null || str === undefined) return str
  return str.length > length ? `${str.substr(0, length)}...` : str
}

export const splitMessageByPattern = (message, pattern) => {
  const normalizedMessage = removeAccents(message).toLowerCase()
  const normalizedPattern = removeAccents(pattern).toLowerCase()
  const patternIndex = normalizedMessage.indexOf(normalizedPattern)

  if (patternIndex < 0) {
    return [{ text: message, isPattern: false }]
  }
  const patternLength = pattern.length
  const patternEndIndex = patternIndex + patternLength
  const beforePattern = message.slice(0, patternIndex)
  const patternMatch = message.slice(patternIndex, patternEndIndex)
  const afterPattern = message.slice(patternEndIndex)

  return [
    { text: beforePattern, isPattern: false },
    { text: patternMatch, isPattern: true },
    { text: afterPattern, isPattern: false },
  ].filter((item) => !!item.text)
}

export const isBlank = (str = '') => {
  return str === undefined || /^\s*$/.test(str)
}

export const isNotBlank = (str = '') => {
  return !this.isBlank(str)
}
