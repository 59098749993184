//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TableEmptyData from '@/components/Shared/Table/TableEmptyData'
import Tag from '@/components/Shared/Tag'
import ChevronRight from 'vue-material-design-icons/ChevronRight'

export default {
  name: 'OccupationRequieredList',
  components: { TableEmptyData, Tag, ChevronRight },
  filters: {
    titleOccupation({ occupation }) {
      return occupation?.title
    },
    typeTag(isActive) {
      return isActive ? 'is-success' : 'is-info'
    },
    translateStatus(isActive) {
      return isActive ? 'Publicada' : 'Sin publicar'
    },
  },
  props: {
    onPageChange: {
      default: () => {},
      type: Function,
    },
    data: {
      default: () => [],
      type: Array,
    },
    total: {
      default: 0,
      type: Number,
    },
    perPage: {
      default: 10,
      type: Number,
    },
  },
  methods: {
    showDetail(data) {
      this.$emit('show', data)
    },
  },
}
