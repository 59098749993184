//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex'
import PROJECT_CONSTANTS from '@/constants/projects'
import ProjectTypeSection from '../../Services/ProjectTypeSection.vue'

export default {
  components: {
    ProjectTypeSection,
  },
  props: {
    permissions: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      getBaseProject: 'project/getBaseProject',
    }),
  },
  methods: {
    ...mapActions({
      setProjectAttribute: 'project/setProjectAttribute',
    }),
    setType(type) {
      this.setProjectAttribute({
        key: 'type',
        value: type,
      })
      if (type === PROJECT_CONSTANTS.MODELS.value) {
        this.setProjectAttribute({
          key: 'occupations',
          value: [],
        })
        this.setProjectAttribute({
          key: 'main_target_occupation_id',
          value: null,
        })
      }
      this.$emit('update', type)
    },
  },
}
