const Request = require('@/repositories/request')
const qs = require('qs')

class ModelQuestionary extends Request {
  save(body) {
    return this.$strapi.$http.$post('/model-questionaries', body)
  }

  get(params) {
    const query = qs.stringify(params, { encode: false })
    return this.$strapi.$http.$get(`/model-questionaries?${query}`)
  }

  count(params) {
    const query = qs.stringify(params, { encode: false })
    return this.$strapi.$http.$get(`/model-questionaries/count?${query}`)
  }

  update(id, body) {
    return this.$strapi.$http.$put(`/model-questionaries/${id}`, body)
  }

  findOne(id) {
    return this.$strapi.$http.$get(`/model-questionaries/${id}`)
  }

  findAll(params) {
    const stringifyParams = qs.stringify({ ...params }, { encode: false })
    return this.$strapi['$model-questionaries'].find(stringifyParams)
  }
}

module.exports = ModelQuestionary
