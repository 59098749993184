//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Button from '@/components/Shared/Button.vue'

export default {
  name: 'NewProjectWizardButtons',
  components: {
    Button,
  },
  props: {
    invalid: {
      type: Boolean,
      default: () => false,
    },
    continueButtonText: {
      type: String,
      default: () => null,
    },
    firstStep: {
      type: Boolean,
      default: () => false,
    },
  },
  methods: {
    handleBack() {
      this.$emit('back')
    },
    handleContinue() {
      this.$emit('next')
    },
  },
}
