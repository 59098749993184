//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FormChangePassword from '@/components/Profile/FormChangePassword'
import { ValidationObserver } from 'vee-validate'
import ModalWithTitleBar from '~/components/Shared/ModalWithTitleBar.vue'
import Button from '~/components/Shared/Button'

export default {
  name: 'ModalChangePassword',
  components: {
    FormChangePassword,
    ModalWithTitleBar,
    Button,
    ValidationObserver,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return { form: { password: '', newPassword: '', passwordConfirm: '' } }
  },
  methods: {
    toggle() {
      this.$emit('toggle')
    },
    submitForm() {
      this.$emit('submit', this.form)
    },
  },
}
