//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Button from '@/components/Shared/Button'
import ModalWithTitleBar from '~/components/Shared/ModalWithTitleBar'

export default {
  components: {
    ModalWithTitleBar,
    Button,
  },
  props: {
    value: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      active: this.value,
    }
  },
  watch: {
    value() {
      this.active = this.value
    },
  },
  methods: {
    closeConfirmedModal() {
      this.active = false
    },
  },
}
