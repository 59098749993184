//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Hero from '@/components/Shared/Hero'
import Breadcrumb from '@/components/Shared/Breadcrumb'
import SelectSkills from '@/components/Experiences/SelectSkills'
import SimpleModal from '@/components/Experiences/SimpleModal'
import ExperienceComplete from '@/assets/experiences/experience-complete.svg'
import ExperienceIncomplete from '@/assets/experiences/experience-incomplete.svg'
import EXP_TYPES from '@/constants/experience-types'
import AddExperienceButton from '@/components/Experiences/AddExperienceButton'
import AddFreeSkills from '@/components/Experiences/AddFreeSkills'
import EDUCATIONAL_AREAS from '~/constants/educational-areas'
import EDUCATIONAL_TYPES from '~/constants/educational-types'
import DEFAULT_FREE_SKILLS from '~/constants/default-free-skills'
import SKILL_MORPHS_CONSTANTS from '~/constants/skill-morphs'
const NEXT_BTN = ['experience.wizard.next', 'experience.wizard.finish']

export default {
  components: {
    Hero,
    Breadcrumb,
    SimpleModal,
    AddExperienceButton,
    SelectSkills,
    AddFreeSkills,
  },
  props: {
    titles: {
      type: Array,
      required: true,
    },
    currentPage: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: true,
      validator: (value) => Object.values(EXP_TYPES).includes(value),
    },
    allowBack: {
      type: Boolean,
      default: true,
    },
    step: {
      type: Number,
      default: 0,
    },
    defaultExperience: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      history: [
        { name: 'Inicio', url: '/inicio' },
        { name: this.$t('experience.title'), url: '/experiencias' },
      ],
      currentStep: 0,
      isFormValid: false,
      experience: null,
      formScope: {
        onNext: async ({ experience, isSuccess }) => {
          await this.handleFormResult({ experience, isSuccess })
        },
        validWatcher: (valid, handleSubmit) => {
          this.isFormValid = valid
          this.formSubmit = handleSubmit
        },
      },
      prevBtnText: this.$t('experience.wizard.previous'),
      nextAction: () => {},
      confirmModalVisible: false,
      allQuestionsAnswered: false,
      possessedSkills: [],
      notPossessedSkills: [],
      originPossessedSkill: SKILL_MORPHS_CONSTANTS.ORIGIN_TYPES.SUGGEST,
      showAddedFreeSkill: false,
      middleEducation: {},
      technicalCollege: {},
      commonPlan: {},
      freeSkillsMiddleEducation: [],
      defaultFreeSkills: [],
    }
  },
  computed: {
    nextBtnText() {
      return this.$t(NEXT_BTN[this.currentStep])
    },
    canGoForward() {
      return this.currentStep || (this.currentStep === 0 && this.isFormValid)
    },
    strapiExperienceEntity() {
      return this.type === EXP_TYPES.WORK_TYPE
        ? '$experience-works'
        : '$experience-educationals'
    },
    experienceRegisterImage() {
      return this.experience.completed
        ? ExperienceComplete
        : ExperienceIncomplete
    },
    experienceRegisterText() {
      return this.experience.completed
        ? this.$t('experience.skills.completed.title')
        : this.$t('experience.skills.uncompleted.title')
    },
  },
  async created() {
    this.freeSkillsMiddleEducation = await this.getFreeSkillsMiddleEducation()

    const educationalTypes = await this.$repository.educationalType.findAll({})
    this.middleEducation = educationalTypes.find(
      (educationalType) =>
        educationalType.name === EDUCATIONAL_TYPES.MIDDLE_EDUCATION
    )
    this.technicalCollege = educationalTypes.find(
      (educationalType) =>
        educationalType.name === EDUCATIONAL_TYPES.TECHNICAL_COLLEGE
    )
    this.commonPlan = await this.$repository.educationalArea.find({
      populate: ['skills'],
      _where: {
        name: EDUCATIONAL_AREAS.COMMON_PLAN,
      },
    })
    this.commonPlan = this.commonPlan[0]
  },
  mounted() {
    if (this.step > 0) {
      this.currentStep = this.step
    }
    if (this.defaultExperience) {
      this.experience = this.defaultExperience
    }
  },
  methods: {
    onPreviousStep(action) {
      if (this.currentStep === 0) {
        this.$router.back()
        return
      }
      action?.call()
    },
    onNextStep(action) {
      this.nextAction = action
      if (action && this.currentStep === 0) {
        this.formSubmit()
        return
      }
      if (this.currentStep === 1) {
        this.toggleConfirmModal()
      }
    },
    async handleFormResult({ experience, isSuccess }) {
      if (!isSuccess) return
      this.experience = experience
      await this.goToSkills(experience)
    },
    async goToSkills(experience) {
      const entity = this.$strapi[this.strapiExperienceEntity]
      if (
        [this.middleEducation.id, this.technicalCollege.id].includes(
          experience.educational_type
        )
      ) {
        const skillData = this.prepareExperienceData(
          true,
          this.commonPlan.skills
        )
        const experience = await entity.update(this.experience.id, skillData)
        this.experience = experience
        await this.saveNewSkillsValidated(
          experience.id,
          experience.user,
          this.commonPlan.skills,
          true
        )
      }

      if (experience.educational_type === this.middleEducation.id) {
        this.showAddedFreeSkill = true
        this.currentStep = 1
        this.allQuestionsAnswered = true
        const skillData = this.prepareExperienceData(
          true,
          this.freeSkillsMiddleEducation
        )
        const experience = await entity.update(this.experience.id, skillData)
        this.experience = experience
        await this.saveNewSkillsValidated(
          experience.id,
          experience.user,
          this.freeSkillsMiddleEducation,
          true
        )
      }

      if (experience.educational_type !== this.middleEducation.id) {
        const { text, route } = experience.completed
          ? {
              text: this.$t(`experience.${this.type}.form.success.noSkills`),
              route: '/experiencias',
            }
          : {
              text: this.$t(`experience.${this.type}.form.success.skills`),
            }
        this.$buefy.notification.open({
          message: text,
          type: 'is-success',
          autoclose: true,
          duration: 1500,
          position: 'is-top-right',
        })

        if (experience.completed) {
          this.$router.push(route)
        } else {
          this.nextAction?.call()
        }
      }
    },
    onSkillSelected(allQuestionsAnswered, possessedSkills, notPossessedSkills) {
      this.allQuestionsAnswered = allQuestionsAnswered
      this.possessedSkills = possessedSkills
      this.notPossessedSkills = notPossessedSkills
      this.originPossessedSkill = SKILL_MORPHS_CONSTANTS.ORIGIN_TYPES.SUGGEST
      if (this.allQuestionsAnswered) {
        this.$refs.btnNext.$el.click()
      }
    },
    onSkillSelectedBeta(
      allQuestionsAnswered,
      possessedSkills,
      notPossessedSkills,
      totalSkillsForValidate
    ) {
      this.allQuestionsAnswered = allQuestionsAnswered
      this.possessedSkills = possessedSkills
      this.notPossessedSkills = notPossessedSkills
      this.originPossessedSkill = SKILL_MORPHS_CONSTANTS.ORIGIN_TYPES.SUGGEST
      this.experience.total_skills_for_validate = totalSkillsForValidate
      if (this.allQuestionsAnswered) {
        this.$refs.btnNext.$el.click()
      }
    },
    onInitSelectSkills(totalSkillsForValidate) {
      this.experience.total_skills_for_validate = totalSkillsForValidate || 0
    },
    toggleConfirmModal() {
      this.confirmModalVisible = !this.confirmModalVisible
    },
    prepareExperienceData(isCompleted, skills) {
      return {
        user: this.$strapi.user.id,
        ...this.experience,
        completed: isCompleted,
        total_has_skills: this.experience.total_has_skills
          ? this.experience.total_has_skills + skills.length
          : skills.length,
      }
    },
    async saveNewSkillsValidated(experienceId, userId, skills, hasSkill) {
      const relatedType =
        this.type === EXP_TYPES.WORK_TYPE
          ? SKILL_MORPHS_CONSTANTS.ENTITY_TYPES.EXPERIENCE_WORKS
          : SKILL_MORPHS_CONSTANTS.ENTITY_TYPES.EXPERIENCE_EDUCATIONALS

      const formattedSkills = skills.map((skill) =>
        this.$repository.skillMorph.create({
          related_id: experienceId,
          related_type: relatedType,
          origin_type: this.originPossessedSkill,
          skill: skill.id || skill,
          user: userId,
          has_skill: hasSkill,
        })
      )
      return await Promise.all(formattedSkills)
    },
    async callSkillDataMethod() {
      const entity = this.$strapi[this.strapiExperienceEntity]
      const experienceData = this.prepareExperienceData(
        this.allQuestionsAnswered,
        this.possessedSkills
      )

      const experience = this.experience.id
        ? await entity.update(this.experience.id, experienceData)
        : await entity.create(experienceData)

      await Promise.all([
        this.saveNewSkillsValidated(
          experience.id,
          experience.user,
          this.possessedSkills,
          true
        ),
        this.saveNewSkillsValidated(
          experience.id,
          experience.user,
          this.notPossessedSkills,
          false
        ),
      ])
      return experience
    },
    sendSkillsData() {
      this.$store.commit('loading', true)
      const message = this.experience.id
        ? 'Experiencia actualizada.'
        : 'Experiencia creada.'
      this.callSkillDataMethod()
        .then((experience) => {
          this.$buefy.notification.open({
            message,
            type: 'is-success',
            autoclose: true,
            duration: 5000,
          })
          this.experience = experience
          this.possessedSkills = []
          this.notPossessedSkills = []

          if (this.activeAddFreeSkills()) {
            this.showAddedFreeSkill = true
          } else {
            this.nextAction?.call()
          }
        })
        .catch(() => {
          this.$buefy.notification.open({
            message:
              'Ha ocurrido un error inesperado, vuelva a intentarlo más tarde.',
            type: 'is-danger',
            autoclose: true,
            duration: 5000,
          })
        })
        .finally(() => {
          this.$store.commit('loading', false)
          this.confirmModalVisible = false
        })
    },
    activeAddFreeSkills() {
      return this.allQuestionsAnswered && !this.showAddedFreeSkill
    },
    handleAddFreeSkills(skills) {
      this.possessedSkills = skills
      this.originPossessedSkill = SKILL_MORPHS_CONSTANTS.ORIGIN_TYPES.FREE
    },
    navigateExperienceWork() {
      if (this.$route.path === `/experiencias/nuevas/laboral`) {
        this.$router.go()
      }
      this.$router.push(`/experiencias/nuevas/laboral`)
    },
    navigateExperienceEducational() {
      if (this.$route.path === `/experiencias/nuevas/educativa`) {
        this.$router.go()
      }
      this.$router.push(`/experiencias/nuevas/educativa`)
    },
    navigateExperienceOther() {
      if (this.$route.path === `/experiencias/nuevas/otras`) {
        this.$router.go()
      }
      this.$router.push(`/experiencias/nuevas/otras`)
    },
    async getFreeSkillsMiddleEducation() {
      const filters = {
        _where: {
          externalCode_contains: DEFAULT_FREE_SKILLS,
        },
      }
      return await this.$repository.skill.findAll(filters)
    },
  },
}
