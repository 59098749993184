//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Chip from '~/components/design-system/Chip.vue'

export default {
  components: {
    Chip,
  },
  props: {
    textInformation: {
      type: String,
      default: null,
    },
    skills: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    isActiveShowMore: false,
    MAX_SHOW_SKILLS: 15,
  }),
  computed: {
    isVisibleShowMoreOption() {
      return this.skills.length > this.MAX_SHOW_SKILLS
    },
    isVisibleContent() {
      return this.skills.length > 0
    },
    showMoreText() {
      return this.isActiveShowMore ? 'Ver menos' : 'Ver más'
    },
    showedSkills() {
      return this.isActiveShowMore
        ? this.skills
        : this.skills.slice(0, this.MAX_SHOW_SKILLS)
    },
    totalSkillsActive() {
      return this.skills.filter((skill) => skill.active).length
    },
    skillCounter() {
      return this.skills.length > 0
        ? `(${this.totalSkillsActive}/${this.skills.length})`
        : '(0)'
    },
  },
  methods: {
    handleShowMore() {
      this.isActiveShowMore = !this.isActiveShowMore
    },
    handleChangeChip({ id, status }) {
      this.$emit('changeSkillStatus', { id, status })
    },
  },
}
