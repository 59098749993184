//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ValidationObserver } from 'vee-validate'
import ModalWithTitleBar from '@/components/Shared/ModalWithTitleBar'
import Button from '~/components/Shared/Button'

export default {
  name: 'CustomOccupationModalForm',
  components: {
    ValidationObserver,
    ModalWithTitleBar,
    Button,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    titleModal: {
      type: String,
      default: '',
    },
    occupation: {
      type: Object,
      default: () => {},
    },
  },
  async fetch() {
    await this.getCompanies()
  },
  data() {
    return {
      description: null,
      status: null,
      title: null,
      companies: [],
      company: {},
    }
  },
  computed: {
    active() {
      return this.status === 'Publicada'
    },
    isEnableChangeCompany() {
      return this.occupation.active
    },
  },
  watch: {
    visible(val) {
      if (val) {
        this.description = this.occupation.description
        this.title = this.occupation.title
        this.status = this.occupation.active ? 'Publicada' : 'Sin Publicar'
      }
    },
    occupation(val) {
      this.company = val.active_companies[0]
    },
  },
  methods: {
    async getCompanies() {
      this.companies = await this.$repository.company.findAll({
        active: 1,
        company_type: 2,
        _sort: 'name',
        populate: ['id', 'name'],
      })
    },
    onToggle() {
      this.$emit('toggle')
    },
    onCancel() {
      this.$emit('cancel')
    },
    onDelete() {
      this.$emit('delete')
    },
    onSubmit() {
      this.$emit('submit', {
        id: this.occupation.id,
        description: this.description,
        active: this.active,
        title: this.title,
        active_companies: [this.company],
      })
    },
  },
}
