//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FormInput from '@/components/Form/FormInput.vue'
import ModalWithTitle from '@/components/Shared/ModalWithTitle'
import WorkersSkillsTable from '@/components/SkillsInventory/tables/WorkersSkillsTable'
import NoResultFinder from '@/components/SkillsInventory/Filters/NoResultFinder'
export default {
  components: { ModalWithTitle, FormInput, WorkersSkillsTable, NoResultFinder },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    close: {
      type: Function,
      default: () => {},
    },
    skill: {
      type: Object,
      default: () => ({ title: '', description: '', users: [] }),
    },
  },
  data: () => ({
    searchTerm: '',
    filteredWorkers: [],
    notResults: false,
  }),
  computed: {
    labelSearchWithTotal() {
      return this.$t('skillsInventory.skillDetail.labelSearch', [
        this.skill.users.length,
      ])
    },
  },
  methods: {
    handleNotResults(value) {
      this.notResults = value
    },
  },
}
