//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Button from '@/components/Shared/Button'
import ROLES from '@/constants/roles'

export default {
  components: { Button },
  props: {
    handleMassive: {
      default: () => {},
      type: Function,
    },
    errors: {
      default: () => [],
      type: Array,
    },
    roleType: {
      type: String,
      default: ROLES.EMPLOYEE,
    },
  },

  data() {
    return {
      file: null,
      message: null,
    }
  },
  computed: {
    hasFile() {
      return !!this.file
    },
  },
  watch: {
    errors(newVal, oldVal) {
      if (newVal.length > 0) {
        this.file = null
      }
    },
  },
  methods: {
    closeDialog() {
      this.$emit('close')
    },
    async preview() {
      const role = this.roleType
      const customMessage = this.message
      const companyName = this.$repository.me.getMe().company?.name
      const jobSeekerFair = this.$repository.me.getMe().job_seeker_fair?.name

      const response = await this.$repository.invitation.previewMail({
        role,
        customMessage,
        companyName,
        jobSeekerFair,
      })
      window.open(response.url, '_blank')
    },
  },
}
