//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    message: {
      type: String,
      default: null,
    },
    listDetails: {
      type: Array,
      default: null,
    },
  },
  computed: {
    details() {
      return (
        this.listDetail || [
          this.$t('components.searchEngine.empty.detail1'),
          this.$t('components.searchEngine.empty.detail2'),
          this.$t('components.searchEngine.empty.detail3'),
        ]
      )
    },
  },
}
