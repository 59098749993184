//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FormExperienceLaboral from '@/components/Experiences/FormExperienceLaboral.vue'
import Button from '@/components/Shared/Button.vue'
import { showErrorNotification } from '~/util/notifications'

export default {
  components: {
    FormExperienceLaboral,
    Button,
  },
  props: {
    defaultExperience: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    isValid: false,
    experience: {},
  }),
  computed: {
    isValidAll() {
      return (
        this.isValid &&
        (this.experience.occupation || this.experience.custom_occupation)
      )
    },
  },
  methods: {
    handleChangeValue(experience) {
      this.experience = experience
    },
    handleChangeForm(valid) {
      this.isValid = valid
    },
    handleBack() {
      this.$emit('back')
    },
    prepareExperience() {
      return {
        ...this.experience,
        user: this.$strapi.user.id,
        occupation: this.experience.occupation
          ? this.experience.occupation.id
          : null,
        business_area: this.experience.business_area.id,
        completed: false,
        total_skills_for_validate: 0,
        total_has_skills: 0,
      }
    },
    async saveExperience() {
      const experienceForSave = this.prepareExperience()
      const experience = experienceForSave.id
        ? await this.$repository.experienceWork.update(
            experienceForSave.id,
            experienceForSave
          )
        : await this.$repository.experienceWork.create(experienceForSave)
      return experience
    },
    async handleNext() {
      try {
        this.$store.commit('loading', true)
        const { id } = await this.saveExperience()
        const experience = await this.$repository.experienceWork.findOne(id, {
          populate: ['business_area', 'occupation'],
        })
        experience.start_date_work = new Date(experience.start_date_work)
        experience.end_date_work = experience.end_date_work
          ? new Date(experience.end_date_work)
          : null
        this.$emit('next', experience)
      } catch (error) {
        showErrorNotification(this)
      } finally {
        this.$store.commit('loading', false)
      }
    },
  },
}
