//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ValidationProvider } from 'vee-validate'
import FormLabel from '@/components/Form/FormLabel'

export default {
  name: 'FormGroup',
  components: {
    ValidationProvider,
    FormLabel,
  },
  props: {
    vid: {
      type: String,
      default: '',
    },
    rules: {
      type: [Object, String],
      default: '',
    },
    data: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Array,
      default: () => [],
    },
    labelField: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      innerValue: '',
      openOnFocus: true,
    }
  },
  watch: {
    value(newVal) {
      this.innerValue = newVal
    },
  },
  created() {
    if (this.value) {
      this.innerValue = this.value
    }
  },
  methods: {
    onInput(value) {
      this.$emit('input', value)
    },
    beforeAdding(tag) {
      return !this.innerValue.some((value) => value.id === tag.id)
    },
  },
}
