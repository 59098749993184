//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AddExperienceButton from '@/components/Experiences/AddExperienceButton'

export default {
  components: {
    AddExperienceButton,
  },
  methods: {
    navigateExperienceWork() {
      if (this.$route.path === `/experiencias/nuevas/laboral`) {
        this.$router.go()
      }
      this.$router.push(`/experiencias/nuevas/laboral`)
    },
    navigateExperienceEducational() {
      if (this.$route.path === `/experiencias/nuevas/educativa`) {
        this.$router.go()
      }
      this.$router.push(`/experiencias/nuevas/educativa`)
    },
    navigateExperienceOther() {
      if (this.$route.path === `/experiencias/nuevas/otras`) {
        this.$router.go()
      }
      this.$router.push(`/experiencias/nuevas/otras`)
    },
  },
}
