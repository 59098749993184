//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CloseCircle from 'vue-material-design-icons/CloseCircle'
export default {
  components: {
    CloseCircle,
  },
  props: {
    canClose: {
      type: Boolean,
      default: true,
    },
    close: {
      default: () => {},
      type: Function,
    },
    title: {
      default: 'title',
      type: String,
    },
    centeredFooter: {
      default: false,
      type: Boolean,
    },
    useCardBody: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    hasFooter() {
      return this.$slots.footer
    },
  },
}
