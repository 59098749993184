//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Button from '@/components/Shared/Button.vue'
import DropdownItem from './DropdownItem.vue'
export default {
  components: {
    Button,
    DropdownItem,
  },
  props: {
    type: {
      type: String,
      default: 'is-primary',
    },
    position: {
      type: String,
      default: 'is-bottom-left',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: undefined,
    },
    text: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    handleClick(key) {
      this.$emit('click', key)
    },
  },
}
