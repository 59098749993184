import EducationalCareer from '@/repositories/educational-career'
import Company from '@/repositories/company'
import CompanyOccupation from '@/repositories/company-occupation'
import CompanyType from '@/repositories/company-types'
import EducationalArea from '@/repositories/educational_area'
import ExperienceEducational from '@/repositories/experience-educationals'
import ExperienceWork from '@/repositories/experience-works'
import Invitation from '@/repositories/invitation'
import Me from '@/repositories/me'
import MeetingRequest from '@/repositories/meeting_request'
import Occupation from '@/repositories/occupation'
import Role from '@/repositories/role'
import Security from '@/repositories/security'
import User from '@/repositories/user'
import Location from '@/repositories/location'
import CompanyConfigs from '@/repositories/company-configs'
import EducationalType from '@/repositories/educational-type'
import EducationalStatuses from '@/repositories/educational-statuses'
import BusinessArea from '@/repositories/business-area'
import JobSeekerFair from '@/repositories/job-seeker-fair'
import JobAnnouncement from '@/repositories/job-announcement'
import ContentManager from '@/repositories/content-manager'
import Address from '@/repositories/address'
import Slider from '@/repositories/slider'
import Skill from '@/repositories/skill'
import ExperienceOther from '@/repositories/experience-others'
import HistoricCustomization from '@/repositories/historic_customization'
import SkillsGroup from '@/repositories/skills-group'
import HierarchySkill from '@/repositories/hierarchy_skills'
import SkillMorph from '@/repositories/skill-morph'
import Suggestion from '@/repositories/suggestion'
import Specialty from '@/repositories/specialty'
import Project from '@/repositories/project'
import ProjectOriginEntities from '@/repositories/project-origin-entities'
import Questionary from '@/repositories/questionary'
import Match from '@/repositories/match'
import SkillSet from '@/repositories/skill-sets'
import ModelQuestionary from '@/repositories/model-questionary'
import Cache from './cache'
import SkillPacks from './skill-packs'
import Permission from './permission'

export default (ctx) => {
  const user = new User(ctx)
  const occupation = new Occupation(ctx)
  const companyOccupation = new CompanyOccupation(ctx)
  const educationalCareer = new EducationalCareer(ctx)
  const educationalArea = new EducationalArea(ctx)
  const security = new Security(ctx)
  const me = new Me(ctx)
  const meetingRequest = new MeetingRequest(ctx)
  const company = new Company(ctx)
  const experienceWork = new ExperienceWork(ctx)
  const experienceEducational = new ExperienceEducational(ctx)
  const invitation = new Invitation(ctx)
  const companyType = new CompanyType(ctx)
  const role = new Role(ctx)
  const companyConfigs = new CompanyConfigs(ctx)
  const location = new Location(ctx)
  const educationalType = new EducationalType(ctx)
  const educationalStatuses = new EducationalStatuses(ctx)
  const businessArea = new BusinessArea(ctx)
  const jobSeekerFair = new JobSeekerFair(ctx)
  const jobAnnouncement = new JobAnnouncement(ctx)
  const contentManager = new ContentManager(ctx)
  const address = new Address(ctx)
  const slider = new Slider(ctx)
  const skill = new Skill(ctx)
  const experienceOther = new ExperienceOther(ctx)
  const historicCustomization = new HistoricCustomization(ctx)
  const hierarchySkill = new HierarchySkill(ctx)
  const skillsGroup = new SkillsGroup(ctx)
  const skillMorph = new SkillMorph(ctx)
  const suggestion = new Suggestion(ctx)
  const specialty = new Specialty(ctx)
  const project = new Project(ctx)
  const projectOriginEntities = new ProjectOriginEntities(ctx)
  const questionary = new Questionary(ctx)
  const match = new Match(ctx)
  const skillSet = new SkillSet(ctx)
  const cache = new Cache(ctx)
  const skillPacks = new SkillPacks(ctx)
  const modelQuestionary = new ModelQuestionary(ctx)
  const permission = new Permission(ctx)

  return {
    jobSeekerFair,
    educationalType,
    user,
    occupation,
    educationalCareer,
    educationalArea,
    company,
    security,
    businessArea,
    me,
    educationalStatuses,
    meetingRequest,
    companyOccupation,
    experienceWork,
    experienceEducational,
    invitation,
    companyType,
    role,
    companyConfigs,
    location,
    jobAnnouncement,
    contentManager,
    address,
    slider,
    skill,
    experienceOther,
    historicCustomization,
    hierarchySkill,
    skillsGroup,
    skillMorph,
    suggestion,
    specialty,
    permission,
    project,
    projectOriginEntities,
    questionary,
    match,
    skillSet,
    cache,
    skillPacks,
    modelQuestionary,
  }
}
