//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import InvitationSelectionButton from './InvitationSelectionButton.vue'

export default {
  components: {
    InvitationSelectionButton,
  },
  props: {
    massiveButtonText: {
      default: 'massiveButtonText',
      type: String,
    },
    individualButtonText: {
      default: 'individualButtonText',
      type: String,
    },
    topButtonsText: {
      default: 'topButtonsText',
      type: String,
    },
    individualClick: {
      default: () => {},
      type: Function,
    },
    massiveClick: {
      default: () => {},
      type: Function,
    },
  },
}
