//
//
//
//
//
//

import NotFound from '@/components/ErrorPage/NotFound'
export default {
  components: { NotFound },
  props: {
    error: {
      type: Object,
      default: () => {},
    },
  },
  layout: 'error',
}
