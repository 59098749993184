const Request = require('@/repositories/request')
const qs = require('qs')

class HierarchySkills extends Request {
  /**
   *
   * @param {Object} params
   * @return {*}
   */
  findAll(params) {
    const stringifyParams = qs.stringify({ ...params }, { encode: false })
    return this.$strapi['$hierarchy-skills'].find(stringifyParams)
  }
}

module.exports = HierarchySkills
