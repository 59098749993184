//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Button from '@/components/Shared/Button'
import TextPolicyData from '@/components/PolicyData/TextPolicyData'
import ModalWithTitleBar from '~/components/Shared/ModalWithTitleBar.vue'
import IconAuthorizePolicyData from '~/assets/svg/icon-autorize-policy-data.svg'
export default {
  name: 'ModalAuthorizePolicyData',
  components: {
    ModalWithTitleBar,
    Button,
    IconAuthorizePolicyData,
    TextPolicyData,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toggleModal() {
      this.$emit('toggle')
    },
    authorize() {
      this.$emit('authorize')
    },
  },
}
