//
//
//
//
//
//
//
//
//

import Hero from '@/components/Shared/Hero'
import CardComponent from '@/components/CardComponent'
import LoginForm from '@/components/Login/LoginForm'
export default {
  middleware: 'maint',
  name: 'Login',
  components: {
    CardComponent,
    LoginForm,
    Hero,
  },
  beforeCreate() {
    this.$router.push('/login/persona')
  },
}
