const Request = require('@/repositories/request')
const qs = require('qs')

class ExperienceEducational extends Request {
  /**
   *
   * @param {Object} params
   * @return {*}
   */
  find(params) {
    const stringifyParams = qs.stringify({ ...params }, { encode: false })
    return this.$strapi['$experience-educationals'].find(stringifyParams)
  }

  /**
   *
   * @param {int} id
   * @returns {*}
   */
  findOne(id, queryObject) {
    const query = qs.stringify(queryObject, { encode: false })
    return this.$strapi.$http.$get(`/experience-educationals/${id}?${query}`)
  }

  /**
 *
 * @param {{_where: Object,
    _limit: int,
    _start: int}} params
 * @returns {*}
 */
  count(params) {
    const stringifyParams = qs.stringify({ ...params })
    return this.$strapi.count('experience-educationals', stringifyParams)
  }

  /**
   *
   * @param params
   * @returns {*}
   */
  create(params) {
    return this.$strapi['$experience-educationals'].create(params)
  }

  /**
   * @param {int} id
   * @param {Object} data
   * @returns {*}
   */
  update(id, data) {
    return this.$strapi['$experience-educationals'].update(id, data)
  }

  updateTotalHasSkills(id, data) {
    this.$strapi.$http.$put(
      `/experience-educationals/update-total-skills/${id}`,
      data
    )
  }

  /**
   * @param {int} id
   * @returns {*}
   */
  async delete(id) {
    return await this.$strapi['$experience-educationals'].delete(id)
  }
}

module.exports = ExperienceEducational
